import styled, { css, useTheme } from 'styled-components'

import {
  Card,
  Chains,
  InfoBlock,
  InfoBlockProps,
  InfoRows,
  InfoRow,
  mediaQuery,
  A,
} from '@packages/ui'

import { Vault } from '../../interfaces'

export interface InfoItem {
  title?: string | React.ReactNode
  summary?: React.ReactNode
  content: React.ReactNode[]
  style?: 'number' | 'bullet' | 'none'
}

export interface Metric extends InfoBlockProps {
  content?: React.ReactNode
  mobileContent?: React.ReactNode
  hideOnMobile?: boolean
}

export interface LearnLink {
  linkText: string
  url: string
}

interface Props {
  vault: Vault
  contractAddress: string
  icon: React.ReactNode
  metrics: Metric[]
  learnLinks: LearnLink[]
  about: InfoItem[]
  instructions: InfoItem[]
}

export function VaultOverview({
  vault,
  contractAddress,
  icon,
  metrics,
  learnLinks,
  about,
  instructions,
}: Props) {
  const theme = useTheme()
  const iconFillColor = theme.colors.icons
  const { url } = Chains.useBlockExplorerBaseUrl()
  const ExplorerIcon = Chains.useBlockExplorerIcon()

  return (
    <VaultOverviewCard data-test-id='vault-overview'>
      <Section>
        <Header>
          <span>Overview</span>
          <a href={`${url}address/${contractAddress}`} target='_blank' rel='noopener noreferrer'>
            <ExplorerIcon width={19} height={19} fill={iconFillColor} />
          </a>
        </Header>
        <VaultDetails>
          {icon}
          <Metrics>
            {metrics.map((metric) => {
              if (metric.content) {
                return metric.content
              }
              return (
                <InfoBlockWrapper
                  key={metric.header}
                  header={metric.header}
                  value={metric.value}
                  activatedHeader={metric.activatedHeader}
                  activatedValue={metric.activatedValue}
                  infoTooltip={metric.infoTooltip}
                />
              )
            })}
          </Metrics>
          <InfoRowsMobileWrapper>
            {metrics.map((metric) => {
              if (!metric.hideOnMobile) {
                if (metric.mobileContent) {
                  return metric.mobileContent
                }
                return (
                  <InfoRowMobileWrapper
                    key={'mobile' + metric.header}
                    header={metric.header}
                    value={metric.value}
                  />
                )
              }
            })}
          </InfoRowsMobileWrapper>
        </VaultDetails>
      </Section>

      <Section>
        <Header>About</Header>
        <SectionWithBackground>
          {about.map((item, index) => {
            return (
              <Instructions key={'instructions' + index}>
                {item.title && <InstructionTitle>{item.title}</InstructionTitle>}
                {item.summary && <InstructionSummary>{item.summary}</InstructionSummary>}
                {item.content.length > 0 && <InfoItemDisplay infoItem={item} index={index} />}
              </Instructions>
            )
          })}
        </SectionWithBackground>
      </Section>

      <Section>
        <Header>Vault Mechanics</Header>
        <SectionWithBackground>
          {instructions.map((item, index) => {
            return (
              <Instructions key={'instructions' + index}>
                {item.title && <InstructionTitle>{item.title}</InstructionTitle>}
                {item.summary && <InstructionSummary>{item.summary}</InstructionSummary>}
                {item.content.length > 0 && <InfoItemDisplay infoItem={item} index={index} />}
              </Instructions>
            )
          })}
        </SectionWithBackground>
      </Section>

      {learnLinks.length > 0 && (
        <Section>
          <Header>Learn</Header>
          <SectionWithBackground>
            <LearnList>
              {learnLinks.map((link, index) => {
                return (
                  <LearnItem key={index}>
                    <A href={link.url}>{link.linkText}</A>
                  </LearnItem>
                )
              })}
            </LearnList>
          </SectionWithBackground>
        </Section>
      )}
    </VaultOverviewCard>
  )
}

interface InfoItemsProps {
  infoItem: InfoItem
  index?: number
}

function InfoItemDisplay({ infoItem, index }: InfoItemsProps) {
  const formattedContent = infoItem.content.map((info, infoIndex) => {
    return <InstructionListItem key={'instruction' + index + infoIndex}>{info}</InstructionListItem>
  })

  if (infoItem.style === 'bullet') {
    return <InstructionsUnorderedList>{formattedContent}</InstructionsUnorderedList>
  } else if (infoItem.style === 'number') {
    return <InstructionsOrderedList>{formattedContent}</InstructionsOrderedList>
  } else {
    return (
      <InstructionsList>
        {infoItem.content.map((info, infoIndex) => {
          return <div key={'instruction' + index + infoIndex}>{info}</div>
        })}
      </InstructionsList>
    )
  }
}

const VaultOverviewCard = styled(Card)`
  min-height: 300px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  ${mediaQuery('small')} {
    gap: 20px;
  }
`

const Section = styled.div``

const SectionWithBackground = styled(Section)`
  padding: 20px;
  background-color: ${(props) => props.theme.colors.backgrounds.primary};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`

const Header = styled.h1`
  font-weight: 600;
  font-size: 20px;
  color: ${(props) => props.theme.colors.text.primary};
  padding: 0;
  margin: 0;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
`

const VaultDetails = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Metrics = styled.div`
  flex: 1;
  display: flex;
  margin-left: 10px;
  flex-wrap: wrap;
  row-gap: 10px;
  ${mediaQuery('small')} {
    display: none;
  }
`

const InfoBlockWrapper = styled(InfoBlock)`
  width: 33%;
  div > span:nth-child(2) {
    font-size: 15px;
  }
  div {
    height: 15px;
  }
`

const InfoRowsMobileWrapper = styled(InfoRows)`
  padding-left: 15px;
  width: 100%;
  display: none;
  ${mediaQuery('small')} {
    display: flex;
  }
`

const InfoRowMobileWrapper = styled(InfoRow)`
  font-size: 14px;
  div:nth-child(2) {
    font-size: 15px;
    padding-left: 5px;
  }
`

const Instructions = styled.div`
  color: ${(props) => props.theme.colors.text.secondary};
  font-weight: 400;
  font-size: 14px;
`

const InstructionTitle = styled.div`
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.text.primary};
`

const InstructionSummary = styled.div`
  padding-bottom: 10px;
`

const InstructionsListStyles = css`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

const InstructionsOrderedList = styled.ol`
  padding: 0px 15px;
  margin: 10px 0px;
  ${InstructionsListStyles}
`

const InstructionsUnorderedList = styled.ul`
  padding: 0px 15px;
  margin: 10px 0px;
  ${InstructionsListStyles}
`

const InstructionsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

const InstructionListItem = styled.li``

const LearnList = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

const LearnItem = styled.li`
  color: ${(props) => props.theme.colors.semantic.link};
  font-weight: 400;
  font-size: 14px;
`

import styled from 'styled-components'

import { CSS_TRANSITION, StylingProps } from '../../styles'
import { formatDataTestId } from '../../utils'

interface Props<T extends string> extends StylingProps {
  handleOnChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
  options: T[]
  selectedOption: T
  displayText?: string
}

export function SmallSelect<T extends string>({
  handleOnChange,
  options,
  selectedOption,
  displayText,
  className,
}: Props<T>) {
  const _options = Object.values(options).map((option) => (
    <Option key={option} value={option}>
      {option}
    </Option>
  ))

  return (
    <SelectWrap
      className={className}
      data-test-id={'small-select' + formatDataTestId(displayText ?? '')}
    >
      <SelectElement onChange={handleOnChange} value={selectedOption}>
        {_options}
      </SelectElement>
      <SelectValue data-test-id='select-value'>
        {selectedOption}
        {displayText && <DisplayText>{displayText}</DisplayText>}
      </SelectValue>
    </SelectWrap>
  )
}

const SelectWrap = styled.div`
  position: relative;
`

const SelectElement = styled.select`
  ${CSS_TRANSITION}
  font-weight: 400;
  font-size: 13px;
  font-family: 'Lexend', sans-serif;
  width: 100px;
  height: 31px;
  border: none;
  border-radius: 8px;
  padding: 6px;
  color: transparent;
  padding: 10px;
  appearance: none;
  cursor: pointer;
  border: none;
  background: ${(props) => props.theme.colors.backgrounds.tertiary}
    url("data:image/svg+xml;utf8,<svg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M9 1L5 5L1 1' stroke='${(
      props
    ) => props.theme.colors.text.secondary.replace('#', '%23')}' stroke-width='1'/></svg>")
    no-repeat;
  background-position: right 10px top 50%;
  option {
    color: transparent;
    font-family: 'Lexend', sans-serif;
    background-color: ${(props) => props.theme.colors.backgrounds.tertiary};
  }
`

const Option = styled.option`
  border: none;
  color: black;
`

const SelectValue = styled.span`
  color: ${(props) => props.theme.colors.text.secondary};
  font-family: 'Lexend', sans-serif;
  font-size: 13px;
  position: absolute;
  pointer-events: none;
  left: 0;
  padding: 6px;
  padding-left: 15px;
`

const DisplayText = styled.span`
  padding-left: 5px;
`

import styled from 'styled-components'

import { hexToRgbString, CSS_TRANSITION } from '../../styles'

export const SmallButton = styled.button<{ disabled?: boolean }>`
  ${CSS_TRANSITION}
  background-color: ${(props) => props.theme.colors.backgrounds.tertiary};
  cursor: not-allowed;
  pointer-events: none;
  width: 73px;
  height: 35px;
  border: none;
  border-radius: 8px;
  color: rgba(46, 48, 52, 0.6); /* Required for Firefox */
  color: ${(props) => hexToRgbString(props.theme.colors.text.primary, 0.6)};
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  font-weight: 500;
  &:hover {
    opacity: 0.8;
  }

  ${(props) =>
    !props.disabled &&
    ` 
      color: ${props.theme.colors.text.button};
      cursor: pointer;
      pointer-events: auto;
      &:hover ::before {
        opacity: 1;
      }
      &:active {
        transform: scale(0.97);
      }
    `}
`

import { useMemo, useState } from 'react'
import styled, { css } from 'styled-components'

import { SAFFRON_RED_HEX } from '../../styles'
import { formatDataTestId } from '../../utils'

interface TabData {
  id: number
  title: string
  content: React.ReactNode
}

function Tab({ tab }: { tab?: TabData }) {
  return <TabContent>{tab?.content}</TabContent>
}

const TabContent = styled.div`
  font-family: inherit;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
`

interface NavigationProps {
  tabs: TabData[]
  activeTabId: number
  onNavClick: (id: number) => void
}

function Navigation({ activeTabId, tabs, onNavClick }: NavigationProps) {
  return (
    <TabsNav>
      {tabs.map((item) => (
        <TabsItem key={item.id} data-test-id={'tab' + formatDataTestId(item.title)}>
          <TabsButton active={activeTabId === item.id} onClick={() => onNavClick(item.id)}>
            {item.title}
          </TabsButton>
        </TabsItem>
      ))}
    </TabsNav>
  )
}

const TabsNav = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  margin-block-start: 0;
  padding-inline-start: 0;
  justify-content: space-between;
`

const TabsItem = styled.li`
  width: 100%;
  text-align: center;
`

const TabsButton = styled.button<{ active: boolean }>`
  width: 100%;
  background: rgba(0, 0, 0, 0);
  box-shadow: none;
  outline: none;
  border: none;
  cursor: pointer;
  display: block;
  position: relative;
  font-weight: 600;
  font-size: 18px;
  color: ${(props) => props.theme.colors.text.secondary};

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: -20px;
    width: 100%;
    height: 1px;
    background: ${(props) => props.theme.colors.text.secondary};
    transition: visibility 0s, opacity 0.4s ease-in-out;
  }

  ${(props) =>
    props.active &&
    css`
      color: ${SAFFRON_RED_HEX};
      &:after {
        height: 1.5px;
        background: ${SAFFRON_RED_HEX};

        transition: visibility 0s, opacity 0.4s ease-in-out;
      }
    `}
`

interface TabProps {
  tabs: TabData[]
}

export function Tabs({ tabs }: TabProps) {
  const [activeTabId, setActiveTab] = useState(tabs[0].id)

  const activeTab = useMemo(() => tabs.find((tab) => tab.id === activeTabId), [activeTabId, tabs])

  return (
    <div>
      <Navigation tabs={tabs} onNavClick={setActiveTab} activeTabId={activeTabId} />
      <Tab tab={activeTab} />
    </div>
  )
}

import styled, { css } from 'styled-components'

import { CSS_TRANSITION, hexToRgbString } from '../styles'

export const NavBarItem = styled.div<{ middle?: boolean; walletbg?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.backgrounds.secondary};
  width: 43px;
  height: 32px;
  border-radius: 10px;
  color: ${(props) => props.theme.colors.text.primary};
  cursor: pointer;
  ${CSS_TRANSITION}
  &:hover {
    background-color: ${(props) => hexToRgbString(props.theme.colors.backgrounds.secondary, 0.6)};
  }
  ${(props) =>
    props.walletbg &&
    css`
      width: 150px;
    `}
`

import { ContractTransaction } from 'ethers'

import { useWeb3ReactPlus, CHAIN_ID, CHAIN_INFO } from '@packages/web3-react-plus'

export function useBlockExplorerBaseUrl() {
  const { chainId } = useWeb3ReactPlus()

  const chainInfo = CHAIN_INFO[chainId as CHAIN_ID]

  if (!chainInfo) {
    return CHAIN_INFO[1].explorer
  }
  return chainInfo.explorer
}

interface Props {
  tx?: ContractTransaction
}

export function useBlockExplorerTxUrl({ tx }: Props) {
  const { url, name } = useBlockExplorerBaseUrl()

  return { url: getBlockExplorerTxUrl({ blockExplorerUrl: url, txHash: tx?.hash ?? '' }), name }
}

export function getBlockExplorerTxUrl({
  blockExplorerUrl,
  txHash,
}: {
  txHash: string
  blockExplorerUrl: string
}) {
  return `${blockExplorerUrl}tx/${txHash}`
}

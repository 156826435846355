import styled, { css } from 'styled-components'

import { mediaQuery, LoadingShimmerStyles } from '@packages/ui'

import { PageHeader } from 'src/pages'

import { VaultInfo } from '../../interfaces'
import { useUniV3Vault } from '../../hooks'
import { getUniV3VaultTitle } from '../../utils'
import { VAULT_STATUS } from '../../constants'
import { UniV3VaultOverview } from './UniV3VaultOverview'
import { UniV3VaultForm } from './forms'
import { getStatusColor, VaultStatusBlock } from '../VaultCardInfo'

interface Props {
  vaultInfo: VaultInfo
}

export function UniswapVaultDetails({ vaultInfo }: Props) {
  const { vault, adapter } = useUniV3Vault(vaultInfo)

  if (!vault || !vault?.status || !adapter) {
    return (
      <Container loading>
        <PageHeader subheader='' />

        <Content>
          <Left></Left>
          <Right></Right>
        </Content>
      </Container>
    )
  }

  return (
    <Container loading={false}>
      <PageHeader
        subheader={`${getUniV3VaultTitle(adapter)} (Uniswap v3)`}
        rightContent={
          <VaultStatus>
            <StatusTitle>Status: </StatusTitle>
            <StatusValue status={vault.status}>{vault.status}</StatusValue>
            <VaultStatusBlockWrapper vault={vault} tooltipPosition='left' />
          </VaultStatus>
        }
      />

      <Content>
        <Left>
          <UniV3VaultOverview vault={vault} adapter={adapter} />
        </Left>

        <Right>
          <UniV3VaultForm vault={vault} adapter={adapter} />
        </Right>
      </Content>
    </Container>
  )
}

const Container = styled.div<{ loading: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 30px;

  ${(props) =>
    props.loading &&
    css`
      ${LoadingShimmerStyles}
    `}
`

const VaultStatus = styled.div`
  font-size: 16px;
  color: ${(props) => props.theme.colors.text.secondary};
  display: flex;
  align-items: center;
  gap: 5px;
`

const StatusTitle = styled.div`
  ${mediaQuery('small')} {
    display: none;
  }
`

const StatusValue = styled.div<{ status: VAULT_STATUS }>`
  font-weight: 600;
  color: ${(props) => getStatusColor(props)};
  ${mediaQuery('small')} {
    display: none;
  }
`

const VaultStatusBlockWrapper = styled(VaultStatusBlock)`
  float: right;
  align-self: center;
  ${mediaQuery('small')} {
    padding-top: 20px;
    padding-left: 5px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  ${mediaQuery('small')} {
    flex-direction: column;
  }
`

const Left = styled.div`
  flex: 5;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
`

const Right = styled.div`
  flex: 3;
  width: 100%;
`

import { BigNumber, Contract } from 'ethers'
import { Web3Provider } from '@ethersproject/providers'

import { ChainInfo } from '@packages/web3-react-plus'
import WETHAbi from '@packages/web3-react-plus/src/assets/abis/WETH9.json'

export async function wrapNativeCurrency({
  amount,
  chainInfo,
  web3Provider,
  setLoadingText,
  smallerLoadingText = false,
}: {
  amount: BigNumber
  chainInfo: ChainInfo
  web3Provider?: Web3Provider
  setLoadingText?: (text: string) => void
  smallerLoadingText?: boolean
}) {
  if (!web3Provider) return

  if (setLoadingText) {
    setLoadingText(
      smallerLoadingText ? 'Wrapping...' : `Wrapping ${chainInfo.nativeCurrency.symbol}...`
    )
  }
  const contract = new Contract(
    chainInfo.wrappedNativeToken.address,
    WETHAbi,
    web3Provider.getSigner()
  )
  const depositTx = await contract.functions.deposit({ value: amount })
  await depositTx.wait()
  return depositTx
}

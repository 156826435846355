import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { FortmaticConnector } from '@web3-react/fortmatic-connector'

import { DEFAULT_CHAIN_ID, FORTMATIC_API_KEY, TEST_ENVIRONMENT } from '../config'
import { RPC_URLS, CHAIN_ID } from '../chains'
import { NetworkConnector } from './NetworkConnector'

export const walletconnect = new WalletConnectConnector({
  rpc: RPC_URLS,
  chainId: DEFAULT_CHAIN_ID,
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
})

// coinbase wallet
export const walletlink = new WalletLinkConnector({
  url: RPC_URLS[1],
  appName: 'saffron.finance',
  supportedChainIds: [CHAIN_ID.Ethereum, CHAIN_ID.Arbitrum, CHAIN_ID.Polygon],
})

// infura RPC
export const networkConnector = new NetworkConnector({
  urls: RPC_URLS,
  defaultChainId: DEFAULT_CHAIN_ID,
})

export const injected = new InjectedConnector({})

export const fortmatic =
  TEST_ENVIRONMENT || DEFAULT_CHAIN_ID !== CHAIN_ID.Ethereum
    ? undefined // Don't add Fortmatic in test environments it does not support them
    : new FortmaticConnector({
        apiKey: FORTMATIC_API_KEY,
        chainId: DEFAULT_CHAIN_ID,
      })

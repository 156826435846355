import styled, { DefaultTheme, ThemedStyledProps } from 'styled-components'
import { useState } from 'react'

import {
  getBpsPercent,
  InfoBlock,
  InfoRow,
  StylingProps,
  Tokens,
  Tooltip,
  TOOLTIP_POSITION,
} from '@packages/ui'
import { useBlock } from '@packages/web3-react-plus'

const MAX_DISPLAY_DECIMALS = 10
const MAX_SIGNIFICANT_DIGITS = 8

import { UniV3Adapter, Vault } from '../interfaces'
import {
  formatEndTime,
  formatVariableCapacity,
  formatTimeRemaining,
  formatDuration,
} from '../utils'
import { UNI_V3_VAULT_DESCRIPTIONS, VAULT_STATUS } from '../constants'

const EquationContainer = styled.div`
  font-family: monospace;
  font-size: 12px !important;
  height: auto !important; /* Required for Firefox */
`

const Item = styled.div`
  font-size: 12px;
  height: auto !important; /* Required for Firefox */
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`

const Title = styled.span`
  font-weight: 600;
`

export function FixedAprTooltip({ vault, adapter }: { vault: Vault; adapter: UniV3Adapter }) {
  return (
    <FixedAprWrapper>
      <Item>
        <Title>Fixed Side APR: </Title>
        <EquationContainer>
          {UNI_V3_VAULT_DESCRIPTIONS.fixedSide.calculations.apr.formula}
        </EquationContainer>
      </Item>
      <Item>
        <EquationContainer>
          fixedRate: {UNI_V3_VAULT_DESCRIPTIONS.fixedSide.calculations.fixedRate.formula}
        </EquationContainer>
      </Item>
    </FixedAprWrapper>
  )
}

const FixedAprWrapper = styled.span`
  width: 200px;
`

export function VariablePnLTooltip({ adapter, vault }: { adapter: UniV3Adapter; vault: Vault }) {
  const feePercent = getBpsPercent(vault.feeBps)

  return (
    <VariableAprWrapper>
      {vault.status !== VAULT_STATUS.NOT_STARTED && (
        <>
          <Item>
            <Title>
              {adapter.pool.token0.symbol} Earnings:{' '}
              {((vault.rates?.variable?.token0Earnings ?? 0) * (1 - feePercent)).toLocaleString(
                undefined,
                {
                  maximumSignificantDigits: MAX_SIGNIFICANT_DIGITS,
                  maximumFractionDigits: MAX_DISPLAY_DECIMALS,
                }
              )}
            </Title>
          </Item>
          <Item>
            <Title>
              {adapter.pool.token1.symbol} Earnings:{' '}
              {((vault.rates?.variable?.token1Earnings ?? 0) * (1 - feePercent)).toLocaleString(
                undefined,
                {
                  maximumSignificantDigits: MAX_SIGNIFICANT_DIGITS,
                  maximumFractionDigits: MAX_DISPLAY_DECIMALS,
                }
              )}
            </Title>
          </Item>
        </>
      )}

      <Item>
        <Title>Variable Side PnL: </Title>
        <EquationContainer>
          {UNI_V3_VAULT_DESCRIPTIONS.variableSide.calculations.pnl.formula}
        </EquationContainer>
      </Item>
    </VariableAprWrapper>
  )
}

const VariableAprWrapper = styled.span`
  width: 200px;
`

export function FixedCapacityTooltip({ vault }: { vault: Vault }) {
  return (
    <FixedCapacityWrapper>
      {UNI_V3_VAULT_DESCRIPTIONS.fixedSide.calculations.capacity.summary(
        vault.fixedSideCapacity?.toString()
      )}
    </FixedCapacityWrapper>
  )
}

const FixedCapacityWrapper = styled.span`
  width: 200px;
`

interface VariableCapacityValueProps extends StylingProps {
  vault: Vault
}

export function VariableCapacityValue({ vault, className }: VariableCapacityValueProps) {
  return (
    <VariableCapacity className={className}>
      <Tokens.TokenIcon size={15} tokenSymbol0={vault.variableAsset.symbol} />
      {formatVariableCapacity(vault)}
    </VariableCapacity>
  )
}

const VariableCapacity = styled.span`
  display: flex;
  align-items: center;
  gap: 5px;
`

interface VaultStatusBlockProps extends StylingProps {
  vault: Vault
  tooltipPosition?: TOOLTIP_POSITION
}

export function VaultStatusBlock({
  vault,
  tooltipPosition = 'right',
  className,
}: VaultStatusBlockProps) {
  return (
    <Container className={className}>
      <TooltipWrapper
        content={<StatusToolTipContent>Status: {vault.status}</StatusToolTipContent>}
        position={tooltipPosition}
      >
        <StatusCircle status={vault.status} />
      </TooltipWrapper>
    </Container>
  )
}

const Container = styled.div`
  padding-top: 5px;
`

const StatusCircle = styled.div<{ status: VAULT_STATUS }>`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-block;

  background-color: ${(props) => getStatusColor(props)};
`

const TooltipWrapper = styled(Tooltip)`
  span {
    top: 24%;
  }
`

const StatusToolTipContent = styled.span`
  width: 120px;
  color: ${(props) => props.theme.colors.text.primary};
`

interface VaultStatusInfoProps extends StylingProps {
  vault: Vault
  header: string
  value: string | React.ReactNode
}

export function VaultStatusInfoBlock({ vault, header, value, className }: VaultStatusInfoProps) {
  return (
    <InfoBlockStatus status={vault.status} header={header} value={value} className={className} />
  )
}

const InfoBlockStatus = styled(InfoBlock)<{ status: VAULT_STATUS }>`
  span:nth-child(2) {
    color: ${(props) => getStatusColor(props)};
  }
`

export function VaultStatusInfoRow({ status }: { status: VAULT_STATUS }) {
  return <InfoRowWrapper key={'mobile-status'} header='Status' value={status} status={status} />
}

const InfoRowWrapper = styled(InfoRow)<{ status: VAULT_STATUS }>`
  div:nth-child(2) {
    color: ${(props) => getStatusColor(props)};
    font-size: 15px;
  }
`

export function getStatusColor(
  props: ThemedStyledProps<
    {
      status: VAULT_STATUS
    },
    DefaultTheme
  >
) {
  return props.status === VAULT_STATUS.NOT_STARTED
    ? props.theme.colors.semantic.valid
    : props.status === VAULT_STATUS.STARTED
    ? '#e47e01'
    : props.theme.colors.semantic.invalid
}

interface VaultTimeInfoProps extends StylingProps {
  vault: Vault
  hideOnMediumWidth?: boolean
}

export function getVaultTimeData({
  vault,
  blockTimestamp,
  active = false,
}: {
  vault: Vault
  blockTimestamp?: number
  active?: boolean
}) {
  let value = ''
  let header = ''

  switch (vault.status) {
    case VAULT_STATUS.NOT_STARTED:
      header = 'Duration'
      value = formatDuration(vault.duration)
      break
    case VAULT_STATUS.STARTED:
      if (active) {
        header = 'End Time'
        value = formatEndTime(vault.endTime)
      } else {
        header = 'Time Remaining'
        value = formatTimeRemaining(blockTimestamp ?? 0, vault.endTime)
      }
      break
    case VAULT_STATUS.ENDED:
      header = 'End Time'
      value = formatEndTime(vault.endTime)
      break
  }

  return { header, value }
}

export function VaultTimeInfoBlock({
  vault,
  hideOnMediumWidth = false,
  className,
}: VaultTimeInfoProps) {
  const block = useBlock()
  const [active, setActive] = useState(false)

  function handleOnMouseEnter() {
    setActive(true)
  }

  function handleOnMouseLeave() {
    setActive(false)
  }

  const { value, header } = getVaultTimeData({
    vault,
    blockTimestamp: block?.timestamp,
    active,
  })

  return (
    <VaultTimeInfoContainer
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      className={className}
    >
      <EndTimeInfoBlock header={header} value={value} hideOnMediumWidth={hideOnMediumWidth} />
    </VaultTimeInfoContainer>
  )
}

const VaultTimeInfoContainer = styled.div`
  width: 14%;
`

export const EndTimeInfoBlock = styled(InfoBlock)`
  width: 100%;
  span:nth-child(2) {
    font-size: 16px;
  }
`

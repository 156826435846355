import { useEffect, useMemo } from 'react'
import { BigNumber } from 'ethers'
import { formatUnits } from 'ethers/lib/utils'

import { getBpsPercent, secondsToDays, Tokens, Uniswap } from '@packages/ui'
import { useBlock, useWeb3ReactPlus } from '@packages/web3-react-plus'
import { BIG_NUMBER_ZERO, bnToFloat } from '@packages/bn'

import { AppStateActionName, useAppStateReducer } from 'src/AppState'
import { VAULT_CONFIG } from 'src/config'

import { DEFAULT_RATES, Rates, UniV3Adapter, VariableRates, Vault, VaultInfo } from '../interfaces'
import { useUniswapAdapter } from './useUniswapAdapter'
import { useClaimTokenTotalSupply, useFixedBearerTokenTotalSupply, useVault } from './useVault'
import { useLiquidityInUsd } from './useLiquidityInUsd'
import { calculateEstimatedVariableRates, calculateFixedRates } from '../utils'
import { USD_BN_DECIMALS, VAULT_STATUS } from '../constants'

export function useUniV3Vault(vaultInfo: VaultInfo): {
  adapter?: UniV3Adapter
  vault?: Vault
  usdLiquidityValue: BigNumber
  rates: Rates
} {
  const { chainId } = useWeb3ReactPlus()
  const [, dispatch] = useAppStateReducer()
  const vault = useVault(vaultInfo)
  const adapter = useUniswapAdapter(vaultInfo)
  const usdLiquidityValue = useLiquidityInUsd({
    fixedSideCapacity: vault?.data?.fixedSideCapacity,
    poolAddress: adapter?.data?.pool.address,
    token0: adapter?.data?.pool.token0,
    token1: adapter?.data?.pool.token1,
    minTick: adapter?.data?.minTick,
    maxTick: adapter?.data?.maxTick,
  })
  const rates = useUniV3Rates(adapter?.data, vault?.data)

  useEffect(() => {
    if (adapter?.data && usdLiquidityValue) {
      const updatedAdapter: UniV3Adapter = { ...adapter.data, usdLiquidityValue }
      dispatch({
        type: AppStateActionName.UpdateVaultAdapter,
        payload: {
          data: updatedAdapter,
          chainId,
          address: vaultInfo.adapterAddress,
        },
      })
    }
  }, [usdLiquidityValue])

  useEffect(() => {
    if (vault?.data && rates) {
      const updatedVault: Vault = { ...vault.data, rates }
      dispatch({
        type: AppStateActionName.UpdateVault,
        payload: {
          data: updatedVault,
          chainId,
          id: vault.data.id,
        },
      })
    }
  }, [rates.variable.earningsRate, rates.fixed.apr, rates.fixed.fixedRate]) /**
    NOTE: need to use the values individually - otherwise it causes a 'Maximum update depth exceeded.' error.
    Due to this method updating vault, and vault being in the dependency array of the useMemo rates calculations
   **/

  const uniV3VaultData = {
    vault: vault?.data,
    adapter: adapter?.data,
    usdLiquidityValue,
    rates,
  }

  return uniV3VaultData
}

export function useUniV3Rates(adapter?: UniV3Adapter, vault?: Vault): Rates {
  const { chainId } = useWeb3ReactPlus()
  const poolDayData = Uniswap.usePoolDayData(adapter?.pool.address ?? '')
  const { data: variableAssetPrice } = Tokens.useTokenPrice(vault?.variableAsset)
  const { data: token0Price } = Tokens.useTokenPrice(adapter?.pool.token0)
  const { data: token1Price } = Tokens.useTokenPrice(adapter?.pool.token1)
  const block = useBlock()
  const earnings = useUniV3Earnings(chainId, adapter, vault)

  const usdLiquidityValue = useLiquidityInUsd({
    fixedSideCapacity: vault?.fixedSideCapacity,
    poolAddress: adapter?.pool.address,
    token0: adapter?.pool.token0,
    token1: adapter?.pool.token1,
    minTick: adapter?.minTick,
    maxTick: adapter?.maxTick,
  })

  const rates: Rates = useMemo(() => {
    // deep clone object
    const rates: Rates = JSON.parse(JSON.stringify(DEFAULT_RATES))

    if (vault && variableAssetPrice && !usdLiquidityValue.isZero()) {
      const durationDays = secondsToDays(vault.duration)

      rates.fixed = calculateFixedRates({
        usdLiquidityValue,
        variableSideCapacity: vault.variableSideCapacity,
        variableAsset: vault.variableAsset,
        variableAssetPrice,
        durationDays,
      })

      const feePercent = getBpsPercent(vault.feeBps)

      if (vault.status === VAULT_STATUS.NOT_STARTED) {
        let estimatedVariableRates: VariableRates | undefined
        if (poolDayData.dayData && poolDayData.dayData.data.length > 0) {
          estimatedVariableRates = calculateEstimatedVariableRates({
            feePercent,
            uniswapDayData: poolDayData.dayData,
            fixedApr: rates.fixed.apr,
            fixedUsdCapacity: bnToFloat(usdLiquidityValue, USD_BN_DECIMALS),
            durationDays,
            variableUsdCapacity: rates.fixed.usdEarnings,
          })
        }
        if (estimatedVariableRates) {
          rates.variable = estimatedVariableRates
        }
      } else {
        if (vault && variableAssetPrice && token0Price && token1Price && block && earnings) {
          const variablePrincipal =
            Number(formatUnits(vault.variableSideCapacity, vault.variableAsset.decimals)) *
            variableAssetPrice

          const token0Earnings = Number(formatUnits(earnings[0], adapter?.pool.token0.decimals))
          const token1Earnings = Number(formatUnits(earnings[1], adapter?.pool.token1.decimals))
          const totalUsdEarnings = token0Earnings * token0Price + token1Earnings * token1Price

          const variableEarnings = totalUsdEarnings * (1 - feePercent) - variablePrincipal
          const earningsRate = variableEarnings / variablePrincipal

          rates.variable.earningsRate = earningsRate
          rates.variable.usdEarnings = variableEarnings
          rates.variable.token0Earnings = token0Earnings
          rates.variable.token1Earnings = token1Earnings
        }
      }
    }
    return rates
  }, [variableAssetPrice, token0Price, token1Price, usdLiquidityValue, poolDayData, earnings])

  return rates
}

function useUniV3Earnings(
  chainId: number,
  adapter?: UniV3Adapter,
  vault?: Vault
): [BigNumber, BigNumber] {
  const [claimTokenTotalSupply] = useClaimTokenTotalSupply(vault)
  const [fixedBearerTokenTotalSupply] = useFixedBearerTokenTotalSupply(vault)

  const fees = Uniswap.useUniswapPositionFees({
    nonFungiblePositionManagerAddress:
      VAULT_CONFIG[chainId].uniswap.nonFungiblePositionManagerAddress,
    tokenId:
      vault?.status !== VAULT_STATUS.NOT_STARTED &&
      !vault?.earningsSettled &&
      (claimTokenTotalSupply?.eq(1) || fixedBearerTokenTotalSupply?.eq(1))
        ? adapter?.tokenId
        : undefined,
  })

  if (vault && adapter) {
    if (vault.earningsSettled) {
      return [adapter.earnings0, adapter.earnings1]
    } else {
      return fees
    }
  }

  return [BIG_NUMBER_ZERO, BIG_NUMBER_ZERO]
}

import styled from 'styled-components'

import { UniswapCreateVaultForm } from 'src/vaults'

import { PageHeader } from './navBar'

export function CreateVaultPage() {
  return (
    <Container>
      <PageHeader subheader={'Create Vault (Uniswap v3)'} />
      <UniswapCreateVaultForm />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
`

import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { DEFAULT_CHAIN_ID, Web3ReactPlusProvider } from '@packages/web3-react-plus'
import { SharedAppState } from '@packages/ui'

import { AppStateProvider } from './AppState'
import { App } from './App'

const queryClient = new QueryClient()

export default function Root() {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Web3ReactPlusProvider defaultChainId={DEFAULT_CHAIN_ID}>
          <SharedAppState.AppStateProvider>
            <AppStateProvider>
              <App />
            </AppStateProvider>
          </SharedAppState.AppStateProvider>
        </Web3ReactPlusProvider>
      </QueryClientProvider>
    </BrowserRouter>
  )
}

import { useState } from 'react'
import { Web3Provider } from '@ethersproject/providers'

import { useUpdatePerBlock, useWeb3ReactPlus } from '@packages/web3-react-plus'

import { UniswapV3Pool } from '../interfaces'
import { getPool } from '../utils'
import { UPDATES_PER_BLOCK } from '../../config'

export function usePool(poolAddress: string) {
  const { provider, chainId } = useWeb3ReactPlus()
  const [pool, setPool] = useState<UniswapV3Pool>()
  const [error, setError] = useState<Error>()
  const [loading, setLoading] = useState(false)

  useUpdatePerBlock(
    () => _getPool({ poolAddress, setPool, setError, setLoading, chainId, provider }),
    [poolAddress, setPool, setError, setLoading, chainId, provider],
    {
      updateEveryNBlocks: UPDATES_PER_BLOCK.SEMI_CRITICAL,
    }
  )

  return { pool, error, loading }
}

async function _getPool({
  poolAddress,
  setPool,
  setLoading,
  setError,
  chainId,
  provider,
}: {
  poolAddress: string
  setPool: (pool: UniswapV3Pool) => void
  setLoading: (loading: boolean) => void
  setError: (error: Error) => void
  chainId: number
  provider?: Web3Provider
}) {
  if (poolAddress.length === 0 || !provider) return
  try {
    setLoading(true)
    const pool = await getPool({ address: poolAddress, chainId, provider })

    setPool(pool)
  } catch (error: any) {
    setError(error)
  } finally {
    setLoading(false)
  }
}

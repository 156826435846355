import { useWeb3ReactPlus } from '@packages/web3-react-plus'

import Uniswap from '../assets/images/platforms/uniswap.svg'
import Sushiswap from '../assets/images/platforms/sushiswap.svg'
import Mojitoswap from '../assets/images/platforms/mojitoswap.svg'
import SFIBlue from '../assets/images/platforms/sfi-blue.svg'
import unknown from '../assets/images/tokens/missing-token-icon.svg'
import { Platform } from '../constants'

const noIcon = ''

export function usePlatformIcon(platform: Platform) {
  const { chainId } = useWeb3ReactPlus()
  const platformIconSvg = getPlatformIcon(platform)

  if (!chainId) return noIcon

  return platformIconSvg
}

const icons: Record<Platform, string> = {
  'Uniswap v2': Uniswap,
  'Uniswap v3': Uniswap,
  Sushiswap: Sushiswap,
  'SFI Staking': SFIBlue,
  Mojitoswap: Mojitoswap,
  '': unknown,
}

export function getPlatformIcon(platform: Platform) {
  const icon = icons[platform]
  if (!icon) return icons['']
  return icon
}

import { Web3Provider } from '@ethersproject/providers'
import { useState } from 'react'

import { VaultFactory__factory } from '@packages/contract-types'
import { UPDATES_PER_BLOCK } from '@packages/ui'
import { useUpdatePerBlock, useWeb3ReactPlus } from '@packages/web3-react-plus'

import { VAULT_CONFIG } from 'src/config'

async function updateNextVaultId({
  web3Provider,
  chainId,
  setNextVaultId,
}: {
  web3Provider?: Web3Provider
  chainId: number
  setNextVaultId: (id: number) => void
}) {
  if (!web3Provider) return

  const vaultFactory = VaultFactory__factory.connect(
    VAULT_CONFIG[chainId].factoryAddress,
    web3Provider
  )

  const nextVaultId = await vaultFactory.nextVaultId()

  setNextVaultId(nextVaultId.toNumber())
}

export function useNextVaultId(): number {
  const { provider, chainId } = useWeb3ReactPlus()
  const [nextVaultId, setNextVaultId] = useState(1)

  useUpdatePerBlock(
    () => updateNextVaultId({ web3Provider: provider, chainId, setNextVaultId }),
    [provider, chainId],
    {
      updateEveryNBlocks: UPDATES_PER_BLOCK.SEMI_CRITICAL,
    }
  )

  return nextVaultId
}

import styled from 'styled-components'

import {
  PercentInput,
  msToMinutes,
  minutesToMs,
  InfoTooltip,
  A,
  Modal,
  ModalCloseIcon,
  ModalTitle,
  ModalProps,
  NumberInputWithLabel,
} from '@packages/ui'

import { UNISWAP_DESCRIPTIONS, UNI_V3_TRX_DEFAULTS } from '../../../constants'

interface Props extends ModalProps {
  expireMs: number
  onExpireMsChange: (expireMs: number) => void

  slippagePercent: number
  onSlippagePercentChange: (slippage: number) => void
}

export function UniV3SettingsModal({
  isOpen,
  onRequestClose,
  expireMs,
  onExpireMsChange,
  slippagePercent,
  onSlippagePercentChange,
}: Props) {
  function onReset() {
    onExpireMsChange(UNI_V3_TRX_DEFAULTS.ALL.EXPIRE_MS)
    onSlippagePercentChange(UNI_V3_TRX_DEFAULTS.ALL.SLIPPAGE_PERCENT)
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <ModalTitleWrapper>Settings</ModalTitleWrapper>
      <ModalCloseIcon onRequestClose={onRequestClose} />
      <ResetContainer>
        <ResetButton onClick={onReset}>Reset</ResetButton>
      </ResetContainer>
      <SettingsItems>
        <SettingsHeader>
          Uniswap{' '}
          <InfoTooltip
            content={
              <ToolTipContent>{UNISWAP_DESCRIPTIONS.transactionSettings.summary}</ToolTipContent>
            }
          />
        </SettingsHeader>
        <SettingsItem>
          <ItemTitle>
            Transaction Deadline{' '}
            <InfoTooltip
              content={
                <ToolTipContent>
                  {UNISWAP_DESCRIPTIONS.transactionSettings.transactionDeadline}
                </ToolTipContent>
              }
            />
          </ItemTitle>
          <ItemInput>
            <NumberInputWrapper
              value={msToMinutes(expireMs)}
              onChange={(num) => onExpireMsChange(minutesToMs(num ?? 0))}
              precision={0}
              label='minutes'
            />
          </ItemInput>
        </SettingsItem>
        <SettingsItem>
          <ItemTitle>
            Slippage Tolerance{' '}
            <InfoTooltip
              content={
                <ToolTipContent>
                  {UNISWAP_DESCRIPTIONS.transactionSettings.slippageTolerance}
                </ToolTipContent>
              }
            />
          </ItemTitle>
          <ItemInput>
            <PercentInputWrapper
              value={slippagePercent}
              onChange={onSlippagePercentChange}
              denominator={UNI_V3_TRX_DEFAULTS.ALL.PERCENT_DENOMINATOR}
            />
          </ItemInput>
        </SettingsItem>
      </SettingsItems>
    </Modal>
  )
}

const ModalTitleWrapper = styled(ModalTitle)`
  margin-bottom: 10px;
`

const ResetContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
`

const ResetButton = styled(A)`
  font-weight: 500;
  font-size: 14px;
  float: right;
`

const SettingsHeader = styled.div`
  font-size: 15px;
  font-weight: 500;
`

const SettingsItems = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 10px;
`

const SettingsItem = styled.div`
  color: ${(props) => props.theme.colors.text.secondary};
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ItemTitle = styled.div``

const ItemInput = styled.div``

const NumberInputWrapper = styled(NumberInputWithLabel)`
  input {
    width: 130px;
    margin-right: -60px;
  }
`

const PercentInputWrapper = styled(PercentInput)`
  justify-content: flex-end;
`

const ToolTipContent = styled.div`
  width: 150px;
`

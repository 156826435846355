import { CHAIN_ID, HARDHAT_CHAIN_ID } from '@packages/web3-react-plus'

export const TEST_ENVIRONMENT =
  process.env.REACT_APP_TEST_ENVIRONMENT?.toLowerCase() === 'true' ?? false

export const SUPPORTED_TESTNET_CHAIN_IDS = [
  HARDHAT_CHAIN_ID,
  CHAIN_ID.Goerli,
  CHAIN_ID.ArbitrumGoerli,
]

export const SUPPORTED_CHAIN_IDS = [
  CHAIN_ID.Ethereum,
  // CHAIN_ID.Polygon,
  CHAIN_ID.Arbitrum,
  ...(TEST_ENVIRONMENT ? SUPPORTED_TESTNET_CHAIN_IDS : []),
]

export const GIT_SHA = process.env.REACT_APP_GIT_SHA ?? 'local'

import styled from 'styled-components'

import { SharedAppState, Toggle } from '@packages/ui'

interface Props {
  onChange: React.ChangeEventHandler<HTMLInputElement>
}

export function ThemeToggle({ onChange }: Props) {
  const [{ theme }] = SharedAppState.useAppStateReducer()
  return <ToggleWrapper onChange={onChange} checked={theme.type === 'dark'} id='theme-toggle' />
}

const ToggleWrapper = styled(Toggle)`
  input {
    &:checked + label {
      &:after {
        transform: translateX(30px) scale(1); // moon shape
      }
    }
  }

  label {
    &:before {
      background-color: #ffa41b; // sun color
    }
  }
`

import styled, { css } from 'styled-components'
import React from 'react'

import { StylingProps } from '../styles'

export type TOOLTIP_POSITION = 'left' | 'right'

interface Props extends StylingProps {
  content: React.ReactNode
  children: React.ReactNode
  position?: TOOLTIP_POSITION
}

export function Tooltip({ content, position = 'right', children, className }: Props) {
  return (
    <Container className={className}>
      <ChildWrapper>{children}</ChildWrapper>
      <TooltipWrapper position={position}>{content}</TooltipWrapper>
    </Container>
  )
}

const Container = styled.span`
  position: relative;

  & > div:hover + span {
    display: flex;
    overflow: visible;
  }
`

const ChildWrapper = styled.div`
  display: inline-flex;
`

const TooltipWrapper = styled.span<{ position: TOOLTIP_POSITION }>`
  background: ${(props) => props.theme.colors.backgrounds.primary};
  box-shadow: 0 5px 25px 5px ${(props) => props.theme.colors.backgrounds.secondary};
  box-sizing: border-box;
  color: ${(props) => props.theme.colors.text.primary};
  font-size: 12px;
  line-height: 1.2;
  letter-spacing: 1px;
  padding: 1em;
  position: absolute;
  display: none;
  transition: all 0.3s ease;
  z-index: 100;
  border-radius: 5px;
  top: 50%;
  transform: translateY(-50%);

  ${(props) => {
    switch (props.position) {
      case 'left':
        return css`
          right: calc(100% + 0.75em);
          &:after {
            right: -5px;
          }
        `
      case 'right':
      default:
        return css`
          left: calc(100% + 0.75em);
          &:after {
            left: -5px;
          }
        `
    }
  }}

  &:after {
    background: ${(props) => props.theme.colors.backgrounds.primary};
    content: '';
    height: 10px;
    position: absolute;
    transform: rotate(45deg);
    width: 10px;
    margin-top: -5px;
    top: 50%;
  }
`

import { BigNumber } from 'ethers'
import JSBI from 'jsbi'

export function fromJSBIToBigNumber(jsbi: JSBI) {
  return BigNumber.from('0x' + jsbi.toString(16))
}

export function fromBigNumbertoJSBI(bigNumber: BigNumber) {
  return JSBI.BigInt(bigNumber.toString())
}

import styled from 'styled-components'
import { BigNumberish, BigNumber, utils } from 'ethers'
import { useState } from 'react'
const { formatUnits } = utils

import { bn, BIG_NUMBER_ZERO } from '@packages/bn'

import {
  StylingProps,
  PercentButtons,
  Button,
  ErrorMessage,
  TextInput,
  validateBigNumber,
  mediaQuery,
} from '../../shared'
import { TokenIcon } from './TokenIcon'
import { useTokenPrice } from '../hooks'
import { Token } from '../interfaces'

interface Props extends StylingProps {
  availableAmount?: BigNumber
  maxAmount?: BigNumber
  submitText: string
  loadingText?: string
  token: Token
  errorContent: React.ReactNode
  clearError: () => void
  onSubmit: (args: { amount: BigNumber; clearInput: () => void }) => void
  hidePercentButtons?: boolean
  hideMaxButton?: boolean
}

export function AvailableAmountFormInput({
  token,
  availableAmount = BIG_NUMBER_ZERO,
  maxAmount,
  clearError,
  onSubmit,
  submitText,
  loadingText,
  errorContent,
  hidePercentButtons = false,
  hideMaxButton = false,
  className,
}: Props) {
  const { data: tokenPrice } = useTokenPrice(token)
  const [inputAmount, setInputAmount] = useState('')
  const [bnInputAmount, setBnInputAmount] = useState<BigNumber>()
  const [showNotEnoughError, setShowNotEnoughError] = useState(false)

  function clearInput() {
    setInputAmount('')
    setBnInputAmount(undefined)
  }

  function onSubmitHandler() {
    if (bnInputAmount) {
      onSubmit({
        amount: bnInputAmount,
        clearInput,
      })
    }
  }

  function onInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target

    const inputAmountBn = validateBigNumber({
      value,
      setLocalValue: setInputAmount,
      setParentValue: (value) => setBnInputAmount(value ?? undefined),
      precision: token.decimals,
      clearErrors: () => {
        clearError()
        setShowNotEnoughError(false)
      },
    })

    if (!inputAmountBn) return

    const hasEnoughBalance = inputAmountBn.lte(availableAmount)
    setShowNotEnoughError(!hasEnoughBalance)
  }

  function onBlur() {
    if (maxAmount && bnInputAmount?.gt(maxAmount)) {
      setInputAmount(formatUnits(maxAmount, token.decimals))
      setBnInputAmount(maxAmount)
    }
  }

  const onPercentageClick = (percentage: number) => {
    let amountBn: BigNumber = BIG_NUMBER_ZERO

    if (percentage === 0.75) {
      amountBn = availableAmount.mul(bn(1, token.decimals)).div(bn(4, token.decimals)).mul(bn(3))
    } else {
      const inversePercentageBn = bn(1 / percentage, token.decimals)
      amountBn = availableAmount.mul(bn(1, token.decimals)).div(inversePercentageBn)
    }
    if (amountBn.toString() === '0') {
      clearInput()
    } else {
      if (maxAmount && amountBn?.gt(maxAmount)) {
        setInputAmount(formatUnits(maxAmount, token.decimals))
        setBnInputAmount(maxAmount)
      } else {
        setInputAmount(formatUnits(amountBn, token.decimals))
        setBnInputAmount(amountBn)
      }
      setShowNotEnoughError(false)
    }
  }

  function getSubmitButtonState() {
    if (showNotEnoughError || !bnInputAmount || loadingText) {
      return { disabled: true, loading: loadingText ? true : undefined }
    }

    return {}
  }

  function renderErrorElement() {
    if (showNotEnoughError) {
      return <ErrorMessageWrapper>Insufficient balance.</ErrorMessageWrapper>
    }
    if (errorContent) {
      return <ErrorMessageWrapper>{errorContent}</ErrorMessageWrapper>
    }
    return
  }

  function onMaxButtonClick() {
    if (maxAmount && !availableAmount.eq(BIG_NUMBER_ZERO)) {
      if (availableAmount.lt(maxAmount)) {
        setInputAmount(formatUnits(availableAmount, token.decimals))
        setBnInputAmount(availableAmount)
      } else {
        setInputAmount(formatUnits(maxAmount, token.decimals))
        setBnInputAmount(maxAmount)
      }
    }
  }

  return (
    <Container className={className} data-test-id='available-amount-form-input'>
      <InputContainer>
        <AvailableAmountContainer onClick={() => onPercentageClick(1)}>
          <BigNumberAmountWrapper
            amount={availableAmount}
            precision={token.decimals}
            tokenSymbol={token.symbol}
            text='Available:'
            amountAlignment='right'
          />
        </AvailableAmountContainer>
        <InputButtonContainer>
          <InputContainerWithMaxButton>
            <AmountInput
              value={inputAmount}
              onChange={onInputChange}
              placeholder='0.0'
              onBlur={onBlur}
              data-test-id='amount-input'
            />
            {!hideMaxButton && maxAmount && !availableAmount.eq(BIG_NUMBER_ZERO) && (
              <MaxButton onClick={onMaxButtonClick}>MAX</MaxButton>
            )}
          </InputContainerWithMaxButton>

          <ButtonWrapper
            {...getSubmitButtonState()}
            onClick={onSubmitHandler}
            data-test-id='available-amount-button'
          >
            {loadingText ? (
              <ButtonContent>{loadingText}</ButtonContent>
            ) : (
              <ButtonContent>{submitText}</ButtonContent>
            )}
          </ButtonWrapper>
        </InputButtonContainer>

        <UsdValueContainer>
          ${((tokenPrice ?? 0) * Number(inputAmount)).toFixed(2)}
        </UsdValueContainer>
      </InputContainer>
      {!hidePercentButtons && (
        <PercentButtons
          onButtonClick={onPercentageClick}
          disabled={(availableAmount ?? BIG_NUMBER_ZERO).isZero()}
        />
      )}
      {renderErrorElement()}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`

const InputButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`

const ButtonWrapper = styled(Button)`
  width: 100%;
  font-size: 17px;
  line-height: 21px;
  letter-spacing: -0.02em;
  padding: 13px 23px;
`

const ButtonContent = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    margin-right: 10px;
  }
`

const InputContainerWithMaxButton = styled.div`
  position: relative;
`

const MaxButton = styled.span`
  cursor: pointer;
  color: ${(props) => props.theme.colors.activated};
  position: absolute;
  font-weight: 600;
  right: 10px;
  top: 17px;
  font-size: 14px;
`

const ErrorMessageWrapper = styled(ErrorMessage)`
  max-width: 400px;
  text-align: center;
`

const AvailableAmountContainer = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.text.secondary};
  cursor: pointer;
`

const BigNumberAmountWrapper = styled(BigNumberAmount)`
  span {
    color: ${(props) => props.theme.colors.text.secondary};
  }
`

const AmountInput = styled(TextInput)`
  margin: 5px 0px;
  padding: 10px;
  padding-left: 15px;
  height: auto;
  font-size: 18px;
  font-weight: 600;
  ::placeholder {
    color: ${(props) => props.theme.colors.text.secondary};
    font-size: 18px;
    font-weight: 600;
  }

  ${mediaQuery('medium')} {
    max-width: 160px;
  }
`

const UsdValueContainer = styled(AvailableAmountContainer)``

interface AmountProps extends StylingProps {
  amount?: BigNumberish
  precision: BigNumberish
  tokenSymbol?: string
  displayTokenIcon?: boolean
  text?: string | React.ReactNode
  amountAlignment?: 'left' | 'right'
}

export function BigNumberAmount({
  amount = BIG_NUMBER_ZERO,
  precision,
  tokenSymbol = '',
  displayTokenIcon = false,
  text,
  className,
  amountAlignment = 'left',
}: AmountProps) {
  const value = formatUnits(amount, precision)
  if (value.indexOf('.') === -1)
    return (
      <>
        {value}&nbsp;{tokenSymbol}&nbsp;{text && text}
      </>
    )
  const [wholeNumber, mantissa] = value.split('.')
  const truncatedMantissa = mantissa.substring(0, 4)
  if (truncatedMantissa === '0000') {
    if (wholeNumber === '0')
      return (
        <>
          &lt; 0.0001 {tokenSymbol} {text && text}
        </>
      )
    return (
      <>
        {wholeNumber} {tokenSymbol} {text && text}
      </>
    )
  }
  return (
    <span className={className}>
      {amountAlignment === 'left' && (
        <Amount data-test-id='token-available-amount'>
          <span key='amount-whole-number'>{wholeNumber}</span>
          <Point key='amount-point'>.</Point>
          <span key='amount-truncated-mantissa'>{truncatedMantissa}</span>
          &nbsp;
        </Amount>
      )}

      <DisplayText>
        {displayTokenIcon && (
          <>
            <TokenIcon size={15} tokenSymbol0={tokenSymbol} />
            &nbsp;
          </>
        )}
        {tokenSymbol}
        {text && <>&nbsp;{text}</>}
      </DisplayText>

      {amountAlignment === 'right' && (
        <Amount data-test-id='token-available-amount'>
          &nbsp;
          <span key='amount-whole-number'>{wholeNumber}</span>
          <Point key='amount-point'>.</Point>
          <span key='amount-truncated-mantissa'>{truncatedMantissa}</span>
        </Amount>
      )}
    </span>
  )
}

const Point = styled.span`
  margin-left: 1px;
  margin-right: 1px;
`

const DisplayText = styled.span`
  display: inline-flex;
  align-items: center;
`

const Amount = styled.span`
  color: ${(props) => props.theme.colors.text.primary};
`

import { getPercent, ProgressBar, secondsToString, StylingProps } from '@packages/ui'
import { useBlock } from '@packages/web3-react-plus'

import { Vault } from '../../interfaces'
import { getTimeElapsed } from '../../utils'

interface Props extends StylingProps {
  vault: Vault
}

export function DurationProgressBar({ vault, className }: Props) {
  const block = useBlock()

  const timeElapsed = getTimeElapsed({ blockTimestamp: block?.timestamp, vault })

  let amount = secondsToString(timeElapsed)

  if (timeElapsed === 0) {
    amount = '0 '
  } else if (timeElapsed < 3600) {
    amount = '<1hr '
  }

  return (
    <ProgressBar
      className={className}
      percent={getPercent(timeElapsed, vault.duration)}
      title='Time Elapsed'
      amount={`${amount}/ ${secondsToString(vault.duration)}`}
    />
  )
}

import styled from 'styled-components'
import { useEffect, useRef, useState } from 'react'
import { initOnRamp } from '@coinbase/cbpay-js'

import { useWeb3ReactPlus } from '@packages/web3-react-plus'

import AddEth from '../assets/images/add-eth.png'
import DefaultButton from '../assets/images/buy-with-coinbase.png'
import SmallButton from '../assets/images/small-button.png'

type ButtonStyle = 'eth' | 'small' | 'default'

interface Props {
  onClick?: () => void
  buttonStyle?: ButtonStyle
  buttonSize?: number
}

export function PayWithCoinbaseButton({
  onClick = () => {},
  buttonStyle = 'default',
  buttonSize = 300,
}: Props) {
  const onrampInstance = useRef<any>()
  const [isReady, setIsReady] = useState(false)
  const { activeAddress, chainId } = useWeb3ReactPlus()

  useEffect(() => {
    if (!activeAddress) return
    console.log('initializing')
    onrampInstance.current = initOnRamp({
      appId: '1fb803fc-9684-4df0-a387-8da9b05e9f8d',
      widgetParameters: {
        destinationWallets: [
          {
            address: activeAddress,
            blockchains: ['ethereum'],
            assets: ['ETH', 'USDC'],
          },
        ],
      },
      onReady: () => {
        setIsReady(true)
      },
      onSuccess: () => {
        console.log('success')
      },
      onExit: (event: any) => {
        console.log('exit', event)
      },
      onEvent: (event: any) => {
        console.log('event', event)
      },
      experienceLoggedIn: 'embedded', //'embedded', 'popup', or 'newtab',
      experienceLoggedOut: 'popup',
      closeOnExit: true,
      closeOnSuccess: true,
    })

    return () => {
      onrampInstance.current?.destroy()
    }
  }, [activeAddress, chainId])

  const handleClick = () => {
    console.log(onrampInstance)
    onrampInstance.current?.open()
    onClick()
  }

  let button = DefaultButton
  switch (buttonStyle) {
    case 'eth':
      button = AddEth
      break
    case 'small':
      button = SmallButton
      break
    default:
      button = DefaultButton
  }

  return <a>{isReady && <Button width={buttonSize} src={button} onClick={handleClick} />}</a>
}

const Button = styled.img`
  cursor: pointer;
`

import styled from 'styled-components'

import { StylingProps } from '../../styles'
import { getPercent, getValueFromPercent } from '../../utils'
import { NumberInputWithLabel } from './NumberInputWithLabel'

interface Props extends StylingProps {
  value?: number
  onChange: (value: number) => void
  denominator?: number
  placeholder?: string
  precision?: number
}

export function PercentInput({
  value,
  onChange,
  denominator = 1,
  placeholder,
  precision,
  className,
}: Props) {
  return (
    <NumberInputWithLabelWrapper
      className={className}
      value={value ? getPercent(value, denominator) : undefined}
      onChange={(val) => onChange(getValueFromPercent(val ?? 0, denominator))}
      placeholder={placeholder}
      precision={precision}
      label={'%'}
    />
  )
}

const NumberInputWithLabelWrapper = styled(NumberInputWithLabel)`
  input {
    max-width: 130px;
  }
`

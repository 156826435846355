export function formatNumber(number: string) {
  return Number(number).toLocaleString('en-US')
}

export function formatNumberCompact(number: number) {
  return Intl.NumberFormat('en-US', { notation: 'compact' }).format(number)
}

export function reverseFormatNumber(val: string, locale = 'en-US') {
  const group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, '')
  const decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, '')
  let reversedVal = val.replace(new RegExp('\\' + group, 'g'), '')
  reversedVal = reversedVal.replace(new RegExp('\\' + decimal, 'g'), '.')
  return Number.isNaN(reversedVal) ? 0 : parseFloat(reversedVal)
}

export function formatUsd(usd: number) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })
  return formatter.format(usd).replace('-$0.00', '$0.00')
}

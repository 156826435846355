import styled, { css } from 'styled-components'

import { CSS_TRANSITION, hexToRgbString } from '../../styles'

export const Card = styled.div<{ opacityOnHover?: boolean }>`
  ${CSS_TRANSITION}
  background-color: ${(props) => props.theme.colors.backgrounds.secondary};
  width: 100%;
  border-radius: 10px;
  ${(props) =>
    props.opacityOnHover &&
    css`
      &:hover {
        background-color: ${(props) =>
          hexToRgbString(props.theme.colors.backgrounds.secondary, 0.6)};
      }
    `}
`

import styled from 'styled-components'

import optionsIcon from '../../assets/images/options.svg'
import { CSS_TRANSITION, StylingProps } from '../../styles'
import { mediaQuery } from '../../utils'

interface Props<T extends string> extends StylingProps {
  handleOnChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
  options: T[]
  selectedOption: T
  isDefault?: boolean
  includeDisabled?: boolean
  displayText?: string
  formatter?: (selectedOption: T) => string
  width?: number
  collapseOnMobile?: boolean
}

export function LargeSelect<T extends string>({
  handleOnChange,
  isDefault = false,
  includeDisabled = false,
  options,
  selectedOption,
  displayText,
  formatter,
  className,
  width = 160,
  collapseOnMobile = true,
}: Props<T>) {
  const _options = Object.values(options).map((option) => (
    <Option key={option} value={option}>
      {formatter ? formatter(option) : option}
    </Option>
  ))

  if (includeDisabled) {
    _options.unshift(
      <Option key='disabled' value='' disabled>
        {displayText}
      </Option>
    )
  }

  return (
    <SelectWrap className={className}>
      <SelectElement
        width={width}
        value={selectedOption}
        onChange={handleOnChange}
        isDefault={isDefault}
        collapseOnMobile={collapseOnMobile}
      >
        {_options}
      </SelectElement>
      <SelectValue collapseOnMobile={collapseOnMobile}>
        {displayText}
        {formatter ? formatter(selectedOption) : selectedOption}
      </SelectValue>
      <OptionsIconWrapper isDefault={isDefault} collapseOnMobile={collapseOnMobile} />
    </SelectWrap>
  )
}

const SelectWrap = styled.div`
  position: relative;
  ${mediaQuery('small')} {
    flex-basis: 54px;
  }
`

const SelectElement = styled.select<{
  isDefault: boolean
  collapseOnMobile: boolean
  width: number
}>`
  ${CSS_TRANSITION}
  font-weight: 500;
  font-size: 14px;
  font-family: 'Lexend', sans-serif;
  width: ${(props) => props.width}px;
  height: 45px;
  border: none;
  border-radius: 10px;
  padding: 6px;
  color: transparent;
  padding: 10px;
  appearance: none;
  cursor: pointer;
  border: none;
  background: ${(props) => props.theme.colors.backgrounds.secondary}
    url("data:image/svg+xml;utf8,<svg width='15' height='10' viewBox='0 0 15 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M14 1L7.5 8L1 0.999999' stroke='${(
      props
    ) => props.theme.colors.text.primary.replace('#', '%23')}' stroke-width='2'/></svg>")
    no-repeat;
  background-position: right 10px top 50%;
  option {
    color: transparent;
    font-family: 'Lexend', sans-serif;
    background-color: ${(props) => props.theme.colors.backgrounds.secondary};
  }

  ${(props) =>
    props.collapseOnMobile &&
    `
    ${mediaQuery('small')} {
      background: ${props.theme.colors.backgrounds.secondary};
      width: 55px;
      cursor: pointer;
      border: ${props.isDefault ? 'none' : `2.5px solid ${props.theme.colors.activated}`};
    }
  `}
`

const Option = styled.option`
  border: none;
  color: black;
`

const SelectValue = styled.span<{ collapseOnMobile: boolean }>`
  color: ${(props) => props.theme.colors.text.primary};
  font-family: 'Lexend', sans-serif;
  font-weight: 400;
  font-size: 14px;
  position: absolute;
  pointer-events: none;
  left: 0;
  padding: 13px;
  ${(props) =>
    props.collapseOnMobile &&
    `${mediaQuery('small')} {
      display: none;
    }`}
`

const OptionsIconWrapper = styled.div<{ isDefault: boolean; collapseOnMobile: boolean }>`
  display: none;
  ${(props) =>
    props.collapseOnMobile &&
    `${mediaQuery('small')} {
      display: block;
    }`}
  background-color: ${(props) =>
    props.isDefault ? props.theme.colors.icons : props.theme.colors.activated};
  mask-image: url(${optionsIcon});
  mask-repeat: no-repeat;
  width: 23px;
  height: 23px;
  position: absolute;
  left: 16px;
  top: 11px;
  background-repeat: no-repeat;
  pointer-events: none;
`

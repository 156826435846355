import { VAULT_STATUS } from '../constants'
import { Vault } from '../interfaces'

export const uniV3SortingOptions = {
  FIXED_APR_DESC: 'Fixed APR ⭣',
  FIXED_APR_ASC: 'Fixed APR ⭡',
  DATE_CREATED_DESC: 'Created ⭣',
  DATE_CREATED_ASC: 'Created ⭡',
  DEFAULT: 'Default',
}

export const uniV3SortByComparators = {
  [uniV3SortingOptions.DEFAULT]: function (vaultA: Vault, vaultB: Vault) {
    return compareStatus(vaultB.status, vaultA.status)
  },
  [uniV3SortingOptions.FIXED_APR_ASC]: function (vaultA: Vault, vaultB: Vault) {
    return compareFixedSideApr(vaultA.rates?.fixed.apr, vaultB.rates?.fixed.apr)
  },
  [uniV3SortingOptions.FIXED_APR_DESC]: function (vaultA: Vault, vaultB: Vault) {
    return compareFixedSideApr(vaultB.rates?.fixed.apr, vaultA.rates?.fixed.apr)
  },
  [uniV3SortingOptions.DATE_CREATED_DESC]: function (vaultA: Vault, vaultB: Vault) {
    return compareDateCreated(vaultA.id, vaultB.id)
  },
  [uniV3SortingOptions.DATE_CREATED_ASC]: function (vaultA: Vault, vaultB: Vault) {
    return compareDateCreated(vaultB.id, vaultA.id)
  },
}

function compareFixedSideApr(aprA?: number, aprB?: number) {
  return (aprA ?? 0) - (aprB ?? 0)
}

function compareStatus(statusA: VAULT_STATUS, statusB: VAULT_STATUS) {
  if (statusA === statusB) return 0

  if (statusA === VAULT_STATUS.NOT_STARTED) return 1
  if (statusB === VAULT_STATUS.NOT_STARTED) return -1

  if (statusA === VAULT_STATUS.STARTED) return 1
  if (statusB === VAULT_STATUS.STARTED) return -1

  return 0
}

function compareDateCreated(idA: number, idB: number) {
  return idA - idB
}

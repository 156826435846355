import JSBI from 'jsbi'

import { JSBI_CONSTANTS } from './constants'

function mulDivRoundingUp(a: JSBI, b: JSBI, denominator: JSBI): JSBI {
  const product = JSBI.multiply(a, b)
  let result = JSBI.divide(product, denominator)
  if (JSBI.notEqual(JSBI.remainder(product, denominator), JSBI_CONSTANTS.ZERO))
    result = JSBI.add(result, JSBI_CONSTANTS.ONE)
  return result
}

export function getAmount0Delta(
  sqrtRatioAX96: JSBI,
  sqrtRatioBX96: JSBI,
  liquidity: JSBI,
  roundUp: boolean
): JSBI {
  if (JSBI.greaterThan(sqrtRatioAX96, sqrtRatioBX96)) {
    ;[sqrtRatioAX96, sqrtRatioBX96] = [sqrtRatioBX96, sqrtRatioAX96]
  }

  const numerator1 = JSBI.leftShift(liquidity, JSBI.BigInt(96))
  const numerator2 = JSBI.subtract(sqrtRatioBX96, sqrtRatioAX96)

  return roundUp
    ? mulDivRoundingUp(
        mulDivRoundingUp(numerator1, numerator2, sqrtRatioBX96),
        JSBI_CONSTANTS.ONE,
        sqrtRatioAX96
      )
    : JSBI.divide(JSBI.divide(JSBI.multiply(numerator1, numerator2), sqrtRatioBX96), sqrtRatioAX96)
}

export function getAmount1Delta(
  sqrtRatioAX96: JSBI,
  sqrtRatioBX96: JSBI,
  liquidity: JSBI,
  roundUp: boolean
): JSBI {
  if (JSBI.greaterThan(sqrtRatioAX96, sqrtRatioBX96)) {
    ;[sqrtRatioAX96, sqrtRatioBX96] = [sqrtRatioBX96, sqrtRatioAX96]
  }

  return roundUp
    ? mulDivRoundingUp(liquidity, JSBI.subtract(sqrtRatioBX96, sqrtRatioAX96), JSBI_CONSTANTS.Q96)
    : JSBI.divide(
        JSBI.multiply(liquidity, JSBI.subtract(sqrtRatioBX96, sqrtRatioAX96)),
        JSBI_CONSTANTS.Q96
      )
}

import { Step, StepProgressModal, StepProgressModalProps } from '../../shared'
import { getBlockExplorerTxUrl, useBlockExplorerBaseUrl } from '../hooks'

export interface TxStep extends Step {
  txHash?: string
}

interface Props extends StepProgressModalProps {
  steps: TxStep[]
}

export function TxStepProgressModal({
  title,
  onRequestClose,
  isOpen,
  steps,
  currentStep,
  error,
  success,
}: Props) {
  const { url: blockExplorerUrl } = useBlockExplorerBaseUrl()

  const formattedSteps = steps.map((step) => {
    if (step.txHash) {
      return { ...step, url: getBlockExplorerTxUrl({ blockExplorerUrl, txHash: step.txHash }) }
    }

    return step
  })

  return (
    <StepProgressModal
      title={title}
      steps={formattedSteps}
      currentStep={currentStep}
      error={error}
      success={success}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    />
  )
}

import { CHAIN_ID, HARDHAT_CHAIN_ID } from '@packages/web3-react-plus'

const DEFAULT_ADAPTER_TYPE_ID = {
  UNI_V3_LIMITED_RANGE_ADAPTER: 1,
  UNI_V3_FULL_RANGE_ADAPTER: 2,
}

const DEFAULT_VAULT_TYPE_ID = {
  UNI_V3: 1,
}

interface VaultConfig {
  factoryAddress: string
  adapterTypeIds: {
    limitedRange: number
    fullRange: number
  }
  vaultTypeIds: {
    uniV3: number
  }
  uniswap: {
    nonFungiblePositionManagerAddress: string
  }
}

export const ENV_VAR_BASE_NAMES = {
  factoryAddress: 'REACT_APP_VAULT_FACTORY_ADDRESS_',
  adapterTypeIds: {
    limitedRange: 'REACT_APP_UNI_V3_LIMITED_RANGE_ADAPTER_TYPE_ID_',
    fullRange: 'REACT_APP_UNI_V3_FULL_RANGE_ADAPTER_TYPE_ID_',
  },
  vaultTypeIds: {
    uniV3: 'REACT_APP_UNI_V3_VAULT_TYPE_ID_',
  },
  uniswap: {
    nonFungiblePositionManagerAddress: 'REACT_APP_UNISWAP_NONFUNGIBLE_POSITION_MANAGER_',
  },
}

function getVaultEnvVars(chainId: number) {
  const vaultConfig: VaultConfig = {
    factoryAddress: getEnvVar(ENV_VAR_BASE_NAMES.factoryAddress, chainId) as string,
    adapterTypeIds: {
      limitedRange: getTypeIdEnvVar(
        DEFAULT_ADAPTER_TYPE_ID.UNI_V3_LIMITED_RANGE_ADAPTER,
        getEnvVar(ENV_VAR_BASE_NAMES.adapterTypeIds.limitedRange, chainId)
      ),
      fullRange: getTypeIdEnvVar(
        DEFAULT_ADAPTER_TYPE_ID.UNI_V3_FULL_RANGE_ADAPTER,
        getEnvVar(ENV_VAR_BASE_NAMES.adapterTypeIds.fullRange, chainId)
      ),
    },
    vaultTypeIds: {
      uniV3: getTypeIdEnvVar(
        DEFAULT_VAULT_TYPE_ID.UNI_V3,
        getEnvVar(ENV_VAR_BASE_NAMES.vaultTypeIds.uniV3, chainId)
      ),
    },
    uniswap: {
      nonFungiblePositionManagerAddress:
        (getEnvVar(
          ENV_VAR_BASE_NAMES.uniswap.nonFungiblePositionManagerAddress,
          chainId
        ) as string) ?? '0xC36442b4a4522E871399CD717aBDD847Ab11FE88',
    },
  }
  return vaultConfig
}

function getTypeIdEnvVar(defaultValue: number, envVar: string | undefined) {
  return envVar ? parseInt(envVar) : defaultValue
}

function getEnvVar(baseName: string, chainId: number) {
  return process.env[baseName + chainId]
}

export const VAULT_CONFIG: Record<number, VaultConfig> = {
  [HARDHAT_CHAIN_ID]: getVaultEnvVars(HARDHAT_CHAIN_ID),
  [CHAIN_ID.Polygon]: getVaultEnvVars(CHAIN_ID.Polygon),
  [CHAIN_ID.Ethereum]: getVaultEnvVars(CHAIN_ID.Ethereum),
  [CHAIN_ID.Goerli]: getVaultEnvVars(CHAIN_ID.Goerli),
  [CHAIN_ID.Arbitrum]: getVaultEnvVars(CHAIN_ID.Arbitrum),
  [CHAIN_ID.ArbitrumGoerli]: getVaultEnvVars(CHAIN_ID.ArbitrumGoerli),
}

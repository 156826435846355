import styled from 'styled-components'

import { A, mediaQuery, StylingProps, SuccessMessage } from '../../shared'
import { getBlockExplorerTxUrl, useBlockExplorerBaseUrl } from '../hooks'

interface Props extends StylingProps {
  txHash: string
}

export function BlockExplorerTxUrl({ txHash, className }: Props) {
  const { url: blockExplorerUrl, name } = useBlockExplorerBaseUrl()

  return (
    <Container data-test-id='block-explorer-tx-url' className={className}>
      <SuccessMessage>Transaction Succeeded.</SuccessMessage>
      <A href={getBlockExplorerTxUrl({ blockExplorerUrl, txHash })}>See details on {name}</A>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  ${mediaQuery('medium')} {
    flex-direction: column;
    gap: 5px;
  }
`

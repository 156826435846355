import styled, { keyframes } from 'styled-components'

import { CHAIN_ID, CHAIN_INFO, DEFAULT_CHAIN_ID, useWeb3ReactPlus } from '@packages/web3-react-plus'

import { switchChain } from '../utils'
import { A } from '../../shared'

export function UnsupportedChain() {
  const { library } = useWeb3ReactPlus()

  function switchToChain() {
    if (!library) return
    switchChain(DEFAULT_CHAIN_ID, library.provider)
  }

  return (
    <Wrap>
      <Text>Looks like you're on an unsupported chain.</Text>
      <Text>
        <SwitchChainLink href='#' onClick={switchToChain} target='_self'>
          Click here to switch to {CHAIN_INFO[DEFAULT_CHAIN_ID as CHAIN_ID]?.name}.
        </SwitchChainLink>
      </Text>
    </Wrap>
  )
}

const fadeInOnLoad = keyframes`
  0% {
    opacity: 0
  }
  100% {
    opacity: 1;
  }
`

const Wrap = styled.div`
  margin-top: 70px;
  text-align: center;
  animation: ${fadeInOnLoad} 0.15s ease-out; ;
`

const Text = styled.p`
  color: ${(props) => props.theme.colors.text.primary};
`

const SwitchChainLink = styled(A)`
  font-size: 15px;
`

import { css } from 'styled-components'

export const AppendAnimationStyles = css`
  @keyframes append-animate {
    0% {
      transform: scale(0.98);
      opacity: 0;
    }
    50% {
      transform: scale(0.98);
      opacity: 0;
      offset: 0.5;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  /* animate new box */
  animation: append-animate 0.25s ease-in;
`

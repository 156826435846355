import styled from 'styled-components'

import { Token } from '../../tokens'
import { usePool } from '../hooks'

const MAX_DISPLAY_DECIMALS = 10
const MAX_SIGNIFICANT_DIGITS = 8

interface PriceRangeStatusProps {
  poolAddress: string
  minPrice: number
  maxPrice: number
  isSorted: boolean
}

export function PriceRangeStatus({
  poolAddress,
  minPrice,
  maxPrice,
  isSorted,
}: PriceRangeStatusProps) {
  const { pool } = usePool(poolAddress)

  const currentPrice = isSorted ? Number(pool?.token0Price ?? 0) : Number(pool?.token1Price ?? 0)

  let inRange = true
  if (currentPrice > 0 && maxPrice > 0 && (currentPrice < minPrice || currentPrice > maxPrice)) {
    inRange = false
  }
  return (
    <Container>
      <Text>Current Price: </Text>
      {currentPrice.toLocaleString(undefined, {
        maximumSignificantDigits: MAX_SIGNIFICANT_DIGITS,
        maximumFractionDigits: MAX_DISPLAY_DECIMALS,
      })}
      &nbsp;
      <RangeTitle inRange={inRange}>{inRange ? 'In Range' : 'Out of Range'}</RangeTitle>{' '}
    </Container>
  )
}

const Container = styled.span`
  font-weight: 500;
  color: ${(props) => props.theme.colors.text.primary};
`

const Text = styled.span`
  font-weight: 400;
  color: ${(props) => props.theme.colors.text.secondary};
`

const RangeTitle = styled.span<{ inRange: boolean }>`
  font-weight: 500;
  font-size: 14px;
  border-radius: 3px;
  padding: 1px 4px;
  color: white;
  background-color: ${(props) =>
    props.inRange ? props.theme.colors.semantic.valid : props.theme.colors.semantic.invalid};
`

interface PriceRangeDisplayProps extends PriceRangeStatusProps {
  token0: Token
  token1: Token
}

export function PriceRangeDisplay({
  minPrice,
  maxPrice,
  token0,
  token1,
  isSorted,
}: PriceRangeDisplayProps) {
  return (
    <Container>
      <Text>Price Range: </Text>
      {minPrice.toLocaleString(undefined, {
        maximumSignificantDigits: MAX_SIGNIFICANT_DIGITS,
        maximumFractionDigits: MAX_DISPLAY_DECIMALS,
      })}{' '}
      -{' '}
      {maxPrice.toLocaleString(undefined, {
        maximumSignificantDigits: MAX_SIGNIFICANT_DIGITS,
        maximumFractionDigits: MAX_DISPLAY_DECIMALS,
      })}{' '}
      {isSorted ? token0.symbol : token1.symbol} per {isSorted ? token1.symbol : token0.symbol}
    </Container>
  )
}

import styled, { useTheme } from 'styled-components'

import { MenuIconDiv, MenuIconWrapper, Kebab, KebabItem, KebabText } from '@packages/ui'
import { ReactComponent as KebabIcon } from '@packages/ui/src/shared/assets/images/nav-bar/kebab.svg'
import { ReactComponent as Discord } from '@packages/ui/src/shared/assets/images/icons/discord.svg'
import { ReactComponent as Twitter } from '@packages/ui/src/shared/assets/images/icons/twitter.svg'
import { ReactComponent as Telegram } from '@packages/ui/src/shared/assets/images/icons/telegram.svg'
import { ReactComponent as Youtube } from '@packages/ui/src/shared/assets/images/icons/youtube.svg'
import { ReactComponent as Github } from '@packages/ui/src/shared/assets/images/icons/github.svg'
import { ReactComponent as Medium } from '@packages/ui/src/shared/assets/images/icons/medium.svg'

interface Props {
  isMobile?: boolean
}

export function Menu({ isMobile = false }: Props) {
  const theme = useTheme()
  const iconFillColor = theme.colors.icons

  return (
    <Kebab
      menuButton={
        <MenuIcon>
          <MenuIconDivWrapper>
            <KebabIcon fill={theme.colors.text.secondary} />
          </MenuIconDivWrapper>
        </MenuIcon>
      }
      transition
      direction={isMobile ? 'top' : 'bottom'}
      align='end'
      position='anchor'
      arrow
      offsetY={-3}
      offsetX={10}
    >
      <KebabItem target='_blank' rel='noreferrer' href='https://medium.com/saffron-finance/'>
        <KebabText>Medium</KebabText>
        <Medium width={19} fill={iconFillColor} />
      </KebabItem>
      <KebabItem target='_blank' rel='noreferrer' href='https://github.com/saffron-finance'>
        <KebabText>Github</KebabText>
        <Github width={19} fill={iconFillColor} />
      </KebabItem>
      <KebabItem target='_blank' rel='noreferrer' href='https://discord.gg/pDXpXKY'>
        <KebabText>Discord</KebabText>
        <Discord width={19} fill={iconFillColor} />
      </KebabItem>
      <KebabItem target='_blank' rel='noreferrer' href='https://t.me/saffronfinance'>
        <KebabText>Telegram</KebabText>
        <Telegram width={19} fill={iconFillColor} />
      </KebabItem>
      <KebabItem target='_blank' rel='noreferrer' href='https://twitter.com/saffronfinance_'>
        <KebabText>Twitter</KebabText>
        <Twitter width={19} fill={iconFillColor} />
      </KebabItem>
      <KebabItem
        target='_blank'
        rel='noreferrer'
        href='https://www.youtube.com/channel/UCk_ZDXcc9Z56p9HWp7tFArA'
      >
        <KebabText>Youtube</KebabText>
        <Youtube width={19} fill={iconFillColor} />
      </KebabItem>
    </Kebab>
  )
}

const MenuIcon = styled(MenuIconWrapper)`
  width: 100%;
  display: flex;
`

const MenuIconDivWrapper = styled(MenuIconDiv)`
  width: 100%;
`

import styled from 'styled-components'

import { CHAIN_ID, useWeb3ReactPlus } from '@packages/web3-react-plus'

import { SAFFRON_RED_HEX } from '../../shared'
import { getChainName } from '../utils'
import { ChainSelectorMenu } from './ChainSelectorMenu'

interface Props {
  supportedChainIds: CHAIN_ID[]
  supportTestnetChainIds: CHAIN_ID[]
}

export function SelectedChainHeader({ supportedChainIds, supportTestnetChainIds }: Props) {
  const { chainId } = useWeb3ReactPlus()

  const menuButton = <A>[change]</A>

  return (
    <>
      <Dot connected={!!chainId} />
      {chainId === 1 ? 'Ethereum Mainnet' : getChainName(chainId)} &nbsp;
      <ChainSelectorMenu
        menuButton={menuButton}
        offSetY={0}
        supportedChainIds={supportedChainIds}
        supportTestnetChainIds={supportTestnetChainIds}
      />
    </>
  )
}

const Dot = styled.div<{ connected: boolean }>`
  color: ${(props) =>
    props.connected ? props.theme.colors.semantic.neutral : props.theme.colors.semantic.invalid};
  background-color: #627eea;
  height: 10px;
  width: 10px;
  border-radius: 100px;
  float: left;
  align-items: center;
  margin-right: 4px;
`

const A = styled.a`
  color: ${SAFFRON_RED_HEX};
  cursor: pointer;
`

import styled from 'styled-components'

import { VaultFormCard } from './DefaultCreateVaultForm'

export function SingleCreateVaultForm() {
  return (
    <VaultFormCard>
      <Message>Coming Soon</Message>
    </VaultFormCard>
  )
}

const Message = styled.div`
  display: flex;
  align-self: center;
  font-weight: 500;
  font-size: 17px;
  color: ${(props) => props.theme.colors.text.primary};
`

import styled from 'styled-components'

import { InfoContainer, ColumnHeader, ColumnValue } from './'
import { StylingProps } from '../../styles'

interface Props extends StylingProps {
  icon: React.ReactNode
  header: string
  subheader: string
}

export function TitleInfoBlock({ icon, header, subheader, className }: Props) {
  return (
    <TitleInfoColumn className={className}>
      <IconColumn>
        <IconContainer>{icon}</IconContainer>
      </IconColumn>
      <TitleColumn>
        <InfoContainer>
          <ColumnHeader>{header}</ColumnHeader>
          <ColumnValue>{subheader}</ColumnValue>
        </InfoContainer>
      </TitleColumn>
    </TitleInfoColumn>
  )
}

const TitleColumn = styled.div`
  width: 17%;
  align-items: center;
  margin-right: auto;
  white-space: nowrap;
`

const TitleInfoColumn = styled.div`
  width: 13%;
  display: inline-flex;
`

const IconColumn = styled.div`
  width: 50px;
  display: inline-flex;
  text-align: left;
  align-items: center;
`

const IconContainer = styled.div`
  width: 50px;
  display: inline-flex;
`

import styled from 'styled-components'
import { useMemo, useState } from 'react'
import * as ethers from 'ethers'

import { CHAIN_ID, CHAIN_INFO, useErc20, useWeb3ReactPlus } from '@packages/web3-react-plus'

import {
  A,
  formatDataTestId,
  Modal,
  ModalCloseIcon,
  ModalProps,
  ModalTitle,
  TextInput,
  useDebounce,
} from '../../shared'
import { Token } from '../interfaces'
import { TokenIcon } from './TokenIcon'
import { useBlockExplorerBaseUrl } from '../../chains'

const DEBOUNCE_MS = 200

interface Props extends ModalProps {
  tokens: Token[]
  onTokenSelected: (token: Token) => void
  useWrappedTokens?: boolean
}

function nativeAndWrappedTokenUpdates({ token, chainId }: { token: Token; chainId: number }) {
  const chainInfo = CHAIN_INFO[chainId as CHAIN_ID]
  if (!chainInfo) return token

  // if it's a native currency change the address to the wrapped token
  if (token.symbol === chainInfo.nativeCurrency.symbol) {
    token.address = chainInfo.wrappedNativeToken.address
    // else if its the wrapped token display the name as the native currency
  } else if (token.symbol === chainInfo.wrappedNativeToken.symbol) {
    token.name = chainInfo.nativeCurrency.name
    token.symbol = chainInfo.nativeCurrency.symbol
  }

  return token
}

function findNativeCurrency({ searchText, token }: { searchText: string; token: Token }) {
  const lowerCaseTokenSymbol = token.symbol.toLowerCase()
  const lowerCaseSearchText = searchText.toLowerCase()
  if (lowerCaseSearchText.charAt(0) === 'w' && 'w' + lowerCaseTokenSymbol === lowerCaseSearchText) {
    return true
  }
}

export function SelectTokenModal({
  tokens,
  onTokenSelected,
  isOpen,
  onRequestClose,
  useWrappedTokens = false,
}: Props) {
  const { chainId } = useWeb3ReactPlus()
  const [searchText, setSearchText] = useState<string>('')
  const debouncedSearchText = useDebounce(searchText, DEBOUNCE_MS)
  const [tokenAddress, setTokenAddress] = useState<string>()
  const customToken = useErc20(tokenAddress)
  const { url } = useBlockExplorerBaseUrl()

  const filteredTokens = useMemo(() => {
    const filtered = tokens.filter((token) => {
      if (token.symbol.toLowerCase().includes(debouncedSearchText)) return true
      if (token.name.toLowerCase().includes(debouncedSearchText)) return true
      if (token.address.toLowerCase().includes(debouncedSearchText.toLowerCase())) return true
      if (useWrappedTokens && findNativeCurrency({ searchText: debouncedSearchText, token }))
        return true

      return false
    })

    if (ethers.utils.isAddress(debouncedSearchText) && filtered.length === 0) {
      setTokenAddress(debouncedSearchText)
    } else {
      setTokenAddress(undefined)
    }

    return filtered
  }, [tokens, debouncedSearchText])

  function onSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target
    setSearchText(value.toLowerCase())
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <ModalTitleWrapper>Select a Token</ModalTitleWrapper>
      <ModalCloseIcon onRequestClose={onRequestClose} />

      <DisplayText>Search or paste token address below</DisplayText>
      <Search
        placeholder='Search'
        value={searchText}
        onChange={onSearchChange}
        data-test-id='select-token-modal-search-input'
        autoFocus
      />
      <TokenList data-test-id='select-token-modal-token-list'>
        {[...(customToken ? [customToken] : []), ...filteredTokens].map((token) => {
          const updatedToken = useWrappedTokens
            ? nativeAndWrappedTokenUpdates({ token, chainId })
            : token
          return (
            <TokenItem
              key={updatedToken.address + updatedToken.symbol}
              onClick={() => onTokenSelected(updatedToken)}
              data-test-id={'select-token-modal-token' + formatDataTestId(updatedToken.symbol)}
            >
              <TokenIconContainer>
                <TokenIcon size={24} tokenSymbol0={updatedToken.symbol}></TokenIcon>
                <TokenSymbol>{updatedToken.symbol}</TokenSymbol>- {updatedToken.name}
              </TokenIconContainer>
              <Link
                href={`${url}address/${updatedToken.address}`}
                onClick={(e) => e.stopPropagation()}
                useIcon
                iconWidth={20}
                iconHeight={14}
              />
            </TokenItem>
          )
        })}
      </TokenList>
    </Modal>
  )
}

const ModalTitleWrapper = styled(ModalTitle)`
  margin-bottom: 11px;
`

const Search = styled(TextInput)`
  background-color: ${(props) => props.theme.colors.backgrounds.tertiary};
  width: 100%;
`

const DisplayText = styled.span`
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  color: ${(props) => props.theme.colors.text.secondary};
  padding-bottom: 5px;
`

const Link = styled(A)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 10px;
  padding-left: 15px;
`

const TokenList = styled.div`
  overflow: scroll;
  height: 300px;
  padding: 10px 0px;
`

const TokenItem = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.colors.backgrounds.tertiary};
  }
`

const TokenSymbol = styled.div`
  font-weight: 700;
  font-size: 15px;
`

const TokenIconContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

import styled from 'styled-components'

import { GIT_SHA } from 'src/config'

export function Footer() {
  return <Container>Version: {GIT_SHA}</Container>
}

const Container = styled.div`
  width: 100%;

  bottom: 0;
  left: 0;
  bottom: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  color: ${(props) => props.theme.colors.text.primary};
`

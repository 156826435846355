import styled, { css } from 'styled-components'

export enum Timeframe {
  ONE_DAY = '24H',
  ONE_WEEK = '7D',
  ONE_MONTH = '1M',
  ONE_YEAR = '1Y',
  ALL_TIME = 'ALL',
}

interface Props {
  selectedTimeframe: Timeframe
  onTimeframeChange: (timeframe: Timeframe) => void
}

export function TimeframeSelector({ selectedTimeframe, onTimeframeChange }: Props) {
  const buttons = Object.keys(Timeframe).map((tf) => {
    const timeframe = Timeframe[tf as keyof typeof Timeframe]
    return (
      <Button
        key={timeframe}
        selected={`${selectedTimeframe}` === timeframe}
        onClick={() => onTimeframeChange(timeframe)}
      >
        {timeframe}
      </Button>
    )
  })
  return <Container>{buttons}</Container>
}

const Container = styled.div`
  height: 30px;
  width: 190px;
  border-radius: 100px;
  background-color: ${(props) => props.theme.colors.backgrounds.primary};
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 0px 10px;
`

const Button = styled.div<{ selected?: boolean }>`
  font-weight: 400;
  font-size: 13px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.text.secondary};
  ${(props) =>
    props.selected &&
    css`
      color: ${(props) => props.theme.colors.text.primary};
    `}
`

import { BigNumber } from 'ethers'
import { Web3Provider } from '@ethersproject/providers'
import { useState } from 'react'

import { INonfungiblePositionManager__factory } from '@packages/contract-types'
import { useUpdatePerBlock, useWeb3ReactPlus } from '@packages/web3-react-plus'
import { BIG_NUMBER_ZERO } from '@packages/bn'

import { UPDATES_PER_BLOCK } from '../../config'

const defaultFees: [BigNumber, BigNumber] = [BIG_NUMBER_ZERO, BIG_NUMBER_ZERO]

export function useUniswapPositionFees({
  tokenId,
  nonFungiblePositionManagerAddress,
}: {
  tokenId?: number
  nonFungiblePositionManagerAddress: string
}) {
  const { provider } = useWeb3ReactPlus()
  const [fees, setFees] = useState<[BigNumber, BigNumber]>(defaultFees)

  useUpdatePerBlock(
    () =>
      getPositionFees({
        web3Provider: provider,
        tokenId,
        setFees,
        nonFungiblePositionManagerAddress,
      }),
    [provider, tokenId],
    {
      updateEveryNBlocks: UPDATES_PER_BLOCK.SEMI_CRITICAL,
    }
  )

  return fees
}

const MAX_UINT128 = BigNumber.from(2).pow(128).sub(1)

async function getPositionFees({
  tokenId,
  web3Provider,
  setFees,
  nonFungiblePositionManagerAddress,
}: {
  tokenId?: number
  web3Provider?: Web3Provider
  setFees: (fees: [BigNumber, BigNumber]) => void
  nonFungiblePositionManagerAddress: string
}) {
  if (!tokenId || !web3Provider) return

  const nonFungiblePositionManagerContract = INonfungiblePositionManager__factory.connect(
    nonFungiblePositionManagerAddress,
    web3Provider
  )

  try {
    const owner = await nonFungiblePositionManagerContract.ownerOf(tokenId)

    const [amount0, amount1] = await nonFungiblePositionManagerContract.callStatic.collect(
      {
        tokenId,
        recipient: owner, // some tokens might fail if transferred to address(0)
        amount0Max: MAX_UINT128,
        amount1Max: MAX_UINT128,
      },
      { from: owner } // need to simulate the call as the owner
    )

    setFees([amount0, amount1])
  } catch (error) {
    console.error(`Error getting ownerOf tokenId ${tokenId}`, error)
  }
}

import { BigNumber } from 'ethers'

import { ProgressBar, StylingProps } from '@packages/ui'

import { VAULT_STATUS } from '../../constants'
import { Vault } from '../../interfaces'
import { formatFixedCapacity } from '../../utils'
import { useClaimTokenTotalSupply, useFixedBearerTokenTotalSupply } from '../../hooks'

interface Props extends StylingProps {
  vault: Vault
  usdLiquidityValue: BigNumber
}

export function FixedCapacityProgressBar({ vault, usdLiquidityValue, className }: Props) {
  const [fixedBearerTokenTotalSupply] = useFixedBearerTokenTotalSupply(vault)
  const [claimTokenTotalSupply] = useClaimTokenTotalSupply(vault)

  const totalSupply =
    (claimTokenTotalSupply?.toNumber() ?? 0) > 0 ? 1 : fixedBearerTokenTotalSupply?.toNumber()
  const title =
    totalSupply === 1 || vault.status !== VAULT_STATUS.NOT_STARTED
      ? 'Fixed Capacity Met'
      : 'Fixed Side Deposit'

  return (
    <ProgressBar
      className={className}
      percent={totalSupply === 1 ? 100 : 0}
      title={title}
      amount={formatFixedCapacity(usdLiquidityValue)}
    />
  )
}

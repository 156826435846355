import styled, { keyframes, ThemeProvider } from 'styled-components'

import { Loading, mediaQuery, Chains, Tokens, SharedAppState } from '@packages/ui'
import { useWeb3ReactPlus } from '@packages/web3-react-plus'

import GlobalStyle from './globalStyles'
import { Footer, NavBar, PageRoutes } from './pages'
import { useVaults } from './vaults'
import { SUPPORTED_CHAIN_IDS } from './config'

export function App() {
  const { triedAutoconnectInjected, chainId } = useWeb3ReactPlus()
  const [{ theme }] = SharedAppState.useAppStateReducer()

  Tokens.useTokens()

  let bodyContent = <Loading />

  if (!Chains.isSupportedChain(chainId, SUPPORTED_CHAIN_IDS)) {
    bodyContent = <Chains.UnsupportedChain />
  } else if (triedAutoconnectInjected) {
    bodyContent = <PageRoutes />
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Section>
        <NavBar />
        <Body>{bodyContent}</Body>
      </Section>
      <Footer />
    </ThemeProvider>
  )
}

const Section = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${mediaQuery('small')} {
    padding: 10px;
  }
`

const fadeInOnLoad = keyframes`
  0% {
    opacity: 0
  }
  100% {
    opacity: 1;
  }
`

const Body = styled.div`
  width: 100%;
  animation: ${fadeInOnLoad} 0.15s ease-out;
`

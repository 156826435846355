import { Web3Provider } from '@ethersproject/providers'
import { BigNumber } from 'ethers'
import { useEffect, useState } from 'react'

import {
  getAmountsFromLiquidity,
  getFullRangeAmountsFromLiquidity,
  getFullRangeLiquidity,
  getRangedLiquidityViaTick,
} from '@packages/uniswap'
import { useUpdatePerBlock, useWeb3ReactPlus } from '@packages/web3-react-plus'
import { BIG_NUMBER_ZERO } from '@packages/bn'
import { Uniswap, UPDATES_PER_BLOCK } from '@packages/ui'

import { Vault, UniV3Adapter } from '../interfaces'

interface Amounts {
  amount0: BigNumber
  amount1: BigNumber
}

const defaultAmounts: Amounts = { amount0: BIG_NUMBER_ZERO, amount1: BIG_NUMBER_ZERO }

export function useAmountsForLiquidity(vault: Vault, adapter?: UniV3Adapter) {
  const { provider } = useWeb3ReactPlus()
  const { pool } = Uniswap.usePool(adapter?.pool.address ?? '')
  const [amounts, setAmounts] = useState<Amounts>(defaultAmounts)

  useUpdatePerBlock(
    () =>
      getAmounts({
        pool,
        liquidity: vault.fixedSideCapacity,
        isFullRange: adapter?.isFullRange,
        minTick: adapter?.minTick,
        maxTick: adapter?.maxTick,
        web3Provider: provider,
        setAmounts,
      }),
    [pool, vault, adapter, provider, setAmounts],
    {
      updateEveryNBlocks: UPDATES_PER_BLOCK.NOT_CRITICAL,
    }
  )

  return amounts
}

interface LiquidityParams {
  fixedCapacityUsd?: number
  isFullRange?: boolean
  minTick?: number
  maxTick?: number
  pool?: Uniswap.UniswapV3Pool
  token0UsdPrice: number
  token1UsdPrice: number
}

export function useEstimatedAmountsForLiquidity({
  fixedCapacityUsd,
  isFullRange,
  minTick,
  maxTick,
  pool,
  token0UsdPrice,
  token1UsdPrice,
}: LiquidityParams) {
  const [amounts, setAmounts] = useState({ amount0: 0, amount1: 0 })

  useEffect(() => {
    if (typeof fixedCapacityUsd !== 'undefined' && typeof pool !== 'undefined') {
      if (isFullRange) {
        fullRangeLiquidity({
          fixedCapacityUsd,
          pool,
          token0UsdPrice,
          token1UsdPrice,
          setAmounts,
        })
      } else if (typeof minTick !== 'undefined' && typeof maxTick !== 'undefined') {
        rangedLiquidity({
          fixedCapacityUsd,
          minTick,
          maxTick,
          pool,
          token0UsdPrice,
          token1UsdPrice,
          setAmounts,
        })
      }
    } else {
      setAmounts({ amount0: 0, amount1: 0 })
    }
  }, [fixedCapacityUsd, pool, token0UsdPrice, token1UsdPrice, minTick, maxTick, isFullRange])

  return { amount0: amounts.amount0.toFixed(4), amount1: amounts.amount1.toFixed(4) }
}

async function rangedLiquidity({
  fixedCapacityUsd,
  minTick,
  maxTick,
  pool,
  token0UsdPrice,
  token1UsdPrice,
  setAmounts,
}: {
  fixedCapacityUsd: number
  minTick: number
  maxTick: number
  pool: Uniswap.UniswapV3Pool
  token0UsdPrice: number
  token1UsdPrice: number
  setAmounts: (params: { amount0: number; amount1: number }) => void
}) {
  const liquidityParams = await getRangedLiquidityViaTick({
    poolTickSpacing: pool.tickSpacing,
    currentTick: pool.tick,
    totalUsdDesiredAmount: fixedCapacityUsd,
    currentPrice: parseFloat(pool.token0Price),
    token0Decimals: pool.token0.decimals,
    token1Decimals: pool.token1.decimals,
    token0UsdPrice: token0UsdPrice,
    token1UsdPrice: token1UsdPrice,
    lowerTick: minTick,
    upperTick: maxTick,
  })
  setAmounts(liquidityParams)
}

async function fullRangeLiquidity({
  fixedCapacityUsd,
  pool,
  token0UsdPrice,
  token1UsdPrice,
  setAmounts,
}: {
  fixedCapacityUsd: number
  pool: Uniswap.UniswapV3Pool
  token0UsdPrice: number
  token1UsdPrice: number
  setAmounts: (params: { amount0: number; amount1: number }) => void
}) {
  const liquidityParams = await getFullRangeLiquidity({
    poolTickSpacing: pool.tickSpacing,
    currentTick: pool.tick,
    totalUsdDesiredAmount: fixedCapacityUsd,
    currentPrice: parseFloat(pool.token0Price),
    token0Decimals: pool.token0.decimals,
    token1Decimals: pool.token1.decimals,
    token0UsdPrice: token0UsdPrice,
    token1UsdPrice: token1UsdPrice,
  })
  setAmounts(liquidityParams)
}

async function getAmounts({
  web3Provider,
  liquidity,
  isFullRange,
  minTick,
  maxTick,
  pool,
  setAmounts,
}: {
  web3Provider?: Web3Provider
  liquidity: BigNumber
  isFullRange?: boolean
  minTick?: number
  maxTick?: number
  pool?: Uniswap.UniswapV3Pool
  setAmounts: (amounts: Amounts) => void
}) {
  if (
    typeof isFullRange === 'undefined' ||
    typeof maxTick === 'undefined' ||
    typeof minTick === 'undefined' ||
    !web3Provider ||
    !pool
  )
    return

  let amounts: Amounts = {
    amount0: BIG_NUMBER_ZERO,
    amount1: BIG_NUMBER_ZERO,
  }

  if (isFullRange) {
    amounts = await getFullRangeAmountsFromLiquidity({
      liquidity,
      tickCurrent: pool.tick,
      poolTickSpacing: pool.tickSpacing,
    })
  } else {
    amounts = await getAmountsFromLiquidity({
      liquidity,
      tickLower: minTick,
      tickUpper: maxTick,
      tickCurrent: pool.tick,
    })
  }

  setAmounts(amounts)
}

import invariant from 'tiny-invariant'
import JSBI from 'jsbi'
import BigNumber from 'bignumber.js'

import { bn } from '@packages/bn'

import { JSBI_CONSTANTS } from './constants'
import { fromBigNumbertoJSBI } from './converters'
import { sqrt } from './utils'

export const MIN_TICK = -887272
export const MAX_TICK = -MIN_TICK

export const MIN_SQRT_RATIO = JSBI.BigInt('4295128739')
export const MAX_SQRT_RATIO = JSBI.BigInt('1461446703485210103287273052203988822378723970342')

export function getMinMaxTicks(tickSpacing: number) {
  const minTick = MIN_TICK - (MIN_TICK % tickSpacing)
  const maxTick = MAX_TICK - (MAX_TICK % tickSpacing)

  return { minTick, maxTick }
}

export const encodePriceSqrt = (price: number | string | JSBI): JSBI => {
  return JSBI.multiply(sqrt(JSBI.BigInt(price)), JSBI_CONSTANTS.Q96)
}

function mulShift(val: JSBI, mulBy: string): JSBI {
  return JSBI.signedRightShift(JSBI.multiply(val, JSBI.BigInt(mulBy)), JSBI.BigInt(128))
}

function mulDiv(a: JSBI, b: JSBI, multiplier: JSBI) {
  return JSBI.divide(JSBI.multiply(a, b), multiplier)
}

export function getSqrtRatioAtTick(tick: number): JSBI {
  invariant(tick >= MIN_TICK && tick <= MAX_TICK && Number.isInteger(tick), 'TICK')
  const absTick: number = tick < 0 ? tick * -1 : tick

  let ratio: JSBI =
    (absTick & 0x1) != 0
      ? JSBI.BigInt('0xfffcb933bd6fad37aa2d162d1a594001')
      : JSBI.BigInt('0x100000000000000000000000000000000')
  if ((absTick & 0x2) != 0) ratio = mulShift(ratio, '0xfff97272373d413259a46990580e213a')
  if ((absTick & 0x4) != 0) ratio = mulShift(ratio, '0xfff2e50f5f656932ef12357cf3c7fdcc')
  if ((absTick & 0x8) != 0) ratio = mulShift(ratio, '0xffe5caca7e10e4e61c3624eaa0941cd0')
  if ((absTick & 0x10) != 0) ratio = mulShift(ratio, '0xffcb9843d60f6159c9db58835c926644')
  if ((absTick & 0x20) != 0) ratio = mulShift(ratio, '0xff973b41fa98c081472e6896dfb254c0')
  if ((absTick & 0x40) != 0) ratio = mulShift(ratio, '0xff2ea16466c96a3843ec78b326b52861')
  if ((absTick & 0x80) != 0) ratio = mulShift(ratio, '0xfe5dee046a99a2a811c461f1969c3053')
  if ((absTick & 0x100) != 0) ratio = mulShift(ratio, '0xfcbe86c7900a88aedcffc83b479aa3a4')
  if ((absTick & 0x200) != 0) ratio = mulShift(ratio, '0xf987a7253ac413176f2b074cf7815e54')
  if ((absTick & 0x400) != 0) ratio = mulShift(ratio, '0xf3392b0822b70005940c7a398e4b70f3')
  if ((absTick & 0x800) != 0) ratio = mulShift(ratio, '0xe7159475a2c29b7443b29c7fa6e889d9')
  if ((absTick & 0x1000) != 0) ratio = mulShift(ratio, '0xd097f3bdfd2022b8845ad8f792aa5825')
  if ((absTick & 0x2000) != 0) ratio = mulShift(ratio, '0xa9f746462d870fdf8a65dc1f90e061e5')
  if ((absTick & 0x4000) != 0) ratio = mulShift(ratio, '0x70d869a156d2a1b890bb3df62baf32f7')
  if ((absTick & 0x8000) != 0) ratio = mulShift(ratio, '0x31be135f97d08fd981231505542fcfa6')
  if ((absTick & 0x10000) != 0) ratio = mulShift(ratio, '0x9aa508b5b7a84e1c677de54f3e99bc9')
  if ((absTick & 0x20000) != 0) ratio = mulShift(ratio, '0x5d6af8dedb81196699c329225ee604')
  if ((absTick & 0x40000) != 0) ratio = mulShift(ratio, '0x2216e584f5fa1ea926041bedfe98')
  if ((absTick & 0x80000) != 0) ratio = mulShift(ratio, '0x48a170391f7dc42444e8fa2')

  if (tick > 0) ratio = JSBI.divide(JSBI_CONSTANTS.MaxUint256, ratio)

  // back to Q96
  return JSBI.greaterThan(JSBI.remainder(ratio, JSBI_CONSTANTS.Q32), JSBI_CONSTANTS.ZERO)
    ? JSBI.add(JSBI.divide(ratio, JSBI_CONSTANTS.Q32), JSBI_CONSTANTS.ONE)
    : JSBI.divide(ratio, JSBI_CONSTANTS.Q32)
}

const toJSBI = (num: number, decimals: number): JSBI => {
  return fromBigNumbertoJSBI(bn(`${num}`, decimals))
}

export function nearestUsableTick(tick: number, tickSpacing: number) {
  invariant(Number.isInteger(tick) && Number.isInteger(tickSpacing), 'INTEGERS')
  invariant(tickSpacing > 0, 'TICK_SPACING')
  invariant(tick >= MIN_TICK && tick <= MAX_TICK, 'TICK_BOUND')
  const rounded = Math.round(tick / tickSpacing) * tickSpacing
  if (rounded < MIN_TICK) return rounded + tickSpacing
  else if (rounded > MAX_TICK) return rounded - tickSpacing
  else return rounded
}

// Need to use bignumber.js lib to not lose precision converting ticks to prices
BigNumber.config({ EXPONENTIAL_AT: 999999, DECIMAL_PLACES: 40 })

const Q96 = new BigNumber(2).pow(96)

function mulDivBigNumber(a: BigNumber, b: BigNumber, multiplier: BigNumber) {
  return a.multipliedBy(b).div(multiplier)
}

function encodePriceSqrtBigNumber(price: number | string | BigNumber): BigNumber {
  return new BigNumber(price).sqrt().multipliedBy(Q96).integerValue(3)
}

function expandDecimalsBigNumber(n: number | string | BigNumber, exp: number): BigNumber {
  return new BigNumber(n).multipliedBy(new BigNumber(10).pow(exp))
}

const priceToTick = ({
  price,
  token0Decimals,
  token1Decimals,
}: {
  price: number
  token0Decimals: number
  token1Decimals: number
}): number => {
  const token0 = expandDecimalsBigNumber(price, Number(token0Decimals))
  const token1 = expandDecimalsBigNumber(1, Number(token1Decimals))
  const sqrtPrice = mulDivBigNumber(
    encodePriceSqrtBigNumber(token1),
    Q96,
    encodePriceSqrtBigNumber(token0)
  ).div(Q96)

  return Math.floor(Math.log(sqrtPrice.toNumber()) / Math.log(Math.sqrt(1.0001)))
}

export const priceToClosestTick = ({
  price,
  tickSpacing,
  token0Decimals,
  token1Decimals,
}: {
  price: number
  tickSpacing: number
  token0Decimals: number
  token1Decimals: number
}): number => {
  const tick = priceToTick({ price, token0Decimals, token1Decimals })
  return nearestUsableTick(tick, tickSpacing)
}

export const tickToPrice = ({
  tick,
  token0Decimals,
  token1Decimals,
}: {
  tick: number
  token0Decimals: number
  token1Decimals: number
}): number => {
  const sqrtPrice = new BigNumber(Math.pow(Math.sqrt(1.0001), tick)).multipliedBy(Q96)
  const token0 = expandDecimalsBigNumber(1, token0Decimals)
  const token1 = expandDecimalsBigNumber(1, token1Decimals)
  const L2 = mulDivBigNumber(
    encodePriceSqrtBigNumber(token0),
    encodePriceSqrtBigNumber(token1),
    Q96
  )
  const price = mulDivBigNumber(L2, Q96, sqrtPrice)
    .div(Q96)
    .div(new BigNumber(10).pow(token0Decimals))
    .pow(2)

  return price.toNumber()
}

const Q192 = new BigNumber(2).pow(192)
const ONE = new BigNumber(1)

export function sqrtPriceX96ToTokenPrices(
  sqrtPriceX96: string,
  token0Decimals: number,
  token1Decimals: number
) {
  const sqrtPrice = new BigNumber(sqrtPriceX96)
  const num = sqrtPrice.multipliedBy(sqrtPrice)
  const denom = Q192
  const price1 = num
    .div(denom)
    .times(expandDecimalsBigNumber(1, token0Decimals))
    .div(expandDecimalsBigNumber(1, token1Decimals))

  const price0 = ONE.div(price1)
  return [price0.toString(), price1.toString()]
}

import { useEffect, useRef, useState } from 'react'

import { useWeb3ReactPlus } from '../Web3ReactPlusProvider'
import { useBlockNumber } from './useBlockNumber'

interface Config {
  updateEveryNBlocks?: number
}

/** Callback function should have a unique function signature,
 * else it will collide with other usages of this hook in the chainState perBlockFunctions map */
export function useUpdatePerBlock(
  callback: () => Promise<any>,
  deps: any[],
  { updateEveryNBlocks = 1 }: Config
) {
  const { provider, chainId, ready } = useWeb3ReactPlus()
  const blockNumber = useBlockNumber()

  const savedCallback = useRef(callback)
  const [lastUpdatedBlock, setLastUpdatedBlock] = useState(0)
  const [isUpdating, setIsUpdating] = useState(false)

  const update = async ({ force } = { force: false }) => {
    if (isUpdating && !force) {
      return // Another component is currently handling this update
    }

    const shouldUpdate = blockNumber - lastUpdatedBlock >= updateEveryNBlocks
    if (!(shouldUpdate || force)) {
      return // We've already updated within the last N blocks, and we're not forcing through anyway
    }

    try {
      setIsUpdating(true)
      await savedCallback.current()
    } catch (error) {
      console.error(error)
    } finally {
      setIsUpdating(false)
      setLastUpdatedBlock(blockNumber)
    }
  }

  useEffect(() => {
    // reset if chainId changes
    setLastUpdatedBlock(0)
    setIsUpdating(false)
  }, [chainId])

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    if (!provider || !blockNumber) return
    update()
  }, [ready, provider, chainId, blockNumber])

  useEffect(() => {
    if (!provider || !blockNumber) return
    update({ force: true })
  }, [...deps])
}

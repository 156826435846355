import { css, keyframes } from 'styled-components'

import { SAFFRON_RED_HEX } from '../styles'

const CycleSfiColors = keyframes`
  0% {
    background-position: right;
  }
  100% {
    background-position: left;
  }
`

export const GradientStyles = css`
  background-image: linear-gradient(
    to right,
    ${SAFFRON_RED_HEX},
    ${SAFFRON_RED_HEX},
    #c48c36,
    ${SAFFRON_RED_HEX},
    ${SAFFRON_RED_HEX}
  );
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;

  background-size: 400%;
  background-position: right;

  animation-name: ${CycleSfiColors};
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
`

import { UniswapTick } from '../interfaces'
import { queryUniswapGraph } from './queryUniswapGraph'

export const getPoolTicks = async (
  poolAddress: string,
  chainId: number
): Promise<UniswapTick[]> => {
  const { ticks } = await queryUniswapGraph(
    chainId,
    `{
      ticks(first: 1000, skip: 0, where: { poolAddress: "${poolAddress}" }, orderBy: tickIdx) {
        tickIdx
        liquidityNet
        price0
        price1
      }
    }`
  )

  return ticks as UniswapTick[]
}

/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  IUniV3Vault,
  IUniV3VaultInterface,
} from "../../../../contracts/interfaces/IVault.sol/IUniV3Vault";

const _abi = [
  {
    inputs: [
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "side",
        type: "uint256",
      },
      {
        internalType: "bytes",
        name: "data",
        type: "bytes",
      },
    ],
    name: "deposit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "earningsSettled",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "fixedSideCapacity",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_vaultId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_duration",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "_adapter",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_fixedSideCapacity",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_variableSideCapacity",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "_variableAsset",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_feeBps",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "_feeReceiver",
        type: "address",
      },
    ],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "isStarted",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "side",
        type: "uint256",
      },
      {
        internalType: "bytes",
        name: "data",
        type: "bytes",
      },
    ],
    name: "withdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
] as const;

export class IUniV3Vault__factory {
  static readonly abi = _abi;
  static createInterface(): IUniV3VaultInterface {
    return new utils.Interface(_abi) as IUniV3VaultInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IUniV3Vault {
    return new Contract(address, _abi, signerOrProvider) as IUniV3Vault;
  }
}

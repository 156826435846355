import { Token } from '../../tokens'
import { UniswapV3Pool } from '../interfaces'

export function tokensIsSorted({
  token0,
  token1,
  chainId,
  pool,
}: {
  token0?: Token
  token1?: Token
  chainId: number
  pool?: UniswapV3Pool
}): boolean {
  return !!(
    token0 &&
    token1 &&
    (token0.address.toLowerCase() < token1.address.toLowerCase() ||
      (pool && pool.token0.address === token0.address))
  )
}

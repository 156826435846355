import styled from 'styled-components'

import { mediaQuery } from '../utils'

export const MenuIconWrapper = styled.div`
  cursor: pointer;
`

export const MenuIconDiv = styled.div`
  margin-right: 5px;
  margin-bottom: 20px;
  margin-top: 20px;
  margin-left: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  ${mediaQuery('small')} {
    margin-left: 5px;
  }
`

import { DefaultTheme } from 'styled-components'

import { sharedThemeConstants } from './shared'

export const darkTheme: DefaultTheme = {
  type: 'dark',
  colors: {
    backgrounds: {
      primary: '#21252A',
      secondary: '#2E3034',
      tertiary: '#25262a',
      accent: '#25262a',
      button: sharedThemeConstants.colors.backgrounds.button,
    },
    type: sharedThemeConstants.colors.type,
    semantic: {
      ...sharedThemeConstants.colors.semantic,
    },
    borders: {
      accent: '#2E3034',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#8E90A2',
      button: sharedThemeConstants.colors.text.button,
      disabled: '#8E90A2',
    },
    icons: '#FFFFFF',
    activated: '#3b8fed',
  },
}

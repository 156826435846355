import React, { createContext, ReactNode, Reducer, useContext, useReducer } from 'react'
import { deepmergeCustom } from 'deepmerge-ts'
import { OrderedMap } from 'immutable'

import { SharedAppState } from '@packages/ui'

import { Adapter, Vault, VaultFactory, VaultInfo } from './vaults'

const merge = deepmergeCustom({ mergeArrays: false })

// Types

export interface AppState {
  vaults: {
    [chainId: number]: {
      info: SharedAppState.AsyncData<{ [id: number]: VaultInfo }>
      vault: { [id: number]: SharedAppState.AsyncData<Vault> }
      adapter: { [address: string]: SharedAppState.AsyncData<Adapter> }
      displayedVaults: OrderedMap<number, VaultInfo>
      blacklistedVaults: number[]
    }
  }
  vaultFactory: {
    [chainId: number]: SharedAppState.AsyncData<VaultFactory>
  }
}

export enum AppStateActionName {
  UpdateVaultFactory = 'UpdateVaultFactory',
  UpdateVaultsInfo = 'UpdateVaultsInfo',
  UpdateVault = 'UpdateVault',
  HideVault = 'HideVault',
  UpdateVaultAdapter = 'UpdateVaultAdapter',
  UpdateDisplayedVaults = 'UpdateDisplayedVaults',
}

// vaults

interface UpdateVaultFactoryAction extends SharedAppState.Action {
  type: AppStateActionName.UpdateVaultFactory
  payload: SharedAppState.AsyncData<VaultFactory> & { chainId: number }
}

interface UpdateVaultsInfoAction extends SharedAppState.Action {
  type: AppStateActionName.UpdateVaultsInfo
  payload: SharedAppState.AsyncData<VaultInfo[]> & { chainId: number }
}

interface UpdateVaultAction extends SharedAppState.Action {
  type: AppStateActionName.UpdateVault
  payload: SharedAppState.AsyncData<Vault> & { chainId: number; id: number }
}

interface HideVaultAction extends SharedAppState.Action {
  type: AppStateActionName.HideVault
  payload: { chainId: number; id: number }
}

interface UpdateVaultAdapterAction extends SharedAppState.Action {
  type: AppStateActionName.UpdateVaultAdapter
  payload: SharedAppState.AsyncData<Adapter> & { chainId: number; address: string }
}

interface UpdateDisplayedVaultsAction extends SharedAppState.Action {
  type: AppStateActionName.UpdateDisplayedVaults
  payload: SharedAppState.AsyncData<VaultInfo[]> & { chainId: number }
}

export type AppStateAction =
  | UpdateVaultFactoryAction
  | UpdateVaultsInfoAction
  | UpdateVaultAction
  | HideVaultAction
  | UpdateVaultAdapterAction
  | UpdateDisplayedVaultsAction

// Reducer action router
function appStateReducer(state: AppState, action: AppStateAction): AppState {
  switch (action.type) {
    case AppStateActionName.UpdateVaultFactory:
      return updateVaultFactory(state, action.payload)
    case AppStateActionName.UpdateVaultsInfo:
      return updateVaultsInfo(state, action.payload)
    case AppStateActionName.UpdateVault:
      return updateVault(state, action.payload)
    case AppStateActionName.HideVault:
      return hideVault(state, action.payload)
    case AppStateActionName.UpdateVaultAdapter:
      return updateVaultAdapter(state, action.payload)
    case AppStateActionName.UpdateDisplayedVaults:
      return updateDisplayedVaults(state, action.payload)
    default:
      throw new Error(`Action type '${action['type']} not supported.`)
  }
}

// Reducers

// vaults

function updateVaultFactory(
  state: AppState,
  payload: UpdateVaultFactoryAction['payload']
): AppState {
  const { chainId } = payload

  const newState = merge(state, {
    vaultFactory: {
      [chainId]: SharedAppState.updateAsyncData(payload),
    },
  }) as AppState

  return newState
}

function updateVaultsInfo(state: AppState, payload: UpdateVaultsInfoAction['payload']): AppState {
  const { chainId } = payload

  let displayedVaults = state.vaults?.[chainId]?.displayedVaults ?? OrderedMap()

  if (payload.data) {
    displayedVaults = displayedVaults.merge(
      OrderedMap(getVaultMapWithBlackList(state.vaults[chainId].blacklistedVaults, payload.data))
    )
  }

  const newState = merge(state, {
    vaults: {
      [chainId]: {
        info: SharedAppState.updateAsyncData({ ...payload.data, data: getVaultMap(payload.data) }),
        ...(displayedVaults.size > 0 && { displayedVaults }),
      },
    },
  }) as AppState

  return newState
}

function updateVault(state: AppState, payload: UpdateVaultAction['payload']): AppState {
  const { chainId, id } = payload

  const newState = merge(state, {
    vaults: {
      [chainId]: {
        vault: { [id]: SharedAppState.updateAsyncData(payload) },
      },
    },
  }) as AppState

  return newState
}

function hideVault(state: AppState, payload: HideVaultAction['payload']): AppState {
  const { chainId, id } = payload
  const newState = merge(state, {
    vaults: {
      [chainId]: {
        displayedVaults: state.vaults[chainId].displayedVaults.filter((vault) => vault.id !== id),
      },
    },
  }) as AppState

  return newState
}

function updateVaultAdapter(
  state: AppState,
  payload: UpdateVaultAdapterAction['payload']
): AppState {
  const { chainId, address } = payload

  const newState = merge(state, {
    vaults: {
      [chainId]: {
        adapter: { [address]: SharedAppState.updateAsyncData(payload) },
      },
    },
  }) as AppState

  return newState
}

function updateDisplayedVaults(
  state: AppState,
  payload: UpdateDisplayedVaultsAction['payload']
): AppState {
  const { chainId, data } = payload

  const newState = merge(state, {
    vaults: {
      [chainId]: {
        ...(data && {
          displayedVaults: getVaultMapWithBlackList(state.vaults[chainId].blacklistedVaults, data),
        }),
      },
    },
  }) as AppState

  return newState
}

function getVaultMap(vaults?: VaultInfo[]) {
  const vaultMap: { [vaultId: number]: VaultInfo } = {}
  vaults?.forEach((vaultInfo) => {
    if (vaultInfo.id > 0) vaultMap[vaultInfo.id] = vaultInfo
  })

  return vaultMap
}

function getVaultMapWithBlackList(blacklistedVaults: number[] = [], vaults?: VaultInfo[]) {
  let vaultMap: OrderedMap<number, VaultInfo> = OrderedMap()
  vaults?.forEach((vaultInfo) => {
    if (!blacklistedVaults.includes(vaultInfo.id)) {
      vaultMap = vaultMap.set(vaultInfo.id, vaultInfo)
    }
  })

  return vaultMap
}

// Context
export const AppStateContext = createContext([{} as AppState, (action: AppStateAction) => {}])

interface AppStateProviderProps {
  children: ReactNode
}

const DefaultAppState: AppState = {
  vaults: {},
  vaultFactory: {},
}

export function AppStateProvider({ children }: AppStateProviderProps) {
  const [store, dispatch] = useReducer<Reducer<AppState, AppStateAction>>(
    appStateReducer,
    DefaultAppState
  )

  return <AppStateContext.Provider value={[store, dispatch]}>{children}</AppStateContext.Provider>
}

// Hook
export function useAppStateReducer() {
  return useContext(AppStateContext) as [AppState, React.Dispatch<AppStateAction>]
}

export type AppStateDispatch = React.Dispatch<AppStateAction>

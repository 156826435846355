import styled from 'styled-components'

import { StylingProps } from '../../styles'

interface Props extends StylingProps {
  groupName: string
  selectedOption?: string
  options: string[]
  onChange: (value: string) => void
}

export function RadioButtons({ groupName, selectedOption, onChange, className, options }: Props) {
  const handleOnChange = (event: any) => {
    onChange(event.target.value)
  }

  return (
    <Container className={className} onChange={handleOnChange}>
      {options?.map((option) => (
        <InnerContainer key={`radio-button-${option}`}>
          <RadioButton
            id={option}
            name={groupName}
            type='radio'
            checked={selectedOption === option}
            value={option}
          />
          <Label htmlFor={option}>{option}</Label>
        </InnerContainer>
      ))}
    </Container>
  )
}

const Container = styled.div`
  font-weight: 400;
  font-size: 14px;
  font-family: 'Lexend', sans-serif;
  color: ${(props) => props.theme.colors.text.secondary};
  display: flex;
  align-items: center;
`

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
`

const RadioButton = styled.input`
  width: 20px;
  height: 20px;
  accent-color: ${(props) => props.theme.colors.borders.accent};
`

const Label = styled.label``

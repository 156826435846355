import { useEffect } from 'react'
import { BigNumber } from '@ethersproject/bignumber'

import { BalanceState, useChainStateReducer } from '../ChainStateProvider'
import { useWeb3ReactPlus } from '../Web3ReactPlusProvider'
import { useBlockNumber } from './useBlockNumber'

export interface UseBalanceOptions {
  address: string
  autoUpdate?: boolean
  updateEveryNBlocks?: number
}

export type UseBalanceReturnValue = [
  value: BigNumber | undefined,
  isUpdating: boolean,
  update: () => void,
  error: Error | undefined,
  mostRecentError: Error | undefined
]

export function useBalance({
  address,
  autoUpdate,
  updateEveryNBlocks = 1,
}: UseBalanceOptions): UseBalanceReturnValue {
  const [chainState, dispatch] = useChainStateReducer()
  const { provider, chainId, ready } = useWeb3ReactPlus()
  const blockNumber = useBlockNumber()

  const update = ({ force } = { force: false }) => {
    if (!provider) return

    const currentBalanceState =
      chainState?.[chainId ?? 0]?.addresses?.[address]?.balance || ({} as BalanceState)

    if (currentBalanceState.isUpdating) {
      return // Another component is currently handling this update
    }

    const lastUpdatedBlock = currentBalanceState.lastUpdatedBlock || 0
    const shouldUpdate = blockNumber - lastUpdatedBlock >= updateEveryNBlocks
    if (!(shouldUpdate || force)) {
      return // We've already updated within the last N-1 blocks, and we're not forcing through anyway
    }

    dispatch({
      type: 'BalanceUpdating',
      payload: { chainId, address },
    })

    provider
      .getBalance(address)
      .then((value) => {
        dispatch({
          type: 'BalanceUpdated',
          payload: {
            chainId,
            address,
            value,
            blockNumber,
          },
        })
      })
      .catch((e: Error) => {
        console.error(e)
        dispatch({
          type: 'BalanceUpdateFailed',
          payload: {
            chainId,
            address,
            error: e,
            blockNumber,
          },
        })
      })
  }

  useEffect(() => {
    if (!ready || !provider || !address || !blockNumber) return
    if (autoUpdate) {
      update()
    }
  }, [ready, provider, address, autoUpdate, chainId, blockNumber])

  const { value, isUpdating, error, mostRecentError } =
    chainState?.[chainId ?? 0]?.addresses?.[address]?.balance || ({} as BalanceState)

  return [value, !!isUpdating, update, error, mostRecentError]
}

import styled from 'styled-components'

import { StylingProps } from '../../styles'

interface InfoRowsProps extends StylingProps {
  children?: React.ReactNode
}

export function InfoRows({ children, className }: InfoRowsProps) {
  return <InfoRowsContainer className={className}>{children}</InfoRowsContainer>
}

const InfoRowsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

interface InfoRowProps extends StylingProps {
  header: string
  value: string | React.ReactNode
}

export function InfoRow({ header, value, className }: InfoRowProps) {
  return (
    <InfoRowContainer className={className}>
      <RowHeader>{header}</RowHeader>
      <RowValue>{value}</RowValue>
    </InfoRowContainer>
  )
}

const InfoRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 200px;
`

const RowHeader = styled.div`
  flex: 1;
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => props.theme.colors.text.secondary};
`

const RowValue = styled.div`
  flex: 1;
  font-weight: 500;
  font-size: 16px;
  color: ${(props) => props.theme.colors.text.primary};
`

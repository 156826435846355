import styled from 'styled-components'

import { useWeb3ReactPlus, CHAIN_ID } from '@packages/web3-react-plus'

import { DefaultPlatformPerChain, TokenSymbol } from '../constants'
import { getPlatformSwapUrl } from '../utils'
import { useAppStateReducer } from '../../AppState'

interface Props {
  outputTokenSymbol: TokenSymbol
  inputTokenSymbol?: TokenSymbol
}

export function PlatformBuyButton({ outputTokenSymbol, inputTokenSymbol }: Props) {
  const { chainId } = useWeb3ReactPlus()
  const [appState] = useAppStateReducer()

  const platform =
    DefaultPlatformPerChain[chainId as CHAIN_ID] ?? DefaultPlatformPerChain[CHAIN_ID.Ethereum]
  const swapUrl = getPlatformSwapUrl({
    platform,
    tokensBySymbol: appState[chainId].tokens.bySymbol?.data,
    outputTokenSymbol,
    inputTokenSymbol,
  })

  return (
    <Button href={swapUrl} target='_blank' rel='noopener noreferrer'>
      Buy {outputTokenSymbol}
    </Button>
  )
}

const Button = styled.a`
  background-color: ${(props) => props.theme.colors.backgrounds.button};
  border-radius: 10px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-family: Lexend, sans-serif;
  color: ${(props) => props.theme.colors.text.button};
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
`

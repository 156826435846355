import styled, { useTheme } from 'styled-components'

import { CSS_TRANSITION, StylingProps } from '../styles'
import { ReactComponent as LinkIcon } from '../assets/images/open-link.svg'

interface Props
  extends StylingProps,
    React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
  useIcon?: boolean
  iconWidth?: number
  iconHeight?: number
  ref?: (instance: HTMLAnchorElement | null) => void // Get rid of type error
}

export function A({
  href,
  useIcon,
  iconWidth = 17,
  iconHeight = 12,
  children,
  className,
  ...rest
}: Props) {
  const theme = useTheme()
  const iconFillColor = theme.colors.semantic.link

  return (
    <Link
      href={href}
      target='_blank'
      rel='noreferrer'
      className={className}
      {...rest}
      data-test-id='link'
    >
      {children}
      {useIcon && <LinkIcon fill={iconFillColor} width={iconWidth} height={iconHeight} />}
    </Link>
  )
}

const Link = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  color: ${(props) => props.theme.colors.semantic.link};
  text-decoration: underline;
  font-weight: 400;
  font-size: 14px;
  ${CSS_TRANSITION}
  &:hover {
    color: ${(props) => props.theme.colors.text.primary};
  }
`

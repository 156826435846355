import { trimDecimalStringToPrecision } from './trimDecimalStringToPrecision'

export function formatApr(apr?: any): string {
  return `${apr ? trimDecimalStringToPrecision(apr, 2) : 0}%`
}

export function formatTvl(tvl?: any): string {
  return `$${tvl ? trimDecimalStringToPrecision(tvl, 0) : 0}`
}

export function formatCoverage(coverage?: any): string {
  return `${coverage ? trimDecimalStringToPrecision(coverage * 100, 2) : 0}%`
}

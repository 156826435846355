export interface Rates {
  fixed: FixedRates
  variable: VariableRates
}

export interface FixedRates {
  apr: number
  fixedRate: number
  usdEarnings: number
}

export interface VariableRates {
  earningsRate: number | '-'
  usdEarnings: number
  token0Earnings: number
  token1Earnings: number
  // predicted values calculated based off of x number of days of platform data
  estimationsByXDayAverage: { [days: number]: VariableEstimatedRates }
}

export interface VariableEstimatedRates {
  apr: number
  platformDailyRate: number
  annualizedUsdEarnings: number
  usdEarnings: number
}

export const DEFAULT_VARIABLE_RATES: VariableRates = {
  earningsRate: '-',
  usdEarnings: 0,
  token0Earnings: 0,
  token1Earnings: 0,
  estimationsByXDayAverage: {
    1: { apr: 0, platformDailyRate: 0, usdEarnings: 0, annualizedUsdEarnings: 0 },
    30: { apr: 0, platformDailyRate: 0, usdEarnings: 0, annualizedUsdEarnings: 0 },
    90: { apr: 0, platformDailyRate: 0, usdEarnings: 0, annualizedUsdEarnings: 0 },
  },
}

export const DEFAULT_FIXED_RATES: FixedRates = {
  usdEarnings: 0,
  apr: 0,
  fixedRate: 0,
}

export const DEFAULT_RATES: Rates = {
  fixed: DEFAULT_FIXED_RATES,
  variable: DEFAULT_VARIABLE_RATES,
}

import { BigNumber } from 'ethers'
import React, { useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { useAutoAnimate } from '@formkit/auto-animate/react'

import { Chains, InfoTooltip, Uniswap, Tokens, Accounts } from '@packages/ui'
import { BIG_NUMBER_TEN, BIG_NUMBER_ZERO } from '@packages/bn'
import {
  CHAIN_ID,
  CHAIN_INFO,
  useBalance,
  useErc20Balance,
  useWeb3ReactPlus,
} from '@packages/web3-react-plus'
import { ReactComponent as GearIcon } from '@packages/ui/src/shared/assets/images/gear.svg'

import { useAppStateReducer } from 'src/AppState'

import {
  TRANSACTION_DESCRIPTIONS,
  UNI_V3_TRX_DEFAULTS,
  UNI_V3_VAULT_DESCRIPTIONS,
  VAULT_STATUS,
} from '../../../constants'
import { formatSymbol, isExpectedAmount, isZeroAmount } from '../../../utils'
import {
  BigNumberAmountWrapper,
  BigNumberButtonAmount,
  ButtonContent,
  ButtonWrapper,
  ErrorMessageWrapper,
  Section,
  VaultFormProps,
} from './UniV3VaultForm'
import {
  getVault,
  useAmountsForLiquidity,
  useClaimTokenBalance,
  useClaimTokenTotalSupply,
  useFixedBearerTokenBalance,
} from '../../../hooks'
import {
  claimFixedTokens,
  depositUniV3Fixed,
  tokenApproveAllowance,
  withdrawUniV3Fixed,
  wrapNativeCurrency,
} from '../../../transactions'
import { VariableCapacityProgressBar } from '../VariableCapacityProgressBar'
import { UniV3SettingsModal } from './UniV3SettingsModal'
import { FixedCapacityProgressBar } from '../FixedCapacityProgressBar'
import { DurationProgressBar } from '../DurationProgressBar'

export function UniV3FixedForm({ vault, adapter }: VaultFormProps) {
  const theme = useTheme()
  const [parent] = useAutoAnimate()

  const [, dispatch] = useAppStateReducer()
  const {
    pool: { token0, token1 },
  } = adapter
  const { activeAddress, provider, chainId } = useWeb3ReactPlus()
  const { pool } = Uniswap.usePool(adapter.pool.address)
  const amounts = useAmountsForLiquidity(vault, adapter)
  const [fixedBearerTokenBalance, , updateFixedBearerTokenBalance] =
    useFixedBearerTokenBalance(vault)
  const [claimTokenBalance, , updateClaimTokenBalance] = useClaimTokenBalance(vault)
  const [claimTokenTotalSupply, , updateClaimTokenTotalSupply] = useClaimTokenTotalSupply(vault)

  const [nativeCurrencyBalance] = useBalance({ address: activeAddress ?? '' })
  const [token0Balance] = useErc20Balance({
    tokenAddress: token0.address ?? '',
    balanceAddress: activeAddress ?? '',
  })
  const [token1Balance] = useErc20Balance({
    tokenAddress: token1.address ?? '',
    balanceAddress: activeAddress ?? '',
  })

  const [settingsModalOpen, setSettingsModalOpen] = useState(false)
  function toggleSettingsModal() {
    setSettingsModalOpen(!settingsModalOpen)
  }

  const [currentTxStep, setCurrentTxStep] = useState<number>()
  function updateCurrentTxStep(step: number) {
    setCurrentTxStep(step)
    setDepositTxModalOpen(true)
  }

  // all tx fields
  const [expireMs, setExpireMs] = useState(UNI_V3_TRX_DEFAULTS.ALL.EXPIRE_MS)
  const [slippagePercent, setSlippagePercent] = useState(UNI_V3_TRX_DEFAULTS.ALL.SLIPPAGE_PERCENT)

  // deposit fields
  const [depositLoadingText, setDepositLoadingText] = useState<string>()
  const [depositTxHash, setDepositTxHash] = useState<string>()
  const [wrapTxHash, setWrapTxHash] = useState<string>()
  const [token0AllowanceTxHash, setToken0AllowanceTxHash] = useState<string>()
  const [token1AllowanceTxHash, setToken1AllowanceTxHash] = useState<string>()
  const [depositError, setDepositError] = useState<React.ReactNode>()
  const [depositTxModalOpen, setDepositTxModalOpen] = useState(false)

  // withdraw fields
  const [withdrawLoadingText, setWithdrawLoadingText] = useState<string>()
  const [withdrawTxHash, setWithdrawTxHash] = useState<string>()
  const [withdrawError, setWithdrawError] = useState<React.ReactNode>()

  // claim fields
  const [claimLoadingText, setClaimLoadingText] = useState<string>()
  const [claimTxHash, setClaimTxHash] = useState<string>()
  const [claimError, setClaimError] = useState<React.ReactNode>()

  const chainInfo = CHAIN_INFO[chainId as CHAIN_ID]

  function resetData() {
    setDepositTxHash(undefined)
    setWrapTxHash(undefined)
    setToken0AllowanceTxHash(undefined)
    setToken1AllowanceTxHash(undefined)
    setWithdrawTxHash(undefined)
    setClaimTxHash(undefined)
    setCurrentTxStep(undefined)

    setDepositError(undefined)
    setWithdrawError(undefined)
    setClaimError(undefined)
  }

  async function updateData() {
    await getVault({ web3Provider: provider, chainId, dispatch, vaultInfo: vault })
    updateFixedBearerTokenBalance()
    updateClaimTokenTotalSupply()
    updateClaimTokenBalance()
  }

  function hasInsufficientBalance(
    tokenSymbol: string,
    amountExpected: BigNumber,
    amountActual?: BigNumber
  ) {
    let notEnoughToken = (amountActual ?? BIG_NUMBER_ZERO).lt(amountExpected.mul(9).div(10))
    if (notEnoughToken && Tokens.isWrappedNativeToken(tokenSymbol, chainInfo)) {
      notEnoughToken = (nativeCurrencyBalance ?? BIG_NUMBER_ZERO)
        .add(amountActual ?? BIG_NUMBER_ZERO)
        .lt(amountExpected.mul(9).div(10))
    }

    return notEnoughToken
  }

  function getDepositButtonState() {
    if (
      hasInsufficientBalance(token0.symbol, amounts.amount0, token0Balance) ||
      hasInsufficientBalance(token1.symbol, amounts.amount1, token1Balance) ||
      vault.status !== VAULT_STATUS.NOT_STARTED ||
      isExpectedAmount(1, claimTokenTotalSupply) ||
      depositLoadingText
    ) {
      return { disabled: true, loading: depositLoadingText ? true : undefined }
    }

    return {}
  }

  function addBuffer(bufferPercent: number, amount: BigNumber) {
    return amount.mul(100 + bufferPercent).div(100)
  }

  function isGreaterThanWithRoundingBuffer(desiredAmount: BigNumber, actualAmount?: BigNumber) {
    // subtract 10 for rounding errors
    return desiredAmount.sub(BIG_NUMBER_TEN).gt(actualAmount ?? 0)
  }

  async function deposit() {
    // Wallet not connected
    if (!provider || !activeAddress || !pool) return

    resetData()
    setDepositTxModalOpen(true)

    const bufferPercent = 5
    const desiredAmount0 = addBuffer(bufferPercent, amounts.amount0)
    const desiredAmount1 = addBuffer(bufferPercent, amounts.amount1)

    let tx
    let step = 0
    try {
      if (
        Tokens.isWrappedNativeToken(token0.symbol, chainInfo) ||
        Tokens.isWrappedNativeToken(token1.symbol, chainInfo)
      ) {
        updateCurrentTxStep(step)
        step++
      }
      if (
        Tokens.isWrappedNativeToken(token0.symbol, chainInfo) &&
        isGreaterThanWithRoundingBuffer(desiredAmount0, token0Balance)
      ) {
        tx = await wrapNativeCurrency({
          amount: desiredAmount0.sub(token0Balance ?? BIG_NUMBER_ZERO),
          chainInfo,
          web3Provider: provider,
          setLoadingText: setDepositLoadingText,
        })
        setWrapTxHash(tx.hash)
      } else if (
        Tokens.isWrappedNativeToken(token1.symbol, chainInfo) &&
        isGreaterThanWithRoundingBuffer(desiredAmount1, token1Balance)
      ) {
        tx = await wrapNativeCurrency({
          amount: desiredAmount1.sub(token1Balance ?? BIG_NUMBER_ZERO),
          chainInfo,
          web3Provider: provider,
          setLoadingText: setDepositLoadingText,
        })
        setWrapTxHash(tx.hash)
      }

      updateCurrentTxStep(step)
      step++
      tx = await tokenApproveAllowance({
        ownerAddress: activeAddress,
        spenderAddress: vault.adapterAddress,
        token: token0,
        desiredAmount: desiredAmount0,
        amountToApprove: desiredAmount0,
        web3Provider: provider,
        setLoadingText: setDepositLoadingText,
      })
      setToken0AllowanceTxHash(tx?.hash)

      updateCurrentTxStep(step)
      step++
      tx = await tokenApproveAllowance({
        ownerAddress: activeAddress,
        spenderAddress: vault.adapterAddress,
        token: token1,
        desiredAmount: desiredAmount1,
        amountToApprove: desiredAmount1,
        web3Provider: provider,
        setLoadingText: setDepositLoadingText,
      })
      setToken1AllowanceTxHash(tx?.hash)

      updateCurrentTxStep(step)
      setDepositLoadingText('Depositing...')
      const currentBlockNumber = await provider.getBlockNumber()
      tx = await depositUniV3Fixed({
        vault,
        adapter,
        pool,
        web3Provider: provider,
        expireMs,
        blockTimestamp: (await provider.getBlock(currentBlockNumber)).timestamp,
        slippagePercent,
      })
      await tx.wait()
      setDepositTxHash(tx.hash)

      await updateData()
    } catch (error: any) {
      setDepositError(<Chains.BlockExplorerError error={error} tx={tx} />)
    } finally {
      setDepositLoadingText(undefined)
    }
  }

  function getWithdrawButtonState() {
    if (
      (isZeroAmount(claimTokenBalance) && vault.status === VAULT_STATUS.NOT_STARTED) ||
      (isZeroAmount(fixedBearerTokenBalance) && vault.status === VAULT_STATUS.ENDED) ||
      vault.status === VAULT_STATUS.STARTED ||
      withdrawLoadingText
    ) {
      return { disabled: true, loading: withdrawLoadingText ? true : undefined }
    }

    return {}
  }

  async function withdraw() {
    // Wallet not connected
    if (!provider || !activeAddress) return

    resetData()

    let tx
    try {
      setWithdrawLoadingText('Withdrawing...')
      const currentBlockNumber = await provider.getBlockNumber()
      tx = await withdrawUniV3Fixed({
        vault,
        web3Provider: provider,
        slippagePercent,
        amount0: amounts.amount0,
        amount1: amounts.amount1,
        expireMs,
        blockTimestamp: (await provider.getBlock(currentBlockNumber)).timestamp,
      })
      await tx.wait()
      setWithdrawTxHash(tx.hash)

      await updateData()
    } catch (error: any) {
      setWithdrawError(<Chains.BlockExplorerError error={error} tx={tx} />)
    } finally {
      setWithdrawLoadingText(undefined)
    }
  }

  function getClaimButtonState() {
    if (
      isZeroAmount(claimTokenBalance) ||
      vault.status === VAULT_STATUS.NOT_STARTED ||
      claimLoadingText ||
      claimTxHash
    ) {
      return { disabled: true, loading: claimLoadingText ? true : undefined }
    }

    return {}
  }

  async function claim() {
    // Wallet not connected
    if (!provider || !activeAddress || vault.status === VAULT_STATUS.NOT_STARTED) return

    resetData()

    let tx
    try {
      setClaimLoadingText('Claiming...')
      tx = await claimFixedTokens({ vault, web3Provider: provider })
      await tx.wait()
      setClaimTxHash(tx.hash)

      await updateData()
    } catch (error: any) {
      setClaimError(<Chains.BlockExplorerError error={error} tx={tx} />)
    } finally {
      setClaimLoadingText(undefined)
    }
  }

  return (
    <>
      <Section data-test-id='vault-fixed-side-form'>
        <FixedCapacityProgressBar
          vault={vault}
          usdLiquidityValue={adapter.usdLiquidityValue ?? BIG_NUMBER_ZERO}
        />
        <VariableCapacityProgressBar vault={vault} />

        <Container>
          <DepositContainer>
            <DepositAvailableAmountContainer data-test-id='fixed-quote-deposit-required-amount'>
              <BigNumberAmountWrapper
                amount={amounts.amount0}
                amountAlignment='right'
                precision={token0.decimals}
                tokenSymbol={formatSymbol(token0.symbol)}
                displayTokenIcon
                text={
                  <>
                    {vault.status === VAULT_STATUS.NOT_STARTED ? 'Required' : ''}
                    <InfoTooltip
                      iconColor={theme.colors.text.secondary}
                      content={
                        UNI_V3_VAULT_DESCRIPTIONS.fixedSide.calculations.tokenAmounts.summary
                      }
                    />
                  </>
                }
              />
            </DepositAvailableAmountContainer>
            <DepositAvailableAmountContainer data-test-id='fixed-base-deposit-required-amount'>
              <BigNumberAmountWrapper
                amount={amounts.amount1}
                amountAlignment='right'
                precision={token1.decimals}
                tokenSymbol={formatSymbol(token1.symbol)}
                displayTokenIcon
                text={
                  <>
                    {vault.status === VAULT_STATUS.NOT_STARTED ? 'Required' : ''}
                    <InfoTooltip
                      iconColor={theme.colors.text.secondary}
                      content={
                        UNI_V3_VAULT_DESCRIPTIONS.fixedSide.calculations.tokenAmounts.summary
                      }
                    />
                  </>
                }
              />
            </DepositAvailableAmountContainer>
          </DepositContainer>
        </Container>

        {activeAddress ? (
          <ButtonsContainer ref={parent}>
            {vault.status === VAULT_STATUS.NOT_STARTED &&
              !isExpectedAmount(1, claimTokenBalance) && (
                <ButtonWrapper
                  {...getDepositButtonState()}
                  onClick={deposit}
                  data-test-id='fixed-deposit-button'
                >
                  {depositLoadingText ? (
                    <ButtonContent>{depositLoadingText}</ButtonContent>
                  ) : (
                    <ButtonContent>Deposit</ButtonContent>
                  )}
                </ButtonWrapper>
              )}
            {depositError && <ErrorMessageWrapper>{depositError}</ErrorMessageWrapper>}
            {depositTxHash && <Chains.BlockExplorerTxUrl txHash={depositTxHash} />}
            <Chains.TxStepProgressModal
              title='Fixed Side Deposit'
              isOpen={depositTxModalOpen}
              onRequestClose={() => setDepositTxModalOpen(false)}
              steps={[
                ...(Tokens.isWrappedNativeToken(token0.symbol, chainInfo) ||
                Tokens.isWrappedNativeToken(token1.symbol, chainInfo)
                  ? [
                      {
                        title: 'Wrapping Native Token',
                        description: TRANSACTION_DESCRIPTIONS.wrappingNativeToken(
                          chainInfo.nativeCurrency.symbol,
                          chainInfo.wrappedNativeToken.symbol
                        ),
                        txHash: wrapTxHash,
                      },
                    ]
                  : []),
                {
                  title: `Approving ${token0?.symbol} Allowance`,
                  description: TRANSACTION_DESCRIPTIONS.tokenApproveAllowance(token0?.symbol),
                  txHash: token0AllowanceTxHash,
                },
                {
                  title: `Approving ${token1?.symbol} Allowance`,
                  description: TRANSACTION_DESCRIPTIONS.tokenApproveAllowance(token1?.symbol),
                  txHash: token1AllowanceTxHash,
                },
                {
                  title: 'Depositing',
                  description: UNI_V3_VAULT_DESCRIPTIONS.fixedSide.transactions.deposit(
                    token0?.symbol,
                    token1?.symbol
                  ),
                  txHash: depositTxHash,
                },
              ]}
              currentStep={currentTxStep}
              success={depositTxHash && <BlockExplorerTxUrlWrapper txHash={depositTxHash} />}
              error={depositError && <ErrorMessageWrapper>{depositError}</ErrorMessageWrapper>}
            />

            {isExpectedAmount(1, claimTokenBalance) &&
              vault.status !== VAULT_STATUS.NOT_STARTED && (
                <ButtonWrapper
                  {...getClaimButtonState()}
                  onClick={claim}
                  data-test-id='fixed-claim-button'
                >
                  {claimLoadingText ? (
                    <ButtonContent>{claimLoadingText}</ButtonContent>
                  ) : (
                    <ButtonContent>
                      Claim (
                      <BigNumberButtonAmount
                        amount={vault.variableSideCapacity}
                        precision={vault.variableAsset.decimals}
                        tokenSymbol={vault.variableAsset.symbol}
                      />
                      )
                    </ButtonContent>
                  )}
                </ButtonWrapper>
              )}
            {claimError && <ErrorMessageWrapper>{claimError}</ErrorMessageWrapper>}
            {claimTxHash && <Chains.BlockExplorerTxUrl txHash={claimTxHash} />}

            <ButtonWrapper
              {...getWithdrawButtonState()}
              onClick={withdraw}
              data-test-id='fixed-withdraw-button'
            >
              {withdrawLoadingText ? (
                <ButtonContent>{withdrawLoadingText}</ButtonContent>
              ) : (
                <ButtonContent>
                  {vault.status === VAULT_STATUS.NOT_STARTED ? 'Cancel & Withdraw' : 'Withdraw'}
                </ButtonContent>
              )}
            </ButtonWrapper>
            {withdrawError && <ErrorMessageWrapper>{withdrawError}</ErrorMessageWrapper>}
            {withdrawTxHash && <Chains.BlockExplorerTxUrl txHash={withdrawTxHash} />}

            {vault.status !== VAULT_STATUS.NOT_STARTED && <DurationProgressBar vault={vault} />}
          </ButtonsContainer>
        ) : (
          <Accounts.ConnectWalletButton />
        )}

        <AdvancedSettings onClick={toggleSettingsModal}>
          Advanced Settings <GearIcon width='10px' height='10px' />
        </AdvancedSettings>
      </Section>

      <UniV3SettingsModal
        expireMs={expireMs}
        onExpireMsChange={setExpireMs}
        slippagePercent={slippagePercent}
        onSlippagePercentChange={setSlippagePercent}
        isOpen={settingsModalOpen ?? false}
        onRequestClose={toggleSettingsModal}
      />
    </>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const DepositContainer = styled.div`
  width: 100%;
`

const ButtonsContainer = styled.div<{ ref: any }>`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

const AvailableAmountContainer = styled.div`
  font-size: 14px;
  margin-bottom: 8px;
  padding-left: 2px;
  color: ${(props) => props.theme.colors.text.secondary};
  display: flex;
  flex-direction: column;
  width: 100%;
`

const DepositAvailableAmountContainer = styled(AvailableAmountContainer)`
  > span > span:nth-child(2) {
    float: right;
  }
`

const AdvancedSettings = styled.div`
  cursor: pointer;
  color: ${(props) => props.theme.colors.text.secondary};
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  gap: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const BlockExplorerTxUrlWrapper = styled(Chains.BlockExplorerTxUrl)`
  flex-direction: column;
`

import { useWeb3ReactPlus } from '@packages/web3-react-plus'

import { getChainIcon } from '../utils'

export function useChainIcon() {
  const { chainId } = useWeb3ReactPlus()

  if (!chainId) return null

  return getChainIcon(chainId)
}

import { TokenSymbol } from '../constants'
import { LPToken } from '../interfaces'
import { getPlatform } from './getPlatform'

export function getLPTokenSymbol(lpToken: LPToken) {
  // Special case
  if (lpToken.symbol === 'SFI') {
    return 'SFI'
  }

  const { underlyingToken0, underlyingToken1 } = lpToken
  if (underlyingToken0 && underlyingToken1) {
    return toStakedTokenSymbol(underlyingToken0.symbol, underlyingToken1.symbol)
  }

  // Shouldn't ever get here
  return 'unknown/unknown'
}

export function toStakedTokenSymbol(
  tokenSymbol0: TokenSymbol | string,
  tokenSymbol1: TokenSymbol | string
) {
  // ETH second if exists
  if (tokenSymbol0 === 'ETH' || tokenSymbol0 === 'WETH') {
    return format(tokenSymbol1, 'ETH')
  }
  if (tokenSymbol1 === 'ETH' || tokenSymbol1 === 'WETH') {
    return format(tokenSymbol0, 'ETH')
  }

  // Or, SFI first if exists
  if (tokenSymbol0 === 'SFI') {
    return format('SFI', tokenSymbol1)
  }
  if (tokenSymbol1 === 'SFI') {
    return format('SFI', tokenSymbol0)
  }

  // Else, just use the order they appear
  return format(tokenSymbol0, tokenSymbol1)
}

function format(tokenSymbol0: TokenSymbol | string, tokenSymbol1: TokenSymbol | string) {
  return `${tokenSymbol0}/${tokenSymbol1}`
}

export function getLPTokenPlatformName(lpToken: LPToken): string {
  if (lpToken.symbol === 'SFI') return 'SFI Staking'
  const platform = getPlatform(lpToken.symbol)
  return `${platform} ${getLPTokenSymbol(lpToken)} LP`
}

import { Web3Provider } from '@ethersproject/providers'
import { BigNumber } from 'ethers'
import { formatUnits } from 'ethers/lib/utils'

import { Tokens, Uniswap } from '@packages/ui'
import { getAmountsFromLiquidity } from '@packages/uniswap'
import { BIG_NUMBER_TEN, BIG_NUMBER_ZERO, bn, bnToFloat } from '@packages/bn'

import { VariableEstimatedRates, VariableRates, FixedRates } from '../interfaces'
import { USD_BN_DECIMALS } from '../constants'

export function annualizeRate({ rate, days }: { rate: number; days: number }) {
  return rate * (365 / days)
}

export function calculateApr({ interest, principal }: { interest: number; principal: number }) {
  return (interest / principal) * 365
}

function calculateVariableApr({
  underlyingPlatformDailyRate,
  feePercent,
  fixedApr,
  durationDays,
}: {
  underlyingPlatformDailyRate: number
  feePercent: number
  fixedApr: number
  durationDays: number
}) {
  const fixedDailyRate = fixedApr / 365
  const variableDailyRate =
    (1 / durationDays) * ((underlyingPlatformDailyRate * (1 - feePercent)) / fixedDailyRate - 1)

  return variableDailyRate * 365
}

function calculateVariableUsdEarnings({
  durationDays,
  underlyingPlatformDailyRate,
  feePercent,
  fixedUsdCapacity,
  variableUsdCapacity,
}: {
  durationDays: number
  underlyingPlatformDailyRate: number
  feePercent: number
  fixedUsdCapacity: number
  variableUsdCapacity: number
}) {
  const earnings =
    durationDays * (underlyingPlatformDailyRate * (1 - feePercent)) * fixedUsdCapacity
  return earnings - variableUsdCapacity
}

function calculateVariableAnnualizedUsdEarnings({
  apr,
  variableUsdCapacity,
}: {
  apr: number
  variableUsdCapacity: number
}) {
  return apr * variableUsdCapacity
}

export function calculateEstimatedVariableRates({
  uniswapDayData,
  feePercent,
  fixedApr,
  durationDays,
  fixedUsdCapacity,
  variableUsdCapacity,
}: {
  uniswapDayData: Uniswap.UniswapV3PoolDayData
  feePercent: number
  fixedApr: number
  durationDays: number
  fixedUsdCapacity: number
  variableUsdCapacity: number
}): VariableRates {
  const { data, averages } = uniswapDayData

  const yesterday = data[0]

  const days1PlatformDailyRate = yesterday.feesUSD / yesterday.tvlUSD
  const days1Apr = calculateVariableApr({
    underlyingPlatformDailyRate: days1PlatformDailyRate,
    feePercent,
    durationDays,
    fixedApr,
  })
  const days1: VariableEstimatedRates = {
    platformDailyRate: days1PlatformDailyRate,
    usdEarnings: calculateVariableUsdEarnings({
      underlyingPlatformDailyRate: days1PlatformDailyRate,
      feePercent,
      durationDays,
      fixedUsdCapacity,
      variableUsdCapacity,
    }),
    apr: days1Apr,
    annualizedUsdEarnings: calculateVariableAnnualizedUsdEarnings({
      apr: days1Apr,
      variableUsdCapacity,
    }),
  }

  const days30PlatformDailyRate = averages[30].feesUSD / averages[30].tvlUSD
  const days30Apr = calculateVariableApr({
    underlyingPlatformDailyRate: days30PlatformDailyRate,
    feePercent,
    durationDays,
    fixedApr,
  })
  const days30: VariableEstimatedRates = {
    platformDailyRate: days30PlatformDailyRate,
    usdEarnings: calculateVariableUsdEarnings({
      underlyingPlatformDailyRate: days30PlatformDailyRate,
      feePercent,
      durationDays,
      fixedUsdCapacity,
      variableUsdCapacity,
    }),
    apr: days30Apr,
    annualizedUsdEarnings: calculateVariableAnnualizedUsdEarnings({
      apr: days30Apr,
      variableUsdCapacity,
    }),
  }

  const days90PlatformDailyRate = averages[90].feesUSD / averages[90].tvlUSD
  const days90Apr = calculateVariableApr({
    underlyingPlatformDailyRate: days90PlatformDailyRate,
    feePercent,
    durationDays,
    fixedApr,
  })
  const days90: VariableEstimatedRates = {
    platformDailyRate: days90PlatformDailyRate,
    usdEarnings: calculateVariableUsdEarnings({
      underlyingPlatformDailyRate: days90PlatformDailyRate,
      feePercent,
      durationDays,
      fixedUsdCapacity,
      variableUsdCapacity,
    }),
    apr: days90Apr,
    annualizedUsdEarnings: calculateVariableAnnualizedUsdEarnings({
      apr: days90Apr,
      variableUsdCapacity,
    }),
  }

  return {
    earningsRate: '-',
    usdEarnings: days30.usdEarnings,
    token0Earnings: 0,
    token1Earnings: 0,
    estimationsByXDayAverage: {
      1: days1,
      30: days30,
      90: days90,
    },
  }
}

export function calculateFixedUsdEarnings({
  variableSideCapacity,
  variableAsset,
  variableAssetPrice,
}: {
  variableSideCapacity: BigNumber
  variableAsset: Tokens.Token
  variableAssetPrice: number
}) {
  return bnToFloat(
    variableSideCapacity?.mul(bn(variableAssetPrice?.toFixed(USD_BN_DECIMALS), USD_BN_DECIMALS)) ??
      BIG_NUMBER_ZERO,
    (variableAsset?.decimals ?? 0) + USD_BN_DECIMALS
  )
}

function calculateFixedFixedRate({
  usdLiquidityValue,
  variableSideCapacity,
  variableAsset,
  variableAssetPrice,
}: {
  usdLiquidityValue: BigNumber
  variableSideCapacity: BigNumber
  variableAsset: Tokens.Token
  variableAssetPrice: number
}) {
  return (
    (Number(formatUnits(variableSideCapacity, variableAsset.decimals)) * variableAssetPrice) /
    Number(formatUnits(usdLiquidityValue, USD_BN_DECIMALS))
  )
}

export function calculateFixedRates({
  usdLiquidityValue,
  variableSideCapacity,
  variableAsset,
  variableAssetPrice,
  durationDays,
}: {
  usdLiquidityValue: BigNumber
  variableSideCapacity: BigNumber
  variableAsset: Tokens.Token
  variableAssetPrice: number
  durationDays: number
}): FixedRates {
  const fixedRate = calculateFixedFixedRate({
    usdLiquidityValue,
    variableSideCapacity,
    variableAsset,
    variableAssetPrice,
  })

  return {
    fixedRate,
    apr: annualizeRate({
      rate: fixedRate,
      days: durationDays,
    }),
    usdEarnings: calculateFixedUsdEarnings({
      variableSideCapacity,
      variableAsset,
      variableAssetPrice,
    }),
  }
}

export async function getUsdValueFromLiquidity({
  web3Provider,
  liquidity,
  pool,
  minTick,
  maxTick,
  token0Price,
  token1Price,
  setUsdValue,
}: {
  pool?: Uniswap.UniswapV3Pool
  web3Provider?: Web3Provider
  liquidity?: BigNumber
  minTick?: number
  maxTick?: number
  token0Price?: number
  token1Price?: number
  setUsdValue: (usdValue: BigNumber) => void
}) {
  if (!web3Provider || !liquidity || !minTick || !maxTick || !token0Price || !token1Price || !pool)
    return

  try {
    const { amount0, amount1 } = await getAmountsFromLiquidity({
      liquidity,
      tickCurrent: pool.tick,
      tickLower: minTick,
      tickUpper: maxTick,
    })

    const usdValue0 = amount0
      .mul(bn(`${token0Price}`, USD_BN_DECIMALS) ?? 0)
      .div(BIG_NUMBER_TEN.pow(pool.token0.decimals))
    const usdValue1 = amount1
      .mul(bn(`${token1Price}`, USD_BN_DECIMALS) ?? 0)
      .div(BIG_NUMBER_TEN.pow(pool.token1.decimals))

    const usdValue = usdValue0.add(usdValue1)

    setUsdValue(usdValue)
  } catch (error) {
    console.error(error)
  }
}

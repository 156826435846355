/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type { PromiseOrValue } from "../../../common";
import type {
  MockAdapter,
  MockAdapterInterface,
} from "../../../contracts/mock/MockAdapter";

const _abi = [
  {
    inputs: [],
    name: "factoryAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "hasAccurateHoldings",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "base",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "depositTolerance",
        type: "uint256",
      },
      {
        internalType: "bytes",
        name: "data",
        type: "bytes",
      },
    ],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "initialized",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_vaultAddress",
        type: "address",
      },
    ],
    name: "setVault",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "vaultAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
] as const;

const _bytecode =
  "0x608060405234801561001057600080fd5b50600180546001600160a01b031916331790556102fa806100326000396000f3fe608060405234801561001057600080fd5b50600436106100625760003560e01c8063158ef93e1461006757806324d5419e14610090578063430bf08a146100ba5780636817031b146100e5578063966dae0e146100f8578063a2118c261461010b575b600080fd5b60015461007b90600160a01b900460ff1681565b60405190151581526020015b60405180910390f35b6100b861009e36600461020f565b50506001805460ff60a01b1916600160a01b179055505050565b005b6000546100cd906001600160a01b031681565b6040516001600160a01b039091168152602001610087565b6100b86100f33660046102a2565b610112565b6001546100cd906001600160a01b031681565b600161007b565b6000546001600160a01b0316156101565760405162461bcd60e51b81526020600482015260036024820152624e564160e81b60448201526064015b60405180910390fd5b6001546001600160a01b031633146101955760405162461bcd60e51b8152602060048201526002602482015261272360f11b604482015260640161014d565b6001600160a01b0381166101d15760405162461bcd60e51b81526020600482015260036024820152624e454960e81b604482015260640161014d565b600080546001600160a01b0319166001600160a01b0392909216919091179055565b80356001600160a01b038116811461020a57600080fd5b919050565b60008060008060006080868803121561022757600080fd5b85359450610237602087016101f3565b93506040860135925060608601356001600160401b038082111561025a57600080fd5b818801915088601f83011261026e57600080fd5b81358181111561027d57600080fd5b89602082850101111561028f57600080fd5b9699959850939650602001949392505050565b6000602082840312156102b457600080fd5b6102bd826101f3565b939250505056fea26469706673582212204c988d33e84431a2ab9c35e26243b66e4c8ad28a1d7dc00beeb94eb0edbf612c64736f6c63430008120033";

type MockAdapterConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: MockAdapterConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class MockAdapter__factory extends ContractFactory {
  constructor(...args: MockAdapterConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): Promise<MockAdapter> {
    return super.deploy(overrides || {}) as Promise<MockAdapter>;
  }
  override getDeployTransaction(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  override attach(address: string): MockAdapter {
    return super.attach(address) as MockAdapter;
  }
  override connect(signer: Signer): MockAdapter__factory {
    return super.connect(signer) as MockAdapter__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): MockAdapterInterface {
    return new utils.Interface(_abi) as MockAdapterInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): MockAdapter {
    return new Contract(address, _abi, signerOrProvider) as MockAdapter;
  }
}

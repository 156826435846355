import { BigNumber } from '@ethersproject/bignumber'

import { UseContractCallValueReturnValue, useContractCallValue } from './useContractCallValue'
import ERC20 from '../assets/abis/ERC20.json'

export function useErc20TotalSupply({
  tokenAddress,
  autoUpdate,
  updateEveryNBlocks = 1,
}: {
  tokenAddress: string
  autoUpdate?: boolean
  updateEveryNBlocks?: number
}): UseContractCallValueReturnValue<BigNumber> {
  return useContractCallValue({
    contractAddress: tokenAddress,
    functionName: 'totalSupply',
    artifact: ERC20,
    args: [],
    autoUpdate,
    updateEveryNBlocks,
  })
}

import { BigNumber } from '@ethersproject/bignumber'

import { UseContractCallValueReturnValue, useContractCallValue } from './useContractCallValue'
import ERC20 from '../assets/abis/ERC20.json'

export function useErc20Balance({
  tokenAddress,
  balanceAddress,
  autoUpdate,
  updateEveryNBlocks = 1,
}: {
  tokenAddress: string
  balanceAddress: string
  autoUpdate?: boolean
  updateEveryNBlocks?: number
}): UseContractCallValueReturnValue<BigNumber> {
  return useContractCallValue({
    contractAddress: balanceAddress ? tokenAddress : '',
    functionName: 'balanceOf',
    artifact: ERC20,
    args: [balanceAddress],
    autoUpdate,
    updateEveryNBlocks,
  })
}

import { useWeb3ReactPlus } from '@packages/web3-react-plus'

import ALPHA from '../assets/images/tokens/alpha.svg'
import BTSE from '../assets/images/tokens/btse.svg'
import DAI from '../assets/images/tokens/dai.svg'
import ETH from '../assets/images/tokens/eth.svg'
import USDC from '../assets/images/tokens/usdc.svg'
import BTCK from '../assets/images/tokens/oec-btc.png'
import PRT from '../assets/images/tokens/prt.svg'
import SFI from '../assets/images/tokens/sfi-red.svg'
import unknown from '../assets/images/tokens/missing-token-icon.svg'
import KCS from '../assets/images/tokens/kucoin.svg'
import MJT from '../assets/images/tokens/mjt.png'
import MATIC from '../assets/images/tokens/matic.svg'
import { TokenSymbol } from '../constants'

const noIcons: string[] = []

export function useTokenIcons(
  tokenSymbol0?: TokenSymbol | string,
  tokenSymbol1?: TokenSymbol | string
) {
  const { chainId } = useWeb3ReactPlus()

  if (!chainId || !tokenSymbol0) return noIcons

  const tokenIconSvg0 = getTokenIcon(tokenSymbol0)
  if (!tokenSymbol1) {
    return [tokenIconSvg0]
  }
  const tokenIconSvg1 = getTokenIcon(tokenSymbol1)

  return [tokenIconSvg0, tokenIconSvg1]
}

const icons: Record<TokenSymbol | string, string> = {
  ALPHA,
  BTSE,
  DAI,
  ETH,
  WETH: ETH,
  WMATIC: MATIC,
  PRT,
  SFI,
  unknown,
  KCS,
  MJT,
  USDC,
  BTCK,
}

export function getTokenIcon(symbol: TokenSymbol | string) {
  const icon = icons[symbol]
  if (!icon) return icons.unknown
  return icon
}

import { SharedAppState } from '@packages/ui'
import { useWeb3ReactPlus } from '@packages/web3-react-plus'

import { useAppStateReducer } from 'src/AppState'

import { Vault } from '../interfaces'

const noVaults: { [id: number]: SharedAppState.AsyncData<Vault> } = {}

export function useVaults(): { [id: number]: SharedAppState.AsyncData<Vault> } {
  const [appState] = useAppStateReducer()
  const { chainId } = useWeb3ReactPlus()

  if (chainId) {
    return appState.vaults[chainId]?.vault
  }

  return noVaults
}

import { BigNumber } from 'ethers'
import JSBI from 'jsbi'

import { BIG_NUMBER_ZERO, bn } from '@packages/bn'

import {
  MAX_TICK,
  MIN_TICK,
  tickToPrice,
  nearestUsableTick,
  getSqrtRatioAtTick,
  MIN_SQRT_RATIO,
  MAX_SQRT_RATIO,
} from './ticks'
import { getAmount0Delta, getAmount1Delta } from './sqrtPriceMath'
import { JSBI_CONSTANTS } from './constants'
import { fromBigNumbertoJSBI, fromJSBIToBigNumber } from './converters'
import { encodeSqrtRatioX96 } from './utils'

function ratiosAfterSlippage({
  slippageTolerance,
  sqrtRatioX96,
}: {
  slippageTolerance: number
  sqrtRatioX96: number
}): {
  sqrtRatioX96Lower: JSBI
  sqrtRatioX96Upper: JSBI
} {
  const sqrtRatioX96JSBI = JSBI.BigInt(sqrtRatioX96)
  const numerator = JSBI.multiply(sqrtRatioX96JSBI, sqrtRatioX96JSBI)

  const TEN_THOUSAND = JSBI.BigInt(10_000)

  const priceLower = JSBI.multiply(numerator, JSBI.BigInt(10_000 - slippageTolerance))
  const priceUpper = JSBI.multiply(numerator, JSBI.BigInt(10_000 + slippageTolerance))

  let sqrtRatioX96Lower = encodeSqrtRatioX96(
    priceLower,
    JSBI.multiply(TEN_THOUSAND, JSBI_CONSTANTS.Q192)
  )
  if (JSBI.lessThanOrEqual(sqrtRatioX96Lower, MIN_SQRT_RATIO)) {
    sqrtRatioX96Lower = JSBI.add(MIN_SQRT_RATIO, JSBI.BigInt(1))
  }
  let sqrtRatioX96Upper = encodeSqrtRatioX96(
    priceUpper,
    JSBI.multiply(TEN_THOUSAND, JSBI_CONSTANTS.Q192)
  )
  if (JSBI.greaterThanOrEqual(sqrtRatioX96Upper, MAX_SQRT_RATIO)) {
    sqrtRatioX96Upper = JSBI.subtract(MAX_SQRT_RATIO, JSBI.BigInt(1))
  }
  return {
    sqrtRatioX96Lower,
    sqrtRatioX96Upper,
  }
}

export function getMinAmountsWithSlippage({
  slippageTolerance,
  tickLower,
  tickUpper,
  liquidity,
  sqrtRatioX96,
}: {
  slippageTolerance: number
  tickLower: number
  tickUpper: number
  liquidity: BigNumber
  sqrtRatioX96: number
}) {
  const { sqrtRatioX96Upper, sqrtRatioX96Lower } = ratiosAfterSlippage({
    slippageTolerance,
    sqrtRatioX96,
  })

  const sqrtRatioAX96 = getSqrtRatioAtTick(tickLower)
  const sqrtRatioBX96 = getSqrtRatioAtTick(tickUpper)
  const liquidityJSBI = fromBigNumbertoJSBI(liquidity)

  const amount0Min = amount0FromLiquiditySqrt({
    liquidity: liquidityJSBI,
    sqrtRatioAX96,
    sqrtRatioBX96,
    sqrtRatioX96: sqrtRatioX96Upper,
  })
  const amount1Min = amount1FromLiquiditySqrt({
    liquidity: liquidityJSBI,
    sqrtRatioAX96,
    sqrtRatioBX96,
    sqrtRatioX96: sqrtRatioX96Lower,
  })
  return {
    amount0Min: fromJSBIToBigNumber(amount0Min),
    amount1Min: fromJSBIToBigNumber(amount1Min),
  }
}

export const getTokenAmountsFromDepositAmounts = ({
  currentPrice,
  lowerTickPrice,
  upperTickPrice,
  priceUsdX,
  priceUsdY,
  totalUsdDesiredAmount,
  isFullRange = false,
}: {
  currentPrice: number
  lowerTickPrice: number
  upperTickPrice: number
  priceUsdX: number
  priceUsdY: number
  totalUsdDesiredAmount: number
  isFullRange?: boolean
}) => {
  const upperPriceCalc = isFullRange ? 0 : 1 / Math.sqrt(upperTickPrice)
  const lowerPriceCalc = isFullRange ? 0 : Math.sqrt(lowerTickPrice)

  const deltaL =
    totalUsdDesiredAmount /
    ((Math.sqrt(currentPrice) - lowerPriceCalc) * priceUsdY +
      (1 / Math.sqrt(currentPrice) - upperPriceCalc) * priceUsdX)

  let deltaY = deltaL * (Math.sqrt(currentPrice) - lowerPriceCalc)
  if (deltaY * priceUsdY < 0) deltaY = 0
  if (deltaY * priceUsdY > totalUsdDesiredAmount) deltaY = totalUsdDesiredAmount / priceUsdY

  let deltaX = deltaL * (1 / Math.sqrt(currentPrice) - upperPriceCalc)
  if (deltaX * priceUsdX < 0) deltaX = 0
  if (deltaX * priceUsdX > totalUsdDesiredAmount) deltaX = totalUsdDesiredAmount / priceUsdX

  return { amount0: deltaY, amount1: deltaX }
}

export async function getFullRangeAmountsFromLiquidity({
  poolTickSpacing,
  liquidity,
  tickCurrent,
}: {
  poolTickSpacing: number
  liquidity: BigNumber
  tickCurrent: number
}) {
  const minTick = MIN_TICK - (MIN_TICK % poolTickSpacing)
  const maxTick = MAX_TICK - (MAX_TICK % poolTickSpacing)

  return await getAmountsFromLiquidity({
    liquidity,
    tickCurrent,
    tickLower: minTick,
    tickUpper: maxTick,
  })
}

export async function getAmountsFromLiquidity({
  liquidity,
  tickCurrent,
  tickLower,
  tickUpper,
}: {
  liquidity: BigNumber
  tickCurrent: number
  tickLower: number
  tickUpper: number
}) {
  return {
    amount0: fromJSBIToBigNumber(
      amount0FromLiquidity({
        liquidity: fromBigNumbertoJSBI(liquidity),
        tickCurrent,
        tickLower,
        tickUpper,
      })
    ),
    amount1: fromJSBIToBigNumber(
      amount1FromLiquidity({
        liquidity: fromBigNumbertoJSBI(liquidity),
        tickCurrent,
        tickLower,
        tickUpper,
      })
    ),
  }
}

function amount0FromLiquidity({
  liquidity,
  tickCurrent,
  tickLower,
  tickUpper,
}: {
  liquidity: JSBI
  tickCurrent: number
  tickLower: number
  tickUpper: number
}) {
  if (tickCurrent < tickLower) {
    return getAmount0Delta(
      getSqrtRatioAtTick(tickLower),
      getSqrtRatioAtTick(tickUpper),
      liquidity,
      false
    )
  } else if (tickCurrent < tickUpper) {
    return getAmount0Delta(
      getSqrtRatioAtTick(tickCurrent),
      getSqrtRatioAtTick(tickUpper),
      liquidity,
      false
    )
  } else {
    return JSBI_CONSTANTS.ZERO
  }
}

function amount0FromLiquiditySqrt({
  liquidity,
  sqrtRatioX96,
  sqrtRatioAX96,
  sqrtRatioBX96,
}: {
  liquidity: JSBI
  sqrtRatioX96: JSBI // current tick
  sqrtRatioAX96: JSBI // lower tick
  sqrtRatioBX96: JSBI // upper tick
}) {
  if (JSBI.lessThan(sqrtRatioX96, sqrtRatioAX96)) {
    return getAmount0Delta(sqrtRatioAX96, sqrtRatioBX96, liquidity, false)
  } else if (JSBI.lessThan(sqrtRatioX96, sqrtRatioBX96)) {
    return getAmount0Delta(sqrtRatioX96, sqrtRatioBX96, liquidity, false)
  } else {
    return JSBI_CONSTANTS.ZERO
  }
}

function amount1FromLiquidity({
  liquidity,
  tickCurrent,
  tickLower,
  tickUpper,
}: {
  liquidity: JSBI
  tickCurrent: number
  tickLower: number
  tickUpper: number
}) {
  if (tickCurrent < tickLower) {
    return JSBI_CONSTANTS.ZERO
  } else if (tickCurrent < tickUpper) {
    return getAmount1Delta(
      getSqrtRatioAtTick(tickLower),
      getSqrtRatioAtTick(tickCurrent),
      liquidity,
      false
    )
  } else {
    return getAmount1Delta(
      getSqrtRatioAtTick(tickLower),
      getSqrtRatioAtTick(tickUpper),
      liquidity,
      false
    )
  }
}

function amount1FromLiquiditySqrt({
  liquidity,
  sqrtRatioX96,
  sqrtRatioAX96,
  sqrtRatioBX96,
}: {
  liquidity: JSBI
  sqrtRatioX96: JSBI // current tick
  sqrtRatioAX96: JSBI // lower tick
  sqrtRatioBX96: JSBI // upper tick
}) {
  if (JSBI.lessThan(sqrtRatioX96, sqrtRatioAX96)) {
    return JSBI_CONSTANTS.ZERO
  } else if (JSBI.lessThan(sqrtRatioX96, sqrtRatioBX96)) {
    return getAmount1Delta(sqrtRatioAX96, sqrtRatioX96, liquidity, false)
  } else {
    return getAmount1Delta(sqrtRatioAX96, sqrtRatioBX96, liquidity, false)
  }
}

interface LiquidityParams {
  poolTickSpacing: number
  token0Decimals: number
  token1Decimals: number
  totalUsdDesiredAmount: number
  currentPrice: number
  currentTick: number
  token0UsdPrice: number
  token1UsdPrice: number
}

export async function getRangedLiquidityViaTick({
  poolTickSpacing,
  token0Decimals,
  token1Decimals,
  totalUsdDesiredAmount,
  lowerTick,
  upperTick,
  currentPrice,
  currentTick,
  token0UsdPrice,
  token1UsdPrice,
}: LiquidityParams & { lowerTick: number; upperTick: number }) {
  const tickLower = nearestUsableTick(parseInt(`${lowerTick}`), poolTickSpacing)
  const tickUpper = nearestUsableTick(parseInt(`${upperTick}`), poolTickSpacing)
  const lowerTickPrice = tickToPrice({
    tick: tickLower,
    token0Decimals,
    token1Decimals,
  })
  const upperTickPrice = tickToPrice({
    tick: tickUpper,
    token0Decimals,
    token1Decimals,
  })

  const { amount0, amount1 } = getTokenAmountsFromDepositAmounts({
    currentPrice,
    lowerTickPrice,
    upperTickPrice,
    priceUsdX: token1UsdPrice,
    priceUsdY: token0UsdPrice,
    totalUsdDesiredAmount,
  })

  if (isNaN(amount0) || isNaN(amount1)) {
    throw new Error('Unable to calculate amount of token0 or token1')
  }

  const liquidity = maxLiquidityForAmounts({
    sqrtRatioCurrentX96: getSqrtRatioAtTick(currentTick),
    sqrtRatioAX96: getSqrtRatioAtTick(tickLower),
    sqrtRatioBX96: getSqrtRatioAtTick(tickUpper),
    amount0: bn(`${amount0}`, token0Decimals),
    amount1: bn(`${amount1}`, token1Decimals),
  })

  return { liquidity, amount0, amount1, tickLower, tickUpper }
}

export async function getFullRangeLiquidity({
  poolTickSpacing,
  token0Decimals,
  token1Decimals,
  totalUsdDesiredAmount,
  currentPrice,
  currentTick,
  token0UsdPrice,
  token1UsdPrice,
}: LiquidityParams) {
  const { amount0, amount1 } = getTokenAmountsFromDepositAmounts({
    currentPrice,
    lowerTickPrice: 0, // Not going to be used
    upperTickPrice: 0, // Not going to be used
    priceUsdX: token1UsdPrice,
    priceUsdY: token0UsdPrice,
    totalUsdDesiredAmount,
    isFullRange: true,
  })

  if (isNaN(amount0) || isNaN(amount1)) {
    throw new Error('Unable to calculate amount of token0 or token1')
  }

  const minTick = MIN_TICK - (MIN_TICK % poolTickSpacing)
  const maxTick = MAX_TICK - (MAX_TICK % poolTickSpacing)

  const liquidity = maxLiquidityForAmounts({
    sqrtRatioCurrentX96: getSqrtRatioAtTick(currentTick),
    sqrtRatioAX96: getSqrtRatioAtTick(minTick),
    sqrtRatioBX96: getSqrtRatioAtTick(maxTick),
    amount0: bn(`${amount0}`, token0Decimals),
    amount1: bn(`${amount1}`, token1Decimals),
  })

  return { liquidity, amount0, amount1 }
}

function maxLiquidityForAmount0(
  sqrtRatioAX96: JSBI,
  sqrtRatioBX96: JSBI,
  amount0: BigNumber
): JSBI {
  if (JSBI.greaterThan(sqrtRatioAX96, sqrtRatioBX96)) {
    ;[sqrtRatioAX96, sqrtRatioBX96] = [sqrtRatioBX96, sqrtRatioAX96]
  }

  const numerator = JSBI.multiply(JSBI.multiply(JSBI.BigInt(amount0), sqrtRatioAX96), sqrtRatioBX96)
  const denominator = JSBI.multiply(JSBI_CONSTANTS.Q96, JSBI.subtract(sqrtRatioBX96, sqrtRatioAX96))

  return JSBI.NE(denominator, JSBI_CONSTANTS.ZERO)
    ? JSBI.divide(numerator, denominator)
    : JSBI_CONSTANTS.ZERO
}

function maxLiquidityForAmount1(
  sqrtRatioAX96: JSBI,
  sqrtRatioBX96: JSBI,
  amount1: BigNumber
): JSBI {
  if (JSBI.greaterThan(sqrtRatioAX96, sqrtRatioBX96)) {
    ;[sqrtRatioAX96, sqrtRatioBX96] = [sqrtRatioBX96, sqrtRatioAX96]
  }
  if (
    (sqrtRatioAX96.toString() === '0' && sqrtRatioBX96.toString() === '0') ||
    sqrtRatioBX96.toString() === sqrtRatioAX96.toString()
  ) {
    return JSBI_CONSTANTS.ZERO
  }
  return JSBI.divide(
    JSBI.multiply(JSBI.BigInt(amount1), JSBI_CONSTANTS.Q96),
    JSBI.subtract(sqrtRatioBX96, sqrtRatioAX96)
  )
}

export function maxLiquidityForAmounts({
  sqrtRatioCurrentX96,
  sqrtRatioAX96,
  sqrtRatioBX96,
  amount0,
  amount1,
}: {
  sqrtRatioCurrentX96: JSBI
  sqrtRatioAX96: JSBI
  sqrtRatioBX96: JSBI
  amount0: BigNumber
  amount1: BigNumber
}): BigNumber {
  if (JSBI.greaterThan(sqrtRatioAX96, sqrtRatioBX96)) {
    ;[sqrtRatioAX96, sqrtRatioBX96] = [sqrtRatioBX96, sqrtRatioAX96]
  }

  let liquidity
  if (JSBI.lessThanOrEqual(sqrtRatioCurrentX96, sqrtRatioAX96)) {
    liquidity = maxLiquidityForAmount0(sqrtRatioAX96, sqrtRatioBX96, amount0)
  } else if (JSBI.lessThan(sqrtRatioCurrentX96, sqrtRatioBX96)) {
    const liquidity0 = maxLiquidityForAmount0(sqrtRatioCurrentX96, sqrtRatioBX96, amount0)
    const liquidity1 = maxLiquidityForAmount1(sqrtRatioAX96, sqrtRatioCurrentX96, amount1)
    liquidity = JSBI.lessThan(liquidity0, liquidity1) ? liquidity0 : liquidity1
  } else {
    liquidity = maxLiquidityForAmount1(sqrtRatioAX96, sqrtRatioBX96, amount1)
  }

  return liquidity.toString() === '0' ? BIG_NUMBER_ZERO : fromJSBIToBigNumber(liquidity)
}

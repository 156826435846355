import { INFURA_API_KEY, TEST_ENVIRONMENT } from './config'
import uniswapTokenList from './assets/files/uniswap-tokens.json'
import polygonTokenList from './assets/files/polygon-tokens.json'
import arbitrumTokenList from './assets/files/arbitrum-tokens.json'
import arbitrumGoerliTokenList from './assets/files/arbitrum-goerli-tokens.json'

export enum CHAIN_ID {
  Ethereum = 1,
  Ropsten = 3,
  Rinkeby = 4,
  Goerli = 5,
  Kovan = 42,

  Polygon = 137,

  KCC = 321,

  Arbitrum = 42161,
  ArbitrumGoerli = 421613,
}

export function isTestnet(chainId: number) {
  return (
    chainId === CHAIN_ID.ArbitrumGoerli ||
    chainId === CHAIN_ID.Goerli ||
    chainId === HARDHAT_CHAIN_ID
  )
}

export function isChainsTestnet(chainId: number, testnetChainId: number) {
  if (chainId === CHAIN_ID.Arbitrum && testnetChainId === CHAIN_ID.ArbitrumGoerli) {
    return true
  }
  if (chainId === CHAIN_ID.Ethereum && testnetChainId === CHAIN_ID.Goerli) {
    return true
  }
  if (chainId === CHAIN_ID.Ethereum && testnetChainId === HARDHAT_CHAIN_ID) {
    return true
  }
  return false
}

export const HARDHAT_CHAIN_ID = 1337

export function getChainId(chain: keyof typeof CHAIN_ID) {
  return TEST_ENVIRONMENT ? HARDHAT_CHAIN_ID : CHAIN_ID[chain]
}

export type ChainName = keyof typeof CHAIN_ID

type RpcUrls = Record<CHAIN_ID, string>

export const RPC_URLS: RpcUrls = {
  [CHAIN_ID.Ethereum]: `https://mainnet.infura.io/v3/${INFURA_API_KEY}`,
  [CHAIN_ID.Ropsten]: `https://ropsten.infura.io/v3/${INFURA_API_KEY}`,
  [CHAIN_ID.Rinkeby]: `https://rinkeby.infura.io/v3/${INFURA_API_KEY}`,
  [CHAIN_ID.Goerli]: `https://goerli.infura.io/v3/${INFURA_API_KEY}`,
  [CHAIN_ID.Kovan]: `https://kovan.infura.io/v3/${INFURA_API_KEY}`,
  [CHAIN_ID.KCC]: 'https://rpc-mainnet.kcc.network',
  [CHAIN_ID.Arbitrum]: `https://arbitrum-mainnet.infura.io/v3/${INFURA_API_KEY}`,
  [CHAIN_ID.ArbitrumGoerli]: `https://arbitrum-goerli.infura.io/v3/${INFURA_API_KEY}`,
  [CHAIN_ID.Polygon]: `https://polygon-mainnet.infura.io/v3/${INFURA_API_KEY}`,
  ...(TEST_ENVIRONMENT && { [HARDHAT_CHAIN_ID]: 'http://localhost:8545' }),
}

export interface ChainInfo {
  name: string
  nativeCurrency: {
    name: string // e.g. 'Goerli ETH',
    symbol: string // e.g. 'gorETH',
    decimals: number // e.g. 18,
  }
  wrappedNativeToken: {
    name: string // e.g. 'Wrapped Ether'
    symbol: string // e.g. 'WETH'
    decimals: number // e.g. 18,
    address: string
  }
  explorer: { name: string; url: string }
  tokenList: {
    useUniswapTokenList?: boolean
    url?: string
    backupStaticList: ApiTokenList
    additionalTokens?: ApiToken[]
  }
}

export interface ApiToken {
  chainId: number
  address: string
  name: string
  symbol: string
  decimals: number
  logoURI?: string
}

export interface ApiTokenList {
  name: string
  timestamp: string
  tokens: ApiToken[]
}

type ChainInfoMap = Record<CHAIN_ID, ChainInfo>

export const SFI_TOKEN = {
  chainId: CHAIN_ID.Ethereum,
  name: 'Spice',
  symbol: 'SFI',
  decimals: 18,
  address: '0xb753428af26e81097e7fd17f40c88aaa3e04902c'.toLowerCase(),
}

const ETHEREUM_MAINNET_TOKEN_LIST = {
  url: 'https://tokens.uniswap.org/',
  backupStaticList: uniswapTokenList,
  additionalTokens: [SFI_TOKEN],
}

const EMPTY_TOKEN_LIST_INFO = {
  url: '',
  backupStaticList: { name: '', timestamp: '', tokens: [] },
  additionalTokens: undefined,
}

export const CHAIN_INFO: ChainInfoMap = {
  [CHAIN_ID.Ethereum]: {
    explorer: { url: 'https://etherscan.io/', name: 'Etherscan' },
    name: 'Ethereum',
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    wrappedNativeToken: {
      name: 'Wrapped Ether',
      symbol: 'WETH',
      decimals: 18,
      address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'.toLowerCase(),
    },
    tokenList: ETHEREUM_MAINNET_TOKEN_LIST,
  },
  [CHAIN_ID.Ropsten]: {
    explorer: { url: 'https://ropsten.etherscan.io/', name: 'Etherscan' },
    name: 'Ropsten',
    nativeCurrency: { name: 'Ropsten Ether', symbol: 'ropETH', decimals: 18 },
    wrappedNativeToken: {
      name: 'Wrapped Ether',
      symbol: 'WETH',
      decimals: 18,
      address: '0xc778417E063141139Fce010982780140Aa0cD5Ab'.toLowerCase(),
    },
    tokenList: ETHEREUM_MAINNET_TOKEN_LIST,
  },
  [CHAIN_ID.Rinkeby]: {
    explorer: { url: 'https://rinkeby.etherscan.io/', name: 'Etherscan' },
    name: 'Rinkeby',
    nativeCurrency: { name: 'Rinkeby Ether', symbol: 'rETH', decimals: 18 },
    wrappedNativeToken: {
      name: 'Wrapped Ether',
      symbol: 'WETH',
      decimals: 18,
      address: '0xc778417E063141139Fce010982780140Aa0cD5Ab'.toLowerCase(),
    },
    tokenList: ETHEREUM_MAINNET_TOKEN_LIST,
  },
  [CHAIN_ID.Goerli]: {
    explorer: { url: 'https://goerli.etherscan.io/', name: 'Etherscan' },
    name: 'Görli',
    nativeCurrency: { name: 'Görli Ether', symbol: 'görETH', decimals: 18 },
    wrappedNativeToken: {
      name: 'Wrapped Ether',
      symbol: 'WETH',
      decimals: 18,
      address: '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6'.toLowerCase(),
    },
    tokenList: ETHEREUM_MAINNET_TOKEN_LIST,
  },
  [CHAIN_ID.Kovan]: {
    explorer: { url: 'https://kovan.etherscan.io/', name: 'Etherscan' },
    name: 'Kovan',
    nativeCurrency: { name: 'Kovan Ether', symbol: 'kovETH', decimals: 18 },
    wrappedNativeToken: {
      name: 'Wrapped Ether',
      symbol: 'WETH',
      decimals: 18,
      address: '0xd0A1E359811322d97991E03f863a0C30C2cF029C'.toLowerCase(),
    },
    tokenList: ETHEREUM_MAINNET_TOKEN_LIST,
  },
  [CHAIN_ID.Polygon]: {
    explorer: { url: 'https://polygonscan.com/', name: 'Polygonscan' },
    name: 'Polygon Mainnet',
    nativeCurrency: { name: 'Polygon', symbol: 'MATIC', decimals: 18 },
    wrappedNativeToken: {
      name: 'Wrapped Matic',
      symbol: 'WMATIC',
      decimals: 18,
      address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270'.toLowerCase(),
    },
    tokenList: {
      url: 'https://api-polygon-tokens.polygon.technology/tokenlists/popularTokens.tokenlist.json',
      backupStaticList: polygonTokenList,
      additionalTokens: [
        {
          chainId: CHAIN_ID.Polygon,
          name: 'Spice',
          symbol: 'SFI',
          decimals: 18,
          address: '0x35b937583F04A24963eb685F728a542240f28Dd8'.toLowerCase(),
        },
      ],
    },
  },
  [CHAIN_ID.KCC]: {
    explorer: { url: 'https://explorer.kcc.io/', name: 'KCC Explorer' },
    name: 'KCC Mainnet',
    nativeCurrency: { name: 'KuCoin Token', symbol: 'KCS', decimals: 18 },
    wrappedNativeToken: {
      name: 'Wrapped KCS',
      symbol: 'WKCS',
      decimals: 18,
      address: '0x4446fc4eb47f2f6586f9faab68b3498f86c07521'.toLowerCase(),
    },
    tokenList: EMPTY_TOKEN_LIST_INFO,
  },
  [CHAIN_ID.Arbitrum]: {
    explorer: { url: 'https://arbiscan.io/', name: 'Arbiscan' },
    name: 'Arbitrum One',
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    wrappedNativeToken: {
      name: 'Wrapped Ether',
      symbol: 'WETH',
      decimals: 18,
      address: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1'.toLowerCase(),
    },
    tokenList: {
      useUniswapTokenList: true,
      url: 'https://bridge.arbitrum.io/token-list-42161.json',
      backupStaticList: arbitrumTokenList,
    },
  },
  [CHAIN_ID.ArbitrumGoerli]: {
    explorer: { url: 'https://goerli.arbiscan.io/', name: 'Arbiscan' },
    name: 'Arbitrum Goerli',
    nativeCurrency: { name: 'Arbitrum Goerli testnet $ETH', symbol: 'AGOR', decimals: 18 },
    wrappedNativeToken: {
      name: 'Wrapped Ether',
      symbol: 'WETH',
      decimals: 18,
      address: '0xe39Ab88f8A4777030A534146A9Ca3B52bd5D43A3'.toLowerCase(),
    },
    tokenList: {
      backupStaticList: arbitrumGoerliTokenList,
    },
  },
  ...(TEST_ENVIRONMENT && {
    [HARDHAT_CHAIN_ID]: {
      explorer: { url: 'https://etherscan.io/', name: 'Etherscan' },
      name: 'Hardhat Local Node',
      nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
      wrappedNativeToken: {
        name: 'Wrapped Ether',
        symbol: 'WETH',
        decimals: 18,
        address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'.toLowerCase(),
      },
      tokenList: ETHEREUM_MAINNET_TOKEN_LIST,
    },
  }),
}

import styled from 'styled-components'
import { Menu as KebabMenu, MenuItem as KebabMenuItem } from '@szhsin/react-menu'

import { hexToRgbString } from '../styles'

export const Kebab = styled(KebabMenu)`
  margin-right: 40px;
  z-index: 100;
  .szh-menu {
    background-color: ${(props) => props.theme.colors.backgrounds.secondary};
  }
  .szh-menu__arrow {
    background-color: ${(props) => props.theme.colors.backgrounds.secondary};
  }
`

export const KebabItem = styled(KebabMenuItem)`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 1px;
  padding: 15px 20px;
  margin: 10px;
  border-radius: 8px;
  &:hover {
    background-color: ${(props) => hexToRgbString(props.theme.colors.backgrounds.tertiary, 0.6)};
  }
`

export const KebabText = styled.div`
  padding-right: 10px;
  font-size: 15px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.text.primary};
`

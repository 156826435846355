import styled, { css } from 'styled-components'
import { BigNumber } from 'ethers'
import { formatUnits } from 'ethers/lib/utils'
import { useEffect, useState } from 'react'

import { trimDecimalStringToPrecision } from '@packages/ui'
import { bnToFloat, BIG_NUMBER_ZERO } from '@packages/bn'
import { useWeb3ReactPlus } from '@packages/web3-react-plus'

import { StylingProps } from '../../shared'
import { TokenIcon } from './TokenIcon'
import { useTokenPrice } from '../hooks'
import { useAppStateReducer } from '../../AppState'
import { Token } from '../interfaces'

interface Props extends StylingProps {
  token: Token
  balance?: BigNumber
  tokenOnClick?: () => void
  buyComponent?: React.ReactNode
}

export function TokenBalance({
  token,
  balance = BIG_NUMBER_ZERO,
  tokenOnClick,
  buyComponent,
  className,
}: Props) {
  const { chainId } = useWeb3ReactPlus()
  const [usdValue, setUsdValue] = useState(0)
  const { data: price } = useTokenPrice(token)
  const [appState] = useAppStateReducer()

  useEffect(() => {
    getTokenUsdValue()
  }, [balance])

  const getTokenUsdValue = async () => {
    const usdBNValue = balance.mul(Math.floor((price ?? 0) * 10 ** 8))
    setUsdValue(bnToFloat(usdBNValue, token.decimals + 8))
  }

  const formattedBalance = formatUnits(balance, token.decimals)
  let trimmedBalance = trimDecimalStringToPrecision(
    formattedBalance,
    token.decimals > 4 ? 4 : token.decimals
  )
  if (trimmedBalance === '0' && !balance.isZero()) {
    trimmedBalance = '<0.0001'
  }

  let formattedUsdValue
  if (usdValue !== 0 && usdValue < 0.01) {
    formattedUsdValue = '<$0.01'
  } else {
    formattedUsdValue = '$' + trimDecimalStringToPrecision(usdValue, 2)
  }

  return (
    <Container>
      <IconColumn onClick={tokenOnClick}>
        <IconContainer>
          <TokenIcon tokenSymbol0={token.symbol} className={className} size={30} />
        </IconContainer>
      </IconColumn>
      <Column onClick={tokenOnClick}>
        <InfoContainer>
          <ColumnHeader>{token.symbol}</ColumnHeader>
          <ColumnValue>
            {appState[chainId]?.tokens?.bySymbol?.data?.[token.symbol]?.name ?? token.symbol}
          </ColumnValue>
        </InfoContainer>
      </Column>
      <Column onClick={tokenOnClick}>
        <InfoContainer>
          <ColumnHeader>{trimmedBalance}</ColumnHeader>
          <ColumnValue>{formattedUsdValue}</ColumnValue>
        </InfoContainer>
      </Column>
      <BuyColumn>{buyComponent}</BuyColumn>
    </Container>
  )
}

const Container = styled.div`
  display: inline-flex;
`

const Column = styled.div<{ onClick?: () => void }>`
  align-items: center;
  margin-right: auto;
  white-space: nowrap;
  flex: 1;
  width: 50px;
  ${(props) =>
    props.onClick &&
    css`
      cursor: pointer;
    `}
`

const BuyColumn = styled(Column)`
  display: flex;
  justify-content: center;
`

const IconColumn = styled.div`
  width: 35px;
  display: inline-flex;
  text-align: left;
  ${(props) =>
    props.onClick &&
    css`
      cursor: pointer;
    `}
`

const IconContainer = styled.div`
  width: 50px;
  margin-top: 5px;
  display: inline-flex;
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 11px;
`

const ColumnHeader = styled.span`
  font-weight: 400;
  font-size: 16px;
  color: ${(props) => props.theme.colors.text.primary};
`

const ColumnValue = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => props.theme.colors.text.secondary};
`

import styled, { useTheme } from 'styled-components'

import { useWeb3ReactPlus, CHAIN_ID, HARDHAT_CHAIN_ID } from '@packages/web3-react-plus'

import { ReactComponent as CautionIcon } from '../../shared/assets/images/caution.svg'
import { MenuIconDiv, MenuIconWrapper, Kebab, KebabItem, KebabText } from '../../shared'
import { useChainIcon } from '../hooks'
import { getChainIcon, getChainName, isSupportedChain, switchChain } from '../utils'

interface Props {
  isMobile?: boolean
  menuButton?: React.ReactElement
  offSetY?: number
  supportedChainIds: CHAIN_ID[]
  supportTestnetChainIds: CHAIN_ID[]
}

export function ChainSelectorMenu({
  isMobile = false,
  offSetY = -10,
  menuButton,
  supportedChainIds,
  supportTestnetChainIds,
}: Props) {
  const theme = useTheme()
  const { chainId, library } = useWeb3ReactPlus()
  const ChainIcon = useChainIcon()
  const iconFillColor = theme.colors.icons
  const chainNames = supportedChainIds
    .filter((chainId) => !supportTestnetChainIds.includes(Number(chainId)))
    .map(getChainName)

  const onChainChange = (id: number) => {
    if (library?.provider) {
      switchChain(id, library.provider)
    }
  }

  const chainSelectors = chainNames.map((chain) => {
    const id = CHAIN_ID[chain]
    const Icon = getChainIcon(id)
    return (
      <KebabItem key={id} onClick={() => onChainChange(id)}>
        <KebabText>{getChainName(id)}</KebabText>
        {Icon ? <Icon width={19} fill={iconFillColor} /> : getChainName(id).charAt(0)}
      </KebabItem>
    )
  })

  let icon
  if (ChainIcon) {
    icon = <ChainIcon width={19} height={19} fill={iconFillColor} />
  } else if (!isSupportedChain(chainId, supportedChainIds)) {
    icon = <CautionIcon width={19} height={19} fill={theme.colors.semantic.warning} />
  } else {
    icon = getChainName(chainId).charAt(0)
  }

  const button = menuButton ? (
    menuButton
  ) : (
    <MenuIcon>
      <MenuIconDivWrapper>{icon}</MenuIconDivWrapper>
    </MenuIcon>
  )

  return (
    <Kebab
      menuButton={button}
      transition
      direction={isMobile ? 'top' : 'bottom'}
      align='end'
      position='anchor'
      arrow
      offsetY={offSetY}
      offsetX={10}
    >
      {chainSelectors}
    </Kebab>
  )
}

const MenuIcon = styled(MenuIconWrapper)`
  width: 100%;
  display: flex;
`

const MenuIconDivWrapper = styled(MenuIconDiv)`
  width: 100%;
`

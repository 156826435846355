import { formatUnits } from 'ethers/lib/utils'

import { ProgressBar, StylingProps } from '@packages/ui'
import { BIG_NUMBER_ZERO } from '@packages/bn'

import { VAULT_STATUS } from '../../constants'
import { Vault } from '../../interfaces'
import { useVariableBearerTokenTotalSupply } from '../../hooks'
import { formatSymbol } from '../../utils'

interface Props extends StylingProps {
  vault: Vault
}

export function VariableCapacityProgressBar({ vault, className }: Props) {
  const [variableBearerTokenTotalSupply] = useVariableBearerTokenTotalSupply(vault)

  const totalSupply =
    vault.status !== VAULT_STATUS.NOT_STARTED
      ? vault.variableSideCapacity
      : variableBearerTokenTotalSupply

  return (
    <ProgressBar
      className={className}
      percent={totalSupply?.mul(100).div(vault.variableSideCapacity).toNumber() ?? 0}
      title='Variable Side Deposits'
      amount={`${formatUnits(
        totalSupply ?? BIG_NUMBER_ZERO,
        vault.variableAsset.decimals
      )}/${formatUnits(
        vault?.variableSideCapacity ?? BIG_NUMBER_ZERO,
        vault.variableAsset.decimals
      )} ${formatSymbol(vault.variableAsset.symbol)}`}
    />
  )
}

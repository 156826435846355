import { BigNumber } from 'ethers'
import { useState } from 'react'
import styled from 'styled-components'
import { useAutoAnimate } from '@formkit/auto-animate/react'

import { Tokens, Chains, Accounts } from '@packages/ui'
import {
  CHAIN_ID,
  CHAIN_INFO,
  useBalance,
  useErc20Balance,
  useWeb3ReactPlus,
} from '@packages/web3-react-plus'
import { BIG_NUMBER_ZERO } from '@packages/bn'

import { useAppStateReducer } from 'src/AppState'

import { formatSymbol, isNonZeroAmount, isZeroAmount } from '../../../utils'
import {
  TRANSACTION_DESCRIPTIONS,
  UNI_V3_VAULT_DESCRIPTIONS,
  VAULT_STATUS,
} from '../../../constants'
import {
  BigNumberButtonAmount,
  ButtonContent,
  ButtonWrapper,
  Section,
  VaultFormProps,
  ErrorMessageWrapper,
} from './UniV3VaultForm'
import {
  getVault,
  useVariableBearerTokenBalance,
  useVariableBearerTokenTotalSupply,
} from '../../../hooks'
import {
  depositUniV3Variable,
  tokenApproveAllowance,
  withdrawUniV3Variable,
  wrapNativeCurrency,
} from '../../../transactions'
import { VariableCapacityProgressBar } from '../VariableCapacityProgressBar'
import { FixedCapacityProgressBar } from '../FixedCapacityProgressBar'
import { DurationProgressBar } from '../DurationProgressBar'

export function UniV3VariableForm({ vault, adapter }: VaultFormProps) {
  const [parent] = useAutoAnimate()

  const [, dispatch] = useAppStateReducer()
  const { variableAsset } = vault
  const { activeAddress, provider, chainId } = useWeb3ReactPlus()
  const [nativeCurrencyBalance] = useBalance({ address: activeAddress ?? '' })
  const [variableAssetBalance] = useErc20Balance({
    tokenAddress: variableAsset.address,
    balanceAddress: activeAddress ?? '',
  })

  const [currentTxStep, setCurrentTxStep] = useState<number>()
  function updateCurrentTxStep(step: number) {
    setCurrentTxStep(step)
    setDepositTxModalOpen(true)
  }

  const [variableBearerTokenBalance, , updateVariableBearerTokenBalance] =
    useVariableBearerTokenBalance(vault)
  const [variableBearerTokenTotalSupply, , updateVariableBearerTokenTotalSupply] =
    useVariableBearerTokenTotalSupply(vault)

  const [depositLoadingText, setDepositLoadingText] = useState<string>()
  const [depositTxHash, setDepositTxHash] = useState<string>()
  const [wrapTxHash, setWrapTxHash] = useState<string>()
  const [allowanceTxHash, setAllowanceTxHash] = useState<string>()
  const [depositError, setDepositError] = useState<React.ReactNode>()
  const [depositTxModalOpen, setDepositTxModalOpen] = useState(false)

  const [earlyWithdrawLoadingText, setEarlyWithdrawLoadingText] = useState<string>()
  const [earlyWithdrawTxHash, setEarlyWithdrawTxHash] = useState<string>()
  const [earlyWithdrawError, setEarlyWithdrawError] = useState<React.ReactNode>()

  const [withdrawEarningsLoadingText, setWithdrawEarningsLoadingText] = useState<string>()
  const [withdrawEarningsTxHash, setWithdrawEarningsTxHash] = useState<string>()
  const [withdrawEarningsError, setWithdrawEarningsError] = useState<React.ReactNode>()

  const chainInfo = CHAIN_INFO[chainId as CHAIN_ID]

  function resetData() {
    setDepositTxHash(undefined)
    setEarlyWithdrawTxHash(undefined)
    setWithdrawEarningsTxHash(undefined)
    setCurrentTxStep(undefined)

    setDepositError(undefined)
    setEarlyWithdrawError(undefined)
    setWithdrawEarningsError(undefined)
  }

  async function updateData() {
    await getVault({ web3Provider: provider, chainId, dispatch, vaultInfo: vault })
    updateVariableBearerTokenBalance()
    updateVariableBearerTokenTotalSupply()
  }

  async function deposit({ amount, clearInput }: { amount: BigNumber; clearInput: () => void }) {
    // Wallet not connected
    if (!provider || !activeAddress) return

    resetData()
    setDepositTxModalOpen(true)

    let tx
    let step = 0

    try {
      if (Tokens.isWrappedNativeToken(variableAsset.symbol, chainInfo)) {
        updateCurrentTxStep(step)
        step++
      }
      if (
        Tokens.isWrappedNativeToken(variableAsset.symbol, chainInfo) &&
        amount.gt(variableAssetBalance ?? BIG_NUMBER_ZERO)
      ) {
        tx = await wrapNativeCurrency({
          amount: amount.sub(variableAssetBalance ?? BIG_NUMBER_ZERO),
          chainInfo,
          web3Provider: provider,
          setLoadingText: setDepositLoadingText,
          smallerLoadingText: true,
        })
        setWrapTxHash(tx.hash)
      }

      updateCurrentTxStep(step)
      step++
      tx = await tokenApproveAllowance({
        ownerAddress: activeAddress,
        spenderAddress: vault.address,
        token: variableAsset,
        desiredAmount: amount,
        amountToApprove: amount,
        web3Provider: provider,
        setLoadingText: setDepositLoadingText,
        smallerLoadingText: true,
      })
      setAllowanceTxHash(tx?.hash)

      updateCurrentTxStep(step)
      step++
      setDepositLoadingText('Depositing...')
      tx = await depositUniV3Variable({ vault, web3Provider: provider, amount })
      await tx.wait()
      setDepositTxHash(tx.hash)

      clearInput()
      await updateData()
    } catch (error: any) {
      setDepositError(<Chains.BlockExplorerError error={error} tx={tx} />)
    } finally {
      setDepositLoadingText(undefined)
    }
  }

  function getEarlyWithdrawButtonState() {
    if (
      isZeroAmount(variableBearerTokenBalance) ||
      earlyWithdrawLoadingText ||
      vault.status !== VAULT_STATUS.NOT_STARTED
    ) {
      return { disabled: true, loading: earlyWithdrawLoadingText ? true : undefined }
    }

    return {}
  }

  async function earlyWithdraw() {
    // Wallet not connected
    if (!provider || !activeAddress) return

    resetData()

    let tx
    try {
      setEarlyWithdrawLoadingText('Withdrawing...')
      tx = await withdrawUniV3Variable({ vault, web3Provider: provider })
      await tx.wait()
      setEarlyWithdrawTxHash(tx.hash)

      await updateData()
    } catch (error: any) {
      setEarlyWithdrawError(<Chains.BlockExplorerError error={error} tx={tx} />)
    } finally {
      setEarlyWithdrawLoadingText(undefined)
    }
  }

  function getWithdrawEarningsButtonState() {
    if (
      isZeroAmount(variableBearerTokenBalance) ||
      withdrawEarningsLoadingText ||
      vault.status !== VAULT_STATUS.ENDED
    ) {
      return { disabled: true, loading: withdrawEarningsLoadingText ? true : undefined }
    }

    return {}
  }

  async function withdrawEarnings() {
    // Wallet not connected
    if (!provider || !activeAddress) return

    resetData()

    let tx
    try {
      setWithdrawEarningsLoadingText('Collecting...')
      tx = await withdrawUniV3Variable({ vault, web3Provider: provider })
      await tx.wait()
      setWithdrawEarningsTxHash(tx.hash)

      await updateData()
    } catch (error: any) {
      setWithdrawEarningsError(<Chains.BlockExplorerError error={error} tx={tx} />)
    } finally {
      setWithdrawEarningsLoadingText(undefined)
    }
  }

  const availableVariableAssetAmount = Tokens.isWrappedNativeToken(variableAsset.symbol, chainInfo)
    ? (variableAssetBalance ?? BIG_NUMBER_ZERO).add(nativeCurrencyBalance ?? BIG_NUMBER_ZERO)
    : variableAssetBalance

  return (
    <>
      <Section data-test-id='vault-variable-side-form'>
        <FixedCapacityProgressBar
          vault={vault}
          usdLiquidityValue={adapter.usdLiquidityValue ?? BIG_NUMBER_ZERO}
        />
        <VariableCapacityProgressBar vault={vault} />

        {activeAddress ? (
          <InputContainer ref={parent}>
            {vault.status === VAULT_STATUS.NOT_STARTED &&
              !(variableBearerTokenTotalSupply ?? BIG_NUMBER_ZERO).eq(
                vault.variableSideCapacity
              ) && ( // capacity not reached
                <Tokens.AvailableAmountFormInput
                  submitText='Deposit'
                  token={{
                    ...variableAsset,
                    symbol: formatSymbol(variableAsset.symbol) ?? variableAsset.symbol,
                  }}
                  availableAmount={availableVariableAssetAmount}
                  maxAmount={vault.variableSideCapacity.sub(
                    variableBearerTokenTotalSupply ?? BIG_NUMBER_ZERO
                  )}
                  loadingText={depositLoadingText}
                  errorContent={depositError}
                  clearError={() => setDepositError(null)}
                  onSubmit={deposit}
                  hidePercentButtons
                />
              )}
            {depositTxHash && <Chains.BlockExplorerTxUrl txHash={depositTxHash} />}
            <Chains.TxStepProgressModal
              title='Variable Side Deposit'
              isOpen={depositTxModalOpen}
              onRequestClose={() => setDepositTxModalOpen(false)}
              steps={[
                ...(Tokens.isWrappedNativeToken(variableAsset.symbol, chainInfo)
                  ? [
                      {
                        title: 'Wrapping Native Token',
                        description: TRANSACTION_DESCRIPTIONS.wrappingNativeToken(
                          chainInfo.nativeCurrency.symbol,
                          chainInfo.wrappedNativeToken.symbol
                        ),
                        txHash: wrapTxHash,
                      },
                    ]
                  : []),
                {
                  title: `Approving ${variableAsset.symbol} Allowance`,
                  description: TRANSACTION_DESCRIPTIONS.tokenApproveAllowance(variableAsset.symbol),
                  txHash: allowanceTxHash,
                },
                {
                  title: 'Depositing',
                  description: UNI_V3_VAULT_DESCRIPTIONS.variableSide.transactions.deposit(
                    variableAsset.symbol
                  ),
                  txHash: depositTxHash,
                },
              ]}
              currentStep={currentTxStep}
              success={depositTxHash && <BlockExplorerTxUrlWrapper txHash={depositTxHash} />}
              error={depositError && <ErrorMessageWrapper>{depositError}</ErrorMessageWrapper>}
            />

            {vault.status === VAULT_STATUS.NOT_STARTED && (
              <WithdrawContainer>
                <ButtonWrapper
                  {...getEarlyWithdrawButtonState()}
                  onClick={earlyWithdraw}
                  data-test-id='variable-early-withdraw-button'
                >
                  {earlyWithdrawLoadingText ? (
                    <ButtonContent>{earlyWithdrawLoadingText}</ButtonContent>
                  ) : (
                    <ButtonContent>
                      Cancel & Withdraw
                      {isNonZeroAmount(variableBearerTokenBalance) && (
                        <>
                          {' ('}
                          <BigNumberButtonAmount
                            amount={variableBearerTokenBalance}
                            precision={variableAsset.decimals}
                            tokenSymbol={variableAsset.symbol}
                          />
                          {')'}
                        </>
                      )}
                    </ButtonContent>
                  )}
                </ButtonWrapper>
              </WithdrawContainer>
            )}
            {earlyWithdrawError && <ErrorMessageWrapper>{earlyWithdrawError}</ErrorMessageWrapper>}
            {earlyWithdrawTxHash && <Chains.BlockExplorerTxUrl txHash={earlyWithdrawTxHash} />}

            {vault.status !== VAULT_STATUS.NOT_STARTED && (
              <ButtonWrapper
                {...getWithdrawEarningsButtonState()}
                onClick={withdrawEarnings}
                data-test-id='variable-withdraw-earnings-button'
              >
                {withdrawEarningsLoadingText ? (
                  <ButtonContent>{withdrawEarningsLoadingText}</ButtonContent>
                ) : (
                  <ButtonContent>Collect Earnings</ButtonContent>
                )}
              </ButtonWrapper>
            )}
            {withdrawEarningsError && (
              <ErrorMessageWrapper>{withdrawEarningsError}</ErrorMessageWrapper>
            )}
            {withdrawEarningsTxHash && (
              <Chains.BlockExplorerTxUrl txHash={withdrawEarningsTxHash} />
            )}

            {vault.status !== VAULT_STATUS.NOT_STARTED && <DurationProgressBar vault={vault} />}
          </InputContainer>
        ) : (
          <Accounts.ConnectWalletButton />
        )}
      </Section>
    </>
  )
}

const WithdrawContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const InputContainer = styled.div<{ ref: any }>`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

const BlockExplorerTxUrlWrapper = styled(Chains.BlockExplorerTxUrl)`
  flex-direction: column;
`

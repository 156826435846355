import { BigNumber } from 'ethers'

import { formatBps, Tokens } from '@packages/ui'
import { CHAIN_ID } from '@packages/web3-react-plus'
import { BIG_NUMBER_ZERO } from '@packages/bn'

import { buildUrl, Routes } from 'src/pages'
import { TEST_ENVIRONMENT } from 'src/config'

import { Vault, UniV3Adapter } from '../interfaces'

export function getTimeElapsed({
  vault,
  blockTimestamp,
}: {
  vault: Vault
  blockTimestamp?: number
}) {
  const timestamp = blockTimestamp ?? 0
  return vault.endTime < timestamp ? vault.duration : vault.duration - (vault.endTime - timestamp)
}

export function getVaultDetailsUrl(vault: Vault) {
  return buildUrl(Routes.VAULT_DETAILS.route, vault.chainId, vault.id)
}

export function getUniV3VaultTitle(adapter: UniV3Adapter) {
  return (
    Tokens.toStakedTokenSymbol(adapter.pool.token0.symbol, adapter.pool.token1.symbol) +
    ` (${formatBps(adapter.pool.fee ?? 0, true)})`
  )
}

// Many uniswap utils will fail if you try to use them with an unsupported chainId, ie the hardhat local chainId
export const getUniswapChainId = (chainId: number) => {
  return TEST_ENVIRONMENT ? CHAIN_ID.Ethereum : chainId
}

export function getVaultSearchText(vault: Vault) {
  const searchItems = new Set(
    [vault?.variableAsset.name, vault?.variableAsset.symbol]
      .map((item) => (item ? item.toLowerCase() : undefined))
      .filter((item) => !!item && item !== '')
  )

  return Array.from(searchItems).join(' ')
}

export function getUniV3SearchText(adapter: UniV3Adapter) {
  const searchItems = new Set(
    [
      'uniswap',
      'uniV3',
      adapter?.pool.token0?.name,
      adapter?.pool.token1?.name,
      adapter?.pool.token0?.symbol,
      adapter?.pool.token1?.symbol,
      adapter?.pool.address,
      formatBps(adapter?.pool.fee ?? 0, true),
    ]
      .map((item) => (item ? item.toLowerCase() : undefined))
      .filter((item) => !!item && item !== '')
  )

  return Array.from(searchItems).join(' ')
}

export function isZeroAmount(amount?: BigNumber) {
  return (amount ?? BIG_NUMBER_ZERO).isZero()
}

export function isNonZeroAmount(amount?: BigNumber) {
  return !isZeroAmount(amount)
}

export function isExpectedAmount(expectedBalance: BigNumber | number, amount?: BigNumber) {
  return (amount ?? BIG_NUMBER_ZERO).eq(expectedBalance)
}

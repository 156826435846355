import { useState } from 'react'
import styled from 'styled-components'

import { Button, StylingProps } from '../../shared'
import { ConnectWalletModal } from './ConnectWalletModal'

export function ConnectWalletButton({ className }: StylingProps) {
  const [walletModalOpen, setWalletModalOpen] = useState(false)

  function toggleConnectWalletModal() {
    setWalletModalOpen(!walletModalOpen)
  }

  return (
    <>
      <ButtonWrapper
        className={className}
        onClick={toggleConnectWalletModal}
        data-test-id='connect-wallet-button'
      >
        Connect Wallet
      </ButtonWrapper>
      <ConnectWalletModal isOpen={walletModalOpen} onRequestClose={toggleConnectWalletModal} />
    </>
  )
}

export const ButtonWrapper = styled(Button)`
  width: 100%;
  font-size: 17px;
  line-height: 21px;
  letter-spacing: -0.02em;
`

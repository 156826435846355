import styled from 'styled-components'

import { StylingProps } from '../../shared'
import { TokenSymbol } from '../constants'
import { useTokenIcons, useTokens } from '../hooks'

interface Props extends StylingProps {
  tokenSymbol0?: TokenSymbol | string
  tokenSymbol1?: TokenSymbol | string
  size: number
}

function transformIpfsUrl(url: string) {
  if (url.includes('ipfs://')) {
    return 'https://cloudflare-ipfs.com/ipfs/' + url.substring(7)
  }

  return url
}

export function TokenIcon({ tokenSymbol0, tokenSymbol1, size, className }: Props) {
  const [token0Svg, token1Svg] = useTokenIcons(tokenSymbol0, tokenSymbol1)
  const { bySymbol } = useTokens()

  let token0Icon = token0Svg
  let token1Icon = token1Svg
  if (bySymbol?.data) {
    const token0LogoURI = bySymbol?.data[tokenSymbol0 ?? '']?.logoURI
    if (token0LogoURI) {
      token0Icon = transformIpfsUrl(token0LogoURI)
    }

    const token1LogoURI = bySymbol?.data[tokenSymbol1 ?? '']?.logoURI
    if (token1LogoURI) {
      token1Icon = transformIpfsUrl(token1LogoURI)
    }
  }

  return (
    <IconColumn className={className} height={size}>
      <IconContainer height={size}>
        <Icon>
          <Img width={size} height={size} src={token0Icon} />
        </Icon>
        {tokenSymbol1 ? (
          <Icon overlap>
            <Img width={size} height={size} src={token1Icon} />
          </Icon>
        ) : null}
      </IconContainer>
    </IconColumn>
  )
}

const IconColumn = styled.div<{ height: number }>`
  display: inline-flex;
  text-align: left;
  height: ${(props) => props.height + 'px'};
`

const IconContainer = styled.div<{ height: number }>`
  display: inline-flex;
  height: ${(props) => props.height + 'px'};
`

const Icon = styled.div<{ overlap?: boolean }>`
  margin-left: ${(props) => (props.overlap ? '-5px' : '0')};
`

const Img = styled.img`
  background: radial-gradient(
    white 50%,
    rgba(255, 255, 255, 0) calc(75% + 1px),
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 50%;
  box-shadow: black 0px 0px 1px;
  border: 0px solid rgba(255, 255, 255, 0);
`

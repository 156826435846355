import { Contract } from 'ethers'
import { Web3Provider } from '@ethersproject/providers'

import ERC20 from '../abis/ERC20.json'

export class ERC20Contract extends Contract {
  constructor(address: string, provider: Web3Provider) {
    super(address, ERC20.abi, provider)
  }
}

import styled, { css } from 'styled-components'

import { CSS_TRANSITION, formatBps, hexToRgbString, Uniswap } from '@packages/ui'

interface Props {
  pools: Uniswap.UniswapV3Pool[]
  feeTier: Uniswap.UNISWAP_FEE_TIER
  selected: boolean
  onClick: () => void
}

export function FeeTierButton({ pools, feeTier, selected = false, onClick }: Props) {
  const getFeeTier = (feeTier: Uniswap.UNISWAP_FEE_TIER) => {
    const pool = pools.find((pool) => pool.feeTier === feeTier)
    if (!pool) return null
    if (+pool.totalValueLockedUSD === 0) return null
    return pool
  }

  const getFeeTierPercentage = (feeTier: Uniswap.UNISWAP_FEE_TIER) => {
    const tier = getFeeTier(feeTier)
    if (tier === null) {
      return 'Not Created'
    }

    const totalLiquidity = pools.reduce(
      (result, curr) =>
        result + Number(curr.totalValueLockedToken0) + Number(curr.totalValueLockedToken1),
      0
    )
    if (totalLiquidity === 0) return 'Not Created'
    return `${Math.round(
      (100 * (Number(tier.totalValueLockedToken0) + Number(tier.totalValueLockedToken1))) /
        totalLiquidity
    )}% select`
  }

  return (
    <Container selected={selected} onClick={onClick} data-test-id={'fee-tier-button-' + selected}>
      <Title>{formatBps(feeTier, true)}</Title>
      <Description>{Uniswap.UNISWAP_FEE_TIER_DESC[feeTier]}</Description>
      <Percentage>{getFeeTierPercentage(feeTier)}</Percentage>
    </Container>
  )
}

const Container = styled.div<{ selected: boolean }>`
  ${CSS_TRANSITION}
  border-radius: 10px;
  border: 3px solid ${(props) => props.theme.colors.backgrounds.tertiary};
  color: ${(props) => props.theme.colors.text.primary};
  padding: 10px;
  display: flex;
  align-content: center;
  flex-direction: column;
  row-gap: 5px;
  max-width: 110px;
  align-content: space-around;
  cursor: pointer;

  background-color: ${(props) =>
    props.selected ? props.theme.colors.backgrounds.tertiary : 'none'};

  &:hover {
    background-color: ${(props) => props.theme.colors.backgrounds.tertiary};
  }

  ${(props) =>
    props.theme.type === 'light' &&
    css`
      border: 3px solid ${props.theme.colors.backgrounds.tertiary};
      background-color: ${props.selected
        ? props.theme.colors.backgrounds.secondary
        : props.theme.colors.backgrounds.tertiary};
      &:hover {
        background-color: ${hexToRgbString(props.theme.colors.backgrounds.tertiary, 0.75)};
      }
    `}
`

const Title = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
`

const Description = styled.div`
  font-size: 13px;
  color: ${(props) => props.theme.colors.text.secondary};
`

const Percentage = styled.div`
  font-size: 12px;
  font-weight: 500;
  border-radius: 5px;
  padding: 5px;
  text-align: center;
  background-color: ${(props) => props.theme.colors.backgrounds.tertiary};

  ${(props) =>
    props.theme.type === 'light' &&
    css`
      background-color: ${(props) => props.theme.colors.backgrounds.secondary};
    `}
`

import { BigNumber } from '@ethersproject/bignumber'
import { hexStripZeros } from '@ethersproject/bytes'
import { ExternalProvider } from '@ethersproject/providers'

import { CHAIN_ID, CHAIN_INFO, HARDHAT_CHAIN_ID, RPC_URLS } from '@packages/web3-react-plus'

function getRpcUrls(chainId: number): string[] {
  switch (chainId) {
    case CHAIN_ID.Ethereum:
    case CHAIN_ID.Ropsten:
    case CHAIN_ID.Rinkeby:
    case CHAIN_ID.Goerli:
    case CHAIN_ID.Kovan:
    case CHAIN_ID.KCC:
    case HARDHAT_CHAIN_ID:
      return [RPC_URLS[chainId as CHAIN_ID]]
    case CHAIN_ID.Arbitrum:
    case CHAIN_ID.ArbitrumGoerli:
      return ['https://arb1.arbitrum.io/rpc']
    case CHAIN_ID.Polygon:
      return ['https://polygon-rpc.com/']
  }

  throw new Error('RPC URLs must use public endpoints')
}

// provider.request returns Promise<any>, but wallet_switchEthereumChain must return null or throw
// see https://github.com/rekmarks/EIPs/blob/3326-create/EIPS/eip-3326.md for more chainInfo on wallet_switchEthereumChain
export async function switchChain(
  chainId: number,
  provider: ExternalProvider
): Promise<null | void> {
  if (!provider.request) {
    return
  }
  const formattedChainId = hexStripZeros(BigNumber.from(chainId).toHexString())
  try {
    await provider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: formattedChainId }],
    })
  } catch (error) {
    console.debug('Switching chain threw an error', error)
    console.debug('Attempting to add chain ' + formattedChainId)

    const chainInfo = CHAIN_INFO[chainId as CHAIN_ID]

    await provider.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: formattedChainId,
          chainName: chainInfo.name,
          rpcUrls: getRpcUrls(chainId),
          nativeCurrency: chainInfo.nativeCurrency,
          blockExplorerUrls: [chainInfo.explorer.url],
        },
      ],
    })
    // metamask (only known implementer) automatically switches after a network is added
    // the second call is done here because that behavior is not a part of the spec and cannot be relied upon in the future
    // metamask's behavior when switching to the current network is just to return null (a no-op)
    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: formattedChainId }],
      })
    } catch (error) {
      console.debug('Added network but could not switch chains', error)
    }
  }
}

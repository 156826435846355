import styled from 'styled-components'

import { formatApr } from '../../shared'

interface Props {
  dailyRate: number
}

export function UniswapDailyRate({ dailyRate }: Props) {
  return (
    <Container>
      <Text>Estimated Uniswap APR: </Text>
      {formatApr(dailyRate * 100 * 365)}
    </Container>
  )
}

const Container = styled.span`
  font-weight: 500;
  color: ${(props) => props.theme.colors.text.primary};
`

const Text = styled.span`
  font-weight: 400;
  color: ${(props) => props.theme.colors.text.secondary};
`

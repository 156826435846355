import { ExternalProvider } from '@ethersproject/providers'

import { TokenSymbol } from '../constants'

export async function addErc20Token({
  provider,
  token,
}: {
  provider?: ExternalProvider
  token: { address: string; symbol: TokenSymbol; decimals: number; image: string }
}) {
  if (!provider?.request) {
    return
  }

  try {
    await provider.request({
      method: 'wallet_watchAsset',
      params: { type: 'ERC20', options: token } as any,
    })
  } catch (error) {
    console.debug('Adding a token threw an error', error)
    console.debug('Attempting to add Erc20 token ' + token.symbol)
  }
}

import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

import { CHAIN_ID } from '@packages/web3-react-plus'

import { useDebounce } from '../../shared'
import { COIN_CAP, COIN_GECKO } from '../constants'
import { CHAIN_ID_TO_COIN_GECKO_PLATFORM, Token } from '../interfaces'
import { useCoinCapTokens } from './useCoinCapTokens'
import { useCoinGeckoTokens } from './useCoinGeckoTokens'

const USD_STABLE_COINS = ['ARB', 'USDC', 'USDT', 'DAI']

const PRICE_REFETCH_INTERVAL_MS = 1000 * 15
const DEBOUNCE_MS = 100

export function useTokenPrice(token?: Token) {
  const [previousPrice, setPreviousPrice] = useState<number>()
  const { data: coinGeckoTokenList } = useCoinGeckoTokens()
  const { data: coinCapTokenList } = useCoinCapTokens()

  const tokenSymbol = token?.symbol ?? ''
  const tokenAddress = token?.address ?? ''

  const {
    error,
    data: coinGeckoPrice,
    isFetching,
  } = useQuery(
    ['coinGeckoTokenPrice', tokenSymbol, Object.keys(coinGeckoTokenList ?? {}).length],
    async () => {
      const platform =
        CHAIN_ID_TO_COIN_GECKO_PLATFORM[(token?.chainId ?? CHAIN_ID.Ethereum) as CHAIN_ID]
      const coinGeckoToken = coinGeckoTokenList?.find(
        (t) =>
          t.symbol.toUpperCase() === tokenSymbol.toUpperCase() &&
          t.platforms?.[platform]?.toUpperCase() === tokenAddress.toUpperCase()
      )
      if (coinGeckoToken) {
        const price = await getPriceFromCoinGecko(coinGeckoToken.id)
        return price
      }
      return 0
    },
    {
      refetchInterval: PRICE_REFETCH_INTERVAL_MS,
      retry: true,
      enabled: !!tokenSymbol,
    }
  )

  const { data: coinCapPrice } = useQuery(
    ['coinCapTokenPrice', tokenSymbol, Object.keys(coinCapTokenList ?? {}).length],
    async () => {
      const coinCapId = coinCapTokenList?.[tokenSymbol]?.id
      if (coinCapId) {
        const price = await getPriceFromCoinCap(coinCapId)
        return price
      }

      return 0
    },
    {
      refetchInterval: PRICE_REFETCH_INTERVAL_MS,
      retry: true,
      enabled: !!tokenSymbol,
    }
  )

  useEffect(() => {
    if (coinGeckoPrice && coinGeckoPrice > 0) {
      setPreviousPrice(coinGeckoPrice)
    } else if (coinCapPrice && coinCapPrice > 0) {
      setPreviousPrice(coinCapPrice)
    }
  }, [coinGeckoPrice, coinCapPrice])

  let finalPrice: number
  if (coinGeckoPrice && coinGeckoPrice > 0) {
    finalPrice = coinGeckoPrice
  } else if (coinCapPrice && coinCapPrice > 0) {
    finalPrice = coinCapPrice
  } else if (previousPrice && previousPrice > 0) {
    finalPrice = previousPrice
  } else if (USD_STABLE_COINS.includes(token?.symbol.toUpperCase() ?? '')) {
    finalPrice = 1
  } else {
    finalPrice = 0
  }
  const debouncedPrice = useDebounce(finalPrice, DEBOUNCE_MS)

  return {
    isFetching,
    error,
    data: debouncedPrice,
  }
}

export interface CoinGeckoPriceResult {
  [coinGeckoId: string]: { usd: string }
}

export async function getPriceFromCoinGecko(
  coinGeckoTokenId: string,
  currencyCode = 'usd'
): Promise<number> {
  const controller = new AbortController()
  const timeout = setTimeout(() => {
    controller.abort()
  }, 1500)
  const url =
    COIN_GECKO.API.HOST +
    COIN_GECKO.API.PRICE_ENDPOINT +
    coinGeckoTokenId +
    `&vs_currencies=${currencyCode}`

  let result: CoinGeckoPriceResult
  try {
    const response = await fetch(url, { signal: controller.signal })
    result = await response.json()
  } catch (error: any) {
    console.info(error)
    throw new Error('Error while retrieving CoinGecko price', error)
  }

  clearInterval(timeout)
  return Number(result[coinGeckoTokenId].usd)
}

interface CoinCapPriceResult {
  data: { priceUsd: string }
}

export async function getPriceFromCoinCap(coinCapId: string): Promise<number> {
  const url = COIN_CAP.API.HOST + COIN_CAP.API.PRICE_ENDPOINT + coinCapId
  let result: CoinCapPriceResult
  try {
    const response = await fetch(url)
    result = await response.json()
  } catch (error: any) {
    console.info(error)
    throw new Error('Error while retrieving CoinCap price', error)
  }
  return Number(result.data.priceUsd)
}

import styled from 'styled-components'

import { StylingProps } from '../../styles'

interface Props extends StylingProps {
  id: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
  checked: boolean
}

export function Toggle({ onChange, checked, id, className }: Props) {
  return (
    <ToggleContainer className={className}>
      <Checkbox type='checkbox' id={id} onChange={onChange} checked={checked} data-test-id={id} />
      <Label htmlFor={id} />
    </ToggleContainer>
  )
}

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Checkbox = styled.input`
  display: none;
  &:checked + label {
    &:before {
      background-color: ${(props) => props.theme.colors.text.secondary};
      transform: translateX(20px);
    }
  }
`

const Label = styled.label`
  position: relative;
  display: block;
  width: 45px;
  height: 25px;
  border-radius: 15px;
  background-color: ${(props) => props.theme.colors.backgrounds.secondary};
  overflow: hidden;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  &:before,
  &:after {
    display: block;
    position: absolute;
    content: '';
    width: 18px;
    height: 18px;
    border-radius: 50%;
    top: 3.5px;
    left: 3.5px;
    transition: 0.5s ease;
  }

  &:before {
    background-color: ${(props) => props.theme.colors.text.secondary};
  }

  &:after {
    background-color: ${(props) => props.theme.colors.backgrounds.secondary};
    left: -14.5px;
    transform: scale(0.00001);
  }
`

import React, { useState, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'

import { injected, networkConnector } from './connectors'
import { useChainStateReducer } from './ChainStateProvider'

export function useActivatingConnector() {
  const { connector } = useWeb3React<Web3Provider>()
  const [activatingConnector, setActivatingConnector] = React.useState<any>(networkConnector)
  React.useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(networkConnector)
    }
  }, [activatingConnector, connector])

  return [activatingConnector, setActivatingConnector]
}

export function useEagerConnect(isReturningUser = true) {
  const { activate, active } = useWeb3React()

  const [tried, setTried] = useState(false)

  useEffect(() => {
    injected.isAuthorized().then((isAuthorized: boolean) => {
      if (isAuthorized && isReturningUser) {
        activate(injected, undefined, true).catch((error) => {
          console.log(error)
          setTried(true)
        })
      } else {
        setTried(true)
      }
    })
  }, []) // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (!tried && active) {
      setTried(true)
    }
  }, [tried, active])

  return tried
}

export function useInactiveListener(suppress = false) {
  const { active, error, activate } = useWeb3React()

  useEffect((): any => {
    const { ethereum } = window as any
    if (ethereum && ethereum.on && !active && !error && !suppress) {
      const handleConnect = () => {
        console.log('Handling "connect" event')
        activate(injected)
      }
      const handleChainChanged = (chainId: string | number) => {
        console.log('Handling "chainChanged" event with payload', chainId)
        activate(injected)
      }
      const handleAccountsChanged = (accounts: string[]) => {
        console.log('Handling "accountsChanged" event with payload', accounts)
        if (accounts.length > 0) {
          activate(injected)
        }
      }
      const handleNetworkChanged = (networkId: string | number) => {
        console.log('Handling "chainChanged" event with payload', networkId)
        console.log('Handling "networkChanged" event with payload', networkId)
        activate(injected)
      }

      ethereum.on('connect', handleConnect)
      ethereum.on('chainChanged', handleChainChanged)
      ethereum.on('accountsChanged', handleAccountsChanged)
      ethereum.on('networkChanged', handleNetworkChanged)

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('connect', handleConnect)
          ethereum.removeListener('chainChanged', handleChainChanged)
          ethereum.removeListener('accountsChanged', handleAccountsChanged)
          ethereum.removeListener('networkChanged', handleNetworkChanged)
        }
      }
    }
  }, [active, error, suppress, activate])
}

export function useBlockNumberUpdates() {
  const { chainId, connector, active, library } = useWeb3React()
  const [chainState, dispatch] = useChainStateReducer()

  const updateBlockNumber = (blockNumber: number) => {
    dispatch({
      type: 'BlockNumberUpdated',
      payload: { chainId, blockNumber },
    })
  }

  useEffect((): any => {
    if (library && active) {
      if (!chainState?.[chainId ?? 0]?.blockNumber) {
        library
          .getBlockNumber()
          .then(updateBlockNumber)
          .catch((e: Error) => {
            console.error(e)
          })
      }

      library.on('block', updateBlockNumber)

      return () => {
        library.removeListener('block', updateBlockNumber)
      }
    }
  }, [library, connector, active])
}

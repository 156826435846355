import { useEffect, useState } from 'react'

import { useWeb3ReactPlus } from '@packages/web3-react-plus'

import { UniswapTick } from '../interfaces'
import { getPoolTicks } from '../utils'

export function usePoolTicks(poolAddress: string) {
  const { chainId } = useWeb3ReactPlus()
  const [ticks, setTicks] = useState<UniswapTick[]>([])
  const [error, setError] = useState<Error>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getTicks({ poolAddress, chainId, setTicks, setError, setLoading })
  }, [poolAddress, setTicks, setError, setLoading])

  return { ticks, error, loading }
}

async function getTicks({
  poolAddress,
  chainId,
  setTicks,
  setLoading,
  setError,
}: {
  poolAddress: string
  chainId: number
  setTicks: (ticks: UniswapTick[]) => void
  setLoading: (loading: boolean) => void
  setError: (error: Error) => void
}) {
  try {
    setLoading(true)
    const tokens = await getPoolTicks(poolAddress, chainId)
    setTicks(tokens)
  } catch (error: any) {
    setError(error)
  } finally {
    setLoading(false)
  }
}

import { format, parseISO } from 'date-fns'

const APPROX_WEEKS_PER_MONTH = 4.34524

export const SECONDS_PER = {
  YEAR: 31_556_952,
  WEEK: 604800,
  DAY: 86400,
  HOUR: 3600,
}

export function epochMsToISO(epochMs: number): string {
  return new Date(epochMs).toISOString().substring(0, 10)
}

export function epochMsToLocaleString(epochMs: number): string {
  return new Date(epochMs).toLocaleString(undefined, {
    day: 'numeric',
    month: 'numeric',
    year: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
  })
}

export function formatDate(isoString?: string): string {
  return isoString ? format(parseISO(isoString), 'MMM d, yyyy') : ''
}

export function dateToSeconds(date: Date): number {
  return Math.floor(date.getTime() / 1000)
}

export function durationToSeconds(
  years: number,
  months: number,
  weeks: number,
  days: number,
  hours: number
) {
  let seconds = SECONDS_PER.YEAR * years
  if (months === 12) {
    seconds += SECONDS_PER.YEAR
  } else {
    seconds += Math.floor(APPROX_WEEKS_PER_MONTH * months * SECONDS_PER.WEEK)
  }
  seconds += SECONDS_PER.WEEK * weeks
  seconds += SECONDS_PER.DAY * days
  seconds += 3600 * hours

  return seconds
}

export function msToSeconds(ms: number): number {
  return Math.floor(ms / 1000)
}

export function minutesToMs(minutes: number): number {
  return minutes * 60000
}

export function msToMinutes(ms: number): number {
  return Math.floor(ms / 60000)
}

export function secondsToString(seconds: number, displayMinutes = false, displaySeconds = false) {
  const years = Math.floor(seconds / SECONDS_PER.YEAR)
  seconds -= years * SECONDS_PER.YEAR

  const monthsRaw = seconds / (SECONDS_PER.WEEK * APPROX_WEEKS_PER_MONTH)
  const months = monthsRaw % 1 >= 0.99 ? Math.round(monthsRaw) : Math.floor(monthsRaw)
  seconds -= Math.round(months * SECONDS_PER.WEEK * APPROX_WEEKS_PER_MONTH)

  const weeks = Math.floor(seconds / SECONDS_PER.WEEK)
  const days =
    Math.floor(seconds / SECONDS_PER.DAY) -
    (weeks + Math.floor(months * APPROX_WEEKS_PER_MONTH)) * 7
  const hours = Math.floor((seconds % SECONDS_PER.DAY) / 3600)
  const min = Math.floor((seconds % 3600) / 60)
  const sec = Math.floor(seconds % 60)

  const yearsDisplay = years > 0 ? years + 'y ' : ''
  const monthDisplay = months > 0 ? months + 'mo ' : ''
  const wDisplay = weeks > 0 ? weeks + 'w ' : ''
  const dDisplay = days > 0 ? days + 'd ' : ''
  const hDisplay = hours > 0 ? hours + 'hr ' : ''
  const mDisplay = displayMinutes ? (min > 0 ? min + 'min ' : '') : ''
  const sDisplay = displaySeconds ? (sec > 0 ? sec + 'sec' : '') : ''

  return yearsDisplay + monthDisplay + wDisplay + dDisplay + hDisplay + mDisplay + sDisplay
}

export function secondsToDays(seconds: number) {
  const days = seconds / SECONDS_PER.DAY
  return days
}

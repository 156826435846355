import { useQuery } from '@tanstack/react-query'

import { COIN_CAP } from '../constants'
import { CoinCapToken } from '../interfaces'
import tokenList from '../assets/files/coin-cap-tokens.json'
import { updateTokenPriceInfoBySymbol } from '../utils'
import { AsyncData } from '../../AppState'

const SFI_COINCAP_TOKEN: CoinCapToken = {
  id: 'saffron-finance',
  symbol: 'SFI',
  name: 'Spice',
}

export function useCoinCapTokens(): AsyncData<{ [tokenSymbol: string]: CoinCapToken }> {
  const { error, data, isFetching } = useQuery(
    ['coinCapTokenList'],
    async () => {
      let list = []
      try {
        list = await getTokensFromCoinCap()
      } catch (error) {
        console.info('Error getting CoinCap token list. Using backup list', error)
        list = tokenList.data as CoinCapToken[]
      }

      const map: { [tokenSymbol: string]: CoinCapToken } = { SFI: SFI_COINCAP_TOKEN }
      list?.forEach((token) => {
        updateTokenPriceInfoBySymbol(map, token)
      })
      return map
    },
    {
      initialData: () => {
        const map: { [tokenSymbol: string]: CoinCapToken } = { SFI: SFI_COINCAP_TOKEN }
        tokenList.data.forEach((token) => {
          updateTokenPriceInfoBySymbol(map, token)
        })
        return map
      },
    }
  )

  return { data, isFetching, error }
}

export async function getTokensFromCoinCap(): Promise<CoinCapToken[]> {
  const controller = new AbortController()
  const timeout = setTimeout(() => {
    controller.abort()
  }, 3000)
  const url = COIN_CAP.API.HOST + COIN_CAP.API.COIN_LIST_ENDPOINT
  let result: { data: CoinCapToken[] }
  try {
    const response = await fetch(url, { signal: controller.signal })
    result = await response.json()
  } catch (error: any) {
    console.info(error)
    throw new Error('Error while retrieving CoinCap token list', error)
  }
  clearInterval(timeout)
  return result.data
}

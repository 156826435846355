import styled from 'styled-components'

import { StylingProps } from '../../styles'
import { NumberInput } from './NumberInput'

interface Props extends StylingProps {
  value?: number
  onChange: (value?: number) => void
  label?: string
  placeholder?: string
  precision?: number
}

export function NumberInputWithLabel({
  value,
  onChange,
  placeholder,
  label,
  precision,
  className,
}: Props) {
  return (
    <Container className={className}>
      <NumberInput
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        precision={precision}
      />
      <Label>{label}</Label>
    </Container>
  )
}

const Container = styled.div`
  flex: 1;
  display: flex;
  input {
    margin-right: -14px;
  }
`

const Label = styled.div`
  position: relative;
  top: 13px;
  left: -12px;
  font-size: 15px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.text.secondary};
`

import styled, { css } from 'styled-components'

import { StylingProps } from '../../styles'

interface Props extends StylingProps {
  option1: string
  option2: string
  selected: string
  onChange: (option: string) => void
}

export function TextToggle({ onChange, option1, option2, selected, className }: Props) {
  return (
    <ToggleContainer className={className}>
      <Button type='button' onClick={() => onChange(option1)} selected={selected === option1}>
        {option1}
      </Button>

      <Button type='button' onClick={() => onChange(option2)} selected={selected === option2}>
        {option2}
      </Button>
    </ToggleContainer>
  )
}

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 20px;

  border: 2px solid ${(props) => props.theme.colors.backgrounds.secondary};
  width: fit-content;
  border-radius: 10px;
  overflow: hidden;
`

const Button = styled.button<{ selected: boolean }>`
  border: none;
  outline: none;
  cursor: pointer;
  text-align: center;
  background: transparent;

  padding: 8px;
  color: ${(props) => props.theme.colors.text.secondary};

  font-weight: 600;
  ${(props) =>
    props.selected &&
    css`
      color: ${(props) => props.theme.colors.text.primary};
      background-color: ${(props) => props.theme.colors.backgrounds.secondary};
    `}
`

import { CHAIN_ID } from '@packages/web3-react-plus'

export function getUniswapInfoUrl(chainId: CHAIN_ID, poolAddress: string) {
  let chainName = ''
  switch (chainId) {
    case CHAIN_ID.Arbitrum:
    case CHAIN_ID.ArbitrumGoerli:
      chainName = 'arbitrum/'
      break
    case CHAIN_ID.Polygon:
      chainName = 'polygon/'
      break
    default:
      break
  }
  return 'https://info.uniswap.org/#/' + chainName + 'pools/' + poolAddress.toLowerCase()
}

// Examples
// https://info.uniswap.org/#/pools/0x88e6a0c2ddd26feeb64f039a2c41296fcb3f5640
// https://info.uniswap.org/#/arbitrum/pools/0xc6962004f452be9203591991d15f6b388e09e8d0
// https://info.uniswap.org/#/polygon/pools/0x45dda9cb7c25131df268515131f647d726f50608

import { useEffect, useState } from 'react'
import styled, { useTheme } from 'styled-components'

import { ReactComponent as SearchIcon } from '@packages/ui/src/shared/assets/images/search-icon.svg'
import { useWeb3ReactPlus } from '@packages/web3-react-plus'
import {
  LargeSelect,
  MultiSelect,
  TextInput,
  mediaQuery,
  Option,
  SharedAppState,
} from '@packages/ui'

import { AppStateActionName, useAppStateReducer } from 'src/AppState'

import { Vault, VaultInfo } from '../interfaces'
import { uniV3SortByComparators, uniV3SortingOptions } from '../utils'
import { VAULT_STATUS } from '../constants'

const ALL_STATUSES: Option[] = [
  VAULT_STATUS.ENDED,
  VAULT_STATUS.NOT_STARTED,
  VAULT_STATUS.STARTED,
].map((status) => {
  return { value: status, id: status }
})

interface Props {
  vaultInfos: SharedAppState.AsyncData<{ [id: number]: VaultInfo }>
}

export function SearchAndFilterVaults({ vaultInfos }: Props) {
  const [appState, dispatch] = useAppStateReducer()
  const { chainId } = useWeb3ReactPlus()
  const theme = useTheme()
  const iconFillColor = theme.colors.icons

  const [selectedStatuses, setSelectedStatuses] = useState<Option[]>(ALL_STATUSES)
  const [sortOptions, setSortOptions] = useState(uniV3SortingOptions)
  const [sortByComparators, setSortByComparators] = useState(uniV3SortByComparators)
  const [searchQuery, setSearchQuery] = useState('')
  const [sortBy, setSortBy] = useState<string>(sortOptions.DEFAULT)
  const [sortByDisplay, setSortByDisplay] = useState<string>(sortOptions.DEFAULT)
  const [initialSort, setInitialSort] = useState(false)

  const vaultCount = appState.vaults?.[chainId]?.vault
    ? (
        Object.values(appState.vaults[chainId].vault).map(
          (vaultAsyncData) => vaultAsyncData.data
        ) as Vault[]
      ).filter((vault) => vault !== undefined).length
    : 0

  function handleSearchOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchQuery(e.target.value)
  }

  function handleSortByChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setSortBy(e.target.value)
    setSortByDisplay(e.target[e.target.selectedIndex].textContent ?? '')
    e.target.blur()
  }

  useEffect(() => {
    // reset all filters if chainId is changed
    setSortOptions(uniV3SortingOptions)
    setSortByComparators(uniV3SortByComparators)
    setSearchQuery('')
    setSortBy(sortOptions.DEFAULT)
    setSortByDisplay(sortOptions.DEFAULT)
    setInitialSort(false)
  }, [chainId])

  // initial sort once vaults have loaded
  useEffect(() => {
    if (!initialSort) {
      const isFetchingVaults = appState.vaults?.[chainId]?.vault
        ? Object.values(appState.vaults?.[chainId]?.vault)
            .map((vault) => vault?.isFetching)
            .filter((vault) => vault !== undefined)
            .reduce(
              (accumulator, currentValue) =>
                typeof currentValue === 'undefined' || (currentValue && accumulator)
            )
        : true
      if (appState.vaults?.[chainId]?.vault && !isFetchingVaults) {
        const filteredVaults = (
          Object.values(appState.vaults[chainId].vault).map(
            (vaultAsyncData) => vaultAsyncData.data
          ) as Vault[]
        ).filter((vault) => vault !== undefined)

        if (filteredVaults.length === 0) {
          return
        }

        const sortedByCreatedDate = filteredVaults.sort(
          sortByComparators[uniV3SortingOptions.DATE_CREATED_ASC]
        )
        const vaults = sortedByCreatedDate.sort(sortByComparators.Default)

        dispatch({
          type: AppStateActionName.UpdateDisplayedVaults,
          payload: {
            chainId,
            data: vaults,
          },
        })

        setInitialSort(true)
      }
    }
  }, [chainId, vaultCount, vaultInfos.data, vaultInfos.isFetching, initialSort])

  useEffect(() => {
    // only filter once all pools are loaded
    if (appState.vaults?.[chainId]?.vault && initialSort) {
      const filteredVaults: Vault[] = Object.values(appState.vaults[chainId].vault)
        .map((vaultAsyncData) => vaultAsyncData.data)
        .filter((vault) => vault !== undefined)
        .filter(
          (vault) => selectedStatuses.findIndex((status) => status.value === vault?.status) !== -1
        ) as Vault[]

      if (searchQuery === '') {
        const vaults = filteredVaults.sort(
          sortByComparators[sortBy] ? sortByComparators[sortBy] : sortByComparators.Default
        )

        dispatch({
          type: AppStateActionName.UpdateDisplayedVaults,
          payload: {
            chainId,
            data: vaults,
          },
        })

        return
      }

      const vaults = filteredVaults
        .sort(sortByComparators[sortBy] ? sortByComparators[sortBy] : sortByComparators.Default)
        .filter(({ searchText, adapterAddress }) => {
          const adapterSearchText =
            appState.vaults[chainId]?.adapter[adapterAddress]?.data?.searchText ?? ''

          const mapped = searchQuery
            .split(' ')
            .map(
              (term) =>
                searchText.indexOf(term.toLowerCase()) !== -1 ||
                adapterSearchText.indexOf(term.toLocaleLowerCase()) !== -1
            )
          return mapped.some((isMatch) => isMatch)
        })

      dispatch({
        type: AppStateActionName.UpdateDisplayedVaults,
        payload: {
          chainId,
          data: vaults,
        },
      })
    }
  }, [chainId, searchQuery, sortBy, sortOptions, sortByComparators, selectedStatuses])

  const isSorted = sortBy !== 'Default'

  function onStatusRemove(selectedList: Option[]) {
    setSelectedStatuses(selectedList)
  }

  function onStatusAdd(selectedList: Option[]) {
    setSelectedStatuses(selectedList)
  }

  return (
    <Container>
      <SearchAndFilterContainer>
        <MultiSelectWrapper
          options={ALL_STATUSES}
          selectedOptions={selectedStatuses}
          onRemove={onStatusRemove}
          onSelect={onStatusAdd}
          placeholder='Status'
        />
        <SearchContainer>
          <Search
            onChange={handleSearchOnChange}
            type='search'
            placeholder='Search Pair or Token'
          />
          <SearchIconWrapper fill={iconFillColor} />
        </SearchContainer>
        <LargeSelect
          width={170}
          handleOnChange={handleSortByChange}
          isDefault={!isSorted}
          options={Object.values(sortOptions).map((option) => option)}
          selectedOption={sortByDisplay}
          displayText='Sort by:&nbsp;'
        />
      </SearchAndFilterContainer>
    </Container>
  )
}

const Container = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const SearchAndFilterContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`

const SearchContainer = styled.div`
  flex: 1;
  justify-content: flex-end;
  display: flex;
`

const Search = styled(TextInput)`
  background-color: ${(props) => props.theme.colors.backgrounds.secondary};
`

const SearchIconWrapper = styled(SearchIcon)`
  position: relative;
  top: 16px;
  left: -28px;
`

const MultiSelectWrapper = styled(MultiSelect)`
  padding: 0px 15px;
  ${mediaQuery('small')} {
    display: none;
  }
`

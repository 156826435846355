import { SMALL_WIDTH, MEDIUM_WIDTH } from '../styles'

const MEDIA_TYPES = {
  small: SMALL_WIDTH,
  medium: MEDIUM_WIDTH,
}

type MediaTypeStrings = keyof typeof MEDIA_TYPES

export function mediaQuery(type: MediaTypeStrings) {
  return `@media (max-width: ${MEDIA_TYPES[type]}px)`
}

export function isMobile() {
  return screen.width <= SMALL_WIDTH
}

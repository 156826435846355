import { useEffect, useState } from 'react'
import { Web3Provider } from '@ethersproject/providers'

import { useWeb3ReactPlus } from '../Web3ReactPlusProvider'
import { ERC20Contract } from '../assets/contracts/ERC20Contract'

interface ERC20 {
  address: string
  decimals: number
  symbol: string
  name: string
  chainId: number
}

export function useErc20(tokenAddress?: string) {
  const { provider, chainId } = useWeb3ReactPlus()
  const [erc20, setERC20] = useState<ERC20>()

  useEffect(() => {
    getERC20({ web3Provider: provider, setERC20, tokenAddress, chainId })
  }, [tokenAddress])

  return erc20
}

export async function getERC20({
  web3Provider,
  tokenAddress,
  setERC20,
  chainId,
}: {
  web3Provider?: Web3Provider
  tokenAddress?: string
  setERC20: (erc20: ERC20) => void
  chainId: number
}) {
  if (!web3Provider || !tokenAddress) return
  const requests = []

  const tokenContract = new ERC20Contract(tokenAddress, web3Provider)

  let decimals = 0
  requests.push((async () => (decimals = await tokenContract.decimals()))())
  let symbol = ''
  requests.push((async () => (symbol = (await tokenContract.symbol()).toUpperCase()))())
  let name = ''
  requests.push((async () => (name = await tokenContract.name()))())

  await Promise.all(requests)

  const token: ERC20 = {
    address: tokenAddress,
    decimals,
    symbol,
    name,
    chainId,
  }

  setERC20(token)

  return token
}

import { useEffect } from 'react'
import hash from 'object-hash'
import { Contract } from 'ethers'

import { CallValueState, useChainStateReducer } from '../ChainStateProvider'
import { useWeb3ReactPlus } from '../Web3ReactPlusProvider'
import { useBlockNumber } from './useBlockNumber'
import { getFunctionSignature } from '../utils/getFunctionSignature'

export interface UseContractCallValueOptions {
  contractAddress: string
  functionName: string
  artifact: any
  args?: any[]
  autoUpdate?: boolean
  updateEveryNBlocks?: number
}

export type UseContractCallValueReturnValue<T = any> = [
  value: T | undefined,
  isUpdating: boolean,
  update: () => void,
  error: Error | undefined,
  mostRecentError: Error | undefined
]

export function useContractCallValue<T = any>({
  contractAddress,
  artifact,
  functionName,
  args = [],
  autoUpdate = true,
  updateEveryNBlocks = 1,
}: UseContractCallValueOptions): UseContractCallValueReturnValue<T> {
  const [chainState, dispatch] = useChainStateReducer()
  const { provider, chainId, ready } = useWeb3ReactPlus()
  const blockNumber = useBlockNumber()

  const functionSignature = getFunctionSignature(artifact, functionName)
  const argsHash = hash(args)

  const update = ({ force } = { force: false }) => {
    const currentCallValueState: CallValueState<T> =
      chainState?.[chainId ?? 0]?.addresses?.[contractAddress]?.callValues?.[functionSignature]?.[
        argsHash
      ] || ({} as CallValueState<T>)

    if (currentCallValueState.isUpdating) {
      return // Another component is currently handling this update
    }

    const lastUpdatedBlock = currentCallValueState.lastUpdatedBlock || 0
    const shouldUpdate = blockNumber - lastUpdatedBlock >= updateEveryNBlocks
    if (!(shouldUpdate || force)) {
      return // We've already updated within the last N blocks, and we're not forcing through anyway
    }

    dispatch({
      type: 'CallValueUpdating',
      payload: {
        chainId,
        address: contractAddress,
        functionSignature,
        argsHash,
      },
    })
    const contract = new Contract(contractAddress, artifact.abi, provider)
    return contract[functionSignature](...args)
      .then((value: T) => {
        dispatch({
          type: 'CallValueUpdated',
          payload: {
            chainId,
            address: contractAddress,
            functionSignature,
            argsHash,
            value,
            blockNumber,
          },
        })
      })
      .catch((e: Error) => {
        console.error(e)
        dispatch({
          type: 'CallValueUpdateFailed',
          payload: {
            chainId,
            address: contractAddress,
            functionSignature,
            argsHash,
            error: e,
            blockNumber,
          },
        })
      })
  }

  useEffect(() => {
    if (!ready || !provider || !contractAddress || contractAddress === '' || !blockNumber) return
    if (autoUpdate) {
      update()
    }
  }, [ready, provider, contractAddress, autoUpdate, chainId, blockNumber])

  const { value, isUpdating, error, mostRecentError }: CallValueState<T> =
    chainState?.[chainId ?? 0]?.addresses?.[contractAddress]?.callValues?.[functionSignature]?.[
      argsHash
    ] || ({} as CallValueState)

  return [value, !!isUpdating, update, error, mostRecentError]
}

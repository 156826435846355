import { range } from '../../utils'
import { SmallSelect } from './SmallSelect'

interface Props {
  value?: number
  onChange: (value: number) => void
}

const YEARS = range(5).map((num) => num.toString())

export function YearDurationPicker({ value, onChange }: Props) {
  return <DurationPicker displayText='Years' options={YEARS} value={value} onChange={onChange} />
}

const MONTHS = range(13).map((num) => num.toString())

export function MonthDurationPicker({ value, onChange }: Props) {
  return <DurationPicker displayText='Months' options={MONTHS} value={value} onChange={onChange} />
}

const WEEKS = range(52).map((num) => num.toString())

export function WeekDurationPicker({ value, onChange }: Props) {
  return <DurationPicker displayText='Weeks' options={WEEKS} value={value} onChange={onChange} />
}

const DAYS = range(31).map((num) => num.toString())

export function DayDurationPicker({ value, onChange }: Props) {
  return <DurationPicker displayText='Days' options={DAYS} value={value} onChange={onChange} />
}

const HOURS = range(25).map((num) => num.toString())

export function HourDurationPicker({ value, onChange }: Props) {
  return <DurationPicker displayText='Hours' options={HOURS} value={value} onChange={onChange} />
}

interface DurationPickerProps {
  value?: number
  onChange: (value: number) => void
  options: string[]
  displayText: string
}

export function DurationPicker({ value, onChange, options, displayText }: DurationPickerProps) {
  function handleOnChange(e: React.ChangeEvent<HTMLSelectElement>) {
    onChange(parseInt(e.target.value))
  }

  return (
    <SmallSelect
      displayText={displayText}
      handleOnChange={handleOnChange}
      options={options}
      selectedOption={value ? `${value}` : ''}
    />
  )
}

import styled, { useTheme } from 'styled-components'

import { CSS_TRANSITION, SAFFRON_RED_HEX, StylingProps } from '../styles'
import { formatDataTestId } from '../utils'

const MAX_DECIMALS = 0

interface Props extends StylingProps {
  percent: number
  title?: string
  amount?: string
}

export function ProgressBar({ percent, title, amount, className }: Props) {
  const theme = useTheme()
  const color = theme.colors.semantic.neutral

  const validatedPercent = percent > 100 ? 100 : percent
  const percentString = validatedPercent.toFixed(MAX_DECIMALS)

  return (
    <Container className={className} data-test-id={'progress-bar' + formatDataTestId(title ?? '')}>
      <Header>
        {title && <Title data-test-id='title'>{title}</Title>}
        {amount && <Amount data-test-id='amount'>{amount}</Amount>}
      </Header>
      <Progress data-test-id='progress-percent'>
        {percent > 0 ? (
          <NonZeroProgressBarContainer
            percent={validatedPercent < 5 ? 5 : validatedPercent}
            color={color}
          >
            {percentString === '0' ? '<1' : percentString}%
          </NonZeroProgressBarContainer>
        ) : (
          <ProgressBarContainer>0%</ProgressBarContainer>
        )}
      </Progress>
    </Container>
  )
}

const Container = styled.div`
  ${CSS_TRANSITION}
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => props.theme.colors.text.primary};
`

const Title = styled.div``

const Amount = styled.div``

const Progress = styled.div`
  ${CSS_TRANSITION}
  background-color: ${(props) => props.theme.colors.backgrounds.primary};
  border-radius: 5px;
`

const ProgressBarContainer = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.text.primary};
  text-align: center;
  height: 20px;
`

const NonZeroProgressBarContainer = styled(ProgressBarContainer)<{
  percent: number
  color: string
}>`
  border-radius: 5px;
  width: ${(props) => props.percent}%;
  background: linear-gradient(to right, ${SAFFRON_RED_HEX}, #c48c36);
  color: white;
`

import { useState } from 'react'
import { BigNumber } from 'ethers'

import { SharedAppState, Tokens, Uniswap, UPDATES_PER_BLOCK } from '@packages/ui'
import { useUpdatePerBlock, useWeb3ReactPlus } from '@packages/web3-react-plus'
import { BIG_NUMBER_ZERO } from '@packages/bn'

import { formatSymbol, getUsdValueFromLiquidity } from '../utils'

export function useLiquidityInUsd({
  fixedSideCapacity,
  poolAddress,
  token0,
  token1,
  minTick,
  maxTick,
}: {
  fixedSideCapacity?: BigNumber
  poolAddress?: string
  token0?: Tokens.Token
  token1?: Tokens.Token
  minTick?: number
  maxTick?: number
}): BigNumber {
  const [usdValue, setUsdValue] = useState(BIG_NUMBER_ZERO)
  const { pool } = Uniswap.usePool(poolAddress ?? '')
  const { provider, chainId } = useWeb3ReactPlus()
  const [
    {
      [chainId]: {
        tokens: { bySymbol },
      },
    },
  ] = SharedAppState.useAppStateReducer()

  const _token0Symbol = formatSymbol(token0?.symbol)
  const _token1Symbol = formatSymbol(token1?.symbol)

  const { data: token0Price } = Tokens.useTokenPrice({
    ...token0,
    symbol: _token0Symbol ?? '',
    name: token0?.name ?? '',
    chainId,
    address: token0?.address ?? '',
    decimals: token0?.decimals ?? 18,
  })
  const { data: token1Price } = Tokens.useTokenPrice({
    ...token1,
    symbol: _token1Symbol ?? '',
    name: token1?.name ?? '',
    chainId,
    address: token1?.address ?? '',
    decimals: token1?.decimals ?? 18,
  })

  useUpdatePerBlock(
    () =>
      getUsdValueFromLiquidity({
        pool,
        liquidity: fixedSideCapacity,
        minTick: minTick,
        maxTick: maxTick,
        web3Provider: provider,
        setUsdValue,
        token0Price: token0Price,
        token1Price: token1Price,
      }),
    [provider, fixedSideCapacity, poolAddress, minTick, maxTick, token0Price, token1Price],
    {
      updateEveryNBlocks: UPDATES_PER_BLOCK.NOT_CRITICAL,
    }
  )

  return usdValue
}

import styled from 'styled-components'

import { CSS_TRANSITION, hexToRgbString } from '../../styles'
import { LoadingShimmerStyles } from '../Loading'

export const Button = styled.button<{
  disabled?: boolean
  loading?: boolean
}>`
  ${CSS_TRANSITION}

  color: ${(props) => props.theme.colors.text.button};
  background-color: ${(props) => props.theme.colors.backgrounds.button};
  cursor: pointer;
  pointer-events: auto;
  &:hover ::before {
    opacity: 1;
  }
  &:active {
    transform: scale(0.97);
  }
  &:hover {
    opacity: 0.8;
  }

  width: 162px;
  height: 47px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 600;

  ${(props) =>
    props.disabled &&
    ` 
    color: ${hexToRgbString(props.theme.colors.text.disabled, 0.75)};
    background-color: ${props.theme.colors.backgrounds.primary};
    cursor: not-allowed;
    pointer-events: none;
    `}

  ${(props) =>
    props.loading &&
    `
      color: ${hexToRgbString(props.theme.colors.text.disabled, 0.75)};
      ${LoadingShimmerStyles}
    `}
`

import { ChainName, CHAIN_ID } from '@packages/web3-react-plus'

export function getChainName(chainId = -1): ChainName {
  return (
    (Object.keys(CHAIN_ID).find((key) => {
      const chainName = key as ChainName
      return CHAIN_ID[chainName] === chainId
    }) as ChainName) ?? 'unknown'
  )
}

import { DefaultTheme } from 'styled-components'

import { sharedThemeConstants } from './shared'

export const lightTheme: DefaultTheme = {
  type: 'light',
  colors: {
    backgrounds: {
      primary: '#ebeff4',
      secondary: '#FFFFFF',
      tertiary: '#E5E5E5',
      accent: '#E6F3FA',
      button: sharedThemeConstants.colors.backgrounds.button,
    },
    type: sharedThemeConstants.colors.type,
    semantic: {
      ...sharedThemeConstants.colors.semantic,
    },
    borders: {
      accent: '#34BFF3',
    },
    text: {
      primary: '#0F1621',
      secondary: '#545463',
      button: sharedThemeConstants.colors.text.button,
      disabled: '#2e3034',
    },
    icons: '#545463',
    activated: '#3b8fed',
  },
}

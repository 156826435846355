import styled, { css } from 'styled-components'

import { StylingProps } from '../styles'
import { mediaQuery } from '../utils'

interface Props extends StylingProps {
  title: string
  subtitle?: React.ReactNode
  buttonContent?: React.ReactNode
  rightContent?: React.ReactNode
}

export function Header({ title, subtitle, rightContent, buttonContent, className }: Props) {
  return (
    <Container className={className}>
      <TitleContainer>
        <LeftTitle>
          <Title>
            <StyledText>{title}</StyledText>
          </Title>
          {subtitle && (
            <Row>
              <SubTitle>{subtitle}</SubTitle>
            </Row>
          )}
        </LeftTitle>
        <RightTitle>
          {buttonContent && <ButtonContentMobile>{buttonContent}</ButtonContentMobile>}
        </RightTitle>
      </TitleContainer>
      <ContentContainer>
        {buttonContent && <ButtonContent>{buttonContent}</ButtonContent>}
        {rightContent && <RightContent>{rightContent}</RightContent>}
      </ContentContainer>
    </Container>
  )
}

const Container = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  gap: 15px;
  display: flex;
  padding: 5px 0;
  flex-wrap: wrap;
  ${mediaQuery('small')} {
    gap: 0px;
  }
`

const TitleContainer = styled.div`
  ${mediaQuery('small')} {
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
  }
`

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const LeftTitle = styled.div``

const RightTitle = styled.div`
  align-self: end;
  ${mediaQuery('small')} {
    align-self: start;
  }
`

const Row = styled.div``

const StyledText = styled.h1`
  color: ${(props) => props.theme.colors.text.primary};
  font-weight: 500;
  font-size: 24px;
`

const SubTitle = styled.div<{ hide?: boolean }>`
  color: ${(props) => props.theme.colors.text.secondary};
  font-size: 13px;
  font-weight: 400;
  margin-top: -15px;
  display: flex;
  align-items: center;
  ${(props) =>
    props.hide &&
    css`
      display: none;
    `}
`

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const ButtonContent = styled.div`
  ${mediaQuery('small')} {
    display: none;
  }
`

const ButtonContentMobile = styled.div`
  display: none;
  ${mediaQuery('small')} {
    display: block;
  }
`

const RightContent = styled.div`
  float: right;
  display: flex;
  align-self: flex-end;
  ${mediaQuery('small')} {
    flex: 1;
  }
`

import { ContractTransaction } from 'ethers'
import styled from 'styled-components'

import { A, ErrorMessage, mediaQuery, StylingProps } from '../../shared'
import { useBlockExplorerTxUrl } from '../hooks'

interface Props extends StylingProps {
  tx?: ContractTransaction
  error: Error
}

export function BlockExplorerError({ tx, error, className }: Props) {
  console.error(error)
  const { url, name } = useBlockExplorerTxUrl({ tx })

  if (!tx || !tx.hash) {
    if (error?.message) {
      if (error.message.includes('user rejected transaction')) {
        return (
          <ErrorMessageWrapper className={className} data-test-id='block-explorer-error'>
            Error: User Rejected Transaction
          </ErrorMessageWrapper>
        )
      }

      const reasonRegex = 'reason="(.*?)"'
      const reasonMatch = error.message.match(reasonRegex)
      if (reasonMatch) {
        return (
          <ErrorMessageWrapper className={className} data-test-id='block-explorer-error'>
            Error: {reasonMatch[1]}
          </ErrorMessageWrapper>
        )
      }

      const messageRegex = '\\"message\\":\\"(.*?)"'
      const messageMatch = error.message.match(messageRegex)
      if (messageMatch) {
        return (
          <ErrorMessageWrapper className={className} data-test-id='block-explorer-error'>
            Error: {messageMatch[1]}
          </ErrorMessageWrapper>
        )
      }

      return (
        <ErrorMessageWrapper className={className} data-test-id='block-explorer-error'>
          {error.message}
        </ErrorMessageWrapper>
      )
    }
    return <></>
  }

  return (
    <Container className={className} data-test-id='block-explorer-error'>
      <ErrorMessage>Error occurred.</ErrorMessage>
      <A href={url} target='_blank' rel='noreferrer'>
        See details on {name}
      </A>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  ${mediaQuery('medium')} {
    flex-direction: column;
    gap: 5px;
  }
`

const ErrorMessageWrapper = styled(ErrorMessage)`
  max-width: 400px;
  overflow: hidden;
`

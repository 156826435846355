import { Web3Provider } from '@ethersproject/providers'
import { BigNumber, Contract, ethers } from 'ethers'

import { getMinAmountsWithSlippage } from './liquidity'
import { getMinMaxTicks } from './ticks'

const abiCoder = new ethers.utils.AbiCoder()

export async function getDeployCapitalData({
  vaultContract,
  blockTimestamp,
  slippagePercent,
  expireMs,
  pool,
}: {
  provider: Web3Provider
  vaultContract: Contract
  blockTimestamp: number
  slippagePercent: number
  expireMs: number
  pool: {
    tickSpacing: number
    tickLower?: number
    tickUpper?: number
    sqrtRatioX96: number
  }
}) {
  // deadline - epoch ms
  const deadline = blockTimestamp + expireMs

  const liquidity = await vaultContract.fixedSideCapacity()

  let tickLower: number, tickUpper: number
  if (!pool.tickLower || !pool.tickUpper) {
    const ticks = getMinMaxTicks(pool.tickSpacing)
    tickLower = ticks.minTick
    tickUpper = ticks.maxTick
  } else {
    tickLower = pool.tickLower
    tickUpper = pool.tickUpper
  }

  const { amount0Min, amount1Min } = getMinAmountsWithSlippage({
    slippageTolerance: slippagePercent,
    tickLower,
    tickUpper,
    sqrtRatioX96: pool.sqrtRatioX96,
    liquidity,
  })

  const deployCapitalData = encodeDeployCapitalData({ amount0Min, amount1Min, deadline })

  return deployCapitalData
}

function encodeDeployCapitalData({
  deadline,
  amount0Min,
  amount1Min,
}: {
  deadline: number
  amount0Min: BigNumber
  amount1Min: BigNumber
}) {
  return abiCoder.encode(['uint256', 'uint256', 'uint256'], [amount0Min, amount1Min, deadline])
}

export function encodeRemoveLiquidityParams({
  amount0Min,
  amount1Min,
  deadline,
}: {
  amount0Min: BigNumber
  amount1Min: BigNumber
  deadline: number
}) {
  return abiCoder.encode(['uint256', 'uint256', 'uint256'], [amount0Min, amount1Min, deadline])
}

export function encodeUniV3InitData(lowerTick: number, upperTick: number) {
  return abiCoder.encode(['int24', 'int24'], [lowerTick, upperTick])
}

import styled from 'styled-components'

import { usePlatformIcon, Platform, TokenSymbol, TokenIcon } from '../../tokens'
import { StylingProps } from '../../shared'

interface Props extends StylingProps {
  tokenSymbol0?: TokenSymbol | string
  tokenSymbol1?: TokenSymbol | string
  platform: Platform
  size: number
}

export function PoolIcon({ tokenSymbol0, tokenSymbol1, platform, size, className }: Props) {
  const platformSvg = usePlatformIcon(platform)

  return (
    <Container className={className}>
      <PlatformIcon width={size} height={size} src={platformSvg} />
      {tokenSymbol0 ? (
        <TopIcon
          overlap
          size={size}
          width={size / 2}
          height={size / 2}
          tokenSymbol0={tokenSymbol0}
        />
      ) : null}
      {tokenSymbol1 ? (
        <BottomIcon
          overlap
          size={size}
          width={size / 2}
          height={size / 2}
          tokenSymbol0={tokenSymbol1}
        />
      ) : null}
    </Container>
  )
}

const Container = styled.span`
  position: relative;
`

const PlatformIcon = styled.img`
  position: relative;
  top: 0;
  left: 0;
`

const Icon = styled(TokenIcon)<{ height: number; width: number }>`
  img {
    height: ${(props) => props.height}px;
    width: ${(props) => props.width}px;
  }
`

const TopIcon = styled(Icon)<{ size: number; overlap?: boolean }>`
  position: absolute;
  margin-left: ${(props) => (props.size ? `-${props.size * 0.34}px` : '0')};
`

const BottomIcon = styled(Icon)<{ size: number; overlap?: boolean }>`
  position: absolute;
  margin-left: ${(props) => (props.size ? `-${props.size * 0.34}px` : '0')};
  margin-top: ${(props) => (props.size ? `${props.size * 0.58}px` : '0')};
`

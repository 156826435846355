import styled from 'styled-components'

import { Card } from '@packages/ui'

export function DefaultCreateVaultForm() {
  return (
    <VaultFormCard>
      <Message>Please Choose a Vault Type</Message>
    </VaultFormCard>
  )
}

export const VaultFormCard = styled(Card)`
  min-height: 400px;
  padding: 19px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 25px;
`

const Message = styled.div`
  display: flex;
  align-self: center;
  font-weight: 500;
  font-size: 17px;
  color: ${(props) => props.theme.colors.text.primary};
`

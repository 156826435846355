import styled, { css } from 'styled-components'

import { ReactComponent as Arrow } from '@packages/ui/src/shared/assets/images/arrow.svg'
import {
  RouterLink,
  Card,
  TitleInfoBlock,
  Pools,
  InfoBlock,
  mediaQuery,
  LoadingShimmerStyles,
  formatUsd,
} from '@packages/ui'

import { useIsVaultParticipant, useUniV3Vault } from '../hooks'
import { VaultInfo } from '../interfaces'
import { formatFixedCapacity, getUniV3VaultTitle, getVaultDetailsUrl, formatApr } from '../utils'
import {
  VariablePnLTooltip,
  VariableCapacityValue,
  FixedAprTooltip,
  FixedCapacityTooltip,
  VaultStatusBlock,
  VaultTimeInfoBlock,
} from './VaultCardInfo'

interface Props {
  vaultInfo: VaultInfo
}

export function UniswapVaultCard({ vaultInfo }: Props) {
  const { vault, adapter, rates, usdLiquidityValue } = useUniV3Vault(vaultInfo)
  const isVaultParticipant = useIsVaultParticipant(vault)

  if (!vault || !adapter) return <Container opacityOnHover loading></Container>

  const { pool } = adapter

  return (
    <Container opacityOnHover loading={false}>
      <RouterLink to={getVaultDetailsUrl(vault)}>
        <CardTop>
          <VaultStatusBlockWrapper vault={vault} />
          <TitleBlock
            icon={
              <Pools.PoolIcon
                tokenSymbol0={pool.token0.symbol}
                tokenSymbol1={pool.token1.symbol}
                size={35}
                platform='Uniswap v3'
              />
            }
            header='Uniswap v3'
            subheader={getUniV3VaultTitle(adapter)}
          />
          <InfoBlockVariableAprWrapper
            header='Variable PnL'
            value={
              rates.variable.earningsRate === '-'
                ? '-'
                : `${formatUsd(rates.variable.usdEarnings)} (${formatApr(
                    rates.variable.earningsRate
                  )})`
            }
            infoTooltip={<VariablePnLTooltip vault={vault} adapter={adapter} />}
            gradient={isVaultParticipant.variable}
          />
          <InfoBlockFixedAprWrapper
            header='Fixed APR'
            value={formatApr(rates.fixed.apr)}
            infoTooltip={<FixedAprTooltip vault={vault} adapter={adapter} />}
            gradient={isVaultParticipant.fixed}
          />
          <InfoBlockVariableCapacityWrapper
            header='Variable Capacity'
            value={<VariableCapacityValueWrapper vault={vault} />}
            hideOnMediumWidth
          />
          <InfoBlockFixedCapacityWrapper
            header='Fixed Capacity'
            infoTooltip={<FixedCapacityTooltip vault={vault} />}
            value={formatFixedCapacity(usdLiquidityValue)}
            hideOnMediumWidth
          />
          <VaultTimeInfoBlockWrapper vault={vault} hideOnMediumWidth />
          <DetailsArrow>
            <ArrowIcon />
          </DetailsArrow>
        </CardTop>
      </RouterLink>
    </Container>
  )
}

const Container = styled(Card)<{ loading: boolean }>`
  display: flex;
  flex-direction: row;
  max-width: 1200px;
  height: 70px;

  ${(props) =>
    props.loading &&
    css`
      ${LoadingShimmerStyles}
    `}
`

const CardTop = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 15px;
  width: 100%;
`

const TitleBlock = styled(TitleInfoBlock)`
  width: 21%;
  ${mediaQuery('medium')} {
    width: 35%;
  }
  ${mediaQuery('small')} {
    width: 50%;
  }
`

const InfoBlockVariableCapacityWrapper = styled(InfoBlock)`
  width: 15%;
`

const InfoBlockFixedCapacityWrapper = styled(InfoBlock)`
  width: 15%;
`

const InfoBlockVariableAprWrapper = styled(InfoBlock)`
  width: 18%;
  ${mediaQuery('medium')} {
    width: 30%;
  }
`

const InfoBlockFixedAprWrapper = styled(InfoBlock)`
  width: 10%;
  ${mediaQuery('medium')} {
    width: 20%;
  }
`

const VaultStatusBlockWrapper = styled(VaultStatusBlock)`
  padding-right: 20px;
`

const VariableCapacityValueWrapper = styled(VariableCapacityValue)`
  height: 21px;

  > div {
    margin-top: -2px;
  }
`

const VaultTimeInfoBlockWrapper = styled(VaultTimeInfoBlock)`
  width: 15%;
`

// Icons

const ArrowIcon = styled(Arrow)`
  path {
    stroke: ${(props) => props.theme.colors.icons};
  }
`

const DetailsArrow = styled.div`
  width: 38px;
`

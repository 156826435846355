import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { BigNumber } from 'ethers'

import { StylingProps } from '@packages/ui'
import { bnToFloat } from '@packages/bn'

import { TextInput } from '.'
import { validateBigNumber } from './validate'

interface Props extends StylingProps {
  precision?: number
  value?: BigNumber
  onChange: (bn?: BigNumber) => void
  placeholder?: string
  disabled?: boolean
}

export function BigNumberInput({
  precision,
  value,
  onChange,
  placeholder = '0.0',
  disabled = false,
  className,
}: Props) {
  const [inputAmount, setInputAmount] = useState('')
  const [isFocused, setIsFocused] = useState(false)

  useEffect(() => {
    // user is typing
    if (isFocused) return

    if (value) {
      if (precision) {
        setInputAmount(`${bnToFloat(value, precision)}`)
      }
    } else {
      setInputAmount('')
    }
  }, [value])

  useEffect(() => {
    if (precision) {
      updateValues(inputAmount)
    }
  }, [precision])

  function onInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target
    updateValues(value)
  }

  function onBlur() {
    setIsFocused(false)
  }

  function updateValues(value: string) {
    validateBigNumber({
      value,
      setLocalValue: setInputAmount,
      setParentValue: (value) => onChange(value ?? undefined),
      precision,
    })
  }

  return (
    <AmountInput
      className={className}
      value={inputAmount}
      onChange={onInputChange}
      onBlur={onBlur}
      onFocus={() => setIsFocused(true)}
      placeholder={placeholder}
      data-test-id='big-number-input'
      disabled={disabled}
    />
  )
}

const AmountInput = styled(TextInput)`
  background-color: ${(props) => props.theme.colors.backgrounds.tertiary};
  max-width: 200px;
`

import { Web3Provider } from '@ethersproject/providers'
import { BigNumber } from 'ethers'

import { Vault__factory } from '@packages/contract-types'
import { encodeRemoveLiquidityParams } from '@packages/uniswap'

import { Vault } from '../interfaces'
import { UNI_V3_TRX_DEFAULTS, VAULT_SIDE } from '../constants'

export async function withdrawUniV3Fixed({
  web3Provider,
  vault,
  amount0,
  amount1,
  slippagePercent,
  blockTimestamp,
  expireMs,
}: {
  vault: Vault
  web3Provider: Web3Provider
  amount0: BigNumber
  amount1: BigNumber
  slippagePercent: number
  blockTimestamp: number
  expireMs: number
}) {
  const vaultContract = Vault__factory.connect(vault.address, web3Provider.getSigner())

  const amount0Min = amount0.sub(
    amount0.mul(slippagePercent).div(UNI_V3_TRX_DEFAULTS.ALL.PERCENT_DENOMINATOR)
  )
  const amount1Min = amount1.sub(
    amount1.mul(slippagePercent).div(UNI_V3_TRX_DEFAULTS.ALL.PERCENT_DENOMINATOR)
  )

  return await vaultContract.withdraw(
    VAULT_SIDE.FIXED,
    encodeRemoveLiquidityParams({
      amount0Min,
      amount1Min,
      deadline: blockTimestamp + expireMs,
    })
  )
}

export async function withdrawUniV3Variable({
  web3Provider,
  vault,
}: {
  vault: Vault
  web3Provider: Web3Provider
}) {
  const vaultContract = Vault__factory.connect(vault.address, web3Provider.getSigner())

  return await vaultContract.withdraw(VAULT_SIDE.VARIABLE, [])
}

import { Web3Provider } from '@ethersproject/providers'
import { BigNumber } from 'ethers'

import { Vault__factory } from '@packages/contract-types'
import { SharedAppState, Tokens, UPDATES_PER_BLOCK } from '@packages/ui'
import {
  useUpdatePerBlock,
  useWeb3ReactPlus,
  useErc20Balance,
  UseContractCallValueReturnValue,
  useErc20TotalSupply,
} from '@packages/web3-react-plus'
import { BIG_NUMBER_ZERO } from '@packages/bn'

import { AppStateActionName, AppStateDispatch, useAppStateReducer } from 'src/AppState'

import { VAULT_STATUS } from '../constants'
import { VaultInfo, Vault } from '../interfaces'
import { getVaultSearchText, isNonZeroAmount } from '../utils'

export function useVault(vaultInfo?: VaultInfo) {
  const { provider, chainId } = useWeb3ReactPlus()
  const [appState, dispatch] = useAppStateReducer()

  const vaults = appState.vaults[chainId] ?? undefined
  const vault =
    vaultInfo && vaults?.vault
      ? (vaults?.vault[vaultInfo.id] as SharedAppState.AsyncData<Vault>)
      : undefined

  useUpdatePerBlock(
    () =>
      getVault({ chainId, dispatch, vaultInfo, web3Provider: provider, currentVault: vault?.data }),
    [chainId, dispatch, provider, vaultInfo?.address],
    {
      updateEveryNBlocks: UPDATES_PER_BLOCK.SEMI_CRITICAL,
    }
  )

  return vault
}

export async function getVault({
  web3Provider,
  chainId,
  dispatch,
  currentVault,
  vaultInfo,
}: {
  web3Provider?: Web3Provider
  chainId: number
  dispatch: AppStateDispatch
  currentVault?: Vault
  vaultInfo?: VaultInfo
}) {
  if (!web3Provider || !vaultInfo) return

  dispatch({
    type: AppStateActionName.UpdateVault,
    payload: { isFetching: true, chainId, id: vaultInfo.id },
  })

  try {
    const vaultContract = Vault__factory.connect(vaultInfo.address, web3Provider)

    let vault: Vault | undefined

    if (currentVault) {
      vault = currentVault
      const [
        endTimeBn,
        isStarted,
        earningsSettled,
        fixedSideCapacity,
        variableSideCapacity,
        blockNumber,
      ] = await Promise.all([
        vaultContract.endTime(),

        vaultContract.isStarted(),
        vaultContract.earningsSettled(),

        vaultContract.fixedSideCapacity(),
        vaultContract.variableSideCapacity(),

        web3Provider.getBlockNumber(),
      ])

      const blockTimestamp = (await web3Provider.getBlock(blockNumber)).timestamp

      const endTime = endTimeBn.toNumber()
      const status = getVaultStatus(isStarted, endTime, blockTimestamp)

      const hideVault = await getHideVault(
        web3Provider,
        vault.variableAsset.address,
        vault.fixedBearerTokenAddress,
        status
      )

      vault.hideVault = hideVault
      vault.status = status
      vault.isStarted = isStarted
      vault.earningsSettled = earningsSettled
      vault.fixedSideCapacity = fixedSideCapacity
      vault.variableSideCapacity = variableSideCapacity
    } else {
      const [
        durationBn,
        endTimeBn,
        isStarted,
        earningsSettled,
        variableAssetAddress,
        fixedSideCapacity,
        variableSideCapacity,
        variableBearerTokenAddress,
        fixedBearerTokenAddress,
        claimTokenAddress,
        feeBps,
        blockNumber,
      ] = await Promise.all([
        vaultContract.duration(),
        vaultContract.endTime(),
        vaultContract.isStarted(),
        vaultContract.earningsSettled(),
        vaultContract.variableAsset(),
        vaultContract.fixedSideCapacity(),
        vaultContract.variableSideCapacity(),
        vaultContract.variableBearerToken(),
        vaultContract.fixedBearerToken(),
        vaultContract.claimToken(),
        vaultContract.feeBps(),
        web3Provider.getBlockNumber(),
      ])

      const blockTimestamp = (await web3Provider.getBlock(blockNumber)).timestamp
      const duration = durationBn.toNumber()
      const endTime = endTimeBn.toNumber()
      const status = getVaultStatus(isStarted, endTime, blockTimestamp)

      const variableAsset = await Tokens.getToken({
        web3Provider,
        tokenAddress: variableAssetAddress,
      })

      const hideVault = await getHideVault(
        web3Provider,
        variableAssetAddress,
        fixedBearerTokenAddress,
        status
      )

      vault = {
        ...vaultInfo,
        chainId,
        duration,
        endTime,
        isStarted,
        hideVault,
        status,
        earningsSettled,
        variableAsset,
        variableSideCapacity,
        fixedSideCapacity,
        variableBearerTokenAddress,
        fixedBearerTokenAddress,
        claimTokenAddress,
        feeBps: feeBps.toNumber(),
        searchText: '',
      }

      vault.searchText = getVaultSearchText(vault)
    }

    dispatch({
      type: AppStateActionName.UpdateVault,
      payload: { data: vault, chainId, id: vaultInfo.id },
    })
  } catch (error: any) {
    console.error(error)
    dispatch({
      type: AppStateActionName.UpdateVault,
      payload: { error, chainId, id: vaultInfo.id },
    })
  }
}

async function getHideVault(
  web3Provider: Web3Provider,
  variableBearerTokenAddress: string,
  fixedBearerTokenAddress: string,
  status: VAULT_STATUS
) {
  let hideVault = false

  if (status === VAULT_STATUS.ENDED) {
    const variableBearerTokenTotalSupply = await Tokens.getTotalSupply({
      web3Provider,
      tokenAddress: variableBearerTokenAddress,
    })
    const fixedBearerTokenTotalSupply = await Tokens.getTotalSupply({
      web3Provider,
      tokenAddress: fixedBearerTokenAddress,
    })
    if (
      variableBearerTokenTotalSupply.eq(BIG_NUMBER_ZERO) &&
      fixedBearerTokenTotalSupply.eq(BIG_NUMBER_ZERO)
    ) {
      hideVault = true
    }
  }

  return hideVault
}

function getVaultStatus(isStarted: boolean, endTime: number, blockTimestamp: number): VAULT_STATUS {
  if (!isStarted) {
    return VAULT_STATUS.NOT_STARTED
  } else if (endTime > blockTimestamp) {
    return VAULT_STATUS.STARTED
  } else {
    return VAULT_STATUS.ENDED
  }
}

const CONTRACT_CALL_RETURN_VALUE_DEFAULT: UseContractCallValueReturnValue<BigNumber> = [
  BIG_NUMBER_ZERO,
  false,
  () => {},
  undefined,
  undefined,
]

export function useVariableBearerTokenBalance(vault: Vault) {
  const { activeAddress, provider } = useWeb3ReactPlus()
  const contractCallReturnValue = useErc20Balance({
    tokenAddress: vault.variableBearerTokenAddress,
    balanceAddress: activeAddress ?? '',
  })

  if (!provider) return CONTRACT_CALL_RETURN_VALUE_DEFAULT

  return contractCallReturnValue
}

export function useVariableBearerTokenTotalSupply(vault: Vault) {
  const { provider } = useWeb3ReactPlus()
  const contractCallReturnValue = useErc20TotalSupply({
    tokenAddress: vault.variableBearerTokenAddress ?? '',
    updateEveryNBlocks: UPDATES_PER_BLOCK.NOT_CRITICAL,
  })

  if (!provider) return CONTRACT_CALL_RETURN_VALUE_DEFAULT

  return contractCallReturnValue
}

export function useFixedBearerTokenTotalSupply(vault?: Vault) {
  const { provider } = useWeb3ReactPlus()
  const contractCallReturnValue = useErc20TotalSupply({
    tokenAddress: vault?.fixedBearerTokenAddress ?? '',
  })

  if (!provider) return CONTRACT_CALL_RETURN_VALUE_DEFAULT

  return contractCallReturnValue
}

export function useClaimTokenTotalSupply(vault?: Vault) {
  const { provider } = useWeb3ReactPlus()
  const contractCallReturnValue = useErc20TotalSupply({
    tokenAddress: vault?.claimTokenAddress ?? '',
  })

  if (!provider) return CONTRACT_CALL_RETURN_VALUE_DEFAULT

  return contractCallReturnValue
}

export function useFixedBearerTokenBalance(vault: Vault) {
  const { activeAddress, provider } = useWeb3ReactPlus()
  const contractCallReturnValue = useErc20Balance({
    tokenAddress: vault.fixedBearerTokenAddress,
    balanceAddress: activeAddress ?? '',
  })

  if (!provider) return CONTRACT_CALL_RETURN_VALUE_DEFAULT

  return contractCallReturnValue
}

export function useClaimTokenBalance(vault: Vault) {
  const { activeAddress, provider } = useWeb3ReactPlus()
  const contractCallReturnValue = useErc20Balance({
    tokenAddress: vault.claimTokenAddress,
    balanceAddress: activeAddress ?? '',
  })

  if (!provider) return CONTRACT_CALL_RETURN_VALUE_DEFAULT

  return contractCallReturnValue
}

export function useIsVaultParticipant(vault?: Vault) {
  const { activeAddress, provider } = useWeb3ReactPlus()

  const [fixedBearerTokenBalance] = useErc20Balance({
    tokenAddress: vault?.fixedBearerTokenAddress ?? '',
    balanceAddress: activeAddress ?? '',
    updateEveryNBlocks: UPDATES_PER_BLOCK.SEMI_CRITICAL,
  })
  const [variableBearerTokenBalance] = useErc20Balance({
    tokenAddress: vault?.variableBearerTokenAddress ?? '',
    balanceAddress: activeAddress ?? '',
    updateEveryNBlocks: UPDATES_PER_BLOCK.SEMI_CRITICAL,
  })
  const [claimTokenBalance] = useErc20Balance({
    tokenAddress: vault?.claimTokenAddress ?? '',
    balanceAddress: activeAddress ?? '',
    updateEveryNBlocks: UPDATES_PER_BLOCK.SEMI_CRITICAL,
  })

  if (!provider || !vault) return { fixed: false, variable: false }

  return {
    fixed: isNonZeroAmount(fixedBearerTokenBalance) || isNonZeroAmount(claimTokenBalance),
    variable: isNonZeroAmount(variableBearerTokenBalance),
  }
}

import { ChainInfo } from '@packages/web3-react-plus'

import { LPPlatformTokenSymbol, Platform, PlatformUrls, TokenSymbol } from '../constants'
import { Token } from '../interfaces'

export function getPlatform(lpSymbol: LPPlatformTokenSymbol): Platform {
  switch (lpSymbol) {
    case 'MOJITO-LP':
      return 'Mojitoswap'
    case 'SFI':
      return 'SFI Staking'
    case 'SLP':
      return 'Sushiswap'
    case 'UNI-V2':
      return 'Uniswap v2'
    default:
      return 'Uniswap v3'
  }
}

export function getPlatformUrlText(platform: Platform) {
  return PlatformUrls[platform]
}

export function getPlatformLiquidityUrl(
  platform: Platform,
  chainInfo: ChainInfo,
  underlyingToken0?: Token,
  underlyingToken1?: Token
) {
  const baseUrl = `https://${PlatformUrls[platform]}`
  switch (platform) {
    case 'Uniswap v3':
      return `${baseUrl}/#/add/${replaceWethWithEth(
        chainInfo,
        underlyingToken0
      )}/${replaceWethWithEth(chainInfo, underlyingToken1)}`
    case 'Uniswap v2':
      return `${baseUrl}/#/add/v2/${underlyingToken0?.address}/${underlyingToken1?.address}`
    case 'Sushiswap':
      return `${baseUrl}/add/${underlyingToken0?.address}/${underlyingToken1?.address}`
    case 'Mojitoswap':
      return `${baseUrl}/add/${underlyingToken0?.address}/${underlyingToken1?.address}`
    default:
      return '#'
  }
}

function replaceWethWithEth(chainInfo: ChainInfo, token?: Token) {
  return chainInfo.wrappedNativeToken.symbol === token?.symbol ? 'ETH' : token?.address
}

export function getPlatformSwapUrl({
  platform,
  tokensBySymbol,
  outputTokenSymbol,
  inputTokenSymbol,
}: {
  platform: Platform
  tokensBySymbol?: { [tokenSymbol: string]: Token }
  outputTokenSymbol: TokenSymbol
  inputTokenSymbol?: TokenSymbol
}) {
  let inputAddress
  if (inputTokenSymbol) {
    inputAddress = tokensBySymbol?.[inputTokenSymbol]?.address
  }
  const outputAddress = tokensBySymbol?.[outputTokenSymbol]?.address

  let params = '?'
  if (inputAddress) {
    params += `inputCurrency=${inputAddress}`
  }
  if (outputAddress) {
    params += `outputCurrency=${outputAddress}`
  }

  const baseUrl = `https://${PlatformUrls[platform]}`
  switch (platform) {
    case 'Uniswap v3':
    case 'Uniswap v2':
      return `${baseUrl}/#/swap${params}`
    case 'Sushiswap':
      return `${baseUrl}/swap${params}`
    case 'Mojitoswap':
      return `${baseUrl}/swap${params}`
    default:
      return '#'
  }
}

import styled, { useTheme } from 'styled-components'

import {
  CHAIN_ID,
  CHAIN_INFO,
  SFI_TOKEN,
  useBalance,
  useErc20Balance,
  useWeb3ReactPlus,
} from '@packages/web3-react-plus'

import { ReactComponent as OffIcon } from '../../shared/assets/images/off.svg'
import { ReactComponent as LinkIcon } from '../../shared/assets/images/open-link.svg'
import { CopyText, A, Tooltip, NavBarItem, Modal, ModalCloseIcon, ModalProps } from '../../shared'
import { PayWithCoinbaseButton } from '../../coinbase-pay'
import { addErc20Token, PlatformBuyButton, TokenBalance, SFI_TOKEN_ADDRESS } from '../../tokens'
import { Address } from './Address'
import { useBlockExplorerBaseUrl } from '../../chains'
import { UPDATES_PER_BLOCK } from '../../config'

export function AccountModal({ isOpen, onRequestClose }: ModalProps) {
  const { chainId, provider, library, activeAddress, deactivate } = useWeb3ReactPlus()
  const [nativeCurrencyBalance] = useBalance({ address: activeAddress ?? '', autoUpdate: true })
  const [sfiBalance] = useErc20Balance({
    tokenAddress: SFI_TOKEN_ADDRESS[chainId as CHAIN_ID]?.address ?? '',
    balanceAddress: activeAddress ?? '',
    updateEveryNBlocks: UPDATES_PER_BLOCK.SEMI_CRITICAL,
  })
  const { url } = useBlockExplorerBaseUrl()
  const theme = useTheme()
  const iconFillColor = theme.colors.text.secondary

  if (!activeAddress) return <></>

  function tokenOnClick() {
    addErc20Token({
      provider: library?.provider,
      token: {
        address: SFI_TOKEN_ADDRESS[chainId as CHAIN_ID]?.address ?? '',
        symbol: 'SFI',
        decimals: 18,
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7617.png',
      },
    })
  }

  function disconnect() {
    deactivate()
    onRequestClose()
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Title>
        <Address address={activeAddress} provider={provider} />
        <Icons>
          <Tooltip content={<span>Copy</span>} position='left'>
            <NavBarItemWrapper>
              <CopyText text={activeAddress} />
            </NavBarItemWrapper>
          </Tooltip>
          <Tooltip content={<span>Explore</span>} position='left'>
            <A href={url + 'address/' + activeAddress} target='_blank' rel='noreferrer'>
              <NavBarItemWrapper>
                <LinkIcon fill={iconFillColor} width={19} height={15} />
              </NavBarItemWrapper>
            </A>
          </Tooltip>
          <Tooltip content={<span>Disconnect</span>} position='left'>
            <NavBarItemWrapper onClick={disconnect}>
              <OffIconContainer>
                <OffIcon fill={iconFillColor} width={19} height={16} />
              </OffIconContainer>
            </NavBarItemWrapper>
          </Tooltip>
        </Icons>
      </Title>
      <ModalCloseIcon onRequestClose={onRequestClose} />
      <BalanceItems>
        <TokenBalance
          token={SFI_TOKEN}
          balance={sfiBalance}
          tokenOnClick={tokenOnClick}
          buyComponent={<PlatformBuyButton outputTokenSymbol='SFI' />}
        />
        {CHAIN_INFO[chainId as CHAIN_ID]?.nativeCurrency.symbol && (
          <TokenBalance
            token={{ ...CHAIN_INFO[chainId as CHAIN_ID]?.nativeCurrency, address: '' }}
            balance={nativeCurrencyBalance}
            buyComponent={
              <PayWithCoinbaseButton onClick={onRequestClose} buttonStyle='small' buttonSize={75} />
            }
          />
        )}
      </BalanceItems>
    </Modal>
  )
}

const Title = styled.div`
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 26px;
  display: flex;
  align-items: center;
`

const NavBarItemWrapper = styled(NavBarItem)`
  background-color: ${(props) => props.theme.colors.backgrounds.primary};
`

const Icons = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  padding-left: 10px;
`

const OffIconContainer = styled.div`
  margin-top: 3px;
  cursor: pointer;
`

const BalanceItems = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  gap: 11px;
`

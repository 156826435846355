import { CHAIN_ID, HARDHAT_CHAIN_ID, TEST_ENVIRONMENT } from '@packages/web3-react-plus'

export const SFI_TOKEN_ADDRESS: {
  [chainId in CHAIN_ID]: { address: string; decimals: number }
} = {
  [CHAIN_ID.Ethereum]: { address: '0xb753428af26e81097e7fd17f40c88aaa3e04902c', decimals: 18 },
  [CHAIN_ID.Ropsten]: { address: '', decimals: 18 },
  [CHAIN_ID.Rinkeby]: { address: '', decimals: 18 },
  [CHAIN_ID.Goerli]: { address: '', decimals: 18 },
  [CHAIN_ID.Kovan]: { address: '', decimals: 18 },
  [CHAIN_ID.Polygon]: { address: '0x35b937583F04A24963eb685F728a542240f28Dd8', decimals: 18 },
  [CHAIN_ID.KCC]: { address: '0xd55d9df77b23a7455613f2244ee4b6a45b6e2d00', decimals: 18 },
  [CHAIN_ID.Arbitrum]: { address: '', decimals: 18 },
  [CHAIN_ID.ArbitrumGoerli]: { address: '', decimals: 18 },
  ...(TEST_ENVIRONMENT && {
    [HARDHAT_CHAIN_ID]: { address: '0xb753428af26e81097e7fd17f40c88aaa3e04902c', decimals: 18 },
  }),
}

import { ChainName } from '@packages/web3-react-plus'

import { ReactComponent as EthIcon } from '../assets/images/eth.svg'
import { ReactComponent as PolygonIcon } from '../assets/images/polygon.svg'
import { ReactComponent as ArbitrumIcon } from '../assets/images/arbitrum.svg'
import { ReactComponent as KCCIcon } from '../assets/images/kcc.svg'
import { getChainName } from './getChainName'

const icons: Record<ChainName, SVG | undefined> = {
  Ethereum: EthIcon,
  Ropsten: undefined,
  Rinkeby: undefined,
  Goerli: undefined,
  Kovan: undefined,
  Polygon: PolygonIcon,
  KCC: KCCIcon,
  Arbitrum: ArbitrumIcon,
}

export function getChainIcon(chainId: number): SVG | undefined {
  return icons[getChainName(chainId)]
}

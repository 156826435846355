import styled from 'styled-components'

import { Button, mediaQuery, StylingProps } from '../../shared'
import { Token } from '../interfaces'
import { TokenIcon } from './TokenIcon'

interface Props extends StylingProps {
  token?: Token
  onClick: () => void
}

export function SelectTokenButton({ token, onClick, className }: Props) {
  return (
    <TokenButton onClick={onClick} className={className} data-test-id='select-token-button'>
      {token ? (
        <TokenIconContainer data-test-id='token-value-container'>
          <TokenIconWrapper size={24} tokenSymbol0={token.symbol} /> {token.symbol}
        </TokenIconContainer>
      ) : (
        <ButtonText>Select a Token</ButtonText>
      )}
    </TokenButton>
  )
}

const TokenButton = styled(Button)`
  padding: 10px 15px;
  display: flex;
  flex: 1;
  max-width: 180px;
  align-items: center;
  background: ${(props) => props.theme.colors.backgrounds.tertiary}
    url("data:image/svg+xml;utf8,<svg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M9 1L5 5L1 1' stroke='${(
      props
    ) => props.theme.colors.text.secondary.replace('#', '%23')}' stroke-width='2'/></svg>")
    no-repeat;
  background-position: right 15px top 50%;
  color: ${(props) => props.theme.colors.text.primary};
`

const TokenIconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const TokenIconWrapper = styled(TokenIcon)``

const ButtonText = styled.span`
  ${mediaQuery('medium')} {
    font-size: 12px;
    width: auto;
  }
`

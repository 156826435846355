import styled from 'styled-components'
import { useAutoAnimate } from '@formkit/auto-animate/react'

import { Loading, SharedAppState, mediaQuery } from '@packages/ui'

import { useVaults } from '../hooks'
import { UniswapVaultCard } from './UniswapVaultCard'
import { VaultInfo } from '../interfaces'

interface Props {
  vaultInfos: SharedAppState.AsyncData<{ [id: number]: VaultInfo }>
}

export function Vaults({ vaultInfos }: Props) {
  const vaults = useVaults()
  const [parent] = useAutoAnimate()

  let vaultCards = null
  if (vaultInfos) {
    if (vaultInfos?.data) {
      vaultCards = Object.values(vaultInfos?.data)
        .reverse()
        .filter(
          (vault) => !(vaults?.[vault.id]?.data?.hideVault ?? false) || !vaults?.[vault.id]?.data
        )
        .map((vaultInfo) => <UniswapVaultCard vaultInfo={vaultInfo} key={vaultInfo.id} />)
    } else if (vaultInfos.isFetching) {
      vaultCards = <Loading />
    }
  }

  return (
    <Container ref={parent} data-test-id='vaults'>
      {vaultCards}
    </Container>
  )
}

const Container = styled.div<{ ref: any }>`
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  gap: 25px;

  ${mediaQuery('small')} {
    padding: 20px 0;
    gap: 15px;
  }
`

import { CHAIN_ID, HARDHAT_CHAIN_ID } from '@packages/web3-react-plus'

const GRAPH_URLS: { [chainId: number]: string } = {
  [CHAIN_ID.Ethereum]: 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3',
  [CHAIN_ID.Goerli]: 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3',
  [HARDHAT_CHAIN_ID]: 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3',
  [CHAIN_ID.Polygon]: 'https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-v3-polygon',
  [CHAIN_ID.Arbitrum]: 'https://api.thegraph.com/subgraphs/name/ianlapham/arbitrum-minimal',
  [CHAIN_ID.ArbitrumGoerli]: 'https://api.thegraph.com/subgraphs/name/ianlapham/arbitrum-minimal',
}

export async function queryUniswapGraph(chainId: number, query: string): Promise<any> {
  const response = await fetch(GRAPH_URLS[chainId], {
    method: 'post',
    body: JSON.stringify({ query }),
  })

  return (await response.json())?.data
}

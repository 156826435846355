import { SAFFRON_RED_HEX } from '../constants'

export const sharedThemeConstants = {
  colors: {
    backgrounds: {
      button: SAFFRON_RED_HEX,
    },
    type: {
      red: '#C44536',
      blue: '#2775CA',
    },
    semantic: {
      neutral: '#2551A9',
      valid: '#0CB04E',
      invalid: '#C82E2E',
      warning: '#fcba03',
      link: '#34BFF3',
    },
    text: {
      button: '#FFFFFF',
    },
  },
}

import { Web3Provider } from '@ethersproject/providers'

import { IUniswapV3Pool__factory } from '@packages/contract-types'

export async function getPoolImmutables(poolAddress: string, provider: Web3Provider) {
  const poolContract = IUniswapV3Pool__factory.connect(poolAddress, provider)

  const [factory, token0, token1, fee, tickSpacing] = await Promise.all([
    poolContract.factory(),
    poolContract.token0(),
    poolContract.token1(),
    poolContract.fee(),
    poolContract.tickSpacing(),
  ])

  const poolImmutables = {
    factory,
    token0,
    token1,
    fee,
    tickSpacing,
  }

  return poolImmutables
}

export async function getPoolState(poolAddress: string, provider: Web3Provider) {
  const poolContract = IUniswapV3Pool__factory.connect(poolAddress, provider)

  const [liquidity, slot] = await Promise.all([poolContract.liquidity(), poolContract.slot0()])

  const poolState = {
    liquidity,
    sqrtPriceX96: slot.sqrtPriceX96,
    tick: slot.tick,
  }

  return poolState
}

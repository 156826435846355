import styled from 'styled-components'

import { StylingProps } from '../../shared'
import { TokenSymbol } from '../constants'
import { TokenIcon } from './TokenIcon'

interface Props extends StylingProps {
  tokenSymbol0?: TokenSymbol
  tokenSymbol1?: TokenSymbol
  metric: string
  value: string
}

export function MetricLabel({ tokenSymbol0, tokenSymbol1, metric, value, className }: Props) {
  return (
    <Container className={className}>
      <TokenIconWrapper tokenSymbol0={tokenSymbol0} tokenSymbol1={tokenSymbol1} size={17} />
      <Text>
        {value}&nbsp;
        {metric}
      </Text>
    </Container>
  )
}

const Container = styled.div`
  height: 31px;
  padding: 3px 12px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  display: flex;
`

const TokenIconWrapper = styled(TokenIcon)`
  align-content: center;
  margin-right: 7px;
`

const Text = styled.span``

const fromExponential = require('from-exponential').default
const fp = require('evm-fp').default
const { BigNumber } = require('ethers')
const { formatUnits } = require('ethers/lib/utils')

exports.bn = function (x, precision = 0) {
  if (BigNumber.isBigNumber(x)) return x
  
  let xs
  let isNum = false

  if (typeof x === 'number') {
    xs = x.toString()
    isNum = true
  } else {
    xs = x
  }

  if (xs.includes('.') && isNum) {
    throw new Error('Pass a string of the float in order to not lose precision')
  }

  if (xs[0] === '.') {
    xs = `0${xs}`
  }

  if (xs.includes('e')) {
    xs = fromExponential(x)
  }

  if (precision) {
    return fp(xs, precision)
  }

  return BigNumber.from(xs)
}

exports.bnToFloat = function (bn, decimals) {
  return parseFloat(formatUnits(bn, decimals))
}

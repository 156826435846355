import { UniswapToken } from '../interfaces'
import { queryUniswapGraph } from './queryUniswapGraph'

export async function getToken(
  tokenAddress: string,
  chainId: number
): Promise<UniswapToken | undefined> {
  const res = await queryUniswapGraph(
    chainId,
    `{
        tokens(where:{id:"${tokenAddress.toLowerCase()}"}) {
            id
            name
            decimals
            symbol
          }
      }`
  )

  if (res === undefined || res.tokens.length === 0) {
    return
  }

  return res.tokens[0] as UniswapToken
}

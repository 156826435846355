import styled, { css } from 'styled-components'

import { CSS_TRANSITION } from '../styles'
import { Modal, ModalCloseIcon, ModalProps, ModalTitle } from './Modal'
import { AppendAnimationStyles } from './Animations'
import { A } from './A'

export interface Step {
  title: string
  description?: string | React.ReactNode
  url?: string
}

export interface StepProgressModalProps extends ModalProps {
  title: string
  steps: Step[]
  currentStep?: number
  error?: string | React.ReactNode
  success?: string | React.ReactNode
}

export function StepProgressModal({
  title,
  onRequestClose,
  isOpen,
  steps,
  currentStep,
  error,
  success,
}: StepProgressModalProps) {
  if (!isOpen) return <></>

  // required for animation to work - need new elements per step
  const stepDescriptions: React.ReactNode[] = []
  if (
    typeof currentStep !== 'undefined' &&
    typeof steps[currentStep]?.description !== 'undefined'
  ) {
    steps.forEach((step, index) => {
      if (currentStep > index) {
        stepDescriptions.push(
          <HiddenStepDescription key={`step-description-${index}`}>
            {step.description}
          </HiddenStepDescription>
        )
      } else if (currentStep === index) {
        stepDescriptions.push(
          <StepDescription key={`step-description-${index}`}>{step.description}</StepDescription>
        )
      }
    })
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <ModalTitle>{title}</ModalTitle>
      <ModalCloseIcon onRequestClose={onRequestClose} />
      <StepsContainer>
        <StepsList>
          {steps.map((step, index) => {
            return (
              <StepItem
                data-step={index}
                completed={(currentStep ?? -1) >= index}
                loading={currentStep === index && !success}
                error={!!(currentStep === index && error)}
                totalSteps={steps.length}
                key={step.title}
              >
                {step.url ? (
                  <StepItemLink href={step.url}>{step.title}</StepItemLink>
                ) : (
                  <>{step.title}</>
                )}
              </StepItem>
            )
          })}
        </StepsList>
        {stepDescriptions}
        {error && error}
        {success && success}
      </StepsContainer>
    </Modal>
  )
}

const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  ${AppendAnimationStyles}
`

const StepsList = styled.ul`
  padding-inline-start: 0px;
  display: flex;
  margin: 0;
  align-self: center;
  max-width: 330px;
`

const StepDescription = styled.div`
  font-size: 14px;
  text-align: center;
  background-color: ${(props) => props.theme.colors.backgrounds.primary};
  padding: 20px;
  border-radius: 10px;
  ${AppendAnimationStyles}
`

const HiddenStepDescription = styled(StepDescription)`
  display: none;
`

const handleLineStyling = (totalSteps: number) => {
  switch (totalSteps) {
    case 2:
      return '-46%'
    case 3:
      return '-44%'
    case 4:
    default:
      return '-42%'
  }
}

const StepItemLink = styled(A)`
  max-width: 110px;
`

const StepItem = styled.li<{
  completed: boolean
  loading: boolean
  error: boolean
  totalSteps: number
}>`
  ${CSS_TRANSITION}
  list-style-type: none;
  flex: 1;
  float: left;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  text-align: center;
  color: ${(props) => props.theme.colors.text.secondary};

  &:before {
    ${CSS_TRANSITION}
    width: 15px;
    height: 15px;
    content: '';
    line-height: 30px;
    border: 2px solid ${(props) => props.theme.colors.text.secondary};
    background-color: ${(props) => props.theme.colors.text.secondary};
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    border-radius: 50%;
  }
  &:after {
    ${CSS_TRANSITION}
    width: 100%;
    height: 2px;
    content: '';
    position: absolute;
    background-color: ${(props) => props.theme.colors.text.secondary};
    top: 7px;

    left: ${({ totalSteps }) => handleLineStyling(totalSteps)};
    z-index: -1;
  }
  &:first-child:after {
    content: none;
  }

  ${(props) =>
    props.completed &&
    css`
      &:before {
        border-color: ${(props) => props.theme.colors.semantic.valid};
        background-color: ${(props) => props.theme.colors.semantic.valid};
      }
      &:after {
        background-color: ${(props) => props.theme.colors.semantic.valid};
      }
    `}

  ${(props) => {
    if (props.error) {
      return css`
        &:before {
          border-color: ${(props) => props.theme.colors.semantic.invalid};
          background-color: ${(props) => props.theme.colors.semantic.invalid};
        }
        &:after {
          background-color: ${(props) => props.theme.colors.semantic.invalid};
        }
      `
    } else if (props.loading) {
      return css`
        @keyframes shimmer {
          from {
            opacity: 0.3;
          }
          to {
            opacity: 1;
          }
        }
        animation: shimmer 0.8s linear infinite alternate;

        &:before {
          border-color: ${(props) => props.theme.colors.text.secondary};
          background-color: ${(props) => props.theme.colors.text.secondary};
        }
        &:after {
          background-color: ${(props) => props.theme.colors.text.secondary};
        }
      `
    }
  }}
`

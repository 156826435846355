import {
  LineChart,
  Line,
  ReferenceDot,
  XAxis,
  YAxis,
  Label,
  ResponsiveContainer,
  ReferenceLine,
} from 'recharts'
import styled, { useTheme } from 'styled-components'

import { isMobile } from '../../shared'
import { usePool } from '../hooks'

interface Props {
  poolAddress: string
  minPrice: number
  maxPrice: number
  isSorted: boolean
}

const Y_CONSTANT = 100
const Y_MIN_CONSTANT = 75
const Y_MAX_CONSTANT = 125
const MAX_DISPLAY_DECIMALS = 8
const MAX_SIGNIFICANT_DIGITS = 6
const LINE_CHART_MARGIN = 60

export function PriceRange({ poolAddress, minPrice, maxPrice, isSorted }: Props) {
  const theme = useTheme()
  const { pool } = usePool(poolAddress)

  const currentPrice = isSorted ? Number(pool?.token0Price ?? 0) : Number(pool?.token1Price ?? 0)

  let inRange = true
  let minValue = minPrice
  let maxValue = maxPrice

  if (currentPrice < minPrice) {
    inRange = false
    minValue = currentPrice
  } else if (currentPrice > maxPrice) {
    inRange = false
    maxValue = currentPrice
  }

  const data = [
    { x: minPrice, y: Y_CONSTANT },
    { x: currentPrice, y: Y_CONSTANT },
    { x: maxPrice, y: Y_CONSTANT },
  ].sort((pricesA, pricesB) => pricesA.x - pricesB.x)

  return (
    <Container>
      {pool && currentPrice > 0 && maxPrice > 0 ? (
        <ChartContainer>
          <ResponsiveContainer>
            <LineChart data={data} margin={{ right: LINE_CHART_MARGIN, left: LINE_CHART_MARGIN }}>
              <Line
                type='monotone'
                dataKey='y'
                stroke={inRange ? theme.colors.semantic.neutral : theme.colors.semantic.invalid}
                strokeWidth={isMobile() ? 3 : 2}
                dot
                isAnimationActive
              />

              {/** Min Price */}
              <ReferenceDot
                x={minPrice}
                y={Y_CONSTANT}
                label={
                  <Label fill={theme.colors.text.primary} offset={10} position='top'>
                    Min Price
                  </Label>
                }
                r={1}
              />
              <ReferenceDot
                x={minPrice}
                y={Y_CONSTANT}
                label={
                  <Label fill={theme.colors.text.primary} offset={10} position='bottom'>
                    {minPrice.toLocaleString(undefined, {
                      maximumSignificantDigits: MAX_SIGNIFICANT_DIGITS,
                      maximumFractionDigits: MAX_DISPLAY_DECIMALS,
                    })}
                  </Label>
                }
                r={1}
              />

              {/** Current Price */}
              <ReferenceLine
                stroke={theme.colors.semantic.neutral}
                strokeWidth={isMobile() ? 3 : 2}
                label={
                  <Label fill={theme.colors.text.primary} offset={12} position='top'>
                    Current Price
                  </Label>
                }
                segment={[
                  { x: currentPrice, y: Y_MIN_CONSTANT },
                  { x: currentPrice, y: Y_MAX_CONSTANT },
                ]}
              />
              <ReferenceDot
                x={currentPrice}
                y={Y_CONSTANT}
                label={
                  <Label fill={theme.colors.text.primary} offset={24} position='bottom'>
                    {currentPrice.toLocaleString(undefined, {
                      maximumSignificantDigits: MAX_SIGNIFICANT_DIGITS,
                      maximumFractionDigits: MAX_DISPLAY_DECIMALS,
                    })}
                  </Label>
                }
                r={1}
              />

              {/** Max Price */}
              <ReferenceDot
                x={maxPrice}
                y={Y_CONSTANT}
                label={
                  <Label fill={theme.colors.text.primary} offset={10} position='top'>
                    Max Price
                  </Label>
                }
                r={1}
              />
              <ReferenceDot
                x={maxPrice}
                y={Y_CONSTANT}
                label={
                  <Label fill={theme.colors.text.primary} offset={10} position='bottom'>
                    {maxPrice.toLocaleString(undefined, {
                      maximumSignificantDigits: MAX_SIGNIFICANT_DIGITS,
                      maximumFractionDigits: MAX_DISPLAY_DECIMALS,
                    })}
                  </Label>
                }
                r={1}
              />

              <XAxis dataKey='x' hide domain={[minValue, maxValue]} scale='linear' type='number' />
              <YAxis dataKey='y' hide domain={[0, Y_CONSTANT * 2]} type='number' />
            </LineChart>
          </ResponsiveContainer>
        </ChartContainer>
      ) : (
        <ChartContainer>
          <ResponsiveContainer>
            <LineChart data={data} margin={{ right: LINE_CHART_MARGIN, left: LINE_CHART_MARGIN }}>
              <Line
                type='monotone'
                dataKey='y'
                stroke={theme.colors.semantic.neutral}
                strokeWidth={isMobile() ? 3 : 2}
                dot
                isAnimationActive
              />
              <XAxis dataKey='x' hide domain={[minValue, maxValue]} scale='linear' type='number' />
              <YAxis dataKey='y' hide domain={[0, Y_CONSTANT * 2]} type='number' />
            </LineChart>
          </ResponsiveContainer>
        </ChartContainer>
      )}
    </Container>
  )
}

const Container = styled.div``

const ChartContainer = styled.div`
  width: 100%;
  height: 100px;
`

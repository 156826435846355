import { Web3Provider } from '@ethersproject/providers'

import { CHAIN_ID, isTestnet } from '@packages/web3-react-plus'
import { sqrtPriceX96ToTokenPrices } from '@packages/uniswap'

import { UNISWAP_FEE_TIER, UNISWAP_TICK_SPACING } from '../constants'
import { UniswapToken, UniswapV3Pool } from '../interfaces'
import { getPoolState } from './contractGetters'
import { queryUniswapGraph } from './queryUniswapGraph'
import { sortTokens } from './sortTokens'

const POOL_QUERY_FIELDS = `
  address: id
  tick
  sqrtPrice
  feeTier
  totalValueLockedUSD
  totalValueLockedToken0
  totalValueLockedToken1
  token0Price
  token1Price
  token0 {
    address: id
    decimals
  }
  token1 {
    address: id
    decimals
  }
`

export async function getPool({
  address,
  chainId,
  provider,
}: {
  chainId: number
  address: string
  provider: Web3Provider
}): Promise<UniswapV3Pool> {
  const { pools } = await queryUniswapGraph(
    chainId,
    `{
     pools(where: {
          id: "${address.toLowerCase()}" }) {
        ${POOL_QUERY_FIELDS}
      } 
    }`
  )

  const uniswapPools: UniswapV3Pool[] = await Promise.all(
    pools.map(async (pool: any) => {
      return await formatPool({ pool, chainId, provider })
    })
  )

  return uniswapPools[0]
}

export async function getPoolsFromTokens({
  token0,
  token1,
  chainId,
  provider,
}: {
  token0: UniswapToken
  token1: UniswapToken
  chainId: number
  provider: Web3Provider
}): Promise<UniswapV3Pool[]> {
  const sortedTokens = sortTokens(token0, token1)

  let response = await queryUniswapGraph(
    chainId,
    `{
      pools(orderBy: feeTier, where: {
          token0: "${sortedTokens[0].address.toLowerCase()}",
          token1: "${sortedTokens[1].address.toLowerCase()}"}) {
          ${POOL_QUERY_FIELDS}
      }
    }`
  )

  if (response) {
    const { pools } = response
    let rawPools = pools

    if (chainId === CHAIN_ID.Arbitrum && pools.length === 0) {
      console.debug('trying to get pool with tokens reversed')
      // reverse order of tokens
      response = await queryUniswapGraph(
        chainId,
        `{
          pools(orderBy: feeTier, where: {
              token0: "${sortedTokens[1].address.toLowerCase()}",
              token1: "${sortedTokens[0].address.toLowerCase()}"}) {
              ${POOL_QUERY_FIELDS}
          }
        }`
      )
      if (response) {
        const { pools } = response
        rawPools = pools
      }
    }

    const uniswapPools: UniswapV3Pool[] = await Promise.all(
      rawPools.map(async (pool: any) => {
        return await formatPool({ pool, chainId, provider })
      })
    )
    console.debug('pools', uniswapPools)

    return uniswapPools
  }

  return []
}

async function formatPool({
  pool,
  chainId,
  provider,
}: {
  pool: any
  chainId: number
  provider: Web3Provider
}): Promise<UniswapV3Pool> {
  const feeTier = parseInt(pool.feeTier)
  const formattedPool = {
    ...pool,
    feeTier,
    tick: parseInt(pool.tick),
    tickSpacing: UNISWAP_TICK_SPACING[feeTier as UNISWAP_FEE_TIER],
  }

  if (isTestnet(chainId)) {
    const poolState = await getPoolState(formattedPool.address, provider)
    formattedPool.tick = poolState.tick
    formattedPool.sqrtPrice = poolState.sqrtPriceX96.toString()
    const [token0Price, token1Price] = sqrtPriceX96ToTokenPrices(
      formattedPool.sqrtPrice,
      pool.token0.decimals,
      pool.token1.decimals
    )
    formattedPool.token0Price = token0Price
    formattedPool.token1Price = token1Price
  }

  return formattedPool
}

function swapTokens({ pool, chainId }: { pool: any; chainId: number }) {}

/* eslint-disable @typescript-eslint/quotes */

import styled from 'styled-components'

import { formatApr } from '@packages/ui'

import { VariableRates } from '../interfaces'

export const VAULT_DESCRIPTIONS = {
  adapter:
    'Creating the adapter, which will handle interactions with the underlying yield-generating platforms.',
  vault:
    'Creating the vault, which will handle deposits from users and accounting their gains and losses.',
  initializeVault: 'Configuring the created vault with the created adapter.',
  performanceProtocolFee: (formattedFeeBps: string) =>
    `The performance protocol fee for this vault is: ${formattedFeeBps}. This fee is applied to the Uniswap v3 position earnings after the vault has ended.`,
}

export const TRANSACTION_DESCRIPTIONS = {
  wrappingNativeToken: (nativeTokenSymbol: string, wrappedTokenSymbol: string) =>
    `Wrapping the native token ${nativeTokenSymbol} to ${wrappedTokenSymbol}. As the vaults only support ERC-20s at this time.`,
  tokenApproveAllowance: (tokenSymbol: string) =>
    `Delegating the vault to spend ${tokenSymbol} on behalf of the token owner.`,
}

export const UNISWAP_DESCRIPTIONS = {
  fullRange:
    'This position is providing liquidity across the "full range" of the price curve from zero to infinity.',
  minTickLTMaxTick: 'The min price must be less than the max price',
  noPools:
    'No Pools for this selected token pair has existing liquidity. If you think this is incorrect, please wait for the data to refresh. Otherwise, please select a different pair.',
  transactionSettings: {
    summary:
      'These settings will be used when calling the Uniswap smart contracts to create the liquidity position',
    transactionDeadline:
      'Your transaction will revert if it is pending for more than this period of time',
    slippageTolerance:
      'Your transaction will revert if the price changes unfavorably by more than this percentage',
  },
}

export const UNI_V3_CREATE_VAULT_DESCRIPTIONS = {
  fixedSide: {
    targetApr:
      'This is the target APR for Fixed Side users given the below fixed capacity. These values will be used to calculate the required variable side capacity to meet this APR. ',
    capacity:
      'This number will be used to derive the Uniswap Liquidity value, the actual value of the liquidity may slightly differ.',
  },
  variableSide: {
    estimatedApr: (variableRates: VariableRates) => (
      <>
        The Estimated Variable APRs below are calculated based off of the Uniswap Fees / Uniswap TVL
        averages then annualized based off of the vault duration. <br />
        Uniswap APRs based off of listed averages:
        <br />
        [1 Day Average]:{' '}
        {formatApr(variableRates.estimationsByXDayAverage[1].platformDailyRate * 100 * 365)} <br />
        [30 Day Average]:{' '}
        {formatApr(variableRates.estimationsByXDayAverage[30].platformDailyRate * 100 * 365)} <br />
        [90 Day Average]:{' '}
        {formatApr(variableRates.estimationsByXDayAverage[90].platformDailyRate * 100 * 365)} <br />
      </>
    ),
  },
}

export const UNI_V3_VAULT_DESCRIPTIONS = {
  summary:
    "This vault facilitates a reverse zero coupon swap, which exchanges the Variable Side's deposits for the earnings received from the Uniswap v3 liquidity position created from the Fixed Side's deposits.",

  fixedSide: {
    calculations: {
      apr: {
        formula: 'annualized(fixedRate, vaultDuration)',
      },
      fixedRate: {
        formula: 'variableCapacityUSD / fixedCapacityUSD',
      },
      capacity: {
        summary: (fixedSideCapacity: string) => (
          <>
            This is an estimated value based off of the vault's actual Uniswap v3 liquidity value
            <BoldText> ({fixedSideCapacity})</BoldText> and the pool's token's current USD prices
          </>
        ),
      },
      tokenAmounts: {
        summary: 'This is an approximation based off of the current price of this pool',
      },
    },
    summary:
      "Fixed Side participants receive the configured Variable Side's fixed amount deposits upfront, in return for giving Variable Side participants their Uniswap v3 liquidity position earnings.",
    instructions: [
      "Deposit the appropriate amounts for the Uniswap v3 pool's tokens to meet the configured Fixed Side capacity.",
      'Deposits can be withdrawn if the vault has not started.',
      'Once the Variable Side capacity has been reached, the vault will start and Fixed Side participants can claim the Variable Side deposits.',
      'Deposits are locked for the duration of the vault. Once the vault has ended, Fixed Side participants can withdraw their initial deposit.',
    ],
    transactions: {
      deposit: (token0Symbol: string, token1Symbol: string) =>
        `Depositing ${token0Symbol} and ${token1Symbol} into the Uniswap pool to create a liquidity position`,
    },
  },
  variableSide: {
    calculations: {
      pnl: {
        formula: '(uniswapUSDEarnings * (1 - protocolFeePercent)) / variableCapacityUSD - 1',
      },
    },
    summary: (variableAssetSymbol: string) =>
      `This vault requires Variable Side participants to deposit ${variableAssetSymbol} tokens upfront.`,
    instructions: [
      'Deposits can be withdrawn if the vault has not started.',
      "This side will receive all of the Fixed Side's Uniswap v3 liquidity position's earnings, which can be collected once the vault has ended.",
    ],
    transactions: {
      deposit: (tokenSymbol: string) => `Depositing ${tokenSymbol} into the vault`,
    },
  },
}

const BoldText = styled.span`
  font-weight: 700;
`

// Typescript implementation of Python's range()
export function range(to: number): number[]
export function range(from: number, to: number, step?: number): number[]
export function range(toOrFrom: number, _to = 0, step = 1): number[] {
  let to: number
  let from = 0
  if (!_to) {
    to = toOrFrom
  } else {
    to = _to
    from = toOrFrom
  }
  return Array.from(Array(Math.floor((to - from) / step))).map(
        (v, k) => from + k * step
  )
 }

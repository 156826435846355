import styled, { useTheme } from 'styled-components'
import { useState } from 'react'

import { ReactComponent as CopyIcon } from '../../assets/images/copy.svg'
import { CSS_TRANSITION } from '../../styles'

interface Props {
  text: string
}

export function CopyText({ text }: Props) {
  const [textCopied, setTextCopied] = useState(false)
  const theme = useTheme()

  const onCopyClick = () => {
    navigator.clipboard.writeText(text)
    setTextCopied(true)
    setTimeout(() => {
      setTextCopied(false)
    }, 1600)
  }
  return (
    <CopyContainer onClick={onCopyClick}>
      {textCopied ? 'Copied' : <CopyIconWrapper fill={theme.colors.text.secondary} />}
    </CopyContainer>
  )
}

const CopyIconWrapper = styled(CopyIcon)`
  cursor: pointer;
`

const CopyContainer = styled.div`
  ${CSS_TRANSITION}
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 16px;
  color: ${(props) => props.theme.colors.text.secondary};
`

import React, { createContext, ReactNode, Reducer, useContext, useReducer } from 'react'
import { deepmergeCustom } from 'deepmerge-ts'
import { DefaultTheme } from 'styled-components'

import { isChainsTestnet } from '@packages/web3-react-plus'

import { darkTheme, lightTheme, ThemeType } from './shared'
import { Tokens } from './'

const merge = deepmergeCustom({ mergeArrays: false })

// Types

export interface AsyncData<T> {
  data?: T
  isFetching?: boolean
  error?: Error | any
}

export interface AppState {
  theme: DefaultTheme
  [chainId: number]: {
    tokens: {
      list: AsyncData<Tokens.Token[]>
      bySymbol: AsyncData<{ [tokenSymbol: string]: Tokens.Token }>
    }
  }
}

export enum AppStateActionName {
  UpdateTheme = 'UpdateTheme',

  UpdateTokens = 'UpdateTokens',
}

export interface Action {
  type: string
  payload: unknown
}

interface UpdateThemeAction extends Action {
  type: AppStateActionName.UpdateTheme
  payload: ThemeType
}

// tokens

interface UpdateTokensAction extends Action {
  type: AppStateActionName.UpdateTokens
  payload: AsyncData<Tokens.Token[]> & { chainId: number }
}

export type AppStateAction = UpdateThemeAction | UpdateTokensAction

// Reducer action router
function appStateReducer(state: AppState, action: AppStateAction): AppState {
  switch (action.type) {
    case AppStateActionName.UpdateTheme:
      return updateTheme(state, action.payload)
    case AppStateActionName.UpdateTokens:
      return updateTokens(state, action.payload)
    default:
      throw new Error(`Action type '${action['type']} not supported.`)
  }
}

// Reducers

function updateTheme(state: AppState, payload: UpdateThemeAction['payload']): AppState {
  let theme: DefaultTheme = darkTheme
  switch (payload) {
    case 'light':
      theme = lightTheme
      break
    case 'dark':
      theme = darkTheme
      break
  }

  const newState = merge(state, {
    theme,
  }) as AppState

  return newState
}

// tokens

function updateTokens(state: AppState, payload: UpdateTokensAction['payload']): AppState {
  const { chainId, data, isFetching, error } = payload

  // don't update if already loaded
  if (
    state[chainId] &&
    state[chainId].tokens.list.data &&
    !!state[chainId].tokens.list.data?.length
  )
    return state

  const tokensList =
    data?.filter(
      (token) =>
        token.chainId && (token.chainId === chainId || isChainsTestnet(token.chainId, chainId))
    ) ?? []

  const tokensBySymbol: { [tokenSymbol: string]: Tokens.Token } = {}
  const filteredTokensList: Tokens.Token[] = []
  tokensList.forEach((token) => {
    // if doesnt already exist in array
    if (!tokensBySymbol[token.symbol]) {
      tokensBySymbol[token.symbol] = token
      filteredTokensList.push(token)
    }
  })

  const newState = merge(state, {
    [chainId]: {
      tokens: {
        list: updateAsyncData({
          data: filteredTokensList.length > 0 ? filteredTokensList : undefined,
          isFetching,
          error,
        }),
        bySymbol: updateAsyncData({
          data: Object.keys(tokensBySymbol).length > 0 ? tokensBySymbol : undefined,
          isFetching,
          error,
        }),
      },
    },
  }) as AppState

  return newState
}

export function updateAsyncData<T>({ data, isFetching, error }: AsyncData<T>): AsyncData<T> {
  // only set data if its !undefined else don't return it in the object
  return { ...(data && { data }), isFetching: isFetching ?? false, error }
}

// Context
export const AppStateContext = createContext([{} as AppState, (action: AppStateAction) => {}])

interface AppStateProviderProps {
  children: ReactNode
}

const DefaultAppState: AppState = {
  theme: darkTheme,
}

export function AppStateProvider({ children }: AppStateProviderProps) {
  const [store, dispatch] = useReducer<Reducer<AppState, AppStateAction>>(
    appStateReducer,
    DefaultAppState
  )

  return <AppStateContext.Provider value={[store, dispatch]}>{children}</AppStateContext.Provider>
}

// Hook
export function useAppStateReducer() {
  return useContext(AppStateContext) as [AppState, React.Dispatch<AppStateAction>]
}

export type AppStateDispatch = React.Dispatch<AppStateAction>

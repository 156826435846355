import { UniswapToken } from '../interfaces'
import { queryUniswapGraph } from './queryUniswapGraph'

export async function getTokens(chainId: number, _page = 0): Promise<UniswapToken[]> {
  const skip = _page * 1000
  if (skip >= 5000) return []

  const res = await queryUniswapGraph(
    chainId,
    `{
        tokens(skip: ${_page * 1000}, first: 1000, orderBy: id) {
          address: id
          name
          symbol
          volumeUSD
          decimals
        }
      }`
  )

  if (res === undefined || res.tokens.length === 0) {
    return []
  }

  return res.tokens as UniswapToken[]
}

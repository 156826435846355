import styled from 'styled-components'
import { useEffect, useState } from 'react'

import {
  Button,
  ErrorMessage,
  PercentButtons,
  StylingProps,
  trimDecimalStringToPrecision,
  Uniswap,
} from '@packages/ui'
import { priceToClosestTick, tickToPrice } from '@packages/uniswap'
import { useWeb3ReactPlus } from '@packages/web3-react-plus'

import { VAULT_CONFIG } from 'src/config'
import { UNISWAP_DESCRIPTIONS } from 'src/vaults/constants'

import { PriceInput } from './PriceInput'

const MAX_DECIMALS = 8

const DEFAULT_TOKEN: Uniswap.UniswapToken = {
  address: '',
  decimals: 18,
  symbol: '',
  name: '',
}

const DEFAULT_POOL: Uniswap.UniswapV3Pool = {
  token0Price: '0',
  token1Price: '0',
  address: '',
  feeTier: Uniswap.UNISWAP_FEE_TIER.THREE_THOUSAND,
  tickSpacing: 0,
  totalValueLockedUSD: '',
  totalValueLockedToken0: '',
  totalValueLockedToken1: '',
  tick: 0,
  sqrtPrice: '',
  token0: {
    address: '',
    decimals: 0,
  },
  token1: {
    address: '',
    decimals: 0,
  },
}

function isDefaultToken(token: Uniswap.UniswapToken) {
  return token.symbol === ''
}

function priceToTickAndBack({
  price,
  tickSpacing,
  token0,
  token1,
  isSorted,
}: {
  price: number
  tickSpacing: number
  token0: Uniswap.UniswapToken
  token1: Uniswap.UniswapToken
  isSorted: boolean
}) {
  const tick = priceToClosestTick({
    price,
    tickSpacing,
    token0Decimals: token0.decimals,
    token1Decimals: token1.decimals,
  })

  const _price = tickToPrice({
    tick: tick,
    token0Decimals: token0.decimals,
    token1Decimals: token1.decimals,
  })

  return {
    price: _price,
    tick,
  }
}

interface Props extends StylingProps {
  feeTier?: Uniswap.UNISWAP_FEE_TIER
  pool?: Uniswap.UniswapV3Pool
  token0?: Uniswap.UniswapToken
  token1?: Uniswap.UniswapToken
  onAdapterChange: (typeId: number) => void
  adapterTypeId: number
  minTick?: number
  maxTick?: number
  onMinTickChange: (minTick: number) => void
  onMaxTickChange: (maxTick: number) => void
  priceRangeValid: boolean
  setPriceRangeValid: (valid: boolean) => void
}

export function PriceRangeInputs({
  feeTier = Uniswap.UNISWAP_FEE_TIER.THREE_THOUSAND,
  pool = DEFAULT_POOL,
  token0 = DEFAULT_TOKEN,
  token1 = DEFAULT_TOKEN,
  onAdapterChange,
  adapterTypeId,
  minTick,
  maxTick,
  onMinTickChange,
  onMaxTickChange,
  priceRangeValid,
  setPriceRangeValid,
  className,
}: Props) {
  const { chainId } = useWeb3ReactPlus()
  const [minPrice, setMinPrice] = useState<number>()
  const [maxPrice, setMaxPrice] = useState<number>()
  const [isDirty, setIsDirty] = useState(false)

  const isSorted = Uniswap.tokensIsSorted({ token0, token1, chainId, pool })

  const tickSpacing = Uniswap.UNISWAP_TICK_SPACING[feeTier]

  function setInitialValues() {
    const currentPrice = isSorted ? Number(pool.token0Price) : Number(pool.token1Price)

    if (currentPrice === 0) return

    // reset
    if (isDefaultToken(token0) || isDefaultToken(token1)) {
      setMinPrice(undefined)
      setMaxPrice(undefined)
      return
    }

    const { price: _minPrice, tick: _minTick } = priceToTickAndBack({
      price: currentPrice * 0.5,
      tickSpacing,
      token0,
      token1,
      isSorted,
    })
    const { price: _maxPrice, tick: _maxTick } = priceToTickAndBack({
      price: currentPrice * 2,
      tickSpacing,
      token0,
      token1,
      isSorted,
    })

    setMinPrice(_minPrice)
    setMaxPrice(_maxPrice)
    onMinTickChange(_minTick)
    onMaxTickChange(_maxTick)
  }

  useEffect(() => {
    if (pool && feeTier && token0 && token1 && !isDirty) {
      setInitialValues()
    }
  }, [pool, feeTier, token0, token1, isDirty])

  useEffect(() => {
    if (
      typeof minTick === 'undefined' &&
      typeof maxTick === 'undefined' &&
      pool &&
      feeTier &&
      token0 &&
      token1
    ) {
      setInitialValues()
    }
  }, [pool, feeTier, token0, token1, minTick, maxTick])

  useEffect(() => {
    if (minPrice && maxPrice && minPrice >= maxPrice) {
      setPriceRangeValid(false)
    } else {
      setPriceRangeValid(true)
    }
  }, [minPrice, maxPrice])

  function onFullRangeClick() {
    setPriceRangeValid(true)
    onAdapterChange(VAULT_CONFIG[chainId].adapterTypeIds.fullRange)
  }

  function onLimitedRangeClick() {
    onAdapterChange(VAULT_CONFIG[chainId].adapterTypeIds.limitedRange)
    setInitialValues()
  }

  // mins
  function onMinPriceDecrement() {
    setIsDirty(true)
    if (minTick) {
      const newTick = minTick - tickSpacing
      updateMins(newTick)
    }
  }
  function onMinPriceIncrement() {
    setIsDirty(true)
    if (minTick) {
      const newTick = minTick + tickSpacing
      updateMins(newTick)
    }
  }
  function updateMins(newTick: number) {
    const newPrice = tickToPrice({
      tick: newTick,
      token0Decimals: token0.decimals,
      token1Decimals: token1.decimals,
    })
    onMinTickChange(newTick)
    setMinPrice(newPrice)
  }

  // maxes
  function onMaxPriceDecrement() {
    setIsDirty(true)
    if (maxTick) {
      const newTick = maxTick - tickSpacing
      updateMaxes(newTick)
    }
  }
  function onMaxPriceIncrement() {
    setIsDirty(true)
    if (maxTick) {
      const newTick = maxTick + tickSpacing
      updateMaxes(newTick)
    }
  }
  function updateMaxes(newTick: number) {
    const newPrice = tickToPrice({
      tick: newTick,
      token0Decimals: token0.decimals,
      token1Decimals: token1.decimals,
    })
    onMaxTickChange(newTick)
    setMaxPrice(newPrice)
  }

  function onMinPriceChange(price?: number) {
    setMinPrice(price)
  }

  function onMaxPriceChange(price?: number) {
    setMaxPrice(price)
  }

  function onMinPriceBlur() {
    setIsDirty(true)
    if (minPrice) {
      const _minTick = priceToClosestTick({
        price: minPrice,
        tickSpacing,
        token0Decimals: token0.decimals,
        token1Decimals: token1.decimals,
      })
      onMinTickChange(_minTick)
      setMinPrice(
        tickToPrice({
          tick: _minTick,
          token0Decimals: token0.decimals,
          token1Decimals: token1.decimals,
        })
      )
    }
  }

  function onMaxPriceBlur() {
    setIsDirty(true)
    if (maxPrice) {
      const _maxTick = priceToClosestTick({
        price: maxPrice,
        tickSpacing,
        token0Decimals: token0.decimals,
        token1Decimals: token1.decimals,
      })
      onMaxTickChange(_maxTick)
      setMaxPrice(
        tickToPrice({
          tick: _maxTick,
          token0Decimals: token0.decimals,
          token1Decimals: token1.decimals,
        })
      )
    }
  }

  const onPercentageClick = (percentage: number) => {
    setIsDirty(true)
    const currentPrice = isSorted ? Number(pool.token0Price) : Number(pool.token1Price)

    if (currentPrice === 0) return

    const { price: _minPrice, tick: _minTick } = priceToTickAndBack({
      price: currentPrice * (1 / percentage),
      tickSpacing,
      token0,
      token1,
      isSorted,
    })
    const { price: _maxPrice, tick: _maxTick } = priceToTickAndBack({
      price: currentPrice * percentage,
      tickSpacing,
      token0,
      token1,
      isSorted,
    })

    setMinPrice(_minPrice)
    setMaxPrice(_maxPrice)
    onMinTickChange(_minTick)
    onMaxTickChange(_maxTick)
  }

  const units = `${token0.symbol} per ${token1.symbol}`
  const currentPrice =
    isDefaultToken(token0) || isDefaultToken(token1)
      ? 0
      : !isSorted
      ? trimDecimalStringToPrecision(pool.token1Price, MAX_DECIMALS)
      : trimDecimalStringToPrecision(pool.token0Price, MAX_DECIMALS)

  return (
    <Container data-test-id='price-range-inputs' className={className}>
      <CurrentPrice data-test-id='current-price'>
        {'Current Price '}
        {currentPrice}
        {` ${units}`}
      </CurrentPrice>

      {adapterTypeId === VAULT_CONFIG[chainId].adapterTypeIds.limitedRange ? (
        <Inputs>
          <PriceInput
            title='Min Price'
            units={units}
            value={minPrice}
            precision={MAX_DECIMALS}
            onChange={onMinPriceChange}
            onBlur={onMinPriceBlur}
            onDecrement={onMinPriceIncrement}
            onIncrement={onMinPriceDecrement}
          />
          <PriceInput
            title='Max Price'
            units={units}
            value={maxPrice}
            precision={MAX_DECIMALS}
            onChange={onMaxPriceChange}
            onBlur={onMaxPriceBlur}
            onDecrement={onMaxPriceIncrement}
            onIncrement={onMaxPriceDecrement}
          />
        </Inputs>
      ) : (
        // FULL RANGE ADAPTER
        <Inputs>
          <PriceInput
            title='Min Price'
            units={units}
            value={minPrice}
            disabledValue='0'
            precision={MAX_DECIMALS}
            onChange={onMinPriceChange}
            onBlur={onMinPriceBlur}
            onDecrement={onMinPriceIncrement}
            onIncrement={onMinPriceDecrement}
          />
          <PriceInput
            title='Max Price'
            units={units}
            value={maxPrice}
            disabledValue='∞'
            precision={MAX_DECIMALS}
            onChange={onMaxPriceChange}
            onBlur={onMaxPriceBlur}
            onDecrement={onMaxPriceIncrement}
            onIncrement={onMaxPriceDecrement}
          />
        </Inputs>
      )}

      {!priceRangeValid && (
        <ErrorMessageWrapper>{UNISWAP_DESCRIPTIONS.minTickLTMaxTick}</ErrorMessageWrapper>
      )}

      <PercentButtonsWrapper
        onButtonClick={onPercentageClick}
        percents={[1.25, 1.5, 2, 3, 5]}
        abbreviatePercents
      />

      {adapterTypeId === VAULT_CONFIG[chainId].adapterTypeIds.limitedRange ? (
        <RangeButton onClick={onFullRangeClick} data-test-id='full-range-button'>
          Switch to Full Range
        </RangeButton>
      ) : (
        <RangeButton onClick={onLimitedRangeClick} data-test-id='limited-range-button'>
          Switch to Limited Range
        </RangeButton>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const CurrentPrice = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.colors.text.secondary};
`

const Inputs = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`

const RangeButton = styled(Button)`
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  background-color: ${(props) => props.theme.colors.backgrounds.tertiary};
  color: ${(props) => props.theme.colors.text.primary};
`

const PercentButtonsWrapper = styled(PercentButtons)``

const ErrorMessageWrapper = styled(ErrorMessage)`
  text-align: center;
  overflow: hidden;
`

import styled, { css } from 'styled-components'
import { useState } from 'react'

import { Button, CSS_TRANSITION, formatDataTestId, hexToRgbString, NumberInput } from '@packages/ui'

interface Props {
  title: string
  units: string
  value?: number
  precision?: number
  onChange: (value?: number) => void
  onBlur: () => void
  disabledValue?: string
  onDecrement: () => void
  onIncrement: () => void
}

export function PriceInput({
  title,
  units,
  value,
  precision = 4,
  onChange,
  onBlur,
  onDecrement,
  onIncrement,
  disabledValue,
}: Props) {
  const [active, setActive] = useState(false)

  function handleOnFocus() {
    setActive(true)
  }

  function handleOnBlur() {
    setActive(false)
    onBlur()
  }

  const isDisabled = !!disabledValue

  return (
    <Container
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      active={active}
      data-test-id={'price-input' + formatDataTestId(title)}
    >
      <Title>{title}</Title>
      <PriceContainer>
        <ActionButton onClick={onDecrement} disabled={isDisabled} data-test-id='decrement-button'>
          -
        </ActionButton>
        {disabledValue ? (
          <DisabledValue data-test-id='disabled-value'>{disabledValue}</DisabledValue>
        ) : (
          <PriceNumberInput onChange={onChange} value={value} precision={precision} />
        )}
        <ActionButton onClick={onIncrement} disabled={isDisabled} data-test-id='increment-button'>
          +
        </ActionButton>
      </PriceContainer>
      <Units>{units}</Units>
    </Container>
  )
}

const Container = styled.div<{ active?: boolean }>`
  ${CSS_TRANSITION}
  padding: 10px;
  border-radius: 10px;
  border: 3px solid
    ${(props) =>
      props.active ? props.theme.colors.activated : props.theme.colors.backgrounds.tertiary};
  color: ${(props) => props.theme.colors.text.primary};
  flex: 1;
  ${(props) =>
    props.theme.type === 'light' &&
    css`
      border: ${props.active ? props.theme.colors.activated : 'none'};
      background-color: ${props.theme.colors.backgrounds.tertiary};
    `}
`

const Title = styled.div`
  font-size: 13px;
  color: ${(props) => props.theme.colors.text.secondary};
  text-align: center;
`

const ActionButton = styled(Button)`
  width: 30px;
  height: 30px;
  font-weight: 700;
  font-size: 20px;
  display: flex;
  justify-content: center;
  color: ${(props) => props.theme.colors.backgrounds.primary};

  ${(props) =>
    props.disabled &&
    ` 
    color: ${hexToRgbString(props.theme.colors.text.disabled, 0.75)};
    `}
`

const PriceContainer = styled.div`
  display: flex;
  align-items: center;
`

const PriceNumberInput = styled(NumberInput)`
  width: 115px;
  height: inherit;
  background-color: ${(props) => props.theme.colors.backgrounds.secondary};
  ${(props) =>
    props.theme.type === 'light' &&
    css`
      background-color: ${props.theme.colors.backgrounds.tertiary};
    `}
  outline: none;
  text-align: center;
  padding-left: 0px;
  ::placeholder {
    font-size: 15px;
    font-weight: 500;
  }
`

const DisabledValue = styled.div`
  width: 115px;
  background-color: ${(props) => props.theme.colors.backgrounds.secondary};
  outline: none;
  text-align: center;
  padding-left: 0px;
  ::placeholder {
    font-size: 15px;
    font-weight: 500;
  }
`

const Units = styled.div`
  text-align: center;
  font-size: 12px;
  color: ${(props) => props.theme.colors.text.secondary};
`

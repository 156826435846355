import { Web3Provider } from '@ethersproject/providers'

import { UniV3LimitedRangeAdapter__factory } from '@packages/contract-types'
import { SharedAppState, Tokens, Uniswap, UPDATES_PER_BLOCK } from '@packages/ui'
import { getMinMaxTicks } from '@packages/uniswap'
import { useUpdatePerBlock, useWeb3ReactPlus } from '@packages/web3-react-plus'

import { AppStateActionName, AppStateDispatch, useAppStateReducer } from 'src/AppState'

import { UniV3Adapter, VaultInfo } from '../interfaces'
import { getUniV3SearchText } from '../utils'

export function useUniswapAdapter(vaultInfo: VaultInfo) {
  const { provider, chainId } = useWeb3ReactPlus()
  const [appState, dispatch] = useAppStateReducer()

  const vaults = appState.vaults?.[chainId] ?? undefined
  const adapter =
    vaultInfo && vaults?.adapter?.[vaultInfo.adapterAddress]
      ? (vaults.adapter[vaultInfo.adapterAddress] as SharedAppState.AsyncData<UniV3Adapter>)
      : undefined

  useUpdatePerBlock(
    () =>
      getAdapter({
        chainId,
        dispatch,
        vaultInfo,
        web3Provider: provider,
        currentAdapter: adapter?.data,
      }),
    [chainId, dispatch, provider, vaultInfo.adapterAddress],
    {
      updateEveryNBlocks: UPDATES_PER_BLOCK.SEMI_CRITICAL,
    }
  )

  return adapter
}

async function getAdapter({
  web3Provider,
  chainId,
  dispatch,
  currentAdapter,
  vaultInfo,
}: {
  web3Provider?: Web3Provider
  chainId: number
  dispatch: AppStateDispatch
  currentAdapter?: UniV3Adapter
  vaultInfo?: VaultInfo
}) {
  if (!web3Provider || !vaultInfo) return

  dispatch({
    type: AppStateActionName.UpdateVaultAdapter,
    payload: { isFetching: true, chainId, address: vaultInfo.adapterAddress },
  })

  const adapterContract = UniV3LimitedRangeAdapter__factory.connect(
    vaultInfo.adapterAddress,
    web3Provider
  )

  try {
    let adapter: UniV3Adapter | undefined = currentAdapter

    if (currentAdapter) {
      adapter = currentAdapter
      // just update fields that are dynamic
      const [liquidity, earnings0, earnings1] = await Promise.all([
        adapterContract.liquidity(),
        adapterContract.earnings0(),
        adapterContract.earnings1(),
      ])
      adapter.liquidity = liquidity
      adapter.earnings0 = earnings0
      adapter.earnings1 = earnings1
    } else {
      const [
        pool,
        minTick,
        maxTick,
        { token0: token0Address, token1: token1Address, fee },
        tokenId,
        liquidity,
        earnings0,
        earnings1,
      ] = await Promise.all([
        adapterContract.pool(),
        adapterContract.poolMinTick(),
        adapterContract.poolMaxTick(),
        adapterContract.poolKey(),
        adapterContract.tokenId(),
        adapterContract.liquidity(),
        adapterContract.earnings0(),
        adapterContract.earnings1(),
      ])

      const [token0, token1] = await Promise.all([
        Tokens.getToken({ web3Provider, tokenAddress: token0Address }),
        Tokens.getToken({ web3Provider, tokenAddress: token1Address }),
      ])

      const tickSpacing = Uniswap.UNISWAP_TICK_SPACING[fee as Uniswap.UNISWAP_FEE_TIER]
      const { minTick: _minTick, maxTick: _maxTick } = getMinMaxTicks(tickSpacing)
      const isFullRange = minTick === _minTick && maxTick === _maxTick

      adapter = {
        ...vaultInfo,
        vaultAddress: vaultInfo.address,
        minTick,
        maxTick,
        isFullRange,
        pool: { address: pool, fee, token0, token1 },
        tokenId: tokenId.toNumber(),
        liquidity,
        earnings0,
        earnings1,
        searchText: '',
      }

      adapter.searchText = getUniV3SearchText(adapter)
    }

    dispatch({
      type: AppStateActionName.UpdateVaultAdapter,
      payload: { data: adapter, chainId, address: vaultInfo.adapterAddress },
    })
  } catch (error: any) {
    console.error(error)
    dispatch({
      type: AppStateActionName.UpdateVaultAdapter,
      payload: { error, chainId, address: vaultInfo.adapterAddress },
    })
  }
}

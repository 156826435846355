import { useState } from 'react'

import { useUpdatePerBlock, useWeb3ReactPlus } from '@packages/web3-react-plus'

import { getPoolDayData } from '../utils'
import { UniswapV3PoolDayData } from '../interfaces'
import { UPDATES_PER_BLOCK } from '../../config'

export function usePoolDayData(poolAddress: string) {
  const { chainId } = useWeb3ReactPlus()
  const [dayData, setDayData] = useState<UniswapV3PoolDayData>()
  const [error, setError] = useState<Error>()
  const [loading, setLoading] = useState(false)

  useUpdatePerBlock(
    () => _getPoolDayData({ poolAddress, chainId, setDayData, setError, setLoading }),
    [poolAddress, chainId, setDayData, setError, setLoading],
    {
      updateEveryNBlocks: UPDATES_PER_BLOCK.NOT_CRITICAL,
    }
  )

  return { dayData, error, loading }
}

async function _getPoolDayData({
  poolAddress,
  chainId,
  setDayData,
  setLoading,
  setError,
}: {
  poolAddress: string
  chainId: number
  setDayData: (dayData: UniswapV3PoolDayData) => void
  setLoading: (loading: boolean) => void
  setError: (error: Error) => void
}) {
  if (poolAddress.length === 0) return
  try {
    setLoading(true)
    const poolDayData = await getPoolDayData(poolAddress, chainId)
    setDayData(poolDayData)
  } catch (error: any) {
    setError(error)
  } finally {
    setLoading(false)
  }
}

import styled from 'styled-components'

import { Tokens, Button, Card, ErrorMessage, Tabs } from '@packages/ui'

import { UniV3Adapter, Vault } from '../../../interfaces'
import { UniV3VariableForm } from './UniV3VariableForm'
import { UniV3FixedForm } from './UniV3FixedForm'

export interface VaultFormProps {
  vault: Vault
  adapter: UniV3Adapter
}

export function UniV3VaultForm({ vault, adapter }: VaultFormProps) {
  const tabs = [
    {
      id: 1,
      title: 'Fixed Side',
      content: <UniV3FixedForm vault={vault} adapter={adapter} />,
    },
    {
      id: 2,
      title: 'Variable Side',
      content: <UniV3VariableForm vault={vault} adapter={adapter} />,
    },
  ]

  return (
    <VaultFormCard>
      <Tabs tabs={tabs} />
    </VaultFormCard>
  )
}

const VaultFormCard = styled(Card)`
  min-height: 400px;
  padding: 19px;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 30px;
  &:not(:last-child) {
    border-bottom: 1px solid #25262a;
    padding-bottom: 30px;
  }
  &:nth-child(2) {
    padding-top: 30px;
  }
`

export const ButtonWrapper = styled(Button)`
  width: 100%;
  font-size: 17px;
  line-height: 21px;
  letter-spacing: -0.02em;
`

export const ButtonContent = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    margin-right: 10px;
  }
`

export const BigNumberAmountWrapper = styled(Tokens.BigNumberAmount)`
  cursor: default;
  padding-top: 10px;

  span:nth-child(2) {
    font-weight: 500;
  }
`

export const BigNumberButtonAmount = styled(Tokens.BigNumberAmount)`
  cursor: default;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: inherit;
  > span {
    color: inherit;
  }
`

export const ErrorMessageWrapper = styled(ErrorMessage)`
  max-width: 400px;
  text-align: center;
`

export enum UNISWAP_FEE_TIER {
  ONE_HUNDRED = 100,
  FIVE_HUNDRED = 500,
  THREE_THOUSAND = 3000,
  TEN_THOUSAND = 10000,
}

export const UNISWAP_FEE_TIERS: UNISWAP_FEE_TIER[] = [100, 500, 3000, 10000]

export const UNISWAP_FEE_TIER_DESC: { [key in UNISWAP_FEE_TIER]: string } = {
  [UNISWAP_FEE_TIER.ONE_HUNDRED]: 'Best for very stable pairs',
  [UNISWAP_FEE_TIER.FIVE_HUNDRED]: 'Best for stable pairs',
  [UNISWAP_FEE_TIER.THREE_THOUSAND]: 'Best for most pairs',
  [UNISWAP_FEE_TIER.TEN_THOUSAND]: 'Best for exotic pairs',
}

import { formatUnits } from 'ethers/lib/utils'
import { BigNumber } from 'ethers'

import { epochMsToLocaleString, formatNumber, secondsToString } from '@packages/ui'
import { BIG_NUMBER_ZERO } from '@packages/bn'

import { Vault } from '../interfaces'
import { USD_BN_DECIMALS } from '../constants'

const MAX_DISPLAY_DECIMALS = 8
const MAX_SIGNIFICANT_DIGITS = 8

export function formatVariableCapacity(vault: Vault) {
  return `${Number.parseFloat(
    formatUnits(vault.variableSideCapacity ?? BIG_NUMBER_ZERO, vault.variableAsset.decimals)
  ).toLocaleString(undefined, {
    maximumSignificantDigits: MAX_SIGNIFICANT_DIGITS,
    maximumFractionDigits: MAX_DISPLAY_DECIMALS,
  })} ${formatSymbol(vault.variableAsset.symbol)}`
}

const BIG_NUMBER_ONE_MILLION = BigNumber.from(1_000_000_000_000)

export function formatFixedCapacity(usdLiquidityValue: BigNumber) {
  if (usdLiquidityValue.gt(BIG_NUMBER_ONE_MILLION)) {
    const remaining = getLiquidityUsdValue(usdLiquidityValue.sub(BIG_NUMBER_ONE_MILLION))
    const fraction = (Number(remaining) / 1_000_000).toFixed(3)
    return `1${Number(fraction) > 0 ? `.${fraction.split('.')?.[1]}` : ''}M USD`
  }
  return `${formatNumber(Number(getLiquidityUsdValue(usdLiquidityValue)).toPrecision(8))} USD`
}

export function formatEndTime(endTimeSeconds: number) {
  return epochMsToLocaleString(endTimeSeconds * 1000)
}

export function formatTimeRemaining(currentTimeSeconds: number, endTimeSeconds: number) {
  if (endTimeSeconds !== 0 && currentTimeSeconds !== 0 && endTimeSeconds > currentTimeSeconds) {
    const timeDiff = endTimeSeconds - currentTimeSeconds
    const timeRemainingStringNoMins = secondsToString(timeDiff)
    if (timeRemainingStringNoMins !== '') return timeRemainingStringNoMins

    const timeRemainingStringNoSeconds = secondsToString(timeDiff, true)
    if (timeRemainingStringNoSeconds !== '') return timeRemainingStringNoSeconds

    const timeRemainingString = secondsToString(timeDiff, true, true)
    if (timeRemainingString !== '') return timeRemainingString
  }

  return '0'
}

export function formatDuration(duration: number) {
  return secondsToString(duration)
}

function getLiquidityUsdValue(usdLiquidityValue: BigNumber) {
  return formatUnits(usdLiquidityValue, USD_BN_DECIMALS)
}

export function formatApr(apr?: number | '-') {
  if (apr === '-') return '-'
  return apr ? (apr * 100).toFixed(2) + '%' : '0.00%'
}

export function formatSymbol(symbol?: string) {
  return symbol === 'WETH' ? 'ETH' : symbol
}

import styled, { css } from 'styled-components'
import { formatUnits } from 'ethers/lib/utils'
import { useCallback, useEffect, useState } from 'react'
import { BigNumber } from 'ethers'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import { Link } from 'react-router-dom'

import { useUpdatePerBlock, useWeb3ReactPlus } from '@packages/web3-react-plus'
import {
  BigNumberInput,
  Button,
  durationToSeconds,
  DayDurationPicker,
  HourDurationPicker,
  WeekDurationPicker,
  MonthDurationPicker,
  UsdInput,
  Tokens,
  Uniswap,
  SharedAppState,
  mediaQuery,
  InfoTooltip,
  Loading,
  A,
  ErrorMessage,
  SuccessMessage,
  Chains,
  Toggle,
  secondsToDays,
  PercentInput,
  Accounts,
  formatUsd,
  SmallButton,
  RadioButtons,
  UPDATES_PER_BLOCK,
} from '@packages/ui'
import { BIG_NUMBER_ZERO, bn } from '@packages/bn'

import { VAULT_CONFIG, TEST_ENVIRONMENT } from 'src/config'
import { useAppStateReducer } from 'src/AppState'

import {
  UNISWAP_DESCRIPTIONS,
  UNI_V3_CREATE_VAULT_DESCRIPTIONS,
  VAULT_DESCRIPTIONS,
} from '../../constants'
import { formatApr, isNonZeroAmount } from '../../utils'
import {
  getVault,
  useEstimatedAmountsForLiquidity,
  useEstimatedRates,
  useVaultFactory,
} from '../../hooks'
import {
  createUniV3FullRangeVault,
  createUniV3LimitedRangeVaultViaTick,
  CreateVaultResponse,
  CreateVaultSetters,
} from '../../transactions'
import { FeeTierButton } from './FeeTierButton'
import { VaultFormCard } from './DefaultCreateVaultForm'
import { PriceRangeInputs } from './PriceRangeInputs'
import { VaultInfo } from '../../interfaces'

export function UniswapCreateVaultForm() {
  const { provider, activeAddress, chainId, ready } = useWeb3ReactPlus()
  const [sharedAppState] = SharedAppState.useAppStateReducer()
  const [, dispatch] = useAppStateReducer()
  const { url } = Chains.useBlockExplorerBaseUrl()
  const [parent] = useAutoAnimate()

  const vaultFactory = useVaultFactory()

  const [token0ModalOpen, setToken0ModalOpen] = useState(false)
  const [token1ModalOpen, setToken1ModalOpen] = useState(false)
  const [variableAssetModalOpen, setVariableAssetModalOpen] = useState(false)
  const [txModalOpen, setTxModalOpen] = useState(false)

  const [currentTxStep, setCurrentTxStep] = useState<number>()
  const [createAdapterTxHash, setCreateAdapterTxHash] = useState<string>()
  const [createVaultTxHash, setCreateVaultTxHash] = useState<string>()
  const [initializeVaultTxHash, setInitializeVaultTxHash] = useState<string>()

  const [useFixedTargetApr, setUseFixedTargetApr] = useState(false)
  const [fixedTargetApr, setFixedTargetApr] = useState<number>()

  const [selectedAdapterTypeId, setSelectedAdapterTypeId] = useState<number>(
    VAULT_CONFIG[chainId].adapterTypeIds.limitedRange
  )
  const [minTick, setMinTick] = useState<number>()
  const [maxTick, setMaxTick] = useState<number>()
  const [priceRangeValid, setPriceRangeValid] = useState(true)

  const [monthsDuration, setMonthsDuration] = useState<number>()
  const [weeksDuration, setWeeksDuration] = useState<number>()
  const [daysDuration, setDaysDuration] = useState<number>()
  const [hoursDuration, setHoursDuration] = useState<number>()

  const [pools, setPools] = useState<Uniswap.UniswapV3Pool[]>([])
  const [fetchingPools, setFetchingPools] = useState(false)
  const [retryFetchingPools, setRetryFetchingPools] = useState(false)
  const [selectedPool, setSelectedPool] = useState<Uniswap.UniswapV3Pool>()
  const [selectedFeeTier, setSelectedFeeTier] = useState<Uniswap.UNISWAP_FEE_TIER>()

  const [token0, setToken0] = useState<Uniswap.UniswapToken>()
  const [token1, setToken1] = useState<Uniswap.UniswapToken>()
  const [variableAsset, setVariableAsset] = useState<Uniswap.UniswapToken>()
  const [variableAssetAmount, setVariableAssetAmount] = useState<BigNumber>()

  const [fixedCapacityUsd, setFixedCapacityUsd] = useState<number>()
  const [fixedCapacityAmount, setFixedCapacityAmount] = useState<BigNumber>()
  const [fixedCapacityToken, setFixedCapacityToken] = useState<Uniswap.UniswapToken>()

  const [txLoadingText, setTxLoadingText] = useState<string>()
  const [txError, setTxError] = useState<Error>()

  const { pool: updatedPool } = Uniswap.usePool(selectedPool?.address ?? '')
  const { data: token0UsdPrice } = Tokens.useTokenPrice(token0)
  const { data: token1UsdPrice } = Tokens.useTokenPrice(token1)
  const { data: variableAssetPrice } = Tokens.useTokenPrice(variableAsset)

  const durationSeconds = durationToSeconds(
    0,
    monthsDuration ?? 0,
    weeksDuration ?? 0,
    daysDuration ?? 0,
    hoursDuration ?? 0
  )

  const { fixed, variable } = useEstimatedRates({
    poolAddress: selectedPool?.address,
    durationSeconds,
    fixedCapacityUsd: fixedCapacityUsd,
    fixedTargetApr: useFixedTargetApr ? fixedTargetApr : undefined,
    variableSideCapacity: variableAssetAmount,
    variableAsset: variableAsset,
  })

  const isSorted = Uniswap.tokensIsSorted({ token0, token1, chainId, pool: selectedPool })

  const ticks = [
    isSorted ? minTick ?? 0 : -(maxTick ?? 0),
    isSorted ? maxTick ?? 0 : -(minTick ?? 0),
  ]

  const amounts = useEstimatedAmountsForLiquidity({
    fixedCapacityUsd,
    minTick: ticks[0],
    maxTick: ticks[1],
    isFullRange: selectedAdapterTypeId === VAULT_CONFIG[chainId].adapterTypeIds.fullRange,
    token0UsdPrice: isSorted ? token0UsdPrice : token1UsdPrice,
    token1UsdPrice: isSorted ? token1UsdPrice : token0UsdPrice,
    pool: updatedPool,
  })

  const [createVaultResponse, setCreateVaultResponse] = useState<CreateVaultResponse>()

  const fetchPools = useCallback(async () => {
    if (!token0 || !token1 || !provider) return

    setFetchingPools(true)
    setSelectedPool(undefined)

    const pools = await Uniswap.getPoolsFromTokens({ token0, token1, chainId, provider })
    setPools(pools)

    if (pools.length === 0) {
      setSelectedPool(undefined)
      setFetchingPools(false)
      setRetryFetchingPools(true)
      return
    }

    let maxPool = pools[0]
    let maxLiquidity = Number(pools[0].totalValueLockedUSD)
    pools.forEach((pool) => {
      if (Number(pool.totalValueLockedUSD) > maxLiquidity) {
        maxPool = pool
        maxLiquidity = Number(pool.totalValueLockedUSD)
      }
    })
    if (maxLiquidity !== 0) {
      setMinTick(undefined)
      setMaxTick(undefined)
      setSelectedPool(maxPool)
      setSelectedFeeTier(maxPool.feeTier)
    }

    setFetchingPools(false)
    setRetryFetchingPools(false)
  }, [token0, token1])

  useEffect(() => {
    fetchPools()
  }, [token0, token1, fetchPools])

  async function shouldRefetchPools() {
    if (retryFetchingPools) {
      await fetchPools()
    }
  }

  useUpdatePerBlock(
    async () => {
      if (retryFetchingPools) {
        await shouldRefetchPools()
      }
    },
    [retryFetchingPools],
    {
      updateEveryNBlocks: UPDATES_PER_BLOCK.SEMI_CRITICAL,
    }
  )

  useEffect(() => {
    if (pools.length > 0) {
      const foundPool = pools.find((pool) => {
        return pool.feeTier === selectedFeeTier
      })
      if (foundPool) {
        setSelectedPool(foundPool)
      }
    }
  }, [selectedFeeTier])

  useEffect(() => {
    // reset form on chainId change
    resetForm()
  }, [chainId])

  useEffect(() => {
    if (typeof currentTxStep !== 'undefined' && !txModalOpen) {
      setTxModalOpen(true)
    }
  }, [currentTxStep])

  useEffect(() => {
    if (
      useFixedTargetApr &&
      fixedTargetApr &&
      variableAsset &&
      variableAssetPrice &&
      fixedCapacityUsd &&
      durationSeconds > 0
    ) {
      const durationDays = secondsToDays(durationSeconds)
      const fixedRate = fixedTargetApr * (durationDays / 365)
      const variableCapacityUsd = fixedCapacityUsd * fixedRate
      setVariableAssetAmount(
        bn((variableCapacityUsd / variableAssetPrice).toPrecision(4), variableAsset.decimals)
      )
    }
  }, [
    useFixedTargetApr,
    fixedTargetApr,
    variableAsset,
    variableAssetPrice,
    fixedCapacityUsd,
    durationSeconds,
  ])

  useEffect(() => {
    if (fixedCapacityToken && fixedCapacityAmount) {
      if (token0 && fixedCapacityToken.symbol === token0.symbol && token0UsdPrice) {
        setFixedCapacityUsd(
          Number(
            formatUnits(
              fixedCapacityAmount.mul(Math.round(token0UsdPrice * 1000)),
              token0.decimals + 3
            )
          )
        )
      } else if (token1 && fixedCapacityToken.symbol === token1.symbol && token1UsdPrice) {
        setFixedCapacityUsd(
          Number(
            formatUnits(
              fixedCapacityAmount.mul(Math.round(token1UsdPrice * 1000)),
              token1.decimals + 3
            )
          )
        )
      }
    }
  }, [fixedCapacityToken, fixedCapacityAmount, token0, token1, token0UsdPrice, token1UsdPrice])

  function toggleToken0Modal() {
    setToken0ModalOpen(!token0ModalOpen)
  }

  function toggleToken1Modal() {
    setToken1ModalOpen(!token1ModalOpen)
  }

  function toggleVariableAssetModal() {
    setVariableAssetModalOpen(!variableAssetModalOpen)
  }

  function toggleTxModal() {
    setTxModalOpen(!txModalOpen)
  }

  function onVariableAssetSelected(token: Uniswap.UniswapToken) {
    toggleVariableAssetModal()
    setVariableAsset(token)
  }

  function onTokenSelected(token: Uniswap.UniswapToken, index: 0 | 1) {
    if (index === 0) {
      setToken0(token)
      toggleToken0Modal()
    } else if (index === 1) {
      setToken1(token)
      toggleToken1Modal()
    }
  }

  function swapTokens() {
    const prevToken0 = token0
    setToken0(token1)
    setToken1(prevToken0)
  }

  function resetForm() {
    setPools([])
    setSelectedPool(undefined)
    setSelectedFeeTier(undefined)
    setToken0(undefined)
    setToken1(undefined)
    setFixedCapacityUsd(undefined)
    setMinTick(undefined)
    setMaxTick(undefined)
    setVariableAssetAmount(undefined)
    setMonthsDuration(undefined)
    setWeeksDuration(undefined)
    setHoursDuration(undefined)
    setDaysDuration(undefined)
    setFixedTargetApr(undefined)
    setVariableAsset(undefined)
  }

  const setters: CreateVaultSetters = {
    setStep: setCurrentTxStep,
    setCreateAdapterTxHash,
    setCreateVaultTxHash,
    setInitializeVaultTxHash,
  }

  const createVault = async () => {
    if (
      activeAddress &&
      provider &&
      variableAsset &&
      selectedPool &&
      updatedPool &&
      fixedCapacityUsd &&
      variableAssetAmount &&
      (monthsDuration || weeksDuration || daysDuration || hoursDuration)
    ) {
      try {
        setTxError(undefined)
        setCreateVaultResponse(undefined)
        setCurrentTxStep(undefined)
        setCreateAdapterTxHash(undefined)
        setCreateVaultTxHash(undefined)
        setInitializeVaultTxHash(undefined)

        toggleTxModal()
        setTxLoadingText('Waiting')

        let response
        if (
          selectedAdapterTypeId === VAULT_CONFIG[chainId].adapterTypeIds.fullRange &&
          token0UsdPrice &&
          token1UsdPrice
        ) {
          response = await createUniV3FullRangeVault({
            setters,
            web3Provider: provider,
            chainId,
            vault: {
              variableCapacity: variableAssetAmount,
              durationSeconds,
              variableTokenAddress: variableAsset.address,
            },
            adapter: {
              poolAddress: selectedPool.address,
              totalUsdDesiredAmount: fixedCapacityUsd,
              poolTickSpacing: updatedPool.tickSpacing,
              currentTick: updatedPool.tick,
              token0Decimals: updatedPool.token0.decimals,
              token1Decimals: updatedPool.token1.decimals,
              token0UsdPrice: isSorted ? token0UsdPrice : token1UsdPrice,
              token1UsdPrice: isSorted ? token1UsdPrice : token0UsdPrice,
              currentPrice: parseFloat(selectedPool.token0Price),
            },
          })
        } else if (
          selectedAdapterTypeId === VAULT_CONFIG[chainId].adapterTypeIds.limitedRange &&
          minTick &&
          maxTick &&
          token0UsdPrice &&
          token1UsdPrice
        ) {
          response = await createUniV3LimitedRangeVaultViaTick({
            setters,
            web3Provider: provider,
            chainId,
            vault: {
              variableCapacity: variableAssetAmount,
              durationSeconds,
              variableTokenAddress: variableAsset.address,
            },
            adapter: {
              poolAddress: selectedPool.address,
              poolTickSpacing: updatedPool.tickSpacing,
              token0Decimals: updatedPool.token0.decimals,
              token1Decimals: updatedPool.token1.decimals,
              currentTick: updatedPool.tick,
              totalUsdDesiredAmount: fixedCapacityUsd,
              tickLower: ticks[0],
              tickUpper: ticks[1],
              currentPrice: parseFloat(selectedPool.token0Price),
              token0UsdPrice: isSorted ? token0UsdPrice : token1UsdPrice,
              token1UsdPrice: isSorted ? token1UsdPrice : token0UsdPrice,
            },
          })
        }

        if (response) {
          setCreateVaultResponse(response)

          const vaultInfo: VaultInfo = {
            id: response.vaultId,
            address: response.vaultAddress,
            creatorAddress: activeAddress,
            adapterAddress: response.adapterAddress,
            adapterTypeId: selectedAdapterTypeId,
            vaultTypeId: VAULT_CONFIG[chainId].vaultTypeIds.uniV3,
          }
          await getVault({ web3Provider: provider, chainId, dispatch, vaultInfo })
        }

        resetForm()
      } catch (error: any) {
        console.error(error)
        setTxError(error)
      }
      setTxLoadingText(undefined)
    }
  }

  function getSubmitDisabledError(): string | undefined {
    if (!ready) {
      return 'Waiting for web3'
    } else if (!selectedPool) {
      return 'No Pool selected'
    } else if (!priceRangeValid) {
      return 'Price Range is invalid'
    } else if (!fixedCapacityUsd) {
      return 'Fixed Capacity is not set'
    } else if (!variableAsset) {
      return 'No Variable Asset selected'
    } else if (!isNonZeroAmount(variableAssetAmount)) {
      return 'Variable asset amount is not set'
    } else if (!(monthsDuration || weeksDuration || daysDuration || hoursDuration)) {
      return 'Duration is not set'
    } else if (
      selectedAdapterTypeId === VAULT_CONFIG[chainId].adapterTypeIds.fullRange ||
      selectedAdapterTypeId === VAULT_CONFIG[chainId].adapterTypeIds.limitedRange
    ) {
      if (!minTick) {
        return 'Min tick is not set'
      } else if (!maxTick) {
        return 'Max tick is not set'
      } else if (!token0UsdPrice) {
        return token0?.name + ' Price is not set'
      } else if (!token1UsdPrice) {
        return token1?.name + ' Price is not set'
      }
    }
  }

  const submitDisabledError = getSubmitDisabledError()

  const isDisabled = vaultFactory?.data?.owner !== activeAddress && !TEST_ENVIRONMENT

  const SuccessMessageContainer = createVaultResponse && (
    <SuccessMessageWrapper data-test-id='vault-created-success-message'>
      Vault Created Successfully:
      <LinkWrapper to={`/vault/${chainId}/${createVaultResponse.vaultId}`}>View Vault</LinkWrapper>
      <A href={`${url}address/${createVaultResponse.vaultAddress}`} useIcon>
        Vault Contract
      </A>
      <A href={`${url}address/${createVaultResponse.adapterAddress}`} useIcon>
        Adapter Contract
      </A>
    </SuccessMessageWrapper>
  )

  return (
    <Container>
      {isDisabled && (
        <ComingSoonBanner>
          Coming Soon. Please reach out to the Saffron team via{' '}
          <BannerLink href='https://t.me/saffronfinance'>Telegram</BannerLink> or{' '}
          <BannerLink href='https://discord.gg/pDXpXKY'>Discord</BannerLink> to create a Vault.
        </ComingSoonBanner>
      )}

      <Content disabled={isDisabled}>
        <Left>
          <H2>Uniswap v3 Pool</H2>
          <Section data-test-id='uniswap-pair-section'>
            <H3>Token Pair</H3>
            <Inputs>
              <SelectTokenButton onClick={toggleToken0Modal} token={token0} />
              <SwapButton onClick={swapTokens}>Swap 🔁</SwapButton>
              <SelectTokenButton onClick={toggleToken1Modal} token={token1} />
            </Inputs>
            {token0 &&
              token1 &&
              !selectedPool &&
              (fetchingPools ? (
                <LoadingWrapper />
              ) : (
                <ErrorMessageWrapper>
                  {UNISWAP_DESCRIPTIONS.noPools}{' '}
                  <SmallButton onClick={fetchPools}>Retry</SmallButton>
                </ErrorMessageWrapper>
              ))}
          </Section>

          <Section data-test-id='fee-tier-section'>
            <H3>Fee Tier</H3>
            <Inputs data-test-id='fee-tier-inputs'>
              {Uniswap.UNISWAP_FEE_TIERS.map((feeTier) => {
                return (
                  <FeeTierButton
                    key={feeTier}
                    pools={pools}
                    feeTier={feeTier}
                    selected={selectedFeeTier === feeTier}
                    onClick={() => setSelectedFeeTier(feeTier)}
                  />
                )
              })}
            </Inputs>
          </Section>

          <Section data-test-id='price-range-section'>
            <H3>Price Range</H3>

            <PriceRangeInputs
              feeTier={selectedFeeTier}
              pool={updatedPool ? updatedPool : selectedPool}
              token0={token0}
              token1={token1}
              adapterTypeId={selectedAdapterTypeId}
              onAdapterChange={setSelectedAdapterTypeId}
              minTick={minTick}
              maxTick={maxTick}
              onMinTickChange={setMinTick}
              onMaxTickChange={setMaxTick}
              priceRangeValid={priceRangeValid}
              setPriceRangeValid={setPriceRangeValid}
            />
          </Section>
        </Left>

        <Right>
          <Section data-test-id='duration-section'>
            <H2>Duration</H2>
            <Inputs>
              <MonthDurationPicker
                onChange={(months) => setMonthsDuration(months)}
                value={monthsDuration}
              />
              <WeekDurationPicker
                onChange={(weeks) => setWeeksDuration(weeks)}
                value={weeksDuration}
              />
              <DayDurationPicker onChange={(days) => setDaysDuration(days)} value={daysDuration} />
              <HourDurationPicker
                onChange={(hours) => setHoursDuration(hours)}
                value={hoursDuration}
              />
            </Inputs>
          </Section>

          <Section data-test-id='capacity-section' ref={parent}>
            <H2>
              Capacity{' '}
              <ToggleSection data-test-id='use-fixed-rate-section'>
                Use Target Fixed APR
                <ToggleWrapper
                  onChange={() => setUseFixedTargetApr(!useFixedTargetApr)}
                  checked={useFixedTargetApr}
                  id='fixed-target-apr-toggle'
                />
              </ToggleSection>
            </H2>

            {useFixedTargetApr && (
              <>
                <H3>
                  Target Fixed APR
                  <ToolTip content={UNI_V3_CREATE_VAULT_DESCRIPTIONS.fixedSide.targetApr} />
                </H3>
                <Inputs data-test-id='fixed-target-apr-inputs'>
                  <PercentInput
                    value={fixedTargetApr}
                    onChange={(value) => setFixedTargetApr(value)}
                  />
                </Inputs>
              </>
            )}

            <FixedSideCapacityH3>
              Fixed Side Capacity{' '}
              {token0 && token1 && (
                <RadioButtons
                  groupName='fixed-capacity-currency'
                  selectedOption={fixedCapacityToken?.symbol ?? 'USD'}
                  options={[token0.symbol, token1.symbol, 'USD']}
                  onChange={(tokenSymbol) => {
                    if (tokenSymbol === token0.symbol) {
                      setFixedCapacityToken(token0)
                    } else if (tokenSymbol === token1.symbol) {
                      setFixedCapacityToken(token1)
                    } else {
                      setFixedCapacityToken(undefined)
                    }
                  }}
                />
              )}
              <ToolTip content={UNI_V3_CREATE_VAULT_DESCRIPTIONS.fixedSide.capacity} />
            </FixedSideCapacityH3>
            <Inputs data-test-id='fixed-capacity-inputs'>
              {!fixedCapacityToken ? (
                <UsdInputWrapper
                  value={fixedCapacityUsd}
                  onChange={setFixedCapacityUsd}
                  placeholder='$0.00'
                />
              ) : (
                <SmallBigNumberInput
                  value={fixedCapacityAmount}
                  onChange={setFixedCapacityAmount}
                  precision={fixedCapacityToken?.decimals}
                />
              )}
              <TokenEstimations>
                <TokenEstimation data-test-id='token0-amount'>
                  <EstimationKey>
                    {(isSorted ? token0?.symbol : token1?.symbol) ?? 'Token0'} Amount:{' '}
                  </EstimationKey>{' '}
                  {amounts.amount0}
                </TokenEstimation>
                <TokenEstimation data-test-id='token1-amount'>
                  <EstimationKey>
                    {(isSorted ? token1?.symbol : token0?.symbol) ?? 'Token1'} Amount:{' '}
                  </EstimationKey>{' '}
                  {amounts.amount1}
                </TokenEstimation>
              </TokenEstimations>
            </Inputs>

            <H3>Variable Side Capacity</H3>
            <Inputs data-test-id='variable-capacity-inputs'>
              <SelectTokenButton onClick={toggleVariableAssetModal} token={variableAsset} />
              <SmallBigNumberInput
                value={variableAssetAmount}
                onChange={setVariableAssetAmount}
                precision={variableAsset?.decimals}
                disabled={useFixedTargetApr}
              />
            </Inputs>
          </Section>

          <Section data-test-id='estimated-rates-section'>
            <H2>Return Estimates</H2>

            <Estimations>
              <EstimationTitle>Fixed Side</EstimationTitle>
              <FixedEstimations>
                <Estimation data-test-id='fixed-apr'>
                  <EstimationKey>APR:</EstimationKey> {formatApr(fixed.apr)}
                </Estimation>
                {'  '}
                <Estimation data-test-id='fixed-usd-earnings'>
                  <EstimationKey>{variableAsset?.symbol} Earnings:</EstimationKey>{' '}
                  {formatUnits(variableAssetAmount ?? BIG_NUMBER_ZERO, variableAsset?.decimals)}
                </Estimation>
              </FixedEstimations>
            </Estimations>

            <Estimations>
              <EstimationTable>
                <EstimationTableRow>
                  <EstimationTableHeader>
                    <EstimationTitle>
                      Variable Side{' '}
                      <ToolTip
                        content={UNI_V3_CREATE_VAULT_DESCRIPTIONS.variableSide.estimatedApr(
                          variable
                        )}
                      />
                    </EstimationTitle>
                  </EstimationTableHeader>
                  <EstimationTableHeader>Earnings</EstimationTableHeader>
                  <EstimationTableHeader>APR</EstimationTableHeader>
                  <EstimationTableHeader>Annualized Earnings</EstimationTableHeader>
                </EstimationTableRow>
                {[1, 30, 90].map((day) => {
                  return (
                    <EstimationTableRow>
                      <EstimationTableMetaData>[{day} Day Average]</EstimationTableMetaData>
                      <EstimationTableData>
                        {formatUsd(variable.estimationsByXDayAverage[day].usdEarnings)}
                      </EstimationTableData>
                      <EstimationTableData>
                        {formatApr(variable.estimationsByXDayAverage[day].apr)}
                      </EstimationTableData>
                      <EstimationTableData>
                        {formatUsd(variable.estimationsByXDayAverage[day].annualizedUsdEarnings)}
                      </EstimationTableData>
                    </EstimationTableRow>
                  )
                })}
              </EstimationTable>
            </Estimations>
          </Section>

          {activeAddress ? (
            <ButtonSection>
              <Button
                onClick={createVault}
                disabled={typeof submitDisabledError === 'string' || !!txLoadingText}
                loading={!!txLoadingText}
                data-test-id='create-vault-button'
              >
                Create Vault
              </Button>
              {submitDisabledError && <ErrorMessage>{submitDisabledError}</ErrorMessage>}
              {txError && <Chains.BlockExplorerError error={txError} />}
              {SuccessMessageContainer}
            </ButtonSection>
          ) : (
            <ConnectWalletButton />
          )}

          {/** Modals */}
          <Tokens.SelectTokenModal
            isOpen={token0ModalOpen}
            onRequestClose={toggleToken0Modal}
            tokens={sharedAppState[chainId].tokens.list?.data ?? []}
            onTokenSelected={(token: Tokens.Token) =>
              onTokenSelected(token as Uniswap.UniswapToken, 0)
            }
            useWrappedTokens
          />
          <Tokens.SelectTokenModal
            isOpen={token1ModalOpen}
            onRequestClose={toggleToken1Modal}
            tokens={sharedAppState[chainId].tokens.list?.data ?? []}
            onTokenSelected={(token: Tokens.Token) =>
              onTokenSelected(token as Uniswap.UniswapToken, 1)
            }
            useWrappedTokens
          />
          <Tokens.SelectTokenModal
            isOpen={variableAssetModalOpen}
            onRequestClose={toggleVariableAssetModal}
            tokens={sharedAppState[chainId].tokens.list?.data ?? []}
            onTokenSelected={(token: Tokens.Token) =>
              onVariableAssetSelected(token as Uniswap.UniswapToken)
            }
            useWrappedTokens
          />

          <Chains.TxStepProgressModal
            title='Creating Vault'
            isOpen={txModalOpen}
            onRequestClose={toggleTxModal}
            steps={[
              {
                title: 'Creating Adapter',
                description: VAULT_DESCRIPTIONS.adapter,
                txHash: createAdapterTxHash,
              },
              {
                title: 'Creating Vault',
                description: VAULT_DESCRIPTIONS.vault,
                txHash: createVaultTxHash,
              },
              {
                title: 'Initializing Vault',
                description: VAULT_DESCRIPTIONS.initializeVault,
                txHash: initializeVaultTxHash,
              },
            ]}
            currentStep={currentTxStep}
            success={SuccessMessageContainer}
            error={txError && <BlockExplorerErrorCentered error={txError} />}
          />
        </Right>
      </Content>
    </Container>
  )
}

const Container = styled(VaultFormCard)`
  display: flex;
  padding-bottom: 40px;
`

const ComingSoonBanner = styled.div`
  color: ${(props) => props.theme.colors.text.primary};
  font-weight: 600;
  font-size: 20px;
  position: absolute;
  top: 350px;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`

const BannerLink = styled(A)`
  font-weight: 600;
  font-size: 20px;
`

const Content = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  justify-content: space-around;
  ${mediaQuery('medium')} {
    flex-direction: column;

    padding-top: 0px;
  }

  ${(props) =>
    props.disabled &&
    css`
      * {
        opacity: 0.45 !important;
        cursor: not-allowed;
        pointer-events: none;
      }
    `}
`

const Left = styled.div``

const Right = styled.div``

const H2 = styled.h2`
  font-weight: 500;
  font-size: 17px;
  color: ${(props) => props.theme.colors.text.primary};
  display: flex;
  justify-content: space-between;
  max-width: 430px;
`

const H3 = styled.h3`
  font-weight: 500;
  font-size: 15px;
  color: ${(props) => props.theme.colors.text.secondary};
`

const FixedSideCapacityH3 = styled(H3)`
  display: flex;
  align-items: center;
`

const SwapButton = styled(SmallButton)`
  width: 76px;
`

const Section = styled.div<{ ref?: any }>``

const ButtonSection = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const Inputs = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
`

const LoadingWrapper = styled(Loading)`
  margin: 30px auto 0;
`

const ToolTip = styled(InfoTooltip)`
  span {
    width: 200px;
    ${mediaQuery('small')} {
      width: 150px;
    }
  }
`

const LinkWrapper = styled(Link)`
  color: ${(props) => props.theme.colors.semantic.link};
  text-decoration: underline;
  font-weight: 400;
  font-size: 14px;

  &:hover {
    color: ${(props) => props.theme.colors.text.primary};
  }
`

const ErrorMessageWrapper = styled(ErrorMessage)`
  padding-top: 10px;
  max-width: 450px;
  overflow: hidden;
`

const SuccessMessageWrapper = styled(SuccessMessage)`
  flex-direction: column;
  align-items: baseline;
  display: flex;
  gap: 10px;
  max-width: 450px;
  overflow: hidden;
`

const BlockExplorerErrorCentered = styled(Chains.BlockExplorerError)`
  text-align: center;
`

const SmallBigNumberInput = styled(BigNumberInput)`
  ${mediaQuery('small')} {
    max-width: 150px;
  }
`

const SelectTokenButton = styled(Tokens.SelectTokenButton)`
  width: 180px;
`

const UsdInputWrapper = styled(UsdInput)`
  max-width: 180px;
`

const Estimations = styled.div`
  color: ${(props) => props.theme.colors.text.primary};
  font-size: 14px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  width: 500px;
  ${mediaQuery('small')} {
    width: auto;
  }
`

const TokenEstimations = styled(Estimations)`
  width: auto;
`

const FixedEstimations = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
`

const TokenEstimation = styled.div`
  display: inline-flex;
  justify-content: space-between;
`

const Estimation = styled.div`
  display: flex;
`

const EstimationTitle = styled.div`
  font-weight: 500;
  font-size: 15px;
  color: ${(props) => props.theme.colors.text.secondary};
`

const EstimationKey = styled.div`
  color: ${(props) => props.theme.colors.text.secondary};
  padding-right: 5px;
`

const EstimationTable = styled.table`
  flex: 1;
  margin-left: -2px;
`
const EstimationTableRow = styled.tr``
const EstimationTableHeader = styled.th`
  text-align: start;
  color: ${(props) => props.theme.colors.text.secondary};
`
const EstimationTableData = styled.td``
const EstimationTableMetaData = styled.td`
  color: ${(props) => props.theme.colors.text.secondary};
`

const ToggleSection = styled.div`
  color: ${(props) => props.theme.colors.text.primary};
  font-size: 14px;
  display: flex;
  gap: 10px;
  align-items: center;
`

const ToggleWrapper = styled(Toggle)`
  label {
    background-color: ${(props) => props.theme.colors.backgrounds.tertiary};
    ${(props) =>
      props.checked &&
      css`
        background-color: rgb(37, 81, 169);
        &:before {
          background-color: ${(props) => props.theme.colors.text.primary} !important;
          ${(props) =>
            props.theme.type === 'light' &&
            css`
              background-color: ${(props) => props.theme.colors.text.button} !important;
            `}
        }
      `}
  }
`

const ConnectWalletButton = styled(Accounts.ConnectWalletButton)`
  margin-top: 20px;
  max-width: 200px;
`

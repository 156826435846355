import { CHAIN_ID } from '@packages/web3-react-plus'

interface CoinGeckoPlatform {
  ethereum?: string
  xdai?: string
  'binance-smart-chain'?: string
  solana?: string
  avalanche?: string
  'kucoin-community-chain'?: string
  'optimistic-ethereum'?: string
  'polygon-pos'?: string
  'arbitrum-one'?: string
}

export interface CoinGeckoToken {
  id: string
  symbol: string
  name: string
  platforms: CoinGeckoPlatform
}

export const CHAIN_ID_TO_COIN_GECKO_PLATFORM: { [key in CHAIN_ID]: keyof CoinGeckoPlatform } = {
  [CHAIN_ID.Ethereum]: 'ethereum',
  [CHAIN_ID.Ropsten]: 'ethereum',
  [CHAIN_ID.Rinkeby]: 'ethereum',
  [CHAIN_ID.Goerli]: 'ethereum',
  [CHAIN_ID.Kovan]: 'ethereum',
  [CHAIN_ID.Polygon]: 'polygon-pos',
  [CHAIN_ID.KCC]: 'kucoin-community-chain',
  [CHAIN_ID.Arbitrum]: 'arbitrum-one',
  [CHAIN_ID.ArbitrumGoerli]: 'arbitrum-one',
}

import { BigNumber } from 'ethers'
import { Web3Provider } from '@ethersproject/providers'

import { ERC20__factory } from '@packages/contract-types'
import { MAX_UINT256 } from '@packages/web3-react-plus'
import { Tokens } from '@packages/ui'

export async function tokenApproveAllowance({
  ownerAddress,
  spenderAddress,
  token,
  desiredAmount,
  amountToApprove = MAX_UINT256,
  web3Provider,
  setLoadingText,
  smallerLoadingText = false,
}: {
  ownerAddress?: string
  spenderAddress: string
  token: Tokens.Token
  desiredAmount: BigNumber
  amountToApprove?: BigNumber | string
  web3Provider?: Web3Provider
  setLoadingText?: (text: string) => void
  smallerLoadingText?: boolean
}) {
  if (!web3Provider || !ownerAddress) return

  const tokenContract = ERC20__factory.connect(token.address, web3Provider.getSigner())
  const allowance = await tokenContract.allowance(ownerAddress, spenderAddress)

  if (desiredAmount.gt(allowance)) {
    if (setLoadingText) {
      setLoadingText('Approving...')
      setLoadingText(smallerLoadingText ? 'Approving...' : `Approving ${token.symbol}...`)
    }
    const approveTx = await tokenContract.approve(spenderAddress, amountToApprove)
    await approveTx.wait()
    return approveTx
  }
}

import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { Header, Chains, mediaQuery, Button, RouterLink, Loading } from '@packages/ui'
import { useWeb3ReactPlus } from '@packages/web3-react-plus'

import { SearchAndFilterVaults, Vaults, useNextVaultId, useVaultInfos } from 'src/vaults'
import { SUPPORTED_CHAIN_IDS, SUPPORTED_TESTNET_CHAIN_IDS } from 'src/config'

import { Routes } from '../constants'

const MAX_VAULTS_PER_PAGE = 4

export function DashboardPage() {
  const [previousChainId, setPreviousChainId] = useState(0)
  const { chainId } = useWeb3ReactPlus()
  const nextVaultId = useNextVaultId()

  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0)

  useEffect(() => {
    if (nextVaultId > 1 && itemOffset === 0) {
      setItemOffset(nextVaultId - 1)
    }
  }, [nextVaultId])

  useEffect(() => {
    if (previousChainId !== chainId) {
      setPreviousChainId(chainId)
      if (nextVaultId > 0) {
        setItemOffset(0)
      }
    }
  }, [chainId])

  const endOffset = itemOffset > 0 ? itemOffset - MAX_VAULTS_PER_PAGE + 1 : itemOffset

  // Invoke when user click to request another page.
  const loadMore = () => {
    const newItemOffset = endOffset - 1

    if (newItemOffset - MAX_VAULTS_PER_PAGE > 0) {
      setItemOffset(newItemOffset)
    } else {
      setItemOffset(MAX_VAULTS_PER_PAGE)
    }
  }

  const vaultInfos = useVaultInfos({
    fromVaultId: endOffset,
    toVaultId: itemOffset,
    chainId,
  })

  if (
    typeof vaultInfos === 'undefined' ||
    typeof vaultInfos?.isFetching === 'undefined' ||
    vaultInfos?.isFetching
  ) {
    return <Loading />
  }

  return (
    <Container>
      <HeaderWrapper
        title='Vaults'
        subtitle={
          <Chains.SelectedChainHeader
            supportedChainIds={SUPPORTED_CHAIN_IDS}
            supportTestnetChainIds={SUPPORTED_TESTNET_CHAIN_IDS}
          />
        }
        buttonContent={
          <RouterLink to={Routes.CREATE_VAULT}>
            <Button>Create Vault</Button>
          </RouterLink>
        }
        rightContent={<SearchAndFilterVaults vaultInfos={vaultInfos} />}
      />
      <Vaults vaultInfos={vaultInfos} />
      <ButtonContainer>
        {itemOffset > MAX_VAULTS_PER_PAGE && (
          <LoadButton onClick={loadMore} loading={vaultInfos?.isFetching}>
            Load More
          </LoadButton>
        )}
      </ButtonContainer>
    </Container>
  )
}

const HeaderWrapper = styled(Header)`
  padding: 20px 0;
  ${mediaQuery('small')} {
    padding: 10px 0;
    & > div:nth-child(2) {
      width: 100%;
      padding: 0px;
      padding-top: 20px;
    }
  }
`

const Container = styled.div`
  width: 100%;

  .pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    color: white;
    justify-content: center;
    width: 100%;

    .page-item.active .page-link {
      z-index: 3;
      color: #fff;
      background-color: #0d6efd;
      border-color: #0d6efd;
    }

    .page-item:not(:first-child) .page-link {
      margin-left: -1px;
    }

    .page-link {
      cursor: pointer;
      padding: 0.375rem 0.75rem;
    }
  }
`

export const CreateVaultButton = styled(Link)`
  cursor: pointer;
  width: 100%;
  color: ${(props) => props.theme.colors.text.primary};
`

const ButtonContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LoadButton = styled(Button)``

import styled, { css } from 'styled-components'

import { CSS_TRANSITION } from '../../styles'

export const InputStyles = css`
  ${CSS_TRANSITION}
  flex: 1;
  color: ${(props) => props.theme.colors.text.primary};
  font-weight: 500;
  font-family: 'Lexend', sans-serif;
  background-color: ${(props) => props.theme.colors.backgrounds.tertiary};
  border: none;
  border-radius: 8px;
  max-width: 339px;
  height: 45px;
  padding-left: 15px;
  ::placeholder {
    color: ${(props) => props.theme.colors.text.primary};
    font-family: 'Lexend', sans-serif;
    font-size: 14px;
    font-weight: 400;
  }

  @supports (-moz-appearance: none) {
    &::placeholder {
      color: ${(props) => props.theme.colors.text.primary};
      opacity: 1;
    }
  }

  :focus::placeholder {
    color: transparent;
  }

  /* clears the 'X' from Internet Explorer */
  ::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  ::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  /* clears the 'X' from Chrome */
  ::-webkit-search-decoration,
  ::-webkit-search-cancel-button,
  ::-webkit-search-results-button,
  ::-webkit-search-results-decoration {
    display: none;
  }
`

export const TextInput = styled.input<{ disabled?: boolean }>`
  ${InputStyles}

  ${(props) =>
    props.disabled &&
    ` 
    color: ${props.theme.colors.text.secondary};
    ::placeholder {
      color: ${props.theme.colors.text.secondary};
    }
  
    @supports (-moz-appearance: none) {
      &::placeholder {
        color: ${props.theme.colors.text.secondary};
      }
    }
    `}
`

import styled from 'styled-components'

import { hexToRgbString, mediaQuery, RouterLink } from '@packages/ui'
import ArrowIcon from '@packages/ui/src/shared/assets/images/grey-left-arrow.svg'

import { Routes } from '../../constants'

interface Props {
  subheader: string
  rightContent?: React.ReactNode
}

export function PageHeader({ subheader, rightContent }: Props) {
  return (
    <Header>
      <LeftHeader>
        <BackButton to={Routes.VAULTS}>
          <Arrow src={ArrowIcon} />
        </BackButton>
        <Title>
          <DashboardLink to={Routes.VAULTS}>
            Dashboard / <Subheader>{subheader}</Subheader>
          </DashboardLink>
        </Title>
      </LeftHeader>
      {rightContent}
    </Header>
  )
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Arrow = styled.img``

const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: ${(props) => props.theme.colors.text.secondary};
`

const Subheader = styled.span`
  color: ${(props) => props.theme.colors.text.primary};
`

const LeftHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 10px;
  ${mediaQuery('small')} {
    padding-top: 5px;
  }
`

const BackButton = styled(RouterLink)`
  background-color: ${(props) => props.theme.colors.backgrounds.secondary};
  width: 43px;
  height: 32px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: ${(props) => hexToRgbString(props.theme.colors.backgrounds.secondary, 0.6)};
  }
`

const DashboardLink = styled(RouterLink)`
  color: ${(props) => props.theme.colors.text.secondary};
`

import styled from 'styled-components'
import { useState } from 'react'

import {
  A,
  InfoTooltip,
  isMobile,
  Pools,
  Uniswap,
  mediaQuery,
  formatBps,
  formatUsd,
  TextToggle,
} from '@packages/ui'
import { tickToPrice } from '@packages/uniswap'
import { BIG_NUMBER_ZERO } from '@packages/bn'
import { CHAIN_ID, useBlock } from '@packages/web3-react-plus'

import {
  UNISWAP_DESCRIPTIONS,
  UNI_V3_VAULT_DESCRIPTIONS,
  VAULT_DESCRIPTIONS,
} from '../../constants'
import { formatApr, formatFixedCapacity } from '../../utils'
import { UniV3Adapter, Vault } from '../../interfaces'
import { InfoItem, LearnLink, Metric, VaultOverview } from './VaultOverview'
import {
  getVaultTimeData,
  VariablePnLTooltip,
  VariableCapacityValue,
  FixedAprTooltip,
  FixedCapacityTooltip,
  VaultStatusInfoBlock,
  VaultStatusInfoRow,
  VaultTimeInfoBlock,
} from '../VaultCardInfo'

interface Props {
  vault: Vault
  adapter: UniV3Adapter
}

export function UniV3VaultOverview({ vault, adapter }: Props) {
  const [selectedTokenSymbol, setSelectedTokenSymbol] = useState<string>(adapter.pool.token0.symbol)
  const block = useBlock()
  const {
    pool: { token0, token1 },
  } = adapter

  const { value: vaultTimeValue, header: vaultTimeHeader } = getVaultTimeData({
    vault,
    blockTimestamp: block?.timestamp,
    active: false,
  })

  const metrics: Metric[] = [
    {
      header: 'Status',
      value: vault.status,
      content: (
        <VaultStatusInfoBlockWrapper
          header='Status'
          value={vault.status}
          vault={vault}
          key='status'
        />
      ),
      mobileContent: <VaultStatusInfoRow status={vault.status} key='status' />,
    },
    {
      header: 'Variable PnL',
      value:
        vault.rates?.variable.earningsRate === '-'
          ? '-'
          : `${formatUsd(vault.rates?.variable.usdEarnings ?? 0)} (${formatApr(
              vault.rates?.variable.earningsRate
            )})`,
      infoTooltip: <VariablePnLTooltip vault={vault} adapter={adapter} />,
    },
    {
      header: 'Fixed APR',
      value: formatApr(vault.rates?.fixed.apr),
      infoTooltip: <FixedAprTooltip vault={vault} adapter={adapter} />,
    },
    {
      header: vaultTimeHeader,
      value: vaultTimeValue,
      content: <VaultTimeInfoBlockWrapper vault={vault} key='time' />,
    },
    {
      header: 'Variable Capacity',
      value: <VariableCapacityValue vault={vault} />,
    },
    {
      header: 'Fixed Capacity',
      value: formatFixedCapacity(adapter.usdLiquidityValue ?? BIG_NUMBER_ZERO),
      infoTooltip: <FixedCapacityTooltip vault={vault} />,
    },
  ]

  const learnLinks: LearnLink[] = [
    // TODO: link to academy articles
  ]

  const isSorted = selectedTokenSymbol === adapter.pool.token0.symbol

  let prices = [0, 0]
  if (adapter && !adapter.isFullRange) {
    const ticks = [
      isSorted ? adapter.minTick ?? 0 : -(adapter.maxTick ?? 0),
      isSorted ? adapter.maxTick ?? 0 : -(adapter.minTick ?? 0),
    ]
    const {
      pool: { token0, token1 },
    } = adapter
    const minPrice = tickToPrice({
      tick: ticks[0],
      token0Decimals: isSorted ? token0.decimals : token1.decimals,
      token1Decimals: isSorted ? token1.decimals : token0.decimals,
    })
    const maxPrice = tickToPrice({
      tick: ticks[1],
      token0Decimals: isSorted ? token0.decimals : token1.decimals,
      token1Decimals: isSorted ? token1.decimals : token0.decimals,
    })
    prices = [minPrice, maxPrice].sort((a, b) => a - b)
  }

  const about: InfoItem[] = [
    {
      title: '',
      summary: [UNI_V3_VAULT_DESCRIPTIONS.summary],
      content: [],
    },
    {
      title: 'Performance Protocol Fee',
      summary: [VAULT_DESCRIPTIONS.performanceProtocolFee(formatBps(vault.feeBps))],
      content: [],
    },
    ...(adapter
      ? [
          {
            title: (
              <UniswapPositionDetailsTitle>
                <h4>Uniswap Position Details</h4>
                <TextToggle
                  onChange={(tokenSymbol) => setSelectedTokenSymbol(tokenSymbol)}
                  option1={adapter.pool.token0.symbol}
                  option2={adapter.pool.token1.symbol}
                  selected={selectedTokenSymbol}
                />
              </UniswapPositionDetailsTitle>
            ),
            style: 'none',
            content: [
              ...(adapter.isFullRange
                ? [
                    <span key='uniswapPriceRange'>
                      <Bold>
                        Full Range Position{' '}
                        <InfoTooltip
                          content={
                            <ToolTipContent>{UNISWAP_DESCRIPTIONS.fullRange}</ToolTipContent>
                          }
                        />
                      </Bold>
                    </span>,
                  ]
                : []),
              ...(!adapter.isFullRange
                ? [
                    <Uniswap.PriceRange
                      poolAddress={adapter?.pool.address}
                      minPrice={prices[0]}
                      maxPrice={prices[1]}
                      isSorted={isSorted}
                    />,
                    <Uniswap.PriceRangeStatus
                      poolAddress={adapter?.pool.address}
                      minPrice={prices[0]}
                      maxPrice={prices[1]}
                      isSorted={isSorted}
                    />,
                    <Uniswap.PriceRangeDisplay
                      poolAddress={adapter?.pool.address}
                      minPrice={prices[0]}
                      maxPrice={prices[1]}
                      token0={token0}
                      token1={token1}
                      isSorted={isSorted}
                    />,
                  ]
                : []),
              <Links>
                {adapter.tokenId !== 0 && !vault.earningsSettled && (
                  <Link
                    href={Uniswap.getUniswapNFTUrl(adapter?.tokenId)}
                    key='uniswapNFTPosition'
                    useIcon
                  >
                    Uniswap Position NFT
                  </Link>
                )}
                <Link
                  href={Uniswap.getUniswapInfoUrl(vault.chainId as CHAIN_ID, adapter.pool.address)}
                  key='uniswapPool'
                  useIcon
                >
                  Uniswap {token0.symbol}/{token1.symbol} Pool
                </Link>
              </Links>,
            ],
          } as InfoItem,
        ]
      : []),
  ]

  const instructions: InfoItem[] = [
    {
      title: 'Fixed Side',
      style: 'number',
      summary: [UNI_V3_VAULT_DESCRIPTIONS.fixedSide.summary],
      content: UNI_V3_VAULT_DESCRIPTIONS.fixedSide.instructions,
    },
    {
      title: 'Variable Side',
      style: 'number',
      summary: [UNI_V3_VAULT_DESCRIPTIONS.variableSide.summary(vault.variableAsset?.symbol)],
      content: UNI_V3_VAULT_DESCRIPTIONS.variableSide.instructions,
    },
  ]

  return (
    <VaultOverview
      vault={vault}
      icon={
        <PoolIconWrapper
          tokenSymbol0={token0.symbol}
          tokenSymbol1={token1.symbol}
          size={isMobile() ? 40 : 55}
          platform='Uniswap v3'
        />
      }
      contractAddress={vault.address}
      metrics={metrics}
      learnLinks={learnLinks}
      about={about}
      instructions={instructions}
    />
  )
}

const VaultTimeInfoBlockWrapper = styled(VaultTimeInfoBlock)`
  width: 33% !important;
  div > span:nth-child(2) {
    font-size: 15px;
  }
`

const VaultStatusInfoBlockWrapper = styled(VaultStatusInfoBlock)`
  width: 33%;
  div > span:nth-child(2) {
    font-size: 15px;
  }
`

const UniswapPositionDetailsTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    font-size: 14px;
  }
  h4 {
    margin-block-start: 0px;
    margin-block-end: 10px;
  }
  ${mediaQuery('medium')} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
  }
`

const PoolIconWrapper = styled(Pools.PoolIcon)`
  background-color: ${(props) => props.theme.colors.backgrounds.primary};
  padding: 16px 26px;
  border-radius: 6px;
  > img {
    margin-left: -10px;
  }

  ${mediaQuery('small')} {
    padding: 10px 20px;
  }
`

const ToolTipContent = styled.div`
  width: 150px;
  font-weight: 400;
`

const Bold = styled.span`
  font-weight: 700;
`

const Link = styled(A)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`

const Links = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 14px;
  max-width: 400px;
  justify-content: space-between;

  ${mediaQuery('small')} {
    gap: 30px;
    justify-content: flex-start;
  }
`

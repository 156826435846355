import { Web3Provider } from '@ethersproject/providers'
import { useEffect } from 'react'
import { BigNumber } from 'ethers'

import { VaultFactory__factory } from '@packages/contract-types'
import { useWeb3ReactPlus } from '@packages/web3-react-plus'

import { VAULT_CONFIG } from 'src/config'
import { AppStateActionName, AppStateDispatch, useAppStateReducer } from 'src/AppState'

import { VaultInfo } from '../interfaces'
import { useNextVaultId } from './useNextVaultId'

const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export function useVaultInfos({
  fromVaultId,
  toVaultId,
  chainId,
}: {
  fromVaultId: number
  toVaultId: number
  chainId: number
}) {
  const { provider } = useWeb3ReactPlus()
  const [appState, dispatch] = useAppStateReducer()
  const nextVaultId = useNextVaultId()

  useEffect(() => {
    getVaultInfos({ chainId, dispatch, web3Provider: provider, fromVaultId, toVaultId })
  }, [chainId, dispatch, provider, nextVaultId, fromVaultId, toVaultId])

  return appState.vaults[chainId]?.info
}

async function getVaultInfos({
  web3Provider,
  chainId,
  dispatch,
  fromVaultId,
  toVaultId,
}: {
  web3Provider?: Web3Provider
  chainId: number
  dispatch: AppStateDispatch
  fromVaultId: number
  toVaultId: number
}) {
  if (!web3Provider || !VAULT_CONFIG[chainId]) return

  dispatch({
    type: AppStateActionName.UpdateVaultsInfo,
    payload: { isFetching: true, chainId },
  })

  const vaultFactory = VaultFactory__factory.connect(
    VAULT_CONFIG[chainId].factoryAddress,
    web3Provider
  )

  try {
    const promises: Promise<{
      info: {
        creatorAddress: string
        addr: string
        adapterAddress: string
        vaultTypeId: BigNumber
      }
      id: number
    }>[] = []
    for (let i = fromVaultId; i < toVaultId + 1; i++) {
      promises.push(
        new Promise((resolve) => vaultFactory.vaultInfo(i).then((info) => resolve({ info, id: i })))
      )
    }
    const adapters: { [address: string]: number } = {}

    const vaultInfosRaw = await Promise.all(promises)
    let vaultInfos: VaultInfo[] = await Promise.all(
      vaultInfosRaw.map(async (vault) => {
        let adapterTypeId: number = adapters[vault.info.adapterAddress]
        if (!adapterTypeId) {
          const adapterId = await vaultFactory.deployedAdapterAddrToId(vault.info.adapterAddress)
          const adapterInfo = await vaultFactory.deployedAdapterInfo(adapterId)
          adapterTypeId = adapterInfo.adapterTypeId.toNumber()
          adapters[vault.info.adapterAddress] = adapterTypeId
        }

        return {
          address: vault.info.addr,
          id: vault.id,
          creatorAddress: vault.info.creatorAddress,
          adapterAddress: vault.info.adapterAddress,
          vaultTypeId: vault.info.vaultTypeId.toNumber(),
          adapterTypeId,
        }
      })
    )

    vaultInfos = vaultInfos.filter((vault) => vault?.address !== ZERO_ADDRESS)

    dispatch({
      type: AppStateActionName.UpdateVaultsInfo,
      payload: { data: vaultInfos, chainId, isFetching: false },
    })
  } catch (error: any) {
    console.error(error)
    dispatch({
      type: AppStateActionName.UpdateVaultsInfo,
      payload: { error, chainId, isFetching: false },
    })
  }
}

import styled from 'styled-components'

import { ChainInfo } from '@packages/web3-react-plus'

import { A } from '../../shared'
import { getPlatformLiquidityUrl, getPlatformUrlText } from '../utils'
import { Token } from '../interfaces'
import { Platform } from '../constants'

interface Props {
  platform: Platform
  chainInfo: ChainInfo
  underlyingToken0?: Token
  underlyingToken1?: Token
}

export function PlatformLink({ platform, chainInfo, underlyingToken0, underlyingToken1 }: Props) {
  return (
    <Link
      useIcon
      href={getPlatformLiquidityUrl(platform, chainInfo, underlyingToken0, underlyingToken1)}
    >
      {getPlatformUrlText(platform)}.
    </Link>
  )
}

const Link = styled(A)`
  color: ${(props) => props.theme.colors.semantic.link};
  text-decoration: underline;
`

import JSBI from 'jsbi'

const Q96 = JSBI.exponentiate(JSBI.BigInt(2), JSBI.BigInt(96))

export const JSBI_CONSTANTS = {
  ZERO: JSBI.BigInt(0),
  ONE: JSBI.BigInt(1),
  Q32: JSBI.exponentiate(JSBI.BigInt(2), JSBI.BigInt(32)),
  Q96,
  Q192: JSBI.exponentiate(Q96, JSBI.BigInt(2)),
  MaxUint256: JSBI.BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'),
}

import { BigNumber } from 'ethers'
import { Web3Provider } from '@ethersproject/providers'

import { Vault__factory } from '@packages/contract-types'
import { getDeployCapitalData } from '@packages/uniswap'
import { Uniswap } from '@packages/ui'

import { UniV3Adapter, Vault } from '../interfaces'
import { VAULT_SIDE } from '../constants'

export async function depositUniV3Fixed({
  web3Provider,
  vault,
  adapter,
  pool,
  blockTimestamp,
  slippagePercent,
  expireMs,
}: {
  vault: Vault
  adapter: UniV3Adapter
  pool: Uniswap.UniswapV3Pool
  web3Provider: Web3Provider
  blockTimestamp: number
  slippagePercent: number
  expireMs: number
}) {
  const vaultContract = Vault__factory.connect(vault.address, web3Provider.getSigner())

  return await vaultContract.deposit(
    0,
    VAULT_SIDE.FIXED,
    getDeployCapitalData({
      provider: web3Provider,
      vaultContract,
      blockTimestamp,
      slippagePercent,
      expireMs,
      pool: {
        tickSpacing: pool.tickSpacing,
        tickLower: adapter.minTick,
        tickUpper: adapter.maxTick,
        sqrtRatioX96: Number(pool.sqrtPrice),
      },
    })
  )
}

export async function depositUniV3Variable({
  web3Provider,
  vault,
  amount,
}: {
  vault: Vault
  web3Provider: Web3Provider
  amount: BigNumber
}) {
  const vaultContract = Vault__factory.connect(vault.address, web3Provider.getSigner())

  return await vaultContract.deposit(amount, VAULT_SIDE.VARIABLE, [])
}

import styled from 'styled-components'
import { useEffect, useState } from 'react'

import { TextInput } from './TextInput'
import { StylingProps } from '../../styles'
import { validateFloat } from './validate'

interface Props extends StylingProps {
  value?: number
  onChange: (value?: number) => void
  placeholder?: string
  precision?: number
}

export function NumberInput({
  value,
  onChange,
  placeholder = '0.0',
  precision = 2,
  className,
}: Props) {
  const [inputAmount, setInputAmount] = useState('')

  useEffect(() => {
    if (value) {
      validateFloat({
        value: `${value}`,
        setLocalValue: setInputAmount,
        setParentValue: (value) => onChange(value ?? undefined),
        precision,
      })
    } else {
      setInputAmount('')
    }
  }, [value])

  function onInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target
    const updatedValue = validateFloat({
      value,
      setLocalValue: setInputAmount,
      setParentValue: (value) => onChange(value ?? undefined),
      precision,
    })
    if (!updatedValue) return

    onChange(parseFloat(updatedValue))
  }

  return (
    <AmountInput
      className={className}
      value={inputAmount}
      onChange={onInputChange}
      placeholder={placeholder}
      data-test-id='number-input'
    />
  )
}

const AmountInput = styled(TextInput)`
  background-color: ${(props) => props.theme.colors.backgrounds.tertiary};
  max-width: 250px;
`

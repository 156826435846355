import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { Loading } from '@packages/ui'
import { useWeb3ReactPlus } from '@packages/web3-react-plus'

import { useVaultInfo, UniswapVaultDetails } from 'src/vaults'

export function VaultDetailsPage() {
  const { chainId } = useWeb3ReactPlus()
  const urlParams = useParams()
  const urlChainId = parseInt(urlParams.chainId || '0')
  const vaultId = parseInt(urlParams.vaultId || '0')
  const [vaultInfo, loading] = useVaultInfo(vaultId, urlChainId)

  if (!vaultInfo && loading && chainId === urlChainId) {
    return <Loading />
  }

  if (!vaultInfo || chainId !== urlChainId) {
    return <Container>No vault data available</Container>
  }

  return <UniswapVaultDetails vaultInfo={vaultInfo} />
}

const Container = styled.div`
  color: ${(props) => props.theme.colors.text.primary};
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-weight: 600;
  font-size: 18px;
  padding: 100px 0px;
`

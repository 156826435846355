export function updateTokenPriceInfoBySymbol<T>(
  tokensBySymbol: {
    [tokenSymbol: string]: T
  },
  token: T & { id: string; symbol: string }
) {
  if (token.id === 'ethereum') {
    tokensBySymbol['ETH'] = token
    tokensBySymbol['WETH'] = token
  } else if (token.id === 'usd-coin') {
    tokensBySymbol['USDC'] = token
  } else if (
    token.symbol.toUpperCase() !== 'ETH' &&
    token.symbol.toUpperCase() !== 'WETH' &&
    token.symbol.toUpperCase() !== 'USDC'
  ) {
    tokensBySymbol[token.symbol.toUpperCase()] = token
  }
}

import { Routes as ReactRouterRoutes, Route } from 'react-router-dom'

import { Routes } from '../constants'
import { CreateVaultPage } from './CreateVaultPage'
import { DashboardPage } from './DashboardPage'
import { VaultDetailsPage } from './VaultDetailsPage'

export function PageRoutes() {
  return (
    <ReactRouterRoutes>
      <Route path={Routes.VAULTS} element={<DashboardPage />} />
      <Route path={Routes.CREATE_VAULT} element={<CreateVaultPage />} />
      <Route
        path={Routes.VAULT_DETAILS.route + Routes.VAULT_DETAILS.param}
        element={<VaultDetailsPage />}
      />
    </ReactRouterRoutes>
  )
}

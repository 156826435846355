import { useWeb3ReactPlus, CHAIN_ID, CHAIN_INFO } from '@packages/web3-react-plus'

import { ReactComponent as EtherscanLogo } from '../assets/images/etherscan.svg'
import { ReactComponent as PolygonLogo } from '../assets/images/polygon.svg'
import { ReactComponent as ArbitrumLogo } from '../assets/images/arbitrum.svg'
import { ReactComponent as KCCLogo } from '../assets/images/kcc-white.svg'

export function useBlockExplorerIcon() {
  const { chainId } = useWeb3ReactPlus()

  const chainInfo = CHAIN_INFO[chainId as CHAIN_ID]

  switch (chainInfo?.explorer.name) {
    case 'Polygonscan':
      return PolygonLogo
    case 'KCC Explorer':
      return KCCLogo
    case 'Arbiscan':
      return ArbitrumLogo
    default:
      return EtherscanLogo
  }
}

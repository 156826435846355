import { BigNumber } from 'ethers'
import { Web3Provider } from '@ethersproject/providers'

import { VaultFactory__factory } from '@packages/contract-types'
import {
  encodeUniV3InitData,
  getFullRangeLiquidity,
  getRangedLiquidityViaTick,
} from '@packages/uniswap'

import { VAULT_CONFIG } from 'src/config'

interface VaultParams {
  variableTokenAddress: string
  variableCapacity: BigNumber
  durationSeconds: number
}

interface AdapterParams {
  poolAddress: string
  poolTickSpacing: number
  totalUsdDesiredAmount: number
  currentPrice: number
  currentTick: number
  token0Decimals: number
  token1Decimals: number
  token0UsdPrice: number
  token1UsdPrice: number
}

export interface CreateVaultResponse {
  vaultId: number
  adapterAddress: string
  vaultAddress: string
}

export interface CreateVaultSetters {
  setStep: (index: number) => void
  setCreateAdapterTxHash: (txHash: string) => void
  setCreateVaultTxHash: (txHash: string) => void
  setInitializeVaultTxHash: (txHash: string) => void
}

export async function createUniV3FullRangeVault({
  adapter,
  vault,
  setters,
  chainId,
  web3Provider,
}: {
  adapter: AdapterParams
  vault: VaultParams
  setters: CreateVaultSetters
  chainId: number
  web3Provider: Web3Provider
}): Promise<CreateVaultResponse> {
  const liquidityParams = await getFullRangeLiquidity({
    ...adapter,
    totalUsdDesiredAmount: adapter.totalUsdDesiredAmount,
    currentPrice: adapter.currentPrice,
    token0UsdPrice: adapter.token0UsdPrice,
    token1UsdPrice: adapter.token1UsdPrice,
  })

  return await createVault({
    vault: {
      typeId: VAULT_CONFIG[chainId].vaultTypeIds.uniV3,
      params: vault,
      fixedCapacityLiquidity: liquidityParams.liquidity,
    },
    adapter: {
      typeId: VAULT_CONFIG[chainId].adapterTypeIds.fullRange,
      params: { data: [], poolAddress: adapter.poolAddress },
    },
    setters,
    chainId,
    web3Provider,
  })
}

export async function createUniV3LimitedRangeVaultViaTick({
  adapter,
  vault,
  setters,
  chainId,
  web3Provider,
}: {
  adapter: AdapterParams & {
    tickLower: number
    tickUpper: number
  }
  vault: VaultParams
  setters: CreateVaultSetters
  chainId: number
  web3Provider: Web3Provider
}): Promise<CreateVaultResponse> {
  const liquidityParams = await getRangedLiquidityViaTick({
    ...adapter,
    totalUsdDesiredAmount: adapter.totalUsdDesiredAmount,
    lowerTick: adapter.tickLower,
    upperTick: adapter.tickUpper,
  })

  if (liquidityParams.liquidity.lte(0)) {
    throw new Error('Unable to calculate fixed side capacity liquidity value')
  } else if (liquidityParams.tickLower >= liquidityParams.tickUpper) {
    const tickUpper = liquidityParams.tickUpper
    const tickLower = liquidityParams.tickLower
    liquidityParams.tickLower = tickUpper
    liquidityParams.tickUpper = tickLower
  }

  return await createVault({
    vault: {
      typeId: VAULT_CONFIG[chainId].vaultTypeIds.uniV3,
      params: vault,
      fixedCapacityLiquidity: liquidityParams.liquidity,
    },
    adapter: {
      typeId: VAULT_CONFIG[chainId].adapterTypeIds.limitedRange,
      params: {
        data: encodeUniV3InitData(liquidityParams.tickLower, liquidityParams.tickUpper),
        poolAddress: adapter.poolAddress,
      },
    },
    setters,
    chainId,
    web3Provider,
  })
}

interface CreateVaultProps {
  vault: {
    typeId: number
    params: VaultParams
    fixedCapacityLiquidity: BigNumber
  }
  adapter: {
    typeId: number
    params: {
      poolAddress: string
      data: any
    }
  }
  setters: CreateVaultSetters
  chainId: number
  web3Provider: Web3Provider
}

export async function createVault({
  setters,
  chainId,
  web3Provider,
  vault,
  adapter,
}: CreateVaultProps): Promise<CreateVaultResponse> {
  const vaultFactory = VaultFactory__factory.connect(
    VAULT_CONFIG[chainId].factoryAddress,
    web3Provider.getSigner()
  )

  // create adapter
  setters.setStep(0)
  const createAdapterTx = await vaultFactory.createAdapter(
    adapter.typeId,
    adapter.params.poolAddress,
    adapter.params.data
  )

  const createAdapterReceipt = await createAdapterTx.wait()
  setters.setCreateAdapterTxHash(createAdapterTx.hash)

  let adapterAddress
  // Find the newly created adapter address from factory log
  const createAdapterLog = vaultFactory.interface.parseLog(createAdapterReceipt.logs[0])
  if (createAdapterLog.name === 'AdapterCreated') adapterAddress = createAdapterLog.args.adapter

  console.log('adapterAddress', adapterAddress)
  console.log('vault.typeId', vault.typeId)

  // create vault
  setters.setStep(1)
  const createVaultTx = await vaultFactory.createVault(vault.typeId, adapterAddress)

  const createVaultReceipt = await createVaultTx.wait()
  setters.setCreateVaultTxHash(createAdapterTx.hash)

  let vaultId, vaultAddress
  // Find the newly added vault ID from factory log
  const createVaultLog = vaultFactory.interface.parseLog(createVaultReceipt.logs[0])
  if (createVaultLog.name === 'VaultCreated') {
    vaultId = createVaultLog.args.vaultId
    vaultAddress = createVaultLog.args.vault
  }

  console.log('vaultAddress', vaultAddress)
  console.log('vaultId', vaultId)

  // initialize vault
  setters.setStep(2)
  const initializeVaultTx = await vaultFactory.initializeVault(
    vaultId,
    vault.fixedCapacityLiquidity,
    vault.params.variableCapacity,
    vault.params.durationSeconds,
    vault.params.variableTokenAddress
  )

  await initializeVaultTx.wait()
  setters.setInitializeVaultTxHash(initializeVaultTx.hash)

  return { vaultId: (vaultId as BigNumber).toNumber(), adapterAddress, vaultAddress }
}

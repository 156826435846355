import styled, { useTheme } from 'styled-components'
import ReactModal from 'react-modal'

import { ReactComponent as CloseIcon } from '../assets/images/close-x.svg'
import { mediaQuery } from '../utils'

export interface ModalProps {
  isOpen: boolean
  onRequestClose: () => void
}

const TRANSITION_MS = 200

interface Props extends ModalProps {
  children: React.ReactNode
}

export function Modal({ isOpen, onRequestClose, children }: Props) {
  return (
    <ReactModal
      closeTimeoutMS={TRANSITION_MS + 17}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className='_'
      overlayClassName='_'
      ariaHideApp={false}
      contentElement={(props, children) => <ModalElement {...props}>{children}</ModalElement>}
      overlayElement={(props, contentElement) => (
        <ModalOverlayElement {...props} transitionMs={TRANSITION_MS}>
          {contentElement}
        </ModalOverlayElement>
      )}
    >
      {children}
    </ReactModal>
  )
}

const ModalElement = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 425px;
  background: ${(props) => props.theme.colors.backgrounds.secondary};
  color: ${(props) => props.theme.colors.text.primary};
  overflow: auto;
  border-radius: 10px;
  outline: none;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  padding: 25px;

  ${mediaQuery('small')} {
    width: 95%;
    max-width: 380px;
  }
`

const ModalOverlayElement = styled.div<{ transitionMs: number }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #eef0fdaf;
  z-index: 10;
  backdrop-filter: blur(2px);
  transition: opacity ${(props) => props.transitionMs}ms ease-out;

  &.ReactModal__Overlay {
    opacity: 0;
  }

  &.ReactModal__Overlay--after-open {
    opacity: 1;
  }

  &.ReactModal__Overlay--before-close {
    opacity: 0;
  }
`

export const ModalTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 26px;
`

export function ModalCloseIcon({ onRequestClose }: { onRequestClose: () => void }) {
  const theme = useTheme()
  return (
    <Close onClick={onRequestClose} data-test-id='modal-close'>
      <CloseIcon fill={theme.colors.icons} />
    </Close>
  )
}

const Close = styled.div`
  position: fixed;
  right: 1px;
  top: 2px;
  padding: 10px 15px;
  cursor: pointer;
`

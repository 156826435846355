import { useEffect } from 'react'

import uniswapTokenList from '@packages/web3-react-plus/src/assets/files/uniswap-tokens.json'
import {
  ApiTokenList,
  CHAIN_ID,
  CHAIN_INFO,
  isChainsTestnet,
  useWeb3ReactPlus,
} from '@packages/web3-react-plus'

import {
  AppState,
  AppStateActionName,
  AppStateDispatch,
  AsyncData,
  useAppStateReducer,
} from '../../AppState'
import { Token } from '../interfaces'

const EMPTY_TOKEN_LIST: Token[] = []

export function useTokens(): {
  list: AsyncData<Token[]>
  bySymbol: AsyncData<{
    [tokenSymbol: string]: Token
  }>
} {
  const { chainId } = useWeb3ReactPlus()
  const [appState, dispatch] = useAppStateReducer()

  useEffect(() => {
    getTokens({ appState, dispatch, chainId })
  }, [appState, dispatch, chainId])

  return appState[chainId]?.tokens
}

async function getTokens({
  chainId,
  dispatch,
  appState,
}: {
  chainId: number
  dispatch: AppStateDispatch
  appState: AppState
}) {
  if (
    appState[chainId]?.tokens.list?.isFetching ||
    appState[chainId]?.tokens.list?.error ||
    (appState[chainId]?.tokens.list?.data &&
      Object.keys(appState[chainId]?.tokens.list?.data ?? EMPTY_TOKEN_LIST).length !== 0)
  ) {
    return
  }
  try {
    if (!CHAIN_INFO[chainId as CHAIN_ID]) return

    dispatch({
      type: AppStateActionName.UpdateTokens,
      payload: { isFetching: true, chainId },
    })

    const tokenListInfo = CHAIN_INFO[chainId as CHAIN_ID].tokenList
    const url = tokenListInfo?.url
    const tokens = []

    if (tokenListInfo?.useUniswapTokenList) {
      tokens.push(...getUniswapTokens(chainId))
    }

    if (url) {
      const response = await fetch(url)
      const json: ApiTokenList = await response.json()
      tokens.push(...json.tokens)
      if (CHAIN_INFO[chainId as CHAIN_ID].tokenList.additionalTokens) {
        tokens.push(...(CHAIN_INFO[chainId as CHAIN_ID].tokenList.additionalTokens ?? []))
      }
      dispatch({
        type: AppStateActionName.UpdateTokens,
        payload: { data: filterTokens(tokens, chainId), chainId },
      })
    } else {
      dispatch({
        type: AppStateActionName.UpdateTokens,
        payload: {
          data: filterTokens(
            CHAIN_INFO[chainId as CHAIN_ID].tokenList.backupStaticList.tokens,
            chainId
          ),
          chainId,
        },
      })
    }
  } catch (error: any) {
    console.error(error)
    const tokenListInfo = CHAIN_INFO[chainId as CHAIN_ID].tokenList
    const tokens: Token[] = tokenListInfo.backupStaticList.tokens

    if (tokenListInfo?.useUniswapTokenList) {
      tokens.push(...getUniswapTokens(chainId))
    }

    dispatch({
      type: AppStateActionName.UpdateTokens,
      payload: {
        data: filterTokens(tokens, chainId),
        chainId,
      },
    })
  } finally {
    dispatch({
      type: AppStateActionName.UpdateTokens,
      payload: { isFetching: false, chainId },
    })
  }
}

function filterTokens(tokens: Token[], chainId: number) {
  const filtered = tokens.filter((token) => {
    if (token.chainId && (token.chainId === chainId || isChainsTestnet(token.chainId, chainId))) {
      return true
    }
  })
  return filtered
}

function getUniswapTokens(chainId: number) {
  const tokens: Token[] = []
  uniswapTokenList.tokens.forEach((token) => {
    const bridgeInfo = (token?.extensions?.bridgeInfo as any)?.[chainId]
    if (bridgeInfo && bridgeInfo.tokenAddress) {
      token.address = bridgeInfo.tokenAddress
      token.chainId = chainId
      tokens.push(token)
    }
  })
  return tokens
}

import styled from 'styled-components'
import { useState } from 'react'

import { useWeb3ReactPlus } from '@packages/web3-react-plus'

import { MenuIconDiv, MenuIconWrapper, NavBarItem } from '../../shared'
import { Address } from './Address'
import { AccountModal } from './AccountModal'
import { ConnectWalletButton } from './ConnectWalletButton'

export function AccountMenu() {
  const { activeAddress, provider } = useWeb3ReactPlus()
  const [accountModalOpen, setAccountModalOpen] = useState(false)

  function toggleAccountModal() {
    setAccountModalOpen(!accountModalOpen)
  }

  return (
    <>
      {activeAddress ? (
        <MenuIconWrapper onClick={toggleAccountModal}>
          <MenuIconDiv>
            <NavBarItem walletbg>
              <Address address={activeAddress ?? ''} provider={provider} />
            </NavBarItem>
          </MenuIconDiv>
        </MenuIconWrapper>
      ) : (
        <ConnectButton />
      )}

      <AccountModal isOpen={accountModalOpen} onRequestClose={toggleAccountModal} />
    </>
  )
}

export const ConnectButton = styled(ConnectWalletButton)`
  width: 162px;
  height: 32px;
  border: none;
  border-radius: 10px;

  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 1px;
`

import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  html,
  body {
    background-color: ${(props) => props.theme.colors.backgrounds.primary};
    padding: 0;
    margin: 0;
    font-family: "Lexend", sans-serif;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
  }

  body {
    padding: 0 0 200px;
  }

  a {
    text-decoration: none;
  }

  /*
    Use a more-intuitive box-sizing model.
  */
  *, *::before, *::after {
    box-sizing: border-box;
  }

  /*
    Improve media defaults
  */
  picture, video, canvas {
    display: block;
    max-width: 100%;
  }
  /*
    Remove built-in form typography styles
  */
  input, button, textarea, select {
    font: inherit;
  }
  /*
    Avoid text overflows
  */
  p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
  }
  /*
    Create a root stacking context
  */
  #root, #__next {
    isolation: isolate;
  }

  @font-face {
    font-family: "Work Sans";
    src: url(./shared/assets/fonts/WorkSans-VariableFont_wght.ttf)
      format("truetype");
    /* other formats include: 'woff2', 'truetype, 'opentype',
                                'embedded-opentype', and 'svg' */
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
`

import { Web3Provider } from '@ethersproject/providers'
import { useEffect } from 'react'

import { VaultFactory__factory } from '@packages/contract-types'
import { useWeb3ReactPlus } from '@packages/web3-react-plus'

import { AppStateActionName, AppStateDispatch, useAppStateReducer } from 'src/AppState'
import { VAULT_CONFIG } from 'src/config'

import { VaultFactory } from '../interfaces'

export function useVaultFactory() {
  const { provider, chainId } = useWeb3ReactPlus()
  const [appState, dispatch] = useAppStateReducer()

  useEffect(() => {
    getVaultFactory({ chainId, dispatch, web3Provider: provider })
  }, [chainId, dispatch, provider])

  return appState.vaultFactory[chainId] ?? undefined
}

async function getVaultFactory({
  web3Provider,
  chainId,
  dispatch,
}: {
  web3Provider?: Web3Provider
  chainId: number
  dispatch: AppStateDispatch
}) {
  if (!web3Provider || !VAULT_CONFIG[chainId]) return

  dispatch({
    type: AppStateActionName.UpdateVaultFactory,
    payload: { isFetching: true, chainId },
  })

  try {
    const vaultFactoryContract = VaultFactory__factory.connect(
      VAULT_CONFIG[chainId].factoryAddress,
      web3Provider
    )

    const [owner, feeBps, feeReceiver] = await Promise.all([
      vaultFactoryContract.owner(),
      vaultFactoryContract.feeBps(),
      vaultFactoryContract.feeReceiver(),
    ])

    const vaultFactory: VaultFactory = {
      owner,
      address: VAULT_CONFIG[chainId].factoryAddress,
      feeBps: feeBps.toNumber(),
      feeReceiver,
    }

    dispatch({
      type: AppStateActionName.UpdateVaultFactory,
      payload: { data: vaultFactory, chainId },
    })
  } catch (error: any) {
    console.error(error)
    dispatch({
      type: AppStateActionName.UpdateVaultFactory,
      payload: { error, chainId },
    })
  }
}

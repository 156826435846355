import styled from 'styled-components'
import { useState } from 'react'
import { AbstractConnector } from '@web3-react/abstract-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'

import {
  useWeb3ReactPlus,
  fortmatic,
  injected,
  walletconnect,
  walletlink,
} from '@packages/web3-react-plus'

import metamaskIcon from '../assets/images/wallets/metamask.svg'
import coinbaseWalletIcon from '../assets/images/wallets/coinbase-wallet.svg'
import walletConnectIcon from '../assets/images/wallets/wallet-connect.svg'
import fortmaticIcon from '../assets/images/wallets/fortmatic.svg'
import { isMobile, Modal, ModalCloseIcon, ModalProps, ModalTitle } from '../../shared'

interface SupportedWallet {
  name: string
  iconSvg: string
  connector: AbstractConnector
}

const supportedWallets: SupportedWallet[] = [
  ...(!isMobile()
    ? [
        {
          name: 'MetaMask',
          iconSvg: metamaskIcon,
          connector: injected,
        },
      ]
    : []),
  {
    name: 'Coinbase Wallet',
    iconSvg: coinbaseWalletIcon,
    connector: walletlink,
  },
  {
    name: 'WalletConnect',
    iconSvg: walletConnectIcon,
    connector: walletconnect,
  },
  ...(fortmatic
    ? [
        {
          name: 'Fortmatic',
          iconSvg: fortmaticIcon,
          connector: fortmatic,
        },
      ]
    : []),
]

export function ConnectWalletModal({ isOpen, onRequestClose }: ModalProps) {
  const { activate } = useWeb3ReactPlus()
  const [triedActivation, setTriedActivation] = useState<boolean>(false)

  if (triedActivation) {
    onRequestClose()
    setTriedActivation(false)
  }

  async function tryActivate(connector: AbstractConnector, _: () => void) {
    if (
      connector instanceof WalletConnectConnector &&
      (connector.walletConnectProvider as any)?.wc?.uri
    ) {
      connector.walletConnectProvider = undefined
    }

    try {
      await activate(connector)
      setTriedActivation(true)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <ModalTitle>Connect to a Wallet:</ModalTitle>
      <ModalCloseIcon onRequestClose={onRequestClose} />

      <WalletItems>
        {supportedWallets.map(({ name, iconSvg, connector }, i: number) => (
          <WalletItem key={`wallet-${i}`} onClick={() => tryActivate(connector, onRequestClose)}>
            <p>{name}</p>
            <div>
              <img alt={`Connect using ${name}`} src={iconSvg} />
            </div>
          </WalletItem>
        ))}
      </WalletItems>
    </Modal>
  )
}

const WalletItems = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  gap: 11px;
`

const WalletItem = styled.div`
  height: 60px;
  border: 3px solid ${(props) => props.theme.colors.backgrounds.tertiary};
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 16px;
  padding: 10px;
  cursor: pointer;

  transition: border ease 0.15s;

  &:hover {
    background-color: ${(props) => props.theme.colors.backgrounds.tertiary};
  }

  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-direction: row;
  align-items: center;

  div {
    width: 40px;
    text-align: center;
    display: flex;
    justify-content: center;
  }
`

import { BigNumber } from 'ethers'
import { useMemo } from 'react'

import { BIG_NUMBER_ZERO, bn } from '@packages/bn'
import { getBpsPercent, secondsToDays, Tokens, Uniswap } from '@packages/ui'

import {
  calculateEstimatedVariableRates,
  calculateFixedRates,
  calculateFixedUsdEarnings,
  isNonZeroAmount,
} from '../utils'
import { DEFAULT_VARIABLE_RATES, DEFAULT_RATES, Rates } from '../interfaces'
import { useVaultFactory } from './useVaultFactory'
import { USD_BN_DECIMALS } from '../constants'

export function useEstimatedRates({
  poolAddress,
  durationSeconds,
  fixedCapacityUsd,
  fixedTargetApr,
  variableSideCapacity,
  variableAsset,
}: {
  poolAddress?: string
  durationSeconds: number
  fixedCapacityUsd?: number
  fixedTargetApr?: number
  variableSideCapacity?: BigNumber
  variableAsset?: Tokens.Token
}) {
  const vaultFactory = useVaultFactory()
  const poolDayData = Uniswap.usePoolDayData(poolAddress ?? '')

  const { data: variableAssetPrice } = Tokens.useTokenPrice(variableAsset)

  const rates: Rates = useMemo(() => {
    if (
      variableAsset &&
      variableAssetPrice &&
      variableSideCapacity &&
      isNonZeroAmount(variableSideCapacity) &&
      fixedCapacityUsd &&
      durationSeconds > 0
    ) {
      const usdLiquidityValue = bn(`${fixedCapacityUsd}`, USD_BN_DECIMALS)
      const durationDays = secondsToDays(durationSeconds)

      let fixedRates, variableRates
      if (fixedTargetApr) {
        fixedRates = {
          fixedRate: fixedTargetApr * (durationDays / 365),
          apr: fixedTargetApr,
          usdEarnings: calculateFixedUsdEarnings({
            variableSideCapacity: variableSideCapacity ?? BIG_NUMBER_ZERO,
            variableAsset,
            variableAssetPrice,
          }),
        }
      } else {
        fixedRates = calculateFixedRates({
          usdLiquidityValue,
          variableSideCapacity,
          variableAsset,
          variableAssetPrice,
          durationDays,
        })
      }

      if (poolDayData.dayData && poolDayData.dayData.data.length > 0) {
        const feePercent = getBpsPercent(vaultFactory?.data?.feeBps ?? 0)

        variableRates = calculateEstimatedVariableRates({
          feePercent,
          uniswapDayData: poolDayData.dayData,
          fixedApr: fixedRates.apr,
          durationDays,
          fixedUsdCapacity: fixedCapacityUsd,
          variableUsdCapacity: fixedRates.usdEarnings,
        })
      }

      return {
        fixed: fixedRates,
        variable: variableRates ?? DEFAULT_VARIABLE_RATES,
      }
    }

    return DEFAULT_RATES
  }, [
    durationSeconds,
    variableAsset,
    variableAssetPrice,
    variableSideCapacity,
    fixedCapacityUsd,
    fixedTargetApr,
    poolDayData.dayData?.data,
  ])

  return rates
}

import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'
import styled, { css } from 'styled-components'
import { Web3Provider } from '@ethersproject/providers'

import '@szhsin/react-menu/dist/transitions/slide.css'
import '@szhsin/react-menu/dist/index.css'
import { isMetaMask } from '@packages/web3-react-plus'

import { StylingProps } from '../../shared'

interface Props extends StylingProps {
  address: string
  showFullAddress?: boolean
  provider?: Web3Provider
}

export function Address({ address, provider, className }: Props) {
  return (
    <Container data-test-id='account-address' className={className}>
      <JazziconContainer isMetaMask={isMetaMask(provider)}>
        <Jazzicon diameter={16} seed={jsNumberForAddress(address)} />
      </JazziconContainer>
      {address.substring(0, 6)}
      ...
      {address.substring(address.length - 4)}
    </Container>
  )
}

const Container = styled.span`
  display: flex;
  align-items: center;
`

const JazziconContainer = styled.div<{ isMetaMask: boolean }>`
  margin-right: 8px;
  margin-top: 5px;
  ${({ isMetaMask }) => {
    if (!isMetaMask) {
      return css`
        .paper {
          background-color: rgb(1, 142, 114) !important;
        }
        svg {
          opacity: 0;
        }
      `
    }
    return
  }}
`

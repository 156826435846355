import styled from 'styled-components'

import { CSS_TRANSITION } from '../styles'

export const DateLabel = styled.div`
  ${CSS_TRANSITION}
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => props.theme.colors.text.secondary};
  flex: 1;
  display: flex;
  justify-content: center;
  align-self: center;
`

import { Web3Provider } from '@ethersproject/providers'
import { useEffect, useState } from 'react'

import { isNullAddress, useWeb3ReactPlus } from '@packages/web3-react-plus'
import { ERC20__factory } from '@packages/contract-types'

import { Token } from '../interfaces'

const defaultToken: Token = {
  address: '',
  decimals: 18,
  symbol: '',
  name: '',
}

export function useToken(tokenAddress?: string) {
  const { provider } = useWeb3ReactPlus()
  const [token, setToken] = useState<Token>(defaultToken)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error>()

  useEffect(() => {
    _getToken({ setToken, setLoading, setError, tokenAddress, web3Provider: provider })
  }, [tokenAddress])

  return { token, loading, error }
}

async function _getToken({
  web3Provider,
  setToken,
  setLoading,
  setError,
  tokenAddress,
}: {
  web3Provider?: Web3Provider
  setToken: (token: Token) => void
  setLoading: (loading: boolean) => void
  setError: (error: Error) => void
  tokenAddress?: string
}) {
  if (!tokenAddress || isNullAddress(tokenAddress) || !web3Provider) return
  try {
    setLoading(true)

    const token = await getToken({ web3Provider, tokenAddress })

    setToken(token)
  } catch (error: any) {
    console.error(error)
    setError(error)
  } finally {
    setLoading(false)
  }
}

export async function getToken({
  web3Provider,
  tokenAddress,
}: {
  web3Provider: Web3Provider
  tokenAddress: string
}) {
  const tokenContract = ERC20__factory.connect(tokenAddress, web3Provider)

  const [decimals, name, symbol] = await Promise.all([
    tokenContract.decimals(),
    tokenContract.name(),
    tokenContract.symbol(),
  ])

  const token: Token = {
    address: tokenAddress,
    decimals,
    name,
    symbol,
  }

  return token
}

export async function getTotalSupply({
  web3Provider,
  tokenAddress,
}: {
  web3Provider: Web3Provider
  tokenAddress: string
}) {
  const tokenContract = ERC20__factory.connect(tokenAddress, web3Provider)

  const totalSupply = await tokenContract.totalSupply()

  return totalSupply
}

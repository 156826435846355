import { BIG_NUMBER_ZERO, bn, bnToFloat } from '@packages/bn'

import { UniswapV3PoolDayData } from '../interfaces'
import { queryUniswapGraph } from './queryUniswapGraph'

export const UNISWAP_DAY_DATA_PRECISION = 25

const EMPTY: UniswapV3PoolDayData = {
  data: [],
  averages: {
    30: { feesUSD: 0, tvlUSD: 0 },
    90: { feesUSD: 0, tvlUSD: 0 },
  },
}

export async function getPoolDayData(
  address: string,
  chainId: number
): Promise<UniswapV3PoolDayData> {
  const { pools } = await queryUniswapGraph(
    chainId,
    `{    
    pools(where: { id: "${address.toLowerCase()}" }) {
        poolDayData(orderBy: date, orderDirection: desc, first: 90, skip: 0) {
            date
            feesUSD
            tvlUSD
        }
    }
}`
  )

  if (pools.length !== 1) return EMPTY
  let tvlUSDTotal90Days = BIG_NUMBER_ZERO
  let feesUSDTotal90Days = BIG_NUMBER_ZERO
  let tvlUSDTotal30Days = BIG_NUMBER_ZERO
  let feesUSDTotal30Days = BIG_NUMBER_ZERO

  const daysData = pools[0].poolDayData.map((dayData: any, index: number) => {
    const feesUSD = bn(dayData.feesUSD, UNISWAP_DAY_DATA_PRECISION)
    const tvlUSD = bn(dayData.tvlUSD, UNISWAP_DAY_DATA_PRECISION)
    tvlUSDTotal90Days = tvlUSDTotal90Days.add(tvlUSD)
    feesUSDTotal90Days = feesUSDTotal90Days.add(feesUSD)
    if (index < 30) {
      tvlUSDTotal30Days = tvlUSDTotal30Days.add(tvlUSD)
      feesUSDTotal30Days = feesUSDTotal30Days.add(feesUSD)
    }
    return { ...dayData, feesUSD: parseFloat(dayData.feesUSD), tvlUSD: parseFloat(dayData.tvlUSD) }
  })

  return {
    data: daysData,
    averages: {
      30: {
        tvlUSD: bnToFloat(tvlUSDTotal30Days.div(30), UNISWAP_DAY_DATA_PRECISION),
        feesUSD: bnToFloat(feesUSDTotal30Days.div(30), UNISWAP_DAY_DATA_PRECISION),
      },
      90: {
        tvlUSD: bnToFloat(tvlUSDTotal90Days.div(90), UNISWAP_DAY_DATA_PRECISION),
        feesUSD: bnToFloat(feesUSDTotal90Days.div(90), UNISWAP_DAY_DATA_PRECISION),
      },
    },
  }
}

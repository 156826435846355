import { useEffect, useState } from 'react'
import { Web3Provider } from '@ethersproject/providers'
import { Block } from '@ethersproject/abstract-provider'

import { useChainStateReducer } from '../ChainStateProvider'
import { useWeb3ReactPlus } from '../Web3ReactPlusProvider'

export function useBlock() {
  const [chainState] = useChainStateReducer()
  const { chainId, provider } = useWeb3ReactPlus()
  const [block, setBlock] = useState<Block>()

  const blockNumber = chainState?.[chainId ?? 0]?.blockNumber || 0
  useEffect(() => {
    getBlock({ web3Provider: provider, blockNumber, setBlock })
  }, [blockNumber])

  return block
}

async function getBlock({
  blockNumber,
  web3Provider,
  setBlock,
}: {
  blockNumber: number
  setBlock: (block: Block) => void
  web3Provider?: Web3Provider
}) {
  if (!web3Provider) return
  const block = await web3Provider.getBlock(blockNumber)
  setBlock(block)
}

import { useState } from 'react'
import styled from 'styled-components'

import ExitIcon from '../assets/images/exit-icon.svg'
import { StylingProps, CSS_TRANSITION, SAFFRON_RED_HEX } from '../styles'

interface Props extends StylingProps {
  title: string
  subTitle?: string
  buttonText?: string
  buttonOnClick?: () => void
  icon?: string
}

export function Announcement({
  title,
  subTitle,
  buttonText,
  buttonOnClick,
  icon,
  className,
}: Props) {
  const [isOpen, setIsOpen] = useState(true)

  return (
    <Header className={className}>
      <Container isOpen={isOpen}>
        <Card>
          <InnerContainer>
            <Left>
              <Title>{title}</Title>
              {subTitle && <SubTitle>{subTitle}</SubTitle>}
              {buttonText && <Button onClick={buttonOnClick}>{buttonText}</Button>}
            </Left>
            {icon && <Icon src={icon} />}
            <X onClick={() => setIsOpen(!isOpen)} src={ExitIcon} />
          </InnerContainer>
        </Card>
      </Container>
    </Header>
  )
}

const Header = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
`

const Container = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0;
`

const Card = styled.div`
  ${CSS_TRANSITION}
  padding: 1rem;
  background-color: ${(props) => props.theme.colors.backgrounds.accent};
  border: 4px solid ${(props) => props.theme.colors.borders.accent};
  border-radius: 10px;
`
const InnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
`

const Left = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 84px;
`

const Title = styled.h3`
  font-size: 24px;
  color: ${(props) => props.theme.colors.text.primary};
  font-weight: 500;
  margin: 0;
`

const SubTitle = styled.p`
  color: #757784;
  font-size: 14px;
  font-weight: 400;
`

const Button = styled.button`
  padding: 10px;
  width: 100px;
  background-color: ${SAFFRON_RED_HEX};
  border: none;
  border-radius: 5px;
  font-family: 'Lexend', sans-serif;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
`

const Icon = styled.img`
  width: 97px;
  height: auto;
  margin-top: auto;
  margin-bottom: auto;
  padding-right: 15px;
`

const X = styled.img`
  width: 15px;
  height: 15px;
  margin-bottom: auto;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`

import _MultiSelect from 'multiselect-react-dropdown'
import styled, { useTheme } from 'styled-components'

import { ReactComponent as ArrowIcon } from '../../assets/images/down-arrow.svg'
import { CSS_TRANSITION, StylingProps } from '../../styles'

export interface Option {
  value: string
  id: string
}

interface Props extends StylingProps {
  options: Option[]
  selectedOptions: Option[]
  placeholder: string
  onSelect(selectedList: Option[], selectedItem: Option): void
  onRemove(selectedList: Option[], removedItem: Option): void
}

export function MultiSelect({
  options,
  selectedOptions,
  placeholder,
  onSelect,
  onRemove,
  className,
}: Props) {
  const theme = useTheme()

  return (
    <Wrapper
      className={className}
      options={options} // Options to display in the dropdown
      selectedValues={selectedOptions} // Preselected value to persist in dropdown
      onSelect={onSelect} // Function will trigger on select event
      onRemove={onRemove} // Function will trigger on remove event
      displayValue='value' // Property name to display in the dropdown options
      placeholder={placeholder}
      showCheckbox
      showArrow
      customArrow={
        <ArrowIcon stroke={theme.colors.icons} fill={theme.colors.backgrounds.secondary} />
      }
    />
  )
}

const Wrapper = styled(_MultiSelect)`
  .chip {
    display: none;
  }

  .multiselect-container {
    border: none;
    border-radius: 8px;
  }

  .icon_down_dir {
    &::before {
      display: none;
    }
  }

  .searchBox {
    margin-top: 6px;
    margin-left: -10px;
    caret-color: transparent;
    cursor: pointer;
    &::placeholder {
      height: 45px;
      padding: 20px;
      color: ${(props) => props.theme.colors.text.primary};
      font-family: 'Lexend', sans-serif;
      font-size: 14px;
      font-weight: 400;
    }
  }

  /* firefox */
  @supports (-moz-appearance: none) {
    .searchBox {
      margin-left: 6px;
      &::placeholder {
        color: ${(props) => props.theme.colors.text.primary};
        opacity: 1;
      }
    }
  }

  .search-wrapper {
    ${CSS_TRANSITION}
    background-color: ${(props) => props.theme.colors.backgrounds.secondary};
    border-radius: 8px;
    border: none;
    height: 45px;
    color: white;
    width: 150px;

    /* safari */
    @media not all and (min-resolution: 0.001dpcm) {
      @media {
        padding-top: 20px;
      }
    }
  }

  .optionContainer {
    border: none;
    border-radius: 8px;
    background-color: ${(props) => props.theme.colors.backgrounds.secondary};
    color: ${(props) => props.theme.colors.text.primary};
    font-size: 15px;
    font-family: 'Lexend', sans-serif;
  }

  .optionListContainer {
    background: none;
    width: 150px;
  }

  .icon_down_dir {
    right: 12px;
  }

  .multiSelectContainer li {
    padding-left: 20px;
  }
`

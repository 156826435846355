import styled, { useTheme } from 'styled-components'
import React from 'react'

import { ReactComponent as InfoIcon } from '../assets/images/info-circle.svg'
import { Tooltip, TOOLTIP_POSITION } from './Tooltip'
import { StylingProps } from '../styles'

interface Props extends StylingProps {
  content: React.ReactNode
  width?: number
  height?: number
  iconColor?: string
  position?: TOOLTIP_POSITION
}

export function InfoTooltip({
  content,
  position,
  width = 15,
  height = 15,
  iconColor,
  className,
}: Props) {
  const theme = useTheme()

  return (
    <Tooltip position={position} content={content} className={className}>
      <InfoIconWrapper
        width={width}
        height={height}
        fill={iconColor ? iconColor : theme.colors.icons}
      />
    </Tooltip>
  )
}

const InfoIconWrapper = styled(InfoIcon)`
  margin-left: 4px;
`

import { Web3Provider } from '@ethersproject/providers'

import { Vault__factory } from '@packages/contract-types'

import { Vault } from '../interfaces'

export async function claimFixedTokens({
  web3Provider,
  vault,
}: {
  vault: Vault
  web3Provider: Web3Provider
}) {
  const vaultContract = Vault__factory.connect(vault.address, web3Provider.getSigner())

  return await vaultContract.claim()
}

import { useQuery } from '@tanstack/react-query'

import { COIN_GECKO } from '../constants'
import { CoinGeckoToken } from '../interfaces'
import tokenList from '../assets/files/coin-gecko-tokens.json'
import { AsyncData } from '../../AppState'

export function useCoinGeckoTokens(): AsyncData<CoinGeckoToken[]> {
  return useQuery(
    ['coinGeckoTokenList'],
    async () => {
      let list = []
      try {
        list = await getTokensFromCoinGecko()
      } catch (error) {
        console.info('Error getting CoinGecko token list. Using backup list', error)
        list = tokenList as CoinGeckoToken[]
      }

      return list
    },
    {
      initialData: () => {
        return tokenList
      },
    }
  )
}

export async function getTokensFromCoinGecko(): Promise<CoinGeckoToken[]> {
  const controller = new AbortController()
  const timeout = setTimeout(() => {
    controller.abort()
  }, 5000)
  const url = COIN_GECKO.API.HOST + COIN_GECKO.API.COIN_LIST_ENDPOINT
  let result: CoinGeckoToken[]
  try {
    const response = await fetch(url, { signal: controller.signal })
    result = await response.json()
  } catch (error: any) {
    console.info(error)
    throw new Error('Error while retrieving CoinGecko token list', error)
  }
  clearInterval(timeout)
  return result
}

import { createContext, ReactNode, useContext } from 'react'
import invariant from 'tiny-invariant'
import { Web3ReactProvider, useWeb3React } from '@web3-react/core'
import { Web3Provider, ExternalProvider, JsonRpcFetchFunc } from '@ethersproject/providers'
import { Web3ReactContextInterface } from '@web3-react/core/dist/types'
import { AbstractConnector } from '@web3-react/abstract-connector'
import { InjectedConnector } from '@web3-react/injected-connector'

import { DEFAULT_CHAIN_ID } from './config'
import { ChainStateProvider } from './ChainStateProvider'
import {
  useActivatingConnector,
  useBlockNumberUpdates,
  useEagerConnect,
  useInactiveListener,
} from './hooksPrivate'
import { networkConnector } from './connectors'
import { useLocalStorage } from './hooks'
import { IS_RETURNING_USER_KEY } from './constants'

function getLibrary(provider: ExternalProvider | JsonRpcFetchFunc): Web3Provider {
  return new Web3Provider(provider)
}

interface IWeb3ReactPlusContext<T = any> extends Web3ReactContextInterface<T> {
  provider?: T
  activeAddress?: string | null
  chainId: number
  ready: boolean
  triedAutoconnectInjected?: boolean
  activatingConnector?: AbstractConnector
}

const Web3ReactPlusContext = createContext<IWeb3ReactPlusContext<Web3Provider>>({
  active: false,
  ready: false,
  chainId: DEFAULT_CHAIN_ID,
  activate: async () => {
    invariant(false, 'No <Web3ReactProviderPlus ... /> found.')
  },
  setError: () => {
    invariant(false, 'No <Web3ReactProviderPlus ... /> found.')
  },
  deactivate: () => {
    invariant(false, 'No <Web3ReactProviderPlus ... /> found.')
  },
})

interface Web3ReactPlusProviderProps {
  children: ReactNode
  defaultChainId: number
}

function _Web3ReactPlusProvider({ defaultChainId, children }: Web3ReactPlusProviderProps) {
  const {
    connector,
    library,
    chainId,
    account,
    activate: _activate,
    deactivate: _deactivate,
    active,
    error,
    setError,
  } = useWeb3React<Web3Provider>()
  const [isReturningUser, setIsReturningUser] = useLocalStorage(IS_RETURNING_USER_KEY, false)
  // handle logic to recognize the connector currently being activated
  const [activatingConnector, setActivatingConnector] = useActivatingConnector()

  async function activate(connector: AbstractConnector) {
    if (connector instanceof InjectedConnector && !isReturningUser) {
      // Runs only when they are brand new, or have hit the disconnect button
      await (window as any).ethereum.request({
        method: 'wallet_requestPermissions',
        params: [
          {
            eth_accounts: {},
          },
        ],
      })
    }

    setActivatingConnector(connector)
    await _activate(connector)
    setIsReturningUser(true)
  }

  async function deactivate() {
    if (connector instanceof InjectedConnector) {
      setIsReturningUser(false)
    }
    await _deactivate()
  }

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect(isReturningUser)

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector)

  // Make sure block number is up-to-date
  useBlockNumberUpdates()

  const ready = !!library && !!chainId && (!!activatingConnector || !!account)

  if (!connector && triedEager) {
    _activate(networkConnector)
  }

  const value: IWeb3ReactPlusContext<Web3Provider> = {
    connector,
    library,
    provider: library,
    chainId: chainId ?? defaultChainId,
    account,
    activeAddress: account,
    activate,
    deactivate,
    active,
    ready,
    error,
    setError,
    triedAutoconnectInjected: triedEager,
    activatingConnector,
  }

  return <Web3ReactPlusContext.Provider value={value}>{children}</Web3ReactPlusContext.Provider>
}

export function Web3ReactPlusProvider(props: Web3ReactPlusProviderProps) {
  const { children } = props
  return (
    <ChainStateProvider>
      <Web3ReactProvider getLibrary={getLibrary}>
        <_Web3ReactPlusProvider defaultChainId={props.defaultChainId}>
          {children}
        </_Web3ReactPlusProvider>
      </Web3ReactProvider>
    </ChainStateProvider>
  )
}

export function useWeb3ReactPlus() {
  return useContext(Web3ReactPlusContext)
}

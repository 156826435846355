import styled from 'styled-components'

import { StylingProps } from '../../styles'
import { SmallButton } from './SmallButton'

interface Props extends StylingProps {
  percents?: number[]
  disabled?: boolean
  abbreviatePercents?: boolean
  onButtonClick(percent: number): void
}

function formatPercent(percent: number, abbreviate = false) {
  if (percent === 1) {
    return 'Max'
  }

  if (abbreviate) {
    return percent + 'x'
  }
  return percent * 100 + '%'
}

export function PercentButtons({
  percents = [0.25, 0.5, 0.75, 1],
  disabled = false,
  abbreviatePercents = false,
  onButtonClick,
  className,
}: Props) {
  const percentButtons = percents.map((percent) => (
    <SmallButton
      key={`small-button-${percent}`}
      onClick={() => onButtonClick(percent)}
      disabled={disabled}
    >
      {formatPercent(percent, abbreviatePercents)}
    </SmallButton>
  ))
  return <Container className={className}>{percentButtons}</Container>
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  button {
    color: ${(props) => props.theme.colors.text.primary};
  }
`

import { CHAIN_ID } from '@packages/web3-react-plus'

export type Platform = 'Uniswap v3' | 'Uniswap v2' | 'Sushiswap' | 'Mojitoswap' | 'SFI Staking' | ''

export const PlatformUrls: { [key in Platform]: string } = {
  'Uniswap v3': 'app.uniswap.org',
  'Uniswap v2': 'app.uniswap.org',
  Sushiswap: 'app.sushi.com',
  Mojitoswap: 'app.mojitoswap.finance',
  'SFI Staking': '',
  '': '',
}

export const DefaultPlatformPerChain: { [key in CHAIN_ID]: Platform } = {
  [CHAIN_ID.Ethereum]: 'Uniswap v3',
  [CHAIN_ID.Ropsten]: 'Uniswap v3',
  [CHAIN_ID.Rinkeby]: 'Uniswap v3',
  [CHAIN_ID.Goerli]: 'Uniswap v3',
  [CHAIN_ID.Kovan]: 'Uniswap v3',
  [CHAIN_ID.KCC]: 'Mojitoswap',
  [CHAIN_ID.Polygon]: 'Uniswap v3',
  [CHAIN_ID.Arbitrum]: 'Uniswap v3',
  [CHAIN_ID.ArbitrumGoerli]: 'Uniswap v3',
}

import styled, { css } from 'styled-components'
import { useState } from 'react'

import { formatDataTestId, mediaQuery } from '../../utils'
import { StylingProps } from '../../styles'
import { InfoTooltip } from '../'
import { GradientStyles } from '../GradientStyles'

export interface InfoBlockProps extends StylingProps {
  header: string
  value: string | React.ReactNode
  infoTooltip?: React.ReactNode
  activatedHeader?: string
  activatedValue?: string | React.ReactNode
  hideOnMediumWidth?: boolean
  gradient?: boolean
}

export function InfoBlock({
  header,
  value,
  infoTooltip,
  activatedHeader,
  activatedValue,
  hideOnMediumWidth = false,
  gradient = false,
  className,
}: InfoBlockProps) {
  const [active, setActive] = useState(false)

  function handleOnMouseEnter() {
    setActive(true)
  }

  function handleOnMouseLeave() {
    setActive(false)
  }

  const dataTestId = 'info-block-value' + formatDataTestId(header)

  return (
    <InfoColumn
      hideOnMediumWidth={hideOnMediumWidth}
      className={className}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      <InfoContainer>
        <ColumnHeader>
          {active && activatedHeader ? activatedHeader : header}{' '}
          {infoTooltip && <InfoTooltip content={infoTooltip} />}
        </ColumnHeader>
        {gradient ? (
          <GradientColumnValue data-test-id={dataTestId}>
            {active && activatedValue ? activatedValue : value}
          </GradientColumnValue>
        ) : (
          <ColumnValue data-test-id={dataTestId}>
            {active && activatedValue ? activatedValue : value}
          </ColumnValue>
        )}
      </InfoContainer>
    </InfoColumn>
  )
}

const InfoColumn = styled.div<{ hideOnMediumWidth?: boolean }>`
  width: 13%;
  ${({ hideOnMediumWidth }) =>
    hideOnMediumWidth
      ? css`
          ${mediaQuery('medium')} {
            display: none;
          }
        `
      : css`
          ${mediaQuery('medium')} {
            width: 20%;
          }
        `}
  ${mediaQuery('small')} {
    display: none;
  }
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 11px;
`

export const ColumnHeader = styled.span`
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => props.theme.colors.text.secondary};
`

export const ColumnValue = styled.span`
  font-weight: 500;
  font-size: 17px;
  color: ${(props) => props.theme.colors.text.primary};
`

const GradientColumnValue = styled.span`
  font-weight: 600;
  font-size: 18px;
  text-align: left;
  ${GradientStyles}
`

import styled from 'styled-components'
import _CurrencyInput from 'react-currency-input-field'

import { InputStyles } from './TextInput'
import { StylingProps } from '../../styles'

interface Props extends StylingProps {
  value?: number
  onChange: (value: number) => void
  placeholder?: string
  precision?: number
  allowDecimals?: boolean
}

export function UsdInput({
  value,
  onChange,
  placeholder = 'Please enter a number',
  precision = 2,
  allowDecimals = false,
  className,
}: Props) {
  function onValueChange(val: string | undefined) {
    onChange(parseFloat(val ?? '0'))
  }

  return (
    <CurrencyInputWrapper
      className={className}
      allowDecimals={allowDecimals}
      placeholder={placeholder}
      decimalsLimit={precision}
      value={value ?? ''}
      onValueChange={onValueChange}
      prefix='$'
      data-test-id='usd-input'
    />
  )
}

const CurrencyInputWrapper = styled(_CurrencyInput)`
  ${InputStyles}
  background-color: ${(props) => props.theme.colors.backgrounds.tertiary};
`

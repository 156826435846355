import styled from 'styled-components'
import { Link as ReactRouterLink, NavLink } from 'react-router-dom'

import { ReactComponent as AcademyIcon } from '@packages/ui/src/shared/assets/images/icons/academy.svg'
import { ReactComponent as GovernmentIcon } from '@packages/ui/src/shared/assets/images/icons/government-icon.svg'
import Logo, {
  ReactComponent as SfiLogo,
} from '@packages/ui/src/shared/assets/images/nav-bar/logo.svg'
// eslint-disable-next-line import/order
import {
  MenuIconDiv,
  MenuIconWrapper,
  KebabItem,
  KebabText,
  mediaQuery,
  NavBarItem,
  ThemeType,
  CSS_TRANSITION,
  Chains,
  Accounts,
  SharedAppState,
  A,
  Kebab,
} from '@packages/ui'

import '@szhsin/react-menu/dist/transitions/slide.css'
import '@szhsin/react-menu/dist/index.css'

import { ReactComponent as MenuIcon } from '@packages/ui/src/shared/assets/images/nav-bar/menu.svg'

import { SUPPORTED_CHAIN_IDS, SUPPORTED_TESTNET_CHAIN_IDS } from 'src/config'

import { Routes } from '../../constants'
import { Menu } from './Menu'
import { ThemeToggle } from './ThemeToggle'

export function NavBar() {
  const [{ theme }, dispatch] = SharedAppState.useAppStateReducer()
  const iconFillColor = theme.colors.icons

  function handleThemeChange() {
    let newTheme: ThemeType = 'light'
    switch (theme.type) {
      case 'light':
        newTheme = 'dark'
        break
      case 'dark':
        newTheme = 'light'
        break
      default:
        break
    }

    dispatch({
      type: SharedAppState.AppStateActionName.UpdateTheme,
      payload: newTheme,
    })
  }

  const MobileNavbar = (
    <FixedNavbar>
      <Accounts.AccountMenu />
      <RightMobileNavContainer>
        <ThemeToggle onChange={handleThemeChange} />
        <NavBarItem>
          <Chains.ChainSelectorMenu
            supportedChainIds={SUPPORTED_CHAIN_IDS}
            supportTestnetChainIds={SUPPORTED_TESTNET_CHAIN_IDS}
          />
        </NavBarItem>
        <NavBarItem>
          <Menu isMobile />
        </NavBarItem>
      </RightMobileNavContainer>
    </FixedNavbar>
  )

  const externalLinks = [
    {
      url: 'https://app.saffron.finance/',
      title: 'Staking',
      icon: <SfiLogo width={19} height={15} fill={iconFillColor} />,
    },
    {
      url: 'https://academy.saffron.finance/',
      title: 'Academy',
      icon: <AcademyIcon width={19} fill={iconFillColor} />,
    },
    {
      url: 'https://gov.saffron.finance/',
      title: 'Governance',
      icon: <GovernmentIcon width={19} height={16} fill={iconFillColor} />,
    },
  ]

  return (
    <Header>
      <Container>
        {MobileNavbar}
        <LinkWrapper to={Routes.VAULTS}>
          <HeaderLogo src={Logo} />
        </LinkWrapper>
        <SubContainer>
          <Links>
            <Link to={Routes.VAULTS} end>
              Vaults
            </Link>
            <ExternalLinksContainer>
              {externalLinks.map((link) => {
                return (
                  <ExternalLink href={link.url} key={link.title}>
                    {link.title}
                  </ExternalLink>
                )
              })}
            </ExternalLinksContainer>
          </Links>
          <MobileLinks>
            <Kebab
              menuButton={
                <MenuIconWrapper>
                  <MenuIconDiv>
                    <MenuIcon fill={theme.colors.text.secondary} />
                  </MenuIconDiv>
                </MenuIconWrapper>
              }
              transition
              direction='bottom'
              align='end'
              position='anchor'
              arrow
              offsetY={-3}
              offsetX={10}
            >
              {externalLinks.map((link) => {
                return (
                  <KebabItem href={link.url} target='_blank' key={link.title}>
                    <KebabText>{link.title}</KebabText>
                    {link.icon}
                  </KebabItem>
                )
              })}
            </Kebab>
          </MobileLinks>
          <RightNavContainer>
            <ThemeToggle onChange={handleThemeChange} />
            <Accounts.AccountMenu />
            <NavBarItem>
              <Chains.ChainSelectorMenu
                supportedChainIds={SUPPORTED_CHAIN_IDS}
                supportTestnetChainIds={SUPPORTED_TESTNET_CHAIN_IDS}
              />
            </NavBarItem>
            <NavBarItem>
              <Menu />
            </NavBarItem>
          </RightNavContainer>
        </SubContainer>
      </Container>
    </Header>
  )
}

const Header = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
`

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 35px 0;
  ${mediaQuery('small')} {
    padding: 5px 0;
  }
`

const HeaderLogo = styled.img`
  margin-right: 15px;
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 30px;
`

const Links = styled.div`
  display: flex;
`

const LinkWrapper = styled(ReactRouterLink)`
  cursor: pointer;
`

const MobileLinks = styled.div`
  display: none;
  ${mediaQuery('small')} {
    display: block;
  }
`

const Link = styled(NavLink)`
  text-decoration: none;
  color: ${(props) => props.theme.colors.text.secondary};
  font-weight: 500;
  margin-right: 25px;
  cursor: pointer;
  ${CSS_TRANSITION}
  &:hover {
    color: ${(props) => props.theme.colors.text.primary};
  }
  &.active {
    background-color: ${(props) => props.theme.colors.backgrounds.secondary};
    padding: 7px 13px 7px 13px;
    border-radius: 10px;
    color: ${(props) => props.theme.colors.text.primary};
  }
`

const ExternalLink = styled(A)`
  text-decoration: none;
  color: ${(props) => props.theme.colors.text.secondary};
  font-weight: 500;
  font-size: 16px;
  margin-right: 25px;
  cursor: pointer;
  ${CSS_TRANSITION}
  &:hover {
    color: ${(props) => props.theme.colors.text.primary};
  }
`

const ExternalLinksContainer = styled.div`
  display: flex;
  align-items: center;
  ${mediaQuery('small')} {
    display: none;
  }
`

const SubContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 34px;
`

const RightNavContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  align-items: center;
  gap: 25px;

  .szh-menu-container {
    // take element out of flex flow
    position: absolute;
  }
  ${mediaQuery('small')} {
    display: none;
  }
`

const RightMobileNavContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
`

export const AddressWrapper = styled.div`
  cursor: default;
  user-select: none;
  height: 32px;
  border-radius: 10px;
  background-color: white;
  padding: 8px 13px;
  display: flex;
  align-items: flex-start;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-right: 14px;
`

const FixedNavbar = styled.div`
  position: fixed;
  display: none;
  bottom: 0;
  width: 100%;
  height: 70px;
  background-color: ${(props) => props.theme.colors.backgrounds.primary};
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  z-index: 100;

  ${mediaQuery('small')} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;

    .szh-menu-container {
      // take element out of flex flow
      position: absolute;
    }
  }
`

import { BigNumber } from 'ethers'

import { bn } from '@packages/bn'

import { isNumeric, trimDecimalStringToPrecision } from '../../utils'

export function validateFloat({
  value,
  setLocalValue,
  setParentValue,
  precision = 18,
  clearErrors = () => {},
}: {
  value: string
  setLocalValue: (value: string) => void
  setParentValue: (value: null) => void
  precision?: number
  clearErrors?: () => void
}) {
  let updatedValue: string = value

  // More than one zero in a row as the while input should be treated as a single zero
  if (updatedValue !== '' && [...updatedValue].every((c) => c === '0')) {
    updatedValue = '0'
  }

  // Leading zero must be followed by a decimal
  if (updatedValue.length >= 2 && updatedValue[0] === '0' && updatedValue[1] !== '.') {
    updatedValue = updatedValue.split('0').slice(-1)[0]
  }

  // No negative numbers
  if (updatedValue[0] === '-') return false

  // No scientific notation
  if (updatedValue.indexOf('e') !== -1) return false

  // Ignore decimal places more than precision
  if (updatedValue.indexOf('.') !== -1 && updatedValue.split('.')[1].length > precision) {
    updatedValue = trimDecimalStringToPrecision(updatedValue, precision) as string
  }

  // No leading zero
  if (updatedValue === '.') {
    setParentValue(null)
    return false
  }

  clearErrors()

  // Allow user to 1. clear the input and 2. have zero, while submit is disabled
  if (updatedValue === '') {
    setLocalValue(updatedValue)
    setParentValue(null)
    return false
  }

  // Must be numeric (final sanity check)
  if (!isNumeric(updatedValue)) return false

  setLocalValue(updatedValue.trim())

  return updatedValue
}

export function validateBigNumber({
  value,
  setLocalValue,
  setParentValue,
  precision,
  clearErrors,
}: {
  value: string
  setLocalValue: (value: string) => void
  setParentValue: (value: BigNumber | null) => void
  precision?: number
  clearErrors?: () => void
}) {
  const updatedValue = validateFloat({
    value,
    setLocalValue,
    setParentValue,
    precision,
    clearErrors,
  })

  if (!updatedValue) return false

  let inputAmountBn
  try {
    const trimmedDecimalValue =
      updatedValue[updatedValue.length - 1] === '.'
        ? updatedValue.slice(0, updatedValue.length - 1)
        : updatedValue
    inputAmountBn = bn(trimmedDecimalValue, precision)
  } catch (e) {
    console.error(e)
    setParentValue(null)
    return
  }

  setParentValue(inputAmountBn)

  return inputAmountBn
}
